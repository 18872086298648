<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.builds" class="cp-admin-nav-item me-1" size="sm"></fa-icon>
				<a routerLink="..">Builds</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page" [title]="product.name + ' - ' + build.version">
				{{ product.name + ' - ' + build.version}}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="showDownloadPageLink" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + ac.urls.mybuilds, build.id]" ngbTooltip="View the build in the same way a non-staff user sees it."
					placement="bottom">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					User View
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canSendNotifications && build.is_enabled === 1" type="button" class="btn btn-outline-primary"
					[routerLink]="['notify']" ngbTooltip="Notify" placement="bottom-end">
					<fa-icon [icon]="ac.sectionIcons.mynotifications" size="sm"></fa-icon>
				</button>

				<button *ngIf="canEdit && build.is_enabled === 1" type="button" class="btn btn-outline-warning" (click)="disable()"
					ngbTooltip="Disabling a build will hide it from download lists" placement="bottom-end">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canEdit && build.is_enabled === 0" type="button" class="btn btn-outline-success" (click)="enable()"
					ngbTooltip="Enable" placement="bottom-end">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button *ngIf="canDelete" type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete"
					placement="bottom-end">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li *ngIf="zenCustomers && zenCustomers.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-zen-tab"
				aria-controls="nav-zen" data-bs-target="#nav-zen">
				ZEN Master
				<span class="cp-tab-badge">
					{{ zenCustomers.length | number }}
				</span>
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-download-log-tab" aria-controls="nav-download-log" data-bs-target="#nav-download-log">
				<fa-icon [icon]="ac.sectionIcons.downloadlogs" size="sm"></fa-icon>
				<!-- Downloads -->
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-journal-tab"
				aria-controls="nav-journal" data-bs-target="#nav-journal">
				<fa-icon [icon]="ac.sectionIcons.journals" size="sm"></fa-icon>
				<!-- Journal -->
				<span *ngIf="journals && journals.length > 0" class="cp-tab-badge ms-1">
					{{ journals.length | number }}
				</span>
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">

			<div *ngIf="!loading && (build.is_enabled === 0) && numFilesWithFreeLinks !== 0" class="cp-warning-message">
				<fa-icon [icon]="['fas', 'exclamation-triangle']" size="sm"></fa-icon>
				This Build has been disabled and has at least one file that's also available via a "Free" token.
				Should the "Free" file token be moved to a different file?
			</div>

			<div *ngIf="!loading && (build.is_enabled === 1 && build.is_private === 1) && numFilesWithFreeLinks !== 0"
				class="cp-warning-message">
				<fa-icon [icon]="['fas', 'exclamation-triangle']" size="sm"></fa-icon>
				This Build has been marked as Private and has at least one file that's also available via a "Free"
				token.
				Should the "Free" file token be moved to a different file?
			</div>

			<div *ngIf="build && build.is_retired === 1" class="cp-info-message">
				<fa-icon [icon]="['fas', 'snooze']" size="sm"></fa-icon>
				Retired/Archived : The installer files associated with this Build can no longer be downloaded
				without direct access.
			</div>

			<div *ngIf="!!build" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span *ngIf="build.is_enabled === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled - downloads are available
					</span>
					<span *ngIf="build.is_enabled === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Inactive - downloads are NOT available
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Visibility:</span>
					<span *ngIf="build.is_private === 1" class="badge bg-warning">
						<fa-icon [icon]="['fas', 'lock']"></fa-icon>This is a private/limited Build
					</span>
					<span *ngIf="build.is_private === 0" class="badge bg-success">
						<fa-icon [icon]="['fas', 'globe']"></fa-icon>This Build is generally available
						to Organizations with access to the Product.
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Product:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + ac.urls.products, product.id]">{{ product.name }}</a>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Version:</span>
					<span class="cp-property-value">{{ build.version }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!build.label && build.label !== ''">
					<span class="cp-property-label">Label:</span>
					<span class="cp-property-value">{{ build.label }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!build.information && build.information !== ''">
					<span class="cp-property-label">Information:</span>
					<span class="cp-property-value cp-pre-text">{{ build.information }}</span>
				</div>

				<div class="cp-property-block" *ngIf="build.last_download">
					<span class="cp-property-label">Last Download:</span>
					<span class="cp-property-value">
						{{ build.last_download | date: ac.pageDateTimeFmt }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ build.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="addedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="build.added_on !== build.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ build.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="editedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="!!organizations && organizations.length !== 0">
					<span class="cp-property-label">Organizations with Direct Access:</span>
					<ul class="mb-0">
						<li *ngFor="let org of organizations" class="cp-property-value">
							<fa-icon *ngIf="org.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Organization is not enabled"></fa-icon>
							<a [routerLink]="['/' + ac.urls.organizations, org.id]" [ngbPopover]="organizationPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ org.name }}</a>
							<ng-template #organizationPopupContent>
								<app-card-organization [object]="org"></app-card-organization>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!!groups && groups.length !== 0">
					<span class="cp-property-label">Organization Groups with Direct Access:</span>
					<ul class="mb-0">
						<li *ngFor="let group of groups" class="cp-property-value">
							<fa-icon *ngIf="group.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
							<a [routerLink]="['/' + ac.urls.organizationgroups, group.id]">{{group.name}}</a>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="!!packages && packages.length !== 0">
					<span class="cp-property-label">Packages with Direct Access:</span>
					<ul class="mb-0">
						<li *ngFor="let pkg of packages" class="cp-property-value">
							<fa-icon *ngIf="pkg.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Package is not enabled"></fa-icon>
							<a [routerLink]="['/' + ac.urls.packages, pkg.id]">{{pkg.name}}</a>
						</li>
					</ul>
				</div>
			</div>

			<div *ngIf="!!build.release_notes && build.release_notes !== ''" class="mb-2 cp-light-message">
				Release Notes:
				<div class="cp-inline-release-notes-short">
					{{ build.release_notes }}
				</div>
			</div>

			<div *ngIf="releaseNotesFile" class="mb-2">
				<fa-icon [icon]="['fas', 'file-download']"></fa-icon>
				Release Notes File:
				<a [routerLink]="['/' + ac.urls.files, build.release_notes_file_id]" [ngbPopover]="filePopupContent"
					popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
					{{ releaseNotesFile.name }}
				</a>
				({{ releaseNotesFile.size | bytes: "1.0-0" }})

				<ng-template #filePopupContent>
					<app-card-file [object]="releaseNotesFile"></app-card-file>
				</ng-template>
			</div>

			<div *ngIf="platforms && platforms.length !== 0" class="mb-2">
				Platform Files

				<table class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th class="border-start">File</th>
							<th class="border-start">Platform</th>
							<th class="order-left">Size</th>
							<th class="border-start">Last Download</th>
							<th class="border-start"># Downloads</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let platformFile of platformFiles">
							<td class="border-start">
								<a [routerLink]="['/' + ac.urls.files, platformFile.id]" [ngbPopover]="filePopupContent"
									popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
									{{ platformFile.name }}
								</a>
							</td>
							<td class="border-start">
								<fa-icon [icon]="mt.getPlatformIcon(platformFile['platform_name'])"></fa-icon>
								{{ platformFile['platform_name'] }}
							</td>
							<td class="border-start text-end">{{ platformFile.size | bytes: "1.0-0" }}</td>
							<td class="border-start">{{ platformFile.last_download | date: ac.tableDateTimeFmt
								}}</td>
							<td class="border-start text-end">{{ platformFile.downloads | number}}</td>
							<td class="border-start">
								<span *ngIf="isFreeFile(platformFile)">
									<fa-icon *ngIf="build.is_enabled === 0 || build.is_private === 1"
										[icon]="['fas', 'exclamation-triangle']" size="sm" class="text-danger">
									</fa-icon>
									Also a Free File
								</span>
							</td>
							<ng-template #filePopupContent>
								<app-card-file [object]="platformFile"></app-card-file>
							</ng-template>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- 
			<div *ngIf="platforms && platforms.length !== 0" class="mb-2">
				Platform Files:
				<div *ngFor="let pl of platforms">
					<fa-icon [icon]="mt.getPlatformIcon(pl.name)"></fa-icon>
					{{ pl.name }}:
					<a [routerLink]="['/' + ac.urls.files, getFileIDForPlatform(pl.id)]">
						{{ getFileName(getFileIDForPlatform(pl.id)) }}
					</a>
					({{ getFileSize(getFileIDForPlatform(pl.id)) | bytes: "1.0-0" }})

					<span *ngIf="isFreeFile(getFileIDForPlatform(pl.id))">
						- <fa-icon *ngIf="build.is_enabled === 0 || build.is_private === 1"
							[icon]="['fas', 'exclamation-triangle']" size="sm" class="text-danger">
						</fa-icon>
						Also a Free File
					</span>

					<span *ngIf="getFileLastDownload(getFileIDForPlatform(pl.id)) != null">
						(Last Download: {{ getFileLastDownload(getFileIDForPlatform(pl.id)) | date:
						ac.pageDateTimeFmt }})
					</span>

					<span *ngIf="getFileDownloads(getFileIDForPlatform(pl.id)) !== 0">
						(# Downloads: {{ getFileDownloads(getFileIDForPlatform(pl.id)) | number }})
					</span>
				</div>
			</div> -->

		</div>

		<div class="tab-pane" id="nav-download-log" role="tabpanel" aria-labelledby="nav-download-log-tab">

			<div *ngIf="!loading && downloadLogs.length === 0" class="cp-light-message">
				No download logs for files that are part of this Build.
			</div>

			<button *ngIf="!loading && downloadLogs.length !== 0" type="button" (click)="runDownloadsReport()"
				class="btn btn-sm btn-outline-secondary mb-1">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Generate Report
			</button>

			<app-downloads-table #downloadsTable1></app-downloads-table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">

			<div *ngIf="!loading && (!!build && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this Build.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

		<div class="tab-pane" id="nav-zen" role="tabpanel" aria-labelledby="nav-zen-tab">
			<div *ngIf="zenCustomers && zenCustomers.length !== 0" class="cp-light-message">
				ZEN Master Sites Using this Version For at least one Active Cloud Broadcaster Cluster
				<li *ngFor="let zc of zenCustomers" class='ms-4'>
					<a [routerLink]="['/' + ac.urls.zencustomers, zc.id]">{{ zc.name }} ({{ zc.dns_prefix
						}})</a>
				</li>
			</div>
		</div>

		<div class="tab-pane" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">
			<div *ngIf="!loading && (!journals || journals.length === 0)" class="cp-light-message">
				No journal entries for this Organization.
			</div>

			<button *ngIf="canAddJournals" type="button" class="btn btn-outline-primary btn-sm mb-2 me-1"
				[routerLink]="['/' + ac.urls.adminlogs, 'journal', 'build', id]">
				<fa-icon [icon]="ac.sectionIcons.journals"></fa-icon> Add Journal Entry
			</button>

			<app-journals-table #journalsTable1></app-journals-table>
		</div>

	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>