<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.organizationgroups" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a href="javascript:void(0)" routerLink="..">Organization Groups</a>
			</li>

			<li *ngIf="organizationGroup && !loading" class="breadcrumb-item">
				{{ organizationGroup.name }}
			</li>

			<li class="breadcrumb-item active" aria-current="page">
				Notify
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				Message
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<form *ngIf="!loading && theForm" [formGroup]="theForm" class="tab-form">

		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit mb-2">

					<div class="form-group">
						<label for="name">Subject*</label>
						<input type="text" id="subject" formControlName="subject" class="form-control" />
					</div>

					<div class="form-group">
						<label for="message">Message*</label>
						<textarea id="message" formControlName="message" class="form-control" rows="4"></textarea>
					</div>

					<div *ngIf="!loading && users.length === 0" class="cp-light-message">
						No users in the organization in this organization group.
					</div>
				</div>

				<table *ngIf="!loading  && users.length !== 0" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th class="border-start">
								<input type="checkbox" class="" id="select_all" formControlName="select_all" value="1"
									(change)="checkAllToggleEmails()" />
							</th>
							<th class="border-start">Name</th>
							<th class="border-start">Organization</th>
							<th class="border-start">E-Mail</th>
							<th class="border-start">Title</th>
							<th class="border-start">Last Login</th>
							<th class="border-start cp-icon-header">
								<fa-icon [icon]="ac.sectionIcons.mynotifications" size="sm" ngbTooltip="User Set-up Notifications"
									placement="bottom">
								</fa-icon>
							</th>
						</tr>
					</thead>
					<tbody *ngIf="users && users.length > 0">
						<tr *ngFor="let item of users">
							<td class="border-start">
								<input type="checkbox" class="" [id]="'user_' + item.id" [formControlName]="'user_' + item.id" value="1" />
							</td>
							<td class="text-nowrap border-start">
								<a [routerLink]="['/' + ac.urls.users, item.id]" [ngbPopover]="userPopupContent"
									popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
									{{ item.name }}</a>
							</td>
							<td class="border-start">{{ item.org_name }}</td>
							<td class="border-start">{{ item.email }}</td>
							<td class="border-start">{{ item.title }}</td>
							<td class="text-nowrap border-start" [attr.data-order]="tt.formatDateTimeUTC(item.last_login)">
								{{ item.last_login | date: ac.tableDateFmt }}
							</td>
							<td class="border-start cp-icon-cell">
								<fa-icon *ngIf="item.notifications == null" [icon]="['fas', 'exclamation-triangle']" size="sm"
									placement="end" class="text-danger" ngbTooltip="User has not setup notifications">
								</fa-icon>
								<fa-icon *ngIf="item.notifications != null" [icon]="['fas', 'check']" size="sm" placement="end"
									class="text-success" ngbTooltip="User has setup notifications">
								</fa-icon>
							</td>
							<ng-template #userPopupContent>
								<app-card-user [object]="item"></app-card-user>
							</ng-template>
						</tr>
					</tbody>
				</table>

				<!-- <div class="form-group mb-0" *ngIf="!saving">
					<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
						<fa-icon [icon]="ac.sectionIcons.mynotifications"></fa-icon> Send
					</button>
					<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
						<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
					</button>
				</div> -->
			</div>
		</div>
	</form>
</div>

<div class="cp-form-padding"></div>

<div *ngIf="!loading && theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="ac.sectionIcons.mynotifications"></fa-icon> Send
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>

		<span class="gj-75 ps-2">* Required</span>
	</div>
</div>