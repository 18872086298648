<div class="app-loading" *ngIf="!showForm">
	<div class="logo">
		<img width="200" alt="Zixi logo" src="assets/images/zixi-white.png" />
	</div>
	<div class="dots-loader"></div>
</div>

<div class="login-container" *ngIf="showForm">
	<div class="row">
		<div class="col text-center">
			<a target='_blank' href="https://www.zixi.com/"><img width="160" alt="Zixi logo" src="assets/images/zixi-white.png" /></a>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'width.px': 360 }">
				<div class="card-body">
					<form (ngSubmit)="onSubmit(f)" #f="ngForm">
						<!-- Credentials Login -->
						<div>
							<h3 class="text-center">{{ ac.appName }}</h3>
							<div *ngIf="isLoading" class="text-center">
								<app-loading-spinner></app-loading-spinner>
							</div>
							<div class="form-group">
								<label for="email">E-Mail</label>
								<input type="email" id="email" class="form-control" ngModel name="email" required email />
							</div>
							<div class="form-group">
								<label for="password">Password</label>
								<input type="password" id="password" class="form-control" ngModel name="password" required minlength="6" />
							</div>

							<div class="text-center mt-2">
								<button [disabled]="isLoading || !f.valid" type="submit" class="btn btn-primary">
									<fa-icon [icon]="['fas', 'sign-in-alt']"></fa-icon>
									Login
									<span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
										aria-hidden="true"></span>
								</button>
							</div>
							<div *ngIf="error" class="alert alert-danger mb-3">
								{{ error }}
							</div>

							<div class="text-center mt-2">
								<button *ngIf="ac.showSelfRegistration" [disabled]="isLoading" class="btn btn-outline-primary me-1"
									type="button" routerLink="/self-registration">
									<fa-icon [icon]="['fas', 'user']"></fa-icon>&nbsp;Create Account
								</button>
								<button [disabled]="isLoading" type="button" class="btn btn-outline-primary" routerLink="/forgot-password">
									<fa-icon [icon]="['fas', 'passport']"></fa-icon>&nbsp;Forgot your password?
								</button>
							</div>

							<div class="mb-0 small text-center mt-1">
								<a [href]="ac.supportURL" target='_blank'>
									<fa-icon [icon]="['fas', 'life-ring']"></fa-icon> Need Help?
								</a>
							</div>

							<!-- <div class="mb-0 small border-top pt-1 mt-1">
								If you setup an account with the previous MediaConnect registration site
								(<strong>www.mediaconnect.zixi.com</strong>), your account has been migrated to this new
								portal, but you need to create a new password.<br />
								Click on the <strong>Forgot your password?</strong> button to do so.
							</div>
 -->
							<div class="text-center border-top pt-1 mt-1">
								<button [disabled]="isLoading" class="btn btn-outline-primary" type="button" routerLink="/sso/auth">
									<fa-icon *ngIf="staffIcon" [icon]="staffIcon"></fa-icon>&nbsp;{{ ssoButtonLabel }}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>

			<p class="small"><span (click)="nextStaffLogin()">{{ getCurrentYear() }}</span> © Zixi LLC. All rights
				reserved.</p>
		</div>
	</div>
</div>