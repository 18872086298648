import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
// import ValidationTools from 'appshared/validation-tools';
// import SharedLicenseTools from 'appshared/shared-license-tools';
// import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';

@Component({
	selector: 'app-card-key',
	templateUrl: './card-key.component.html',
	styleUrls: ['./card-key.component.scss']
})
export class CardKeyComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.LPActivation = null;
	@Input() keyProducts: Models.LicenseProduct[] = [];
	@Input() organizations: Models.Organization[] = [];
	@Input() users: Models.User[] = [];
	@Input() forBasic: boolean = false;
	// @Input() protocolSets: Models.ProtocolSet[] = [];

	// <app-card-key [object]="key" [keyProducts]="keyProducts" [organizations]="organizations" [users]="users" [forBasic]="false"></app-card-key>

	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.LPActivation = null;

	// options


	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];


	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.LPActivation) {

		this.objectToShow = MiscTools.deepClone(object);

		if (!this.objectToShow) return;

		this.nameToShow = this.objectToShow.key;
		this.typeToShow = 'License Key';
		this.iconToShow = AppConstants.sectionIcons.licensing;

		this.infoLines = [];

		let keyWarnings: string[] = [];
		if (this.forBasic)
			keyWarnings = LicenseValidationTools.getKeyWarnings(this.objectToShow, 'user');
		else
			keyWarnings = LicenseValidationTools.getKeyWarnings(this.objectToShow, 'staff-all');

		if (keyWarnings.length > 0) {
			this.infoLines.push('Warnings:');
			for (const warning of keyWarnings)
				this.infoLines.push('-' + warning);
		} // if

		if (!this.forBasic) {
			let createdByName = '';
			if (this.objectToShow.zcp_created_by && +this.objectToShow.zcp_created_by > 0 && this.users && this.users.length > 0) {
				const idx = MiscTools.findIndex(this.users, +this.objectToShow.zcp_created_by);
				if (idx !== -1)
					createdByName = ' by ' + this.users[idx].name;
			} // if
			this.infoLines.push('Created: ' + TextTools.formatDateNiceUTC(this.objectToShow.created_at) + createdByName);

			if (this.objectToShow.zcp_updated_at != null && this.objectToShow.zcp_updated_at !== this.objectToShow.created_at) {
				let updatedByName = '';
				if (this.objectToShow.zcp_updated_by && +this.objectToShow.zcp_updated_by > 0 && this.users && this.users.length > 0) {
					const idx = MiscTools.findIndex(this.users, +this.objectToShow.zcp_updated_by);
					if (idx !== -1)
						updatedByName = ' by ' + this.users[idx].name;
				} // if
				this.infoLines.push('Edited: ' + TextTools.formatDateNiceUTC(this.objectToShow.zcp_updated_at) + updatedByName);
			} // if
		} // if

		const expiryMode = SharedLicenseTools.getExpiryMode(this.objectToShow);
		const keyExpiry = SharedLicenseTools.getKeyExpiration(this.objectToShow, '', true);

		if (!this.objectToShow.parsedParameters)
			this.objectToShow.parsedParameters = SharedLicenseTools.parseRubyHash(this.objectToShow.parameters);

		const enforcedMeters = !(this.objectToShow.parsedParameters.dont_enforce_meters && +this.objectToShow.parsedParameters.dont_enforce_meters === 1);

		if (expiryMode === 'meter')
			this.infoLines.push('Expiry: ' + TextTools.formatDateNiceUTC(keyExpiry) + ' - from meter(s)');
		else if (expiryMode === 'duration')
			this.infoLines.push('Duration: ' + SharedLicenseTools.niceKeyDuration(this.objectToShow.duration));
		else if (expiryMode === 'date')
			this.infoLines.push('Expiry: ' + TextTools.formatDateNiceUTC(keyExpiry));
		else if (expiryMode === 'never')
			this.infoLines.push('Expiry: never');

		// enabled

		if (!SharedLicenseTools.isSpecialKey(this.objectToShow)) {
			if (this.objectToShow.enabled === 1)
				this.infoLines.push('Enabled: Yes');
			else
				this.infoLines.push('Enabled: No');
		} // if

		if (!this.forBasic) {
			if (this.objectToShow.org_name)
				this.infoLines.push('Organization: ' + this.objectToShow.org_name);
			else
				this.infoLines.push('Organization: not linked to an organization');

			if (this.organizations && this.organizations.length > 0) {
				if (this.objectToShow.zcp_org_id && +this.objectToShow.zcp_org_id !== 0) {
					const idx = MiscTools.findIndex(this.organizations, +this.objectToShow.zcp_org_id);
					if (idx !== -1) {
						if (this.organizations[idx].salesforce_account_owner && this.organizations[idx].salesforce_account_owner !== '')
							this.infoLines.push('Account Owner: ' + this.organizations[idx].salesforce_account_owner);

						if (this.organizations[idx].salesforce_se && this.organizations[idx].salesforce_se !== '')
							this.infoLines.push('SE: ' + this.organizations[idx].salesforce_se);

						if (this.organizations[idx].salesforce_tam && this.organizations[idx].salesforce_tam !== '')
							this.infoLines.push('TAM: ' + this.organizations[idx].salesforce_tam);
					} // if
				} // if
			} else {
				if (this.objectToShow['__sales_engineer'])
					this.infoLines.push('SE: ' + this.objectToShow['__sales_engineer']);
				if (this.objectToShow['__tam'])
					this.infoLines.push('TAM: ' + this.objectToShow['__tam']);
			} // if

			this.infoLines.push(AppConstants.keyInfoFieldLabel + ': ' + this.objectToShow.info);

			if (this.objectToShow.ext_label && this.objectToShow.ext_label !== '')
				this.infoLines.push('Label (External): ' + this.objectToShow.ext_label);
		} else {
			if (this.objectToShow.ext_label && this.objectToShow.ext_label !== '')
				this.infoLines.push('Label: ' + this.objectToShow.ext_label);
		} // if

		let keyProduct: Models.LicenseProduct = null;
		if (this.keyProducts) {
			const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', this.objectToShow.product);
			if (idx !== -1) keyProduct = this.keyProducts[idx];
		} // if

		let niceProduct = TextTools.capitalizeFirstLetter(this.objectToShow.product);
		if (keyProduct)
			if (this.forBasic)
				niceProduct = keyProduct.basic_label;
			else
				niceProduct = keyProduct.label;
		else if (this.objectToShow['__niceProduct'])
			niceProduct = this.objectToShow['__niceProduct'];

		this.infoLines.push('Product: ' + niceProduct);

		this.infoLines.push('Type: ' + TextTools.capitalizeFirstLetter(this.objectToShow.type));
		this.infoLines.push('Activations: ' + TextTools.formatNumber(this.objectToShow.count) + ' of ' + TextTools.formatNumber(this.objectToShow.max));
		if (this.objectToShow.discard_licenses === 1)
			this.infoLines.push('Activated licenses for this key are deleted ' + AppConstants.maxStaleLicenseDays
				+ ' days after creation or last update.')

		if (this.objectToShow.billingCodes && this.objectToShow.billingCodes.length !== 0) {
			const listToShow: string[] = [];
			for (const bc of this.objectToShow.billingCodes)
				if (listToShow.length < 10)
					listToShow.push(bc.billing_code);
			this.infoLines.push('Billing Codes Reported: ' + listToShow.join(', ')
				+ (listToShow.length < this.objectToShow.billingCodes.length ? ' +' + (this.objectToShow.billingCodes.length - listToShow.length) + ' more' : ''));
		} // if

		if (!this.forBasic) {
			if (this.objectToShow.commercial_type && this.objectToShow.commercial_type !== '') {
				const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', this.objectToShow.commercial_type);
				if (cIdx !== -1)
					this.infoLines.push('Commercial Type: ' + (cIdx + 1) + '. ' + AppConstants.keyCommercialTypes[cIdx].short_label);
				else
					this.infoLines.push('Commercial Type: ' + this.objectToShow.commercial_type);
			} else {
				this.infoLines.push('Commercial Type: NOT SET');
			} // if

			if (this.objectToShow.commercial_info && this.objectToShow.commercial_info !== '') {
				this.infoLines.push('Commercial Notes:')
				const noteLines = this.objectToShow.commercial_info.trim().split('\n');
				const cleanNoteLines = [];
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						cleanNoteLines.push(noteLine);

				for (let i = 0; i < 8 && i < cleanNoteLines.length; i++)
					this.infoLines.push(cleanNoteLines[i]);
				if (cleanNoteLines.length > 8) this.infoLines.push('...');
				// this.infoLines.push(this.objectToShow.notes);
			} // if

			if (this.objectToShow.protocolSets && this.objectToShow.protocolSets.length !== 0) {
				this.infoLines.push('# Linked Protocol Sets: ' + this.objectToShow.protocolSets.length);
			} // if

			if (this.objectToShow.marketplace != null && this.objectToShow.marketplace.marketplace !== ''
				&& this.objectToShow.marketplace.accountIdentifier && this.objectToShow.marketplace.accountIdentifier !== ''
				&& this.objectToShow.marketplace.productIdentifiers && this.objectToShow.marketplace.productIdentifiers.length > 0) {

				let marketplaceProducts: string[] = [];
				let lastMarketplaceUsageReported: Date = null;
				const marketplaceAccountIdentifier = this.objectToShow.marketplace.accountIdentifier;
				let marketplaceLabel = this.objectToShow.marketplace.marketplace;
				let accountIdLabel = 'ID';
				let productSelections: any[] = [];
				const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', this.objectToShow.marketplace.marketplace);
				if (idx1 !== -1) {
					marketplaceLabel = AppConstants.marketPlaceSelections[idx1].label + ' (' + this.objectToShow.marketplace.marketplace + ')';
					productSelections = AppConstants.marketPlaceSelections[idx1].selections;
					accountIdLabel = AppConstants.marketPlaceSelections[idx1].accountLabel;
				} // if

				for (const prodCode of this.objectToShow.marketplace.productIdentifiers) {
					const idx = MiscTools.findIndexGeneric(productSelections, 'productCode', prodCode);
					if (idx === -1)
						marketplaceProducts.push('Unknown product code (' + prodCode + ')');
					else
						marketplaceProducts.push(productSelections[idx].label + '(' + prodCode + ')');
				} // for

				if (this.objectToShow.marketplace.latestReport != null)
					lastMarketplaceUsageReported = new Date(this.objectToShow.marketplace.latestReport);
				if (lastMarketplaceUsageReported != null && isNaN(lastMarketplaceUsageReported.getTime())) lastMarketplaceUsageReported = null;

				this.infoLines.push('Marketplace: ' + marketplaceLabel);
				this.infoLines.push(accountIdLabel + ': ' + marketplaceAccountIdentifier);
				this.infoLines.push('Marketplace Product(s): ' + marketplaceProducts.join(', '));
				if (lastMarketplaceUsageReported)
					this.infoLines.push('Marketplace Last Reported: ' + TextTools.formatDateNiceUTC(lastMarketplaceUsageReported));
			} // if
		} // if

		let showMeters = !this.forBasic;
		if (keyProduct && !showMeters && keyProduct.show_meters && keyProduct.show_meters === 2)
			showMeters = true;

		let showProtocols = !this.forBasic;
		// if (!showProtocols && keyProduct.show_protocols && keyProduct.show_protocols > 0
		// 	&& keyProduct.name.startsWith('broadcaster')
		// 	&& TextTools.getUserPropValue(this.authUser, AppConstants.showBxProtocolStatsOverride) === 'yes') {
		// 	this.protocolReady = true;
		// } // if

		if (showMeters && this.objectToShow.first_meter_report && this.objectToShow.last_meter_report)
			this.infoLines.push('Meter Reporting: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_meter_report) + ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_meter_report));

		if (showMeters && this.objectToShow.first_meter_usage && this.objectToShow.last_meter_usage)
			this.infoLines.push('Meter Usage: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_meter_usage) + ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_meter_usage));

		if (showProtocols && this.objectToShow.first_protocol_report && this.objectToShow.first_protocol_report)
			this.infoLines.push('Protocol Reporting: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_protocol_report) + ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_protocol_report));

		if (showProtocols && this.objectToShow.first_protocol_usage && this.objectToShow.last_protocol_usage)
			this.infoLines.push('Protocol Usage: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_protocol_usage) + ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_protocol_usage));

		// put info about meters...
		if (showMeters && this.objectToShow.meters && this.objectToShow.meters.length > 0) {
			if (enforcedMeters)
				this.infoLines.push('Meter limits are enforced.');
			else
				this.infoLines.push('Meter limits are NOT enforced.');

			const now = new Date();
			const meterSummaryInfo: any = SharedLicenseTools.buildMeterSummary(this.objectToShow.meters);
			let firstOne: boolean = true;
			for (const product of AppConstants.meterProducts) {
				if (meterSummaryInfo[product + '.never'] || meterSummaryInfo[product + '.monthly']) {
					if (firstOne) {
						this.infoLines.push('Summary of Active Meters');
						firstOne = false;
					} // if

					if (meterSummaryInfo[product + '.monthly'] && +meterSummaryInfo[product + '.monthly'].limit > 0) {
						let meterExtra: string = '';
						if (+meterSummaryInfo[product + '.monthly'].count === 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.monthly'].count + ' active meter)';
						else if (+meterSummaryInfo[product + '.monthly'].count > 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.monthly'].count + ' active meters)';
						this.infoLines.push('- ' + SharedLicenseTools.meterDescription(meterSummaryInfo[product + '.monthly'], true) + meterExtra);
					} // if

					if (meterSummaryInfo[product + '.never'] && +meterSummaryInfo[product + '.never'].limit > 0) {
						let meterExtra: string = '';
						if (+meterSummaryInfo[product + '.never'].count === 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.never'].count + ' active meter)';
						else if (+meterSummaryInfo[product + '.never'].count > 1)
							meterExtra = ' (' + +meterSummaryInfo[product + '.never'].count + ' active meters)';
						this.infoLines.push('- ' + SharedLicenseTools.meterDescription(meterSummaryInfo[product + '.never'], true) + meterExtra);
					} // if
				} // if
			} // for

			// const metersByProduct: any = {};
			// for (const meter of this.objectToShow.meters) {
			// 	if (!metersByProduct[meter.product]) metersByProduct[meter.product] = [];
			// 	metersByProduct[meter.product].push(meter);
			// } // for

			// const meterTypes: string[] = Object.keys(metersByProduct);
			// for (const product of meterTypes) {
			// 	this.infoLines.push('Meter Info - ' + SharedLicenseTools.niceProtocol(product, false) + ':');

			// 	const monthlyMeters: Models.LPMeter[] = [];
			// 	const neverMeters: Models.LPMeter[] = [];
			// 	for (const meter of metersByProduct[product]) {
			// 		if (meter.resets === 'monthly') monthlyMeters.push(meter);
			// 		if (meter.resets === 'never') neverMeters.push(meter);
			// 	} // for

			// 	let orderedMeters: Models.LPMeter[] = monthlyMeters;
			// 	orderedMeters = orderedMeters.concat(neverMeters);

			// 	for (const meter of orderedMeters) {
			// 		this.infoLines.push('- ' + SharedLicenseTools.meterDescription(meter, false));
			// 	} // for
			// } // for
		} // if

		if (!this.forBasic && this.objectToShow.notes && this.objectToShow.notes !== '') {
			this.infoLines.push('Notes:')
			const noteLines = this.objectToShow.notes.split('\n');
			const cleanNoteLines = [];
			for (const noteLine of noteLines)
				if (noteLine.trim() !== '')
					cleanNoteLines.push(noteLine);

			for (let i = 0; i < 8 && i < cleanNoteLines.length; i++)
				this.infoLines.push(cleanNoteLines[i]);
			if (cleanNoteLines.length > 8) this.infoLines.push('...');
		} // if

		if (!this.forBasic) {
			if (this.objectToShow.notes && this.objectToShow.notes !== '') {
				this.infoLines.push('Notes (Internal):')
				const noteLines = TextTools.chopString(this.objectToShow.notes, 255, '...').split('\n');
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						this.infoLines.push(noteLine);
			} // if
			if (this.objectToShow.ext_notes && this.objectToShow.ext_notes !== '') {
				this.infoLines.push('Notes (External):')
				const noteLines = TextTools.chopString(this.objectToShow.ext_notes, 255, '...').split('\n');
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						this.infoLines.push(noteLine);
			} // if
		} else {
			if (this.objectToShow.ext_notes && this.objectToShow.ext_notes !== '') {
				this.infoLines.push('Notee:')
				const noteLines = TextTools.chopString(this.objectToShow.ext_notes, 255, '...').split('\n');
				for (const noteLine of noteLines)
					if (noteLine.trim() !== '')
						this.infoLines.push(noteLine);
			} // if
		} // if


		// this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.zcp_created_by));
		// this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.created_at));

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
