import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { BundlesService } from '../bundles.service';
import { DownloadLogsService } from '../../download-logs/download-logs.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { MyFileSharingService } from '../../my-file-sharing/my-file-sharing.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { DownloadsTableComponent } from 'client/app/components/shared/downloads-table/downloads-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-bundle',
	templateUrl: './bundle.component.html',
	styleUrls: ['./bundle.component.scss']
})
export class BundleComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('downloadsTable1') downloadsTable1: DownloadsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;

	// 'standard' view stuff
	id: number;
	bundle: Models.Bundle;

	canEdit = false;
	canDelete = false;
	canAddFiles = false;

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	downloadLogs: Models.DownloadLog[] = [];
	adminLogs: Models.AdminLog[] = [];

	users: Models.User[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private mySettingsService: MySettingsService,
		private myFileSharingService: MyFileSharingService,
		private bundlesService: BundlesService,
		private usersService: UsersService,
		private downloadLogsService: DownloadLogsService,
		private adminLogsService: AdminLogsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
		});
	}

	ngOnInit(): void {
		// downloads
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;
				this.loadData();
			}
		});

	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData(forceRefresh = false) {
		this.loading = true;

		const user = await this.mySettingsService.getUser();

		this.bundle = await this.bundlesService.getOne(this.id);
		if (!this.bundle || this.bundle == null || this.bundle.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		}

		this.bundle.files.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

		const isLocked = MiscTools.daysSince(this.bundle.lock_at, false) > 0;
		let isSender = false;
		if (this.bundle.btype.endsWith('-send'))
			isSender = ((this.bundle.btype === 'staff-send' && this.bundle.staff_id === user.id)
				|| (this.bundle.btype === 'group-send' && user.group_ids.includes(this.bundle.staff_id)));
		else
			isSender = (this.bundle.org_id && this.bundle.org_id !== 0 && this.bundle.org_id === user.org_id)
				|| (this.bundle.user_ids.length !== 0 && this.bundle.user_ids.includes(user.id));

		// only the creator of the bundle can edit it and only in the first week
		this.canEdit = !isLocked && !this.bundle.deleted_at && (this.bundle.added_by === this.authUser.id)

		// files can be added by the sender(s) during the first week (!locked)
		this.canAddFiles = !isLocked && !this.bundle.deleted_at && isSender;

		// only the creator of the bundle can delete it and only in the first week
		// and only if it doesn't have files
		this.canDelete = !isLocked && (this.bundle.added_by === this.authUser.id) && this.bundle.files.length === 0;

		this.addedByUser = null;
		if (this.bundle.added_by && this.bundle.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.bundle.added_by);

		this.editedByUser = null;
		if (this.bundle.edited_by && this.bundle.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.bundle.edited_by);

		this.users = [];
		if (this.bundle.user_ids && this.bundle.user_ids.length > 0) {
			for (const userID of this.bundle.user_ids) {
				const thisUser = this.usersService.getOne(userID);
				if (thisUser)
					this.users.push(thisUser);
			} // for
		}

		this.downloadLogs = await this.downloadLogsService.getLogsForBundle(this.id);
		this.adminLogs = await this.adminLogsService.getLogs(['bundle'], this.id);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-bundle-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });
		if (this.downloadsTable1)
			this.downloadsTable1.updateContent(this.downloadLogs, 'cp-bundle-view-download-logs', { showUserInfo: true, linkUser: true });

	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete Bundle',
			'If you delete this Bundle, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Bundle', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.bundlesService.deleteOne(this.id);
		if (result) {
			this.uiAlertsService.addMsg('The bundle has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			await this.myFileSharingService.getUserBundles(true);
			this.router.navigate([AppConstants.urls.bundles]);
		} else {
			return false;
		}
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod

}
