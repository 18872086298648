<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.users" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="/users">Users</a>
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Groups</a></li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ group.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!group">
				<button type="button" class="btn btn-outline-secondary" (click)="openGroupReport()">
					<fa-icon icon="file-excel" size="sm"></fa-icon>
					<span class="cp-action-button-text">Report</span>
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canManageAccess" type="button" class="btn btn-outline-primary" [routerLink]="['access']" ngbTooltip="Access"
					placement="start">
					<fa-icon icon="user-lock" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Access</span> -->
				</button>

				<button *ngIf="canEdit && group.is_enabled === 1" type="button" class="btn btn-outline-warning" (click)="disable()"
					ngbTooltip="Disable" placement="start">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canEdit && group.is_enabled === 0" type="button" class="btn btn-outline-success" (click)="enable()"
					ngbTooltip="Enable" placement="start">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button *ngIf="canDelete" type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete"
					placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!group" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span *ngIf="group.is_enabled === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled -
						Users inherit access
					</span>
					<span *ngIf="group.is_enabled === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Disabled - Users don't inherit access

					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ group.name }}</span>
				</div>

				<div *ngIf="managers && managers.length > 0" class="cp-property-block">
					<span class="cp-property-label">Manager(s):</span>
					<ul class="mb-0">
						<li *ngFor="let user of managers" class="cp-property-value">
							<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="User is not enabled"></fa-icon>
							<a [routerLink]="['/' + ac.urls.users, user.id]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ user.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="user"></app-card-user>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">
						Members(s):
						<fa-icon *ngIf="emails && emails.length > 0" [icon]="['fas', 'copy']" ngxClipboard
							(click)="copyToClipboardAlert('')" [cbContent]="emails.join(';')"
							ngbTooltip="Copy E-Mail Addresses to Clipboard"></fa-icon>
					</span>

					<div class="row">
						<div *ngFor="let col of [1, 2, 3]">
							<div *ngIf="getColumnSubList(col, members).length > 0">
								<ul class="cp-property-value mb-0">
									<li *ngFor="let user of getColumnSubList(col, members)">
										<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
											ngbTooltip="User is not enabled"></fa-icon>
										<a [routerLink]="['/' + ac.urls.users, user.id]" [ngbPopover]="userPopupContent"
											popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
											{{ user.name }}</a>
										<ng-template #userPopupContent>
											<app-card-user [object]="user"></app-card-user>
										</ng-template>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<!-- 
					<ul class="mb-0">
						<li *ngFor="let user of members" class="cp-property-value">
							<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="User is not enabled"></fa-icon>
							<a [routerLink]="['/' + ac.urls.users, user.id]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ user.name }}</a>
							<ng-template #userPopupContent>
								<div class="cp-popover-banner">{{ user.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getUserPopoverLines(user).join('\n')}}</div>
							</ng-template>
						</li>
					</ul> -->
				</div>

				<div class="cp-property-block" *ngIf="!!group.information && group.information !== ''">
					<span class="cp-property-label">Information:</span>
					<span class="cp-property-value cp-pre-text">{{ group.information }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ group.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="addedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="group.added_on !== group.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ group.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="editedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="group.accessTags && group.accessTags.length !== 0">
					<span class="cp-property-label">
						Access:
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showAccessTags = !showAccessTags">
							<fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
							{{ (showAccessTags ? 'Hide' : 'Show') }} Access - {{ group.accessTags.length }}
						</button>
					</span>
					<span *ngIf="showAccessTags" class="cp-property-value">
						<div *ngFor="let grouping of getGroupings(assignedTagGroupings)" class='ms-4'>
							{{ grouping }}
							<ul class='mb-0'>
								<li *ngFor="let tag of assignedTagGroupings[grouping]">
									{{ acl.accessControlTags[tag] }}
								</li>
							</ul>
						</div>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="groupTemplates && groupTemplates.length !== 0">
					<span class="cp-property-label">
						License Key Templates:
						<button type="button" class="btn btn-outline-info btn-sm" (click)="showGroupTemplates = !showGroupTemplates">
							<fa-icon [icon]="ac.sectionIcons.licensingadmin"></fa-icon>
							{{ (showGroupTemplates ? 'Hide' : 'Show') }} Templates - {{ groupTemplates.length }}
						</button>
					</span>
					<span *ngIf="showGroupTemplates" class="cp-property-value">
						<div *ngFor="let template of groupTemplates" class='ms-4'>
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'templates', template.id]" [ngbPopover]="templatePopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ template.name }}
							</a>
							<ng-template #templatePopupContent>
								<app-card-key-template [object]="template"></app-card-key-template>
								<!-- <div class="cp-popover-banner">{{ template.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyTemplatePopoverLines(template).join('\n')}}</div> -->
							</ng-template>
						</div>
					</span>
				</div>

			</div>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && (!!group && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this Group.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>