import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import AppConstants from 'appshared/app-constants';
// import * as Models from 'appshared/models/shared';
import { RoleGuard } from 'client/app/guards/role.guard';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { OrganizationFormComponent } from './organization-form/organization-form.component';
import { ImportSalesForceUsersComponent } from './import-sales-force-users/import-sales-force-users.component';
import { LinkKeysFormComponent } from './link-keys-form/link-keys-form.component';
import { ImportZenMasterUsersComponent } from './import-zen-master-users/import-zen-master-users.component';

import { OrganizationsResolverService } from './organizations-resolver.service';
import { PlatformsResolverService } from '../platforms/platforms-resolver.service';
import { ProductsResolverService } from '../products/products-resolver.service';
import { UsersResolverService } from '../users/users-resolver.service';
import { BuildsResolverService } from '../builds/builds-resolver.service';
import { DocumentsResolverService } from '../documents/documents-resolver.service';
import { OrganizationGroupsResolverService } from '../organizations/organization-groups-resolver.service';
import { PartnersResolverService } from '../partner-admin/partners-resolver.service';
import { ZenmasterResolverService } from '../zen-customers/zenmaster-resolver.service';
import { OrganizationNotificationFormComponent } from './organization-notification-form/organization-notification-form.component';

import { OrganizationGroupComponent } from './organization-group/organization-group.component';
import { OrganizationGroupListComponent } from './organization-group-list/organization-group-list.component';
import { OrganizationGroupFormComponent } from './organization-group-form/organization-group-form.component';
import { OrganizationGroupNotificationFormComponent } from './organization-group-notification-form/organization-group-notification-form.component';
import { SetBillingCodeAdminsFormComponent } from './set-billing-code-admins-form/set-billing-code-admins-form.component';


const routes: Routes = [
	{
		path: 'groups',
		component: OrganizationGroupListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationGroupsResolverService]
	}, {
		path: 'groups/new',
		component: OrganizationGroupFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationGroupsResolverService, ProductsResolverService, PlatformsResolverService,
			OrganizationsResolverService, UsersResolverService]
	}, {
		path: 'groups/:id',
		component: OrganizationGroupComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationGroupsResolverService, ProductsResolverService, PlatformsResolverService,
			OrganizationsResolverService, UsersResolverService, BuildsResolverService,
			DocumentsResolverService]
	}, {
		path: 'groups/:id/edit',
		component: OrganizationGroupFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationGroupsResolverService, ProductsResolverService, PlatformsResolverService,
			OrganizationsResolverService, UsersResolverService]
	}, {
		path: 'groups/:id/notify',
		component: OrganizationGroupNotificationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationGroupsResolverService, UsersResolverService]
	}, {
		path: '',
		component: OrganizationListComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: 'new',
		component: OrganizationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ProductsResolverService, PlatformsResolverService,
			UsersResolverService]
	}, {
		path: 'new/:salesforce-account-id',
		component: OrganizationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ProductsResolverService, PlatformsResolverService]
	}, {
		path: ':id',
		component: OrganizationComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, PlatformsResolverService,
			ProductsResolverService, UsersResolverService, BuildsResolverService,
			DocumentsResolverService, OrganizationGroupsResolverService, PartnersResolverService,
			ZenmasterResolverService]
	}, {
		path: ':id/edit',
		component: OrganizationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, ProductsResolverService, PlatformsResolverService,
			UsersResolverService]
	}, {
		path: ':id/salesforce-contacts',
		component: ImportSalesForceUsersComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':id/zen-master-users',
		component: ImportZenMasterUsersComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService, ZenmasterResolverService]
	}, {
		path: ':id/link-keys',
		component: LinkKeysFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService]
	}, {
		path: ':id/notify',
		component: OrganizationNotificationFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}, {
		path: ':id/billing-code-admins',
		component: SetBillingCodeAdminsFormComponent,
		canActivate: [RoleGuard],
		data: { role: AppConstants.staffUserRole },
		resolve: [OrganizationsResolverService, UsersResolverService]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class OrganizationsRoutingModule { }
