import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-organizations-table',
	templateUrl: './organizations-table.component.html',
	styleUrls: ['./organizations-table.component.scss']
})
export class OrganizationsTableComponent implements OnInit {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			users 
			storageKey
			options
			- showOrganization : true
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;
	starIcon = ['fas', 'star'];

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	showFavorite: boolean = false;
	showSalesEng: boolean = true;
	showSalesRep: boolean = true;

	items: Models.Organization[] = [];
	itemsToShow: Models.Organization[] = [];

	columnDefs: any[] = [
		{
			field: 'is_favorite',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Favorite',
			icon: this.starIcon
		}, {
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: '__issues',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Issues',
			icon: 'exclamation-triangle'
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'otype',
			type: 'baseText',
			sortType: 'text',
			label: 'Type',
			toolTip: null,
			icon: null
		}, {
			field: 'salesforce_account_owner',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Account Owner',
			icon: ['fab', 'salesforce']
		}, {
			field: 'salesforce_se',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'SE',
			icon: ['fas', 'user-graduate']
		}, {
			field: 'salesforce_tam',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Technical Account Manager',
			icon: ['fas', 'user-ninja']
		}, {
			field: 'num_users',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Users',
			icon: AppConstants.sectionIcons.users
		}, {
			field: '__num_groups',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Groups',
			icon: AppConstants.sectionIcons.organizationgroups
		}, {
			field: '__num_products_platforms',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Product/Platforms',
			icon: AppConstants.sectionIcons.platforms
		}, {
			field: '__num_builds',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Direct Access Builds',
			icon: AppConstants.sectionIcons.builds
		}, {
			field: '__num_documents',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Direct Access Documents',
			icon: AppConstants.sectionIcons.documents
		}, {
			field: 'num_partnerships',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Partnerships',
			icon: AppConstants.sectionIcons.partneradmin
		}, {
			field: 'num_active_zen_domains',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Active ZEN Master Sites',
			icon: AppConstants.sectionIcons.zencustomers
		}, {
			field: 'num_keys',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Linked Keys',
			icon: AppConstants.sectionIcons.licensing
		}, {
			field: 'num_billing_codes',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Number of Billing Codes',
			icon: ['fas', 'sack-dollar']
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}, {
			field: '__days_since_download',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Last Download',
			icon: 'file-download'
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			const starPref = TextTools.getUserPropValue(this.authUser, AppConstants.starIconKey);
			if (starPref && starPref !== '') this.starIcon = ['fas', starPref];

			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.Organization[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('showshowFavoriteSalesEng')) this.showFavorite = options.showFavorite;
		if (optionKeys.includes('showSalesEng')) this.showSalesEng = options.showSalesEng;
		if (optionKeys.includes('showSalesRep')) this.showSalesRep = options.showSalesRep;

		this.fieldsToShow = [];
		if (this.showFavorite) this.fieldsToShow.push('is_favorite');
		this.fieldsToShow.push('is_enabled');
		this.fieldsToShow.push('__issues');
		this.fieldsToShow.push('name');
		this.fieldsToShow.push('otype');
		if (this.showSalesRep) this.fieldsToShow.push('salesforce_account_owner');
		if (this.showSalesEng) this.fieldsToShow.push('salesforce_se');
		if (this.showSalesEng) this.fieldsToShow.push('salesforce_tam');
		this.fieldsToShow.push('num_users');
		this.fieldsToShow.push('__num_groups');
		this.fieldsToShow.push('__num_products_platforms');
		this.fieldsToShow.push('__num_builds');
		this.fieldsToShow.push('__num_documents');
		this.fieldsToShow.push('num_partnerships');
		this.fieldsToShow.push('num_active_zen_domains');
		this.fieldsToShow.push('num_keys');
		this.fieldsToShow.push('num_billing_codes');
		this.fieldsToShow.push('__days_since_add');
		this.fieldsToShow.push('__days_since_download');

		for (const item of this.items) {
			item['__issues'] = ValidationTools.getOrganizationIssues(item, true).join(' ');

			if (item.salesforce_account_owner && item.salesforce_account_owner !== 'Unknown'
				&& item.salesforce_account_owner.length > 2)
				item['__short_owner'] = TextTools.acronym(item.salesforce_account_owner);

			if (item.salesforce_se && item.salesforce_se !== 'Unknown'
				&& item.salesforce_se.length > 2)
				item['__short_se'] = TextTools.acronym(item.salesforce_se);

			if (item.salesforce_tam && item.salesforce_tam !== 'Unknown'
				&& item.salesforce_tam.length > 2)
				item['__short_tam'] = TextTools.acronym(item.salesforce_tam);

			item['__num_groups'] = item.group_ids.length;
			item['__num_products_platforms'] = item.product_platforms.length;
			item['__num_builds'] = item.build_ids.length;
			item['__num_documents'] = item.document_ids.length;

			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);
			if (item.last_download)
				item['__days_since_download'] = MiscTools.diffDays(this.now, item.last_download, true);
			else
				item['__days_since_download'] = 99999999;
		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'name', 'asc');

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {

		const tmpList: Models.Organization[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| item.otype.toLowerCase().includes(w)
						|| (this.showSalesRep && item.salesforce_account_owner && item.salesforce_account_owner.toLowerCase().includes(w))
						|| (this.showSalesEng && item.salesforce_se && item.salesforce_se.toLowerCase().includes(w))
						|| (item['__issues'] && item['__issues'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for

		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
