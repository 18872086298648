import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { MyBuildsService } from '../my-builds.service';
import { AuthService } from 'client/app/services/auth.service';
import { LinkLogsService } from '../../link-logs/link-logs.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-my-builds-list',
	templateUrl: './my-builds-list.component.html',
	styleUrls: ['./my-builds-list.component.scss']
})

export class MyBuildsListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	mt = MiscTools;
	tt = TextTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	authUser: Models.AuthUser = null;

	items: Models.DownloadableBuild[] = [];
	itemsToShow: Models.DownloadableBuild[] = [];
	// builds: Models.DownloadableBuild[] = [];
	loading = true;

	// other stuff
	showIsPrivate = false;
	private userSubscription: Subscription;
	products: Models.Product[] = [];

	recentBuilds: Models.DownloadableBuild[];
	recentProducts: Models.Product[] = [];

	lastTab: string = 'nav-by-product-tab';

	hidePrivate: boolean = false;
	isStaff: boolean = false;

	byProductFilter: number = 0;
	byNewProductFilter: number = 0;

	now = new Date();

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-my-builds';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: '__product',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: '__version',
			type: 'baseText',
			sortType: 'text',
			label: 'Version',
			toolTip: null,
			icon: null
		}, {
			field: '__platforms',
			type: 'baseText',
			sortType: 'text',
			label: 'Platforms',
			toolTip: null,
			icon: null
		}, {
			field: '__added',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Added',
			toolTip: null,
			icon: null
		}
	];

	constructor(
		private myBuildsService: MyBuildsService,
		private authService: AuthService,
		private linkLogsService: LinkLogsService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;
		if (localStorage.getItem('CLEAN.downloadBuilds.lastTab') != null)
			this.lastTab = localStorage.getItem('CLEAN.downloadBuilds.lastTab');

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			this.showIsPrivate = authUser && authUser.role !== AppConstants.basicUserRole;
			this.getData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async getData(forceRefresh: boolean = false) {
		this.loading = true;

		const usersBuilds = await this.myBuildsService.getBuilds(forceRefresh);

		this.items = [];
		for (const build of usersBuilds)
			if (!this.hidePrivate || build.build.is_private === 0)
				this.items.push(build);

		this.items.sort(TextTools.compareDownloadableBuilds).reverse();

		// go through all of the downloads and get unique products/platforms
		const products = [];
		for (const dl of this.items)
			if (MiscTools.findIndex(products, dl.build.product_id) === -1)
				products.push(dl.product);

		products.sort((a, b) => (a.name > b.name) ? 1 : -1);
		products.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
		this.products = products;

		// recent...
		this.recentBuilds = [];
		this.recentProducts = [];
		for (const dl of this.items)
			if (MiscTools.daysSince(dl.build.added_on) < AppConstants.dashboardRecentItemsDays)
				this.recentBuilds.push(dl);

		// go through all of the downloads and get unique products/platforms
		const recProducts = [];
		for (const dl of this.recentBuilds)
			if (MiscTools.findIndex(recProducts, dl.build.product_id) === -1)
				recProducts.push(dl.product);

		recProducts.sort((a, b) => (a.name > b.name) ? 1 : -1);
		recProducts.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
		this.recentProducts = recProducts;

		if (this.recentBuilds.length > 0) {
			if (localStorage.getItem('CLEAN.downloadBuilds.lastTab') == null)
				this.saveLastTab('nav-recent-tab');

		} else {
			if (this.lastTab === 'nav-recent-tab')
				this.saveLastTab('nav-by-product-tab');
		} // if

		if (this.lastTab != null) this.clickButton(this.lastTab);

		for (const item of this.items) {
			item['__product'] = item.build.prod_name;
			item['__version'] = item.build.version;
			if (item.build.label && item.build.label !== '') item['__version'] += ' ' + item.build.label;

			item['__platforms'] = '';
			for (const pl of MiscTools.sortPlatforms(item.platforms, this.getPlatformIDsFromPlatformFiles(item.build.platform_files)))
				item['__platforms'] += ' ' + pl.name;

			item['__added'] = item.build.added_on;
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, '__added', 'desc');
	}

	// ------------------------------------------------------------------------
	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	// ------------------------------------------------------------------------
	getFileName(theBuild: Models.DownloadableBuild, fileID: number): string {
		const idx = MiscTools.findIndexGeneric(theBuild.files, 'id', fileID);
		if (idx !== -1) {
			return theBuild.files[idx].name;
		}
		return '??? - ' + fileID;
	}

	// ------------------------------------------------------------------------
	getFileSize(theBuild: Models.DownloadableBuild, fileID: number): number {
		const idx = MiscTools.findIndexGeneric(theBuild.files, 'id', fileID);
		if (idx !== -1) {
			return theBuild.files[idx].size;
		}
		return 0;
	}

	// ------------------------------------------------------------------------
	scrollToSpot(id: string) {
		const el: HTMLElement | null = document.getElementById(id);
		if (el) {
			setTimeout(() =>
				el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 0);
		}
	}

	// ------------------------------------------------------------------------
	saveLastTab(id: string) {
		this.lastTab = id;
		localStorage.setItem('CLEAN.downloadBuilds.lastTab', id);
	}

	// ------------------------------------------------------------------------
	clickButton(id: string) {
		if (document.getElementById(id))
			document.getElementById(id).click();
	} // clickButton

	// ------------------------------------------------------------------------
	async toggleHidePrivate() {
		this.hidePrivate = !this.hidePrivate;
		await this.getData();
	}

	// ------------------------------------------------------------------------
	setByProductFilter(productId: number) {
		if (this.byProductFilter === productId)
			this.byProductFilter = 0;
		else
			this.byProductFilter = productId;
	}

	// ------------------------------------------------------------------------
	setByNewProductFilter(productId: number) {
		if (this.byNewProductFilter === productId)
			this.byNewProductFilter = 0;
		else
			this.byNewProductFilter = productId;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.DownloadableBuild[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if ((item['__product'] && item['__product'].toLowerCase().includes(w))
						|| (item['__version'] && item['__version'].toLowerCase().includes(w))
						|| (item['__platforms'] && item['__platforms'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}



}
