<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.documents" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Documents
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Document</span>
			</button>
			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<app-documents-table #documentsTable1></app-documents-table>

</div>