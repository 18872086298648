import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
// import ValidationTools from 'appshared/validation-tools';
// import SharedLicenseTools from 'appshared/shared-license-tools';
// import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-card-build-download',
	templateUrl: './card-build-download.component.html',
	styleUrls: ['./card-build-download.component.scss']
})
export class CardBuildDownloadComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.DownloadableBuild = null;
	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.DownloadableBuild = null;

	// options


	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];


	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.DownloadableBuild) {

		this.objectToShow = MiscTools.deepClone(object);

		if (!this.objectToShow) return;

		this.nameToShow = this.objectToShow.product.name + ' - ' + this.objectToShow.build.version;
		this.typeToShow = 'Build';
		this.iconToShow = AppConstants.sectionIcons.builds;

		this.infoLines = [];

		this.infoLines.push('Product: ' + this.objectToShow.product.name);
		if (this.objectToShow.build.label && this.objectToShow.build.label !== '')
			this.infoLines.push('Version: ' + this.objectToShow.build.version + ' (' + this.objectToShow.build.label + ')');
		else
			this.infoLines.push('Version: ' + this.objectToShow.build.version);

		const platformIds = [];
		for (const pf of this.objectToShow.build.platform_files)
			platformIds.push(pf.platform_id);

		const sortedPlatforms: Models.Platform[] = MiscTools.sortPlatforms(this.objectToShow.platforms, platformIds)

		if (sortedPlatforms.length > 0) {
			this.infoLines.push('Platforms:');
			for (const platform of sortedPlatforms)
				this.infoLines.push(' - ' + platform.name);
		} // if

		this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.build.added_on));
		if (this.objectToShow.build.edited_on && this.objectToShow.build.edited_on !== this.objectToShow.build.added_on) this.infoLines.push('Edited: ' + TextTools.formatDateNiceUTC(this.objectToShow.build.edited_on));

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
