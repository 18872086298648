import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { BackgroundTasksService } from '../background-tasks.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import MiscTools from 'appshared/misc-tools';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-search-tasks',
	templateUrl: './search-tasks.component.html',
	styleUrls: ['./search-tasks.component.scss']
})
export class SearchTasksComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	items: Models.BackgroundTask[] = [];
	itemsToShow: Models.BackgroundTask[] = [];

	loading = true;
	refreshing = false;

	// other stuff...
	maxMessage = '';
	showFilters = false;
	theForm: UntypedFormGroup;
	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;

	private userSubscription: Subscription;
	role = '';
	expandNames = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-bg-tasks';

	columnDefs: any[] = [
		{
			field: 'added_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Started',
			toolTip: null,
			icon: null
		}, {
			field: '__duration',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'Finished',
			toolTip: null,
			icon: null
		}, {
			field: '__user',
			type: 'baseText',
			sortType: 'text',
			label: 'User',
			toolTip: null,
			icon: null
		}, {
			field: 'task_type',
			type: 'baseText',
			sortType: 'text',
			label: 'Type',
			toolTip: null,
			icon: null
		}, {
			field: 'info',
			type: 'baseText',
			sortType: 'text',
			label: 'Information',
			toolTip: null,
			icon: null
		}, {
			field: 'results',
			type: 'baseText',
			sortType: 'text',
			label: 'Results',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	constructor(
		private router: Router,
		private backgroundTasksService: BackgroundTasksService,
		private usersService: UsersService,
		private authService: AuthService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {

		let settings: any = {};
		try {
			if (localStorage.getItem('cp-backgroundTasks.settings'))
				settings = JSON.parse(localStorage.getItem('cp-backgroundTasks.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('notBefore')) this.notBefore = settings.notBefore;
		if (settingKeys.includes('notAfter')) this.notAfter = settings.notAfter;

		this.showFilters = (this.notBefore != null || this.notAfter != null);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser)
				this.role = authUser.role;
		});

		this.setup();
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	setup() {
		const users = this.usersService.getAll();

		this.theForm = new UntypedFormGroup({
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});

		this.loadLogs();
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		let settings: any = {
			notBefore: this.notBefore,
			notAfter: this.notAfter
		};
		localStorage.setItem('cp-backgroundTasks.settings', JSON.stringify(settings));

		this.loadLogs();
	}

	// ------------------------------------------------------------------------
	async loadLogs() {
		this.loading = true;

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		this.items = await this.backgroundTasksService.getLogs(startDate, endDate);

		if (this.items && this.items.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum logs (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of logs retrieved.';
		else
			this.maxMessage = '';

		for (const item of this.items) {
			item['__duration'] = this.getNiceDuration(item);
			item['__user'] = this.getUser(item);
		}

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'created_at', 'desc');
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	getNiceDuration(obj: Models.BackgroundTask) {
		let timeSeconds = 0;
		if (obj.added_on && obj.ended_on) {
			const s = new Date(obj.added_on);
			const e = new Date(obj.ended_on);
			const timeSeconds = Math.floor((e.getTime() - s.getTime()) / 1000);
			if (timeSeconds >= 1)
				return TextTools.formattedDuration(timeSeconds, false);
			else
				return 'Under a second';
		}
		return '';
	}

	// ------------------------------------------------------------------------
	getUser(obj: Models.BackgroundTask) {
		const userObj = this.usersService.getOne(obj.added_by);
		if (userObj)
			return userObj.name;
		return '';
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.BackgroundTask[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.task_type.toLowerCase().includes(w)
						|| (item.info && item.info.toLowerCase().includes(w))
						|| (item.results && item.results.toLowerCase().includes(w))
						|| (item['__user'] && item['__user'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
