import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
// import ValidationTools from 'appshared/validation-tools';
// import SharedLicenseTools from 'appshared/shared-license-tools';
// import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-card-partnership',
	templateUrl: './card-partnership.component.html',
	styleUrls: ['./card-partnership.component.scss']
})
export class CardPartnershipComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.Partnership = null;
	@Input() organizations: Models.Organization[] = [];
	@Input() staffView: boolean = false;
	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.Partnership = null;

	// options


	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];


	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.Partnership) {

		this.objectToShow = MiscTools.deepClone(object);

		if (!this.objectToShow) return;

		let theOrg: Models.Organization = null;
		if (this.organizations)
			theOrg = MiscTools.pickItem(this.organizations, 'id', this.objectToShow.org_id);

		this.nameToShow = this.objectToShow.name;
		this.typeToShow = 'Partnership';
		this.iconToShow = AppConstants.sectionIcons.partneradmin;

		this.infoLines = [];
		if (this.staffView)
			if (this.objectToShow.is_listed === 1)
				this.infoLines.push('Listed in Partners Directory: Yes');
			else
				this.infoLines.push('Listed in Partners Directory: No');

		// const idx = MiscTools.findIndex(organizations, this.objectToShow.org_id);
		// if (idx !== -1) {
		// 	this.infoLines.push('Organization: ' + organizations[idx].name);
		// } // if

		// this.infoLines.push('Name: ' + this.objectToShow.name);

		if (theOrg)
			this.infoLines.push('Organization: ' + theOrg.name);

		if (!!this.objectToShow.information && this.objectToShow.information !== '') this.infoLines.push('Information/Features: ' + this.objectToShow.information);

		if (this.objectToShow.extras != null) {
			if (this.objectToShow.extras.sdk_features && this.objectToShow.extras.sdk_features !== '') this.infoLines.push('SDK Features: ' + this.objectToShow.extras.sdk_features);
			if (this.objectToShow.extras.link && this.objectToShow.extras.link !== '') this.infoLines.push('Product/Company Link: ' + this.objectToShow.extras.link);
			if (this.objectToShow.extras.document_link && this.objectToShow.extras.document_link !== '') this.infoLines.push('More Info Link: ' + this.objectToShow.extras.document_link);
			if (this.objectToShow.extras.version && this.objectToShow.extras.version !== '') this.infoLines.push('Version: ' + this.objectToShow.extras.version);
			if (this.staffView && this.objectToShow.extras.status && this.objectToShow.extras.status !== '') this.infoLines.push('Status: ' + this.objectToShow.extras.status);
			if (this.objectToShow.extras.zm_certified && this.objectToShow.extras.zm_certified === 1) this.infoLines.push('ZEN Master Certified: yes');
			if (this.objectToShow.extras.build_version && this.objectToShow.extras.build_version !== '') this.infoLines.push('Build: ' + this.objectToShow.extras.build_version);
			if (this.objectToShow.extras.itype && this.objectToShow.extras.itype !== '') this.infoLines.push('SDK Type: ' + this.objectToShow.extras.itype);
			if (this.objectToShow.extras.dtype && this.objectToShow.extras.dtype !== '') this.infoLines.push('Partnership Type: ' + this.objectToShow.extras.dtype);
		} // if

		if (this.objectToShow.added_on) this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.added_on));
		if (this.objectToShow.edited_on) this.infoLines.push('Edited: ' + TextTools.formatDateNiceUTC(this.objectToShow.edited_on));

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
