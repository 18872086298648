<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol *ngIf="pageMode === 'activation'" class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mykeys" class="cp-user-nav-item me-1" size="sm"></fa-icon>
				<a [routerLink]="['/' +  ac.urls.mykeys]">License Keys</a>
			</li>
			<li *ngIf="activation" class="breadcrumb-item active" aria-current="page">
				{{ activation.key }}
			</li>
		</ol>

		<ol *ngIf="pageMode === 'hostid'" class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.mykeys" class="cp-user-nav-item me-1" size="sm"></fa-icon>
				<a [routerLink]="['/' +  ac.urls.mykeys]">License Keys</a>
			</li>
			<li *ngIf="activation" class="breadcrumb-item">
				<a [routerLink]="['/' +  ac.urls.mykeys, activation.key]">{{ activation.key }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ hostid }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div *ngIf="pageMode === 'activation'" class="btn-group">
				<button *ngIf="showAdminButton && userKey" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + ac.urls.licensing,  'activation', userKey.activation_id]"
					ngbTooltip="Switch to the Staff/Admin view of this key" placement="bottom">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					Staff View
				</button>

				<button *ngIf="!loading && showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Compact Data Sizes" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

				<button *ngIf="!loading && !showRawData" type="button" class="btn btn-outline-secondary" (click)="toggleShowRaw()"
					ngbTooltip="Show Expanded Data Sizes in Base Unit (megabytes)" placement="bottom-end">
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
					<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				</button>

				<button *ngIf="registeredKey && orgUsers && orgUsers.length !== 0 && userKey.is_deleted === 0" type="button"
					class="btn btn-outline-primary" [routerLink]="['/' + ac.urls.mykeys, userKey.id,  'share']"
					ngbTooltip="Share keys with members of your organization" placement="bottom-end">
					<fa-icon [icon]="['fas', 'share-alt']" size="sm"></fa-icon>
					<span class="cp-action-button-text">Share</span>
				</button>

				<button *ngIf="registeredKey && userKey && userKey.is_deleted === 0" type="button" class="btn btn-outline-primary"
					[routerLink]="['/' + ac.urls.mykeys, userKey.id,  'edit']" ngbTooltip="Add/change a label for this key"
					placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<span class="cp-action-button-text">Edit</span>
				</button>

				<button *ngIf="registeredKey && userKey && userKey.is_deleted === 0" type="button" class="btn btn-outline-danger"
					(click)="delete()" ngbTooltip="Move this key to the trash" placement="bottom-end">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>

				<button *ngIf="registeredKey && userKey && userKey.is_deleted === 1" type="button" class="btn btn-outline-primary"
					(click)="recover()" ngbTooltip="Take this key out of the trash" placement="bottom-end">
					<fa-icon [icon]="['fas', 'life-ring']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Recover</span> -->
				</button>

				<button *ngIf="registeredKey && userKey && showPurge" type="button" class="btn btn-outline-danger" (click)="purge()"
					ngbTooltip="Permanently remove this keys from your account" placement="bottom-end">
					<fa-icon [icon]="['fas', 'exclamation-triangle']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Purge</span> -->
				</button>

				<button type="button" class="btn btn-outline-secondary" (click)="loadData(true)">
					<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="bottom-end">
					</fa-icon>
					<!-- <span class="cp-action-button-text">Refresh</span> -->
				</button>

				<button *ngIf="prevId > 0" type="button" class="btn btn-outline-secondary" (click)="routeToKey(prevId)"
					[ngbTooltip]="prevNote" placement="bottom-end">
					<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
					<!-- Prev -->
				</button>

				<button *ngIf="nextId > 0" type="button" class="btn btn-outline-secondary" (click)="routeToKey(nextId)"
					[ngbTooltip]="nextNote" placement="bottom-end">
					<fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
					<!-- Next -->
				</button>
			</div>

			<div *ngIf="pageMode === 'hostid'" class="btn-group">
				<button *ngIf="showAdminButton && activation" type="button" class="btn btn-outline-secondary"
					[routerLink]="['/' + ac.urls.licensing, 'hostid', hostid, activation.id]"
					ngbTooltip="Switch to the Staff/Admin view of this host ID" placement="bottom">
					<fa-icon [icon]="['fas', 'glasses']" size="sm"></fa-icon>
					Staff View
				</button>

				<button *ngIf="registeredKey" type="button" class="btn btn-outline-primary"
					[routerLink]="['/' + ac.urls.mykeys, this.key, 'host', this.hostid, 'edit']"
					ngbTooltip="Add/change a label for this host ID." placement="bottom-end">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<span class="cp-action-button-text">Edit</span>
				</button>

				<button type="button" class="btn btn-outline-secondary" (click)="loadData(true)">
					<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="bottom-end">
					</fa-icon>
					<!-- <span class="cp-action-button-text">Refresh</span> -->
				</button>
			</div>

		</div>
	</div>
</div>

<div class="tab-container">

	<ul *ngIf="pageMode === 'activation'" class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-base-tab" aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="secnav-activationsond-tab" aria-controls="nav-activations" data-bs-target="#nav-activations">
				<fa-icon [icon]="['fas', 'server']" size="sm"></fa-icon>
				Activations
				<span *ngIf="activation && (!loading || activation.licenses.length) " class="cp-tab-badge">
					{{ activation.licenses.length | number }}<span *ngIf="showMaxHostsExtra">+</span>
				</span>
			</button>
		</li>

		<li *ngIf="activeLicenses.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-active-tab"
				aria-controls="nav-active" data-bs-target="#nav-active">
				<fa-icon [icon]="['fas', 'bolt']" size="sm"></fa-icon>
				Active
				<span class="cp-tab-badge">
					{{ activeLicenses.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-features-tab" aria-controls="nav-features" data-bs-target="#nav-features">
				<fa-icon [icon]="['fas', 'clipboard-list-check']" size="sm"></fa-icon>
				Features/Limits
			</button>
		</li>

		<li *ngIf="showMeters && activation.meters.length > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-meters-tab"
				aria-controls="nav-meters" data-bs-target="#nav-meters">
				<fa-icon [icon]="['fas', 'tachometer-alt']" size="sm"></fa-icon>
				Meters
				<span *ngIf="activation && (!loading || activation.meters.length > 0) " class="cp-tab-badge">
					{{ activation.meters.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showMeterTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-meter-traffic-tab" aria-controls="nav-meter-traffic" data-bs-target="#nav-meter-traffic" ngbTooltip="Meter Traffic">
				<fa-icon [icon]="['fas', 'tachometer-alt']" size="sm"></fa-icon>
				Traffic<sup>m</sup>
			</button>
		</li>

		<li *ngIf="showProtocolTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-protocol-traffic-tab" aria-controls="nav-protocol-traffic" data-bs-target="#nav-protocol-traffic"
				ngbTooltip="Protocol Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>p</sup>
			</button>
		</li>

		<li *ngIf="showChronoTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-time-traffic-tab" aria-controls="nav-time-traffic" data-bs-target="#nav-time-traffic"
				ngbTooltip="Time-based Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>t</sup>
			</button>
		</li>

		<li *ngIf="sharedWithUsers.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-shares-tab"
				aria-controls="nav-shares" data-bs-target="#nav-shares">
				<fa-icon [icon]="ac.sectionIcons.users" size="sm"></fa-icon>
				Shared With
				<span *ngIf="activation && (!loading || sharedWithUsers.length) " class="cp-tab-badge">
					{{ sharedWithUsers.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showMaxHostsExtra" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-compact-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-search-tab"
				aria-controls="nav-search" data-bs-target="#nav-search">
				<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
				Search
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<ul *ngIf="pageMode === 'hostid'" class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true"
				id="nav-activations-tab" aria-controls="nav-activations" data-bs-target="#nav-activations">
				<fa-icon [icon]="['fas', 'key']" size="sm"></fa-icon>
				Activations
				<span *ngIf="licenses && licenses.length > 1" class="cp-tab-badge">
					{{ licenses.length | number }}
				</span>
			</button>
		</li>

		<li *ngIf="showMeterTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-meter-traffic-tab" aria-controls="nav-meter-traffic" data-bs-target="#nav-meter-traffic" ngbTooltip="Meter Traffic">
				<fa-icon [icon]="['fas', 'tachometer-alt']" size="sm"></fa-icon>
				Traffic<sup>m</sup>
			</button>
		</li>

		<li *ngIf="showProtocolTraffic" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-protocol-traffic-tab" aria-controls="nav-protocol-traffic" data-bs-target="#nav-protocol-traffic"
				ngbTooltip="Protocol Traffic">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				Traffic<sup>p</sup>
			</button>
		</li>

		<li *ngIf="versionHistory.length !== 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-versions-tab"
				aria-controls="nav-versions" data-bs-target="#nav-versions">
				<fa-icon [icon]="['fas', 'history']" size="sm"></fa-icon>
				Version History
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div *ngIf="pageMode === 'activation'" class="tab-pane show active" role="tabpanel" id="nav-base" aria-labelledby="nav-base-tab">

			<div *ngIf="!loading && !registeredKey" class="cp-info-message border">
				This key isn't currently registered with your user account.
				<button type="button" class="btn btn-outline-primary btn-sm" (click)="registerKey()" placement="bottom"
					ngbTooltip="Registering a key will link this license key with your user account and it can be found under 'License Keys'.">
					Register Key</button>
			</div>

			<div *ngIf="!!activation && keyWarnings && keyWarnings.length !== 0" class="cp-warning-message mb-2">
				<div *ngFor="let item of keyWarnings">
					<fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
					{{ item }}
				</div>
			</div>

			<div *ngIf="showSelfServiceExtendButton" class="cp-info-message">
				This key no longer has any available activations. You can increase the maximum number of
				activations.<br />
				<button type="button" class="btn btn-outline-primary btn-sm" (click)="addActivationsToKey()">
					Increase the Number of Activations
				</button>
			</div>

			<div *ngIf="showSelfServiceCreateButton" class="cp-info-message">
				This key has either:
				<ul class="mb-0">
					<li>Expired</li>
					<li>or it's been disabled</li>
					<li>or it's reached the maximum allowed activations</li>
				</ul>
				You can create a new key if you wish.<br />
				<button type="button" class="btn btn-outline-primary btn-sm" (click)="generateKeyViaTemplate()">
					Create a New Key
				</button>
			</div>

			<div *ngIf="activation" style="max-width: 1400px">
				<div class="row justify-content-start g-1">
					<div class="col-6 px-0">

						<div class="bg-light border m-1 p-1">
							<div *ngIf="registeredKey" class="cp-property-block">
								<span class="cp-property-label">
									My Label:
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Your own custom label for this key. Click 'Edit' button to set/change."
										placement="bottom-start">
									</fa-icon>
								</span>
								<span class="cp-property-value">
									<span *ngIf="!userKey.label || userKey.label === ''" class='font-italic'>Not
										Set</span>
									<span *ngIf="userKey.label && userKey.label !== ''">{{ userKey.label }}</span>
								</span>
							</div>

							<div *ngIf="activation.ext_label && activation.ext_label !== ''" class="cp-property-block">
								<span class="cp-property-label">
									Label:
									<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="A label set for this key by Zixi"
										placement="bottom-start">
									</fa-icon>
								</span>
								<span class="cp-property-value">{{ activation.ext_label }}</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Key:</span>
								<span class="cp-property-value">
									<span class="cp-key">{{ activation.key }}</span>
									&nbsp;<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert(activation.key)"
										[cbContent]="activation.key" ngbTooltip="Copy to Clipboard" placement="end"></fa-icon>
								</span>
							</div>

							<div *ngIf="!sharedLicenseTools.isSpecialKey(activation)" class="cp-property-block">
								<span class="cp-property-label">Enabled:</span>
								<span *ngIf="activation.enabled === 1" class="cp-property-value">Yes</span>
								<span *ngIf="activation.enabled === 0" class="cp-property-value text-danger">No</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Product:</span>
								<span *ngIf="keyProduct" class="cp-property-value" [title]="keyProduct.name">
									{{ keyProduct.basic_label }}
								</span>
								<span *ngIf="!keyProduct" class="cp-property-value">
									{{ activation.product }}
								</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Type:</span>
								<span class="cp-property-value">{{ niceKeyType(activation) | titlecase }}</span>
							</div>

							<div class="cp-property-block">
								<span class="cp-property-label">Activations:</span>
								<span class="cp-property-value" [ngClass]="{'text-danger': +activation.count >= +activation.max }">
									{{ activation.count | number }} of {{ activation.max | number }} used
								</span>
							</div>

							<div class="cp-property-block" *ngIf="activation.created_at">
								<span class="cp-property-label">Created:</span>
								<span class="cp-property-value">{{ activation.created_at | date:
									ac.pageDateFmt }}</span>
							</div>

							<div *ngIf="expiryMode && expiryMode !== ''" class="cp-property-block">
								<span class="cp-property-label">Expiration Mode:</span>
								<span class="cp-property-value">
									{{ sharedLicenseTools.niceKeyExpiryMode(expiryMode, true) }}
									<fa-icon [icon]="['fas', 'question-circle']"
										[ngbTooltip]="sharedLicenseTools.niceKeyExpiryMode(expiryMode, false)" placement="bottom-start">
									</fa-icon>
									<span *ngIf="activation.enabled === 0 && !sharedLicenseTools.isSpecialKey(activation)">
										- Key is not enabled</span>
								</span>
							</div>

							<div *ngIf="activation.enabled === 1 && expiryMode && expiryMode === 'meter'" class="cp-property-block">
								<!-- <span class="cp-property-label">Expiry:</span>
								<span class="cp-property-value">See meters</span> -->
								<span *ngIf="mt.hasExpired(keyExpiryCalc)" class="cp-property-label">Expired
									(meter):</span>
								<span *ngIf="!mt.hasExpired(keyExpiryCalc)" class="cp-property-label">Expires
									(meter):</span>
								<span class="cp-property-value" [ngClass]="{'text-danger': mt.hasExpired(keyExpiryCalc)}">
									{{ keyExpiryCalc | date: ac.shortPageDateFmt:'UTC' }}
									- {{ tt.niceExpireText(keyExpiryCalc) }}
								</span>
							</div>

							<div *ngIf="expiryMode && expiryMode === 'date' && activation.expires_at" class="cp-property-block">
								<span *ngIf="mt.hasExpired(activation.expires_at)" class="cp-property-label">Expired:</span>
								<span *ngIf="!mt.hasExpired(activation.expires_at)" class="cp-property-label">Expires:</span>
								<span class="cp-property-value" [ngClass]="{'text-danger': mt.hasExpired(activation.expires_at)}">
									{{ activation.expires_at | date: ac.shortPageDateFmt:'UTC' }}
									- {{ tt.niceExpireText(activation.expires_at) }}
								</span>
							</div>

							<div *ngIf="expiryMode && expiryMode === 'duration' && activation.duration && activation.duration !== 0"
								class="cp-property-block">
								<span class="cp-property-label">
									Duration
									<fa-icon [icon]="['fas', 'question-circle']" ngbTooltip="Lifespan of licenses from date of issue"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">{{
									sharedLicenseTools.niceKeyDuration(activation.duration)
									}}</span>
							</div>

							<div class="cp-property-block" *ngIf="recentUsedHosts.length !== 0">
								<span class="cp-property-label">
									# Recent Host IDs
									<fa-icon [icon]="['fas', 'question-circle']" [ngbTooltip]="recentHostsPopup" placement="bottom-start">
									</fa-icon>:
								</span>
								<span class="cp-property-value">
									{{ recentUsedHosts.length | number }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="showMeters && activation.first_meter_report">
								<span class="cp-property-label">
									Meter Reporting
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in against a meter"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_meter_report | date: ac.shortPageDateFmt:'UTC' }} to
									{{ activation.last_meter_report | date: ac.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="showMeters && activation.first_meter_usage">
								<span class="cp-property-label">
									Meter Usage
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in non-zero traffic against a meter"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_meter_usage | date: ac.shortPageDateFmt:'UTC' }} to
									{{ activation.last_meter_usage | date: ac.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="protocolReady && activation.first_protocol_report">
								<span class="cp-property-label">
									Protocol Reporting
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in protocol traffic"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_protocol_report | date: ac.shortPageDateFmt:'UTC' }}
									to
									{{ activation.last_protocol_report | date: ac.shortPageDateFmt:'UTC' }}
								</span>
							</div>

							<div class="cp-property-block" *ngIf="protocolReady && activation.first_protocol_usage">
								<span class="cp-property-label">
									Protocol Usage
									<fa-icon [icon]="['fas', 'question-circle']"
										ngbTooltip="Timeframe this key has activations reporting in non-zero protocol traffic"
										placement="bottom-start">
									</fa-icon> :
								</span>
								<span class="cp-property-value">
									{{ activation.first_protocol_usage | date: ac.shortPageDateFmt:'UTC' }} to
									{{ activation.last_protocol_usage | date: ac.shortPageDateFmt:'UTC' }}
								</span>
							</div>
						</div>

						<div *ngIf="registeredKey && userKey && (userKey.is_deleted === 0 || keySnoozes.length > 0)"
							class="bg-light border m-1 p-1">
							<button *ngIf="registeredKey && userKey && userKey.is_deleted === 0" type="button"
								class="btn btn-outline-primary btn-sm ms-1" [routerLink]="['/' + ac.urls.mykeys, userKey.id,  'snooze']"
								ngbTooltip="You can stop receiving notifications about this key for a specified period of time"
								placement="bottom-end">
								<fa-icon [icon]="['fas', 'snooze']" size="sm"></fa-icon>
								<span class="cp-action-button-text">Snooze/Pause Notifications</span>
							</button>

							<div *ngIf="keySnoozes.length > 0" class="mt-1">
								Current Snoozes/Pauses for this Key

								<div *ngFor="let snooze of keySnoozes" class="pt-2">
									<div class="btn-group mb-1">
										<button type="button" class="btn btn-outline-primary btn-sm"
											[routerLink]="['/' + ac.urls.mykeys, userKey.id, 'snooze', 'edit', snooze.id]"
											ngbTooltip="Update the type or expiration of this snooze/pause">
											<fa-icon icon="pencil" size="sm"></fa-icon>
										</button>
										<button type="button" class="btn btn-outline-danger btn-sm" (click)="deleteSnooze(snooze)"
											ngbTooltip="Cancel this snooze/pause">
											<fa-icon icon="trash-alt" size="sm"></fa-icon>
										</button>
									</div>

									<div class="cp-property-block">
										<span class="cp-property-label">Type:</span>
										<span class="cp-property-value">
											{{ mt.fetchLabel(ac.keySnoozeTypes, snooze.snooze_type) }}
										</span>
									</div>

									<div class="cp-property-block">
										<span class="cp-property-label">Resume Notifications On:</span>
										<span class="cp-property-value">
											{{ snooze.expires_at | date: ac.shortPageDateFmt:'UTC' }}
											({{ tt.niceDaysText(snooze.expires_at) }})
										</span>
									</div>
								</div>
							</div>
						</div>

					</div>

					<div class="col-6 px-0">
						<div *ngIf="activation.ext_notes && activation.ext_notes.trim() !== ''"
							class="bg-light border m-1 p-1 overflow-auto" style="max-height: 480px">
							<div class="cp-property-block">
								<span class="cp-property-label">Notes:</span>
								<div class="cp-property-value cp-pre-text">
									{{ activation.ext_notes.trim() }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-features" aria-labelledby="nav-features-tab">
			<app-key-features #keyFeatures></app-key-features>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-activations" aria-labelledby="nav-activations-tab">
			<div *ngIf="!loading && activation && activation.licenses.length === 0" class="cp-light-message">
				This key does not have any activations.
			</div>

			<div *ngIf="!loading && activation && showMaxHostsExtra" class="cp-warning-message">
				{{ maxHostsMsg }}
				<span *ngIf="activeLicenses.length > 0">Use the Active tab to find active licenses or use Search tab to
					find a specific license.</span>
				<span *ngIf="activeLicenses.length === 0">Use the Search tab to find a specific license.</span>
			</div>

			<button *ngIf="registeredKey && activation && activation.licenses && activation.licenses.length !== 0" type="button"
				class="btn btn-outline-primary btn-sm mb-2 me-1" [routerLink]="['/' + ac.urls.mykeys, activation.key, 'register-hostids']">
				Set Labels for Host IDs
			</button>

			<button *ngIf="activation && activation.licenses.length !== 0" type="button" class="btn btn-outline-secondary btn-sm mb-2 me-1"
				(click)="runLicenseReport()" ngbTooltip="Create a spreadsheet with information about all licenses issued from this key.">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				Generate License Report
			</button>

			<app-licenses-table #allLicensesTable></app-licenses-table>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-active" aria-labelledby="nav-active-tab">
			<div class="cp-light-message mb-1">
				Below are systems that have reported in the last {{ ac.maxActiveLicenseDays }} days.
			</div>

			<app-licenses-table #activeLicensesTable></app-licenses-table>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-shares" aria-labelledby="nav-shares-tab">
			<div class="ms-2">You shared this key with the following users:</div>
			<ul>
				<li *ngFor="let item of sharedWithUsers">{{ item.name }}</li>
			</ul>
		</div>

		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-search" aria-labelledby="nav-search-tab">
			<form *ngIf="searchForm" [formGroup]="searchForm" class="ms-4">
				<div class="row">
					<div class="form-group">
						<div class="input-group">
							<input type="text" id="hostidFilter" formControlName="hostidFilter" class="form-control"
								placeholder="Host ID(s)/IP(s)" />
							<button class="btn btn-primary btn-sm" type="button" (click)="doSearch()">
								<fa-icon [icon]="['fas', 'search']"></fa-icon> Search
							</button>
						</div>
					</div>
				</div>
			</form>

			<div [ngStyle]="{'display': !showSearchResults ? 'none' : '' }">
				<app-licenses-table #searchLicensesTable></app-licenses-table>
			</div>
		</div>

		<div *ngIf="pageMode === 'hostid'" class="tab-pane show active" role="tabpanel" id="nav-activations"
			aria-labelledby="nav-activations-tab">

			<div class="cp-width-limit-1400">
				<div class="row gx-1">
					<div class="col-6">
						<div class=" border p-2 mb-1">
							<div class="cp-property-block">
								<span class="cp-property-label">Label:</span>
								<span *ngIf="label && label !== ''" class="cp-property-value">{{ label }}</span>
								<span *ngIf="!label || label === ''" class="cp-property-value font-italic">
									Not Set</span>
							</div>
						</div>
					</div>
					<div class="col-6">
					</div>
				</div>

				<div class="row gx-1">
					<div class="col-6">
						<div class=" overflow-auto" style="max-height: calc(100vh - 200px);">
							<div *ngFor="let item of licenses" class="border p-2 mb-1">
								<span class="text-end" style="float:right">
									<button (click)="showLicenseFeatures(item.id)" type="button" class="btn btn-outline-primary btn-sm mb-1"
										[ngClass]="{'btn-outline-primary': selectedLicenseID !== item.id, 'btn-primary': selectedLicenseID === item.id}"
										title="Show features/limits set with this license"
										ngbTooltip="Show features/limits set with this license" placement="start">
										<fa-icon [icon]="['fas', 'clipboard-list-check']" [fixedWidth]="true">
										</fa-icon>
									</button><br />
								</span>

								<div class="cp-property-block">
									<span class="cp-property-label">Issued:</span>
									<span class="cp-property-value">
										{{ item.created_at | date: ac.shortPageDateTimeFmt }}
									</span>
								</div>

								<div *ngIf="item.updated_at" class="cp-property-block">
									<span class="cp-property-label">Updated:</span>
									<span class="cp-property-value">
										{{ item.updated_at | date: ac.shortPageDateTimeFmt }}
									</span>
								</div>

								<div *ngIf="!item.expires_at" class="cp-property-block">
									<span class="cp-property-label">Expires:</span>
									<span class="cp-property-value">Never - Permanent License</span>
								</div>

								<div *ngIf="item.expires_at" class="cp-property-block">
									<span *ngIf="mt.hasExpired(item.expires_at)" class="cp-property-label">Expired:</span>
									<span *ngIf="!mt.hasExpired(item.expires_at)" class="cp-property-label">
										Expires
										<fa-icon *ngIf="expiryExtraActivation[item.id] && expiryExtraActivation[item.id] !== ''"
											[icon]="['fas', 'question-circle']" size="sm" [ngbTooltip]="expiryExtraActivation[item.id]"
											placement="bottom-start"></fa-icon>
										:
									</span>
									<span class="cp-property-value">
										{{ item.expires_at | date: ac.shortPageDateTimeFmt }}
									</span>
								</div>

								<div class="cp-property-block">
									<span class="cp-property-label">Product:</span>
									<span class="cp-property-value">
										{{ niceKeyProduct(item.product) }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="item.ip && item.ip !== ''">
									<span class="cp-property-label">
										IP
										<fa-icon [icon]="['fas', 'question-circle']" placement="end" [ngbTooltip]="ac.hostIDIPNote">
										</fa-icon>
										:
									</span>
									<span class="cp-property-value">
										{{ item.ip }}
										&nbsp;<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert(item.ip)"
											[cbContent]="item.ip" ngbTooltip="Copy to Clipboard"></fa-icon>
									</span>
									<a [href]="'https://ipinfo.io/' + item.ip" target="_blank" class="ms-1">
										<fa-icon [icon]="['fas', 'external-link-square-alt']" size="sm"></fa-icon>
										IP Info
									</a>
								</div>

								<div class="cp-property-block" *ngIf="item.extraProperties">
									<span class="cp-property-label">
										Extra Properties:
									</span>
									<div class="cp-property-value cp-pre-text cp-75 ms-2">
										{{ sharedLicenseTools.niceLicenseExtraProperties(item.extraProperties) }}
									</div>
								</div>

								<div *ngIf="meterTypes.length > 0" class="cp-property-block">
									<span class="cp-property-label">Meter Types:</span>
									<span class="cp-property-value">{{ meterTypes.join(', ') }}</span>
								</div>

								<div class="cp-property-block" *ngIf="showMeterTraffic && item.first_meter_report">
									<span class="cp-property-label">
										Meter Reporting
										<fa-icon [icon]="['fas', 'question-circle']"
											ngbTooltip="Timeframe this activation reports in against a meter" placement="top-start">
										</fa-icon> :
									</span>
									<span class="cp-property-value">
										{{ item.first_meter_report | date: ac.shortPageDateFmt:'UTC' }} to
										{{ item.last_meter_report | date: ac.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="showMeterTraffic && item.first_meter_usage">
									<span class="cp-property-label">
										Meter Usage
										<fa-icon [icon]="['fas', 'question-circle']"
											ngbTooltip="Timeframe this activation reports in non-zero traffic against a meter"
											placement="top-start">
										</fa-icon> :

									</span>
									<span class="cp-property-value">
										{{ item.first_meter_usage | date: ac.shortPageDateFmt:'UTC' }} to
										{{ item.last_meter_usage | date: ac.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="protocolReady && item.first_protocol_report">
									<span class="cp-property-label">
										Protocol Reporting
										<fa-icon [icon]="['fas', 'question-circle']"
											ngbTooltip="Timeframe this activation reports in protocol traffic" placement="top-start">
										</fa-icon> :
									</span>
									<span class="cp-property-value">
										{{ item.first_protocol_report | date: ac.shortPageDateFmt:'UTC' }} to
										{{ item.last_protocol_report | date: ac.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block" *ngIf="protocolReady && item.first_protocol_usage">
									<span class="cp-property-label">
										Protocol Usage
										<fa-icon [icon]="['fas', 'question-circle']"
											ngbTooltip="Timeframe this activation reports in non-zero protocol traffic"
											placement="top-start">
										</fa-icon> :
									</span>
									<span class="cp-property-value">
										{{ item.first_protocol_usage | date: ac.shortPageDateFmt:'UTC' }} to
										{{ item.last_protocol_usage | date: ac.shortPageDateFmt:'UTC' }}
									</span>
								</div>

								<div class="cp-property-block"
									*ngIf="item.last_protocol_bx_version && item.last_protocol_bx_version !== ''">
									<span class="cp-property-label">Last Reported Version:</span>
									<span class="cp-property-value">
										{{ tt.cleanVersion(item.last_protocol_bx_version) }}
									</span>
								</div>

							</div>
						</div>
					</div>

					<div class="col-6" *ngIf="selectedLicenseLoading">
						<app-loading-spinner></app-loading-spinner>
					</div>

					<div class="col-6" *ngIf="showSelectedKeyLicFeatures">
						<app-key-features #hostFeatures></app-key-features>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'hostid'" class="tab-pane" role="tabpanel" id="nav-versions" aria-labelledby="nav-versions-tab">
			<table class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th class='text-start'>Earliest</th>
						<th class='text-start'>Latest</th>
						<th class='text-start'>Version</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let vh of versionHistory">
						<td class="text-start text-nowrap">
							{{ vh.first_reported | date: ac.pageDateFmt:'UTC'}}
						</td>
						<td class="text-start text-nowrap">
							{{ vh.last_reported | date: ac.pageDateFmt:'UTC'}}
						</td>
						<td class="text-start text-nowrap">
							{{ tt.cleanVersion(vh.version) }}
						</td>
					</tr>
				</tbody>
			</table>

		</div>

		<!-- stuff after this point should be identical between the staff and non-staff versions of this page -->
		<div *ngIf="pageMode === 'activation'" class="tab-pane" role="tabpanel" id="nav-meters" aria-labelledby="nav-meters-tab">

			<app-key-meters [showRawData]="showRawData"></app-key-meters>

		</div>

		<div class="tab-pane" role="tabpanel" id="nav-meter-traffic" aria-labelledby="nav-meter-traffic-tab">
			<div *ngIf="meterProductsToShow.length > 0">
				<div *ngIf="filterID !== 0 && activationIDsWithUsage.length > 1">
					Showing usage for <span class="fw-bold">{{ hostid }}</span> using <span class="fw-bold">{{ filterKey
						}}</span>
					<!-- <span *ngIf="activationIDsWithUsage.length > 1" (click)="filterTraffic(0)" class="ms-1"
						ngbTooltip="Remove filter and show all usage">
						<fa-icon icon="times-circle"></fa-icon>
					</span> -->
				</div>

				<div class="mb-1 text-start">
					<button *ngIf="showMeterTraffic" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="openUsageReport('meter-data')" placement="bottom-start"
						ngbTooltip="Get a spreadsheet with all monthly and daily meter usage data for this host id">
						<fa-icon icon="file-excel" size="sm"></fa-icon>
						Detailed Meter Traffic Report
					</button>

					<div class="btn-group me-1" *ngIf="chunkedMeterUsage.length > 0">
						<button *ngFor="let mode of timeModes" type="button" class="btn btn-sm"
							[ngClass]="{'btn-outline-secondary': mode.value !== timeMode, 'btn-secondary': mode.value === timeMode }"
							(click)="changeTimeMode(mode.value)">
							View by {{ mode.label }}
						</button>
					</div>

					<button *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv" type="button"
						class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowPhantom()">
						Hide Meter/Protocol Comparison
					</button>

					<button *ngIf="canShowMeterProtocolEquiv && !showMeterProtocolEquiv" type="button"
						class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowPhantom()">
						Show Meter/Protocol Comparison
					</button>

					<div *ngIf="timeMode === 'month'" class="cp-65 mt-1">
						Click on a month's usage to get a day by day breakdown.
					</div>
				</div>

				<div class="row justify-content-start flex-nowrap">
					<div class="col-auto overflow-auto" [ngClass]="{ 'cp-75p-max-width': (byDayMeterLoading || byDayMeterProduct !== '') }">
						<div class="row justify-content-start">
							<div *ngFor="let product of meterProductsToShow" class="col-auto">
								<div class="fw-bold">
									{{ ac.meterTypeObjects[product].label }}
									<fa-icon [icon]="['fas', 'info-circle']" [ngbTooltip]="ac.meterTypeObjects[product].description"
										placement="bottom">
									</fa-icon>

									<div *ngIf="meterLabelsByType[product] && meterLabelsByType[product].length === 1"
										style="max-width:200px">
										{{ meterLabelsByType[product].join(', ') }}
									</div>
									<div *ngIf="meterLabelsByType[product] && meterLabelsByType[product].length > 1"
										style="max-width:200px">
										Multiple Labels
										<fa-icon [icon]="['fas', 'info-circle']" [ngbTooltip]="meterLabelsByType[product].join(', ')"
											placement="bottom">
										</fa-icon>
									</div>

									<div *ngIf="anyMeterLabels && (!meterLabelsByType[product] || meterLabelsByType[product].length === 0)"
										style="max-width:200px" class="font-italic">
										No Label Set
									</div>

								</div>
								<table class="cp-std-table cp-auto-width">
									<thead>
										<tr>
											<th class='cp-usage-center'>{{ timeModeLabel }}</th>
											<th class='cp-usage-center'>
												Used
												<span *ngIf="showRawData">(MB)</span>
											</th>
											<th *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Equivalent Amount Using Protocol Usage">
													P.E.
												</span>
											</th>
											<th *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Difference Between Meter Usage and Equivalent Protocol Usage">
													P.E. &Delta;
												</span>
											</th>
											<th *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Difference Between Meter Usage and Equivalent Protocol Usage">
													P.E. &Delta; %
												</span>
											</th>
											<th *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class='cp-usage-center'>
												<span
													ngbTooltip="Projected Amount of Traffic for this Month - based on monthly reset meter(s)">
													Projected
													<span *ngIf="showRawData">(MB)</span>
												</span>
											</th>
											<th *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class='cp-usage-center'>
												<span ngbTooltip="Amount of traffic over projected amount for the month">
													Over
													<span *ngIf="showRawData">(MB)</span>
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let record of pullUsageForMeterProduct(product)">
											<td class="cp-usage-left">
												{{ makeTimeChunkHeader(record.month, record.year, false) }}
											</td>
											<td class="cp-usage-right cp-pointer"
												(click)="selectMeterDayToFocus(product, record.year, record.month)">
												<span [ngbTooltip]="makeDataTip(record.used, true, true)" ngxClipboard
													[cbContent]="record.used">
													{{ showDataValue(record.used) }}
												</span>
											</td>
											<td *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												[class]="productClass(getPhantomProduct(product)) + ' cp-usage-right text-nowrap'">
												<span ngxClipboard
													[cbContent]="getUsageForProtocolForTimeChunk(getPhantomProduct(product), record.year, record.month)">
													{{
													showDataValue(getUsageForProtocolForTimeChunk(getPhantomProduct(product),
													record.year,
													record.month)) }}
												</span>
											</td>

											<td *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												[class]="productClass(getPhantomProduct(product)) + ' cp-usage-right text-nowrap'">
												<span>
													{{ showDataValue(record.used -
													getUsageForProtocolForTimeChunk(getPhantomProduct(product),
													record.year, record.month)) }}
												</span>
											</td>
											<td *ngIf="canShowMeterProtocolEquiv && showMeterProtocolEquiv && chunkedProtocolUsage.length > 0"
												[class]="productClass(getPhantomProduct(product)) + ' cp-usage-right text-nowrap'">
												<span>
													{{ phantomPercent(record.used,
													getUsageForProtocolForTimeChunk(getPhantomProduct(product),
													record.year, record.month)) }}
												</span>
											</td>

											<td *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class="cp-usage-right">
												<span [ngbTooltip]="makeDataTip(workoutMonthsProjected(record), true, false)" ngxClipboard
													[cbContent]="workoutMonthsProjected(record)">
													{{ showDataValue(workoutMonthsProjected(record)) }}
												</span>
											</td>
											<td *ngIf="timeMode === 'month' && showOverageCounts[product] && +showOverageCounts[product] > 0"
												class="cp-usage-right">
												<span [ngbTooltip]="makeDataTip(workoutMonthsOverage(record), true, false)" ngxClipboard
													[cbContent]="workoutMonthsOverage(record)">
													{{ showDataValue(workoutMonthsOverage(record)) }}
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="col-auto overflow-auto">
						<div *ngIf="!byDayMeterLoading && byDayMeterProduct !== ''" class="fw-bold">
							{{ niceProtocol(byDayMeterProduct, true) }}
							{{ tt.getMonthName(byDayMeterMonth, true) }} {{ byDayMeterYear }}
						</div>

						<!-- <button *ngIf="!byDayMeterLoading && byDayMeterProduct !== ''" type="button"
								class="btn btn-outline-secondary btn-sm me-2 mb-1" (click)="toggleShowNoUseDays()">
								{{ showNoUseDays ? 'Hide Days With No Usage' : 'Show Days With No Usage' }}
							</button> -->

						<app-loading-spinner *ngIf="byDayMeterLoading"></app-loading-spinner>

						<table *ngIf="byDayMeterUsage.length > 0" class="cp-std-table cp-auto-width">
							<thead>
								<tr>
									<th class="cp-usage-center">
										Day
									</th>
									<th class="cp-usage-center">
										Used
										<span *ngIf="showRawData">(MB)</span>
									</th>
									<th class="cp-usage-center" title="% change from previous day">
										% Chg.
									</th>
									<th class="cp-usage-center">
										Avg.<br />Rate<br />Mbits/s
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let record of byDayMeterUsage; let i = index">
									<td class="border text-center text-nowrap">{{ dayLabel(record) }}
									</td>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(record.used)" ngxClipboard [cbContent]="record.used">
											{{ showDataValue(record.used) }}
										</span>
									</td>
									<td *ngIf="i === 0" class="cp-usage-right">-</td>
									<td *ngIf="i !== 0" class="cp-usage-right">
										{{ getDayToDayRatio('meter-data', i) | percent:percFmt }}
									</td>
									<td class="cp-usage-right">
										{{ dailyRate(record.used) | number:rateNumFmt }}
									</td>
								</tr>

								<tr *ngIf="byDayMeterUsage.length > 1">
									<th class="cp-usage-left">Total</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayMeterTotal)" ngxClipboard [cbContent]="byDayMeterTotal">
											{{ showDataValue(byDayMeterTotal) }}
										</span>
									</td>
									<td class="cp-usage-right"></td>
									<td class="cp-usage-right"></td>
								</tr>

								<tr *ngIf="byDayMeterUsage.length > 1">
									<th class="cp-usage-left">Avg.</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayMeterTotal / byDayMeterUsage.length, false)">
											{{ showDataValue(byDayMeterTotal / byDayMeterUsage.length) }}
										</span>
									</td>
									<td class="cp-usage-right">
										&plusmn; {{ byDayMeterAvgAbsChg | percent:percFmt }}
									</td>
									<td class="cp-usage-right">
										{{ dailyRate(byDayMeterTotal / byDayMeterUsage.length) | number:rateNumFmt
										}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-protocol-traffic" aria-labelledby="nav-protocol-traffic-tab">
			<div *ngIf="protocolProductsToShow.length > 0">
				<div *ngIf="filterID !== 0 && activationIDsWithUsage.length > 1">
					Showing usage for <span class="fw-bold">{{ hostid }}</span> using <span class="fw-bold">{{ filterKey
						}}</span>
					<!-- <span *ngIf="activationIDsWithUsage.length > 1" (click)="filterTraffic(0)" class="ms-1"
						ngbTooltip="Remove filter and show all usage">
						<fa-icon icon="times-circle"></fa-icon>
					</span> -->
				</div>

				<div class="mb-1 text-start">
					<button *ngIf="showProtocolTraffic" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="openUsageReport('protocol-data')" placement="bottom-start"
						ngbTooltip="Get a spreadsheet with all monthly and daily protocol usage data for this host id">
						<fa-icon icon="file-excel" size="sm"></fa-icon>
						Detailed Protocol Traffic Report
					</button>

					<button type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleAxis()" ngbTooltip="Swap Axis/Rotate"
						placement="bottom-start">
						<fa-icon [icon]="['fas', 'ruler-triangle']" size="sm" [rotate]="rotateAxis ? 90 : 0">
						</fa-icon>
					</button>

					<div class="btn-group me-1">
						<button *ngFor="let mode of timeModes" type="button" class="btn btn-sm"
							[ngClass]="{'btn-outline-secondary': mode.value !== timeMode, 'btn-secondary': mode.value === timeMode }"
							(click)="changeTimeMode(mode.value)">
							View by {{ mode.label }}
						</button>
					</div>

					<button *ngIf="showSetsButton" type="button" class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="modal"
						data-bs-target="#protocolSetsModal">
						<fa-icon [icon]="['fas', 'tasks']" size="sm">
						</fa-icon>
						Protocol Sets
					</button>

					<button *ngIf="usedProtocolBillingCodes && usedProtocolBillingCodes.length > 0" type="button"
						class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#protocolBillingCodesModal">
						<fa-icon [icon]="['fas', 'sack-dollar']" size="sm">
						</fa-icon>
						Billing Codes
					</button>

					<button *ngIf="onlyShowTotals" type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleTotalsOnly()">
						<fa-icon [icon]="['fas', 'abacus']" size="sm"></fa-icon>
						Show All Protocols
					</button>

					<button *ngIf="!onlyShowTotals" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="toggleTotalsOnly()">
						<fa-icon [icon]="['fas', 'calculator']" size="sm"></fa-icon>
						Just Show Totals
					</button>

					<button
						*ngIf="timeMode === 'month' && !protocolShowAllMonths && protocolTimeChunks.length > ac.protocolTrafficMonthsToShow"
						type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
						<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
						Show All {{ protocolTimeChunks.length }} Months
					</button>

					<button
						*ngIf="timeMode === 'month' && protocolShowAllMonths && protocolTimeChunks.length > ac.protocolTrafficMonthsToShow"
						type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
						<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
						Just Show Last {{ ac.protocolTrafficMonthsToShow }} Months
					</button>

				</div>

				<div class="row justify-content-start flex-nowrap">
					<div class="col-auto overflow-auto"
						[ngClass]="{ 'cp-75p-max-width': (byDayProtocolLoading || byDayProtocolProduct !== '') }">
						<!-- if there are billing codes being used with this Bx -->
						<div *ngIf="usedProtocolBillingCodes && usedProtocolBillingCodes.length > 0 && activeProtocolBillingCode !== -1"
							class="my-3">
							<span class="cp-table-orange p-2">
								<fa-icon (click)="selectProtocolBillingCodeFilter(-2)" [icon]="['fas', 'times-circle']"
									ngbTooltip="Remove this filter">
								</fa-icon>
								Filter:
								<span class="fw-bold">
									{{ activeProtocolBillingCodeLabel }}
								</span>
							</span>
						</div>

						<div *ngIf="timeMode === 'month'" class="cp-65">
							Click on a month's usage to get a day by day breakdown.
							<!-- <hr />
							Traffic information is for the last {{ ac.keyUsageMonthsBack }} months. -->
						</div>

						<table *ngIf="protocolProductsVisible.length > 0 && rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										Protocol
									</th>
									<th *ngFor="let ym of protocolTimeChunksToShow" class="border text-center cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, true) }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let product of protocolProductsVisible">
									<td [class]="productClass(product) + ' text-start text-nowrap fw-bold'"
										[ngbPopover]="protocolPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ niceProtocol(product, true) }}
										<span *ngIf="showRawData">(MB)</span>
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-data') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-data') }}
										</sup>
									</td>
									<td *ngFor="let ym of protocolTimeChunksToShow"
										[class]="productClass(product) + ' text-end text-nowrap'">
										<span *ngIf="product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)">
											{{ getUsageForProtocolForTimeChunk(product, ym.year, ym.month) }}%
										</span>
										<span *ngIf="!product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)"
											(click)="selectProtocolDayToFocus(product, ym.year, ym.month)" ngxClipboard
											[cbContent]="getUsageForProtocolForTimeChunk(product, ym.year, ym.month)">
											{{ showDataValue(getUsageForProtocolForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>

										<!-- <span
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)"
											ngxClipboard
											[cbContent]="getUsageForProtocolForTimeChunk(product, ym.year, ym.month)">
											{{ showDataValue(getUsageForProtocolForTimeChunk(product, ym.year,
											ym.month)) }}
										</span> -->
									</td>

									<ng-template #protocolPopupContent>
										<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
										<div class="cp-pre-text">
											{{ getProtocolPopoverLines(product, 'protocol-data').join('\n') }}
										</div>
									</ng-template>
								</tr>
							</tbody>
						</table>

						<table *ngIf="protocolProductsVisible.length > 0 && !rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										{{ timeModeLabel }}
									</th>
									<th *ngFor="let product of protocolProductsVisible"
										[class]="productClass(product) + ' text-center cp-pre-text'" [ngbPopover]="protocolPopupContent"
										popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
										{{ niceProtocol(product, true) }}
										<span *ngIf="showRawData">(MB)</span>
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-data') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-data') }}
										</sup>

										<ng-template #protocolPopupContent>
											<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
											<div class="cp-pre-text">
												{{ getProtocolPopoverLines(product, 'protocol-data').join('\n')
												}}
											</div>
										</ng-template>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let ym of protocolTimeChunksToShow">
									<td class="cp-usage-left fw-bold cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, false) }}
									</td>
									<td *ngFor="let product of protocolProductsVisible"
										[class]="productClass(product) + ' text-end text-nowrap'">

										<span *ngIf="product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)">
											{{ getUsageForProtocolForTimeChunk(product, ym.year, ym.month) }}%
										</span>
										<span *ngIf="!product.startsWith('pset-percent-used:')" placement="top-end"
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)"
											(click)="selectProtocolDayToFocus(product, ym.year, ym.month)" ngxClipboard
											[cbContent]="getUsageForProtocolForTimeChunk(product, ym.year, ym.month)">
											{{ showDataValue(getUsageForProtocolForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>

										<!-- <span
											[ngbTooltip]="makeExpDataTip(product, ym, getUsageForProtocolForTimeChunk(product, ym.year, ym.month), true, true)"
											ngxClipboard
											[cbContent]="getUsageForProtocolForTimeChunk(product, ym.year, ym.month)">
											{{ showDataValue(getUsageForProtocolForTimeChunk(product, ym.year,
											ym.month)) }}
										</span> -->
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-auto">
						<app-loading-spinner *ngIf="byDayProtocolLoading"></app-loading-spinner>

						<div *ngIf="!byDayProtocolLoading && byDayProtocolProduct !== ''" class="fw-bold">
							{{ niceProtocol(byDayProtocolProduct, true) }} -
							{{ tt.getMonthName(byDayProtocolMonth, true) }} {{ byDayProtocolYear }}
							<div *ngIf="activeProtocolBillingCodeShortLabel !== ''">
								{{ activeProtocolBillingCodeShortLabel }}
							</div>
						</div>

						<table *ngIf="!byDayProtocolLoading && byDayProtocolUsage.length > 0" class="table table-sm table-bordered">
							<thead>
								<tr>
									<th class="cp-usage-center">
										Day
									</th>
									<th class="cp-usage-center">
										Used
										<span *ngIf="showRawData">(MB)</span>
									</th>
									<th class="cp-usage-center" title="% change from previous day">
										% Chg.
									</th>
									<th class="cp-usage-center">
										Avg.<br />Rate<br />Mbits/s
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let record of byDayProtocolUsage; let i = index">
									<td [class]="productClass(byDayProtocolProduct) + ' text-center text-nowrap'">
										{{ dayLabel(record) }}
									</td>
									<td [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">
										<span [ngbTooltip]="makeDataTip(record.used)" ngxClipboard [cbContent]="record.used">
											{{ showDataValue(record.used) }}
										</span>
									</td>
									<td *ngIf="i === 0" [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">-
									</td>
									<td *ngIf="i !== 0" [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">
										{{ getDayToDayRatio('protocol-data', i) | percent:percFmt }}
									</td>
									<td [class]="productClass(byDayProtocolProduct) + ' text-end text-nowrap'">
										{{ dailyRate(record.used) | number:rateNumFmt }}
									</td>
								</tr>

								<tr *ngIf="byDayProtocolUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">
										Total
									</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayProtocolTotal)" ngxClipboard [cbContent]="byDayProtocolTotal">
											{{ showDataValue(byDayProtocolTotal) }}
										</span>
									</td>
									<td class="cp-usage-left"></td>
									<td class="cp-usage-left"></td>
								</tr>

								<tr *ngIf="byDayProtocolUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">Avg.</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeDataTip(byDayProtocolTotal/byDayProtocolUsage.length, false)">
											{{ showDataValue(byDayProtocolTotal/byDayProtocolUsage.length) }}
										</span>
									</td>
									<td class="cp-usage-right">
										&plusmn; {{ byDayProtocolAvgAbsChg | percent:percFmt }}
									</td>
									<td class="cp-usage-right">
										{{ dailyRate(byDayProtocolTotal / byDayProtocolUsage.length) |
										number:rateNumFmt }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-time-traffic" aria-labelledby="nav-time-traffic-tab">
			<div *ngIf="chronoProductsToShow.length > 0">
				<div *ngIf="filterID !== 0 && activationIDsWithUsage.length > 1">
					Showing usage for <span class="fw-bold">{{ hostid }}</span> using <span class="fw-bold">{{ filterKey
						}}</span>
					<!-- <span *ngIf="activationIDsWithUsage.length > 1" (click)="filterTraffic(0)" class="ms-1"
						ngbTooltip="Remove filter and show all usage">
						<fa-icon icon="times-circle"></fa-icon>
					</span> -->
				</div>

				<div class="mb-1 text-start">
					<button *ngIf="showChronoTraffic" type="button" class="btn btn-outline-secondary btn-sm mb-1 me-1"
						(click)="openUsageReport('protocol-time')" placement="bottom-start"
						ngbTooltip="Get a spreadsheet with all monthly and daily time-based protocol usage data for this host id">
						<fa-icon icon="file-excel" size="sm"></fa-icon>
						Detailed Time-based Traffic Report
					</button>

					<button type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleAxis()" ngbTooltip="Swap Axis/Rotate"
						placement="bottom-start">
						<fa-icon [icon]="['fas', 'ruler-triangle']" size="sm" [rotate]="rotateAxis ? 90 : 0">
						</fa-icon>
					</button>

					<div class="btn-group me-1">
						<button *ngFor="let mode of timeModes" type="button" class="btn btn-sm"
							[ngClass]="{'btn-outline-secondary': mode.value !== timeMode, 'btn-secondary': mode.value === timeMode }"
							(click)="changeTimeMode(mode.value)">
							View by {{ mode.label }}
						</button>
					</div>

					<button *ngIf="showSetsButton" type="button" class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="modal"
						data-bs-target="#chronoSetsModal">
						<fa-icon [icon]="['fas', 'tasks']" size="sm">
						</fa-icon>
						Protocol Sets
					</button>

					<button *ngIf="usedChronoBillingCodes && usedChronoBillingCodes.length > 0" type="button"
						class="btn btn-outline-secondary btn-sm me-1" data-bs-toggle="modal" data-bs-target="#chronoBillingCodesModal">
						<fa-icon [icon]="['fas', 'sack-dollar']" size="sm">
						</fa-icon>
						Billing Codes
					</button>

					<button
						*ngIf="timeMode === 'month' && !protocolShowAllMonths && chronoTimeChunks.length > ac.protocolTrafficMonthsToShow"
						type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
						<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
						Show All {{ chronoTimeChunks.length }} Months
					</button>

					<button
						*ngIf="timeMode === 'month' && protocolShowAllMonths && chronoTimeChunks.length > ac.protocolTrafficMonthsToShow"
						type="button" class="btn btn-outline-secondary btn-sm me-1" (click)="toggleShowAllProtocolMonths()">
						<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
						Just Show Last {{ ac.protocolTrafficMonthsToShow }} Months
					</button>

				</div>

				<div class="row justify-content-start flex-nowrap">
					<div class="col-auto overflow-auto"
						[ngClass]="{ 'cp-75p-max-width': (byDayProtocolLoading || byDayProtocolProduct !== '') }">

						<!-- if there are billing codes being used with this Bx -->
						<div *ngIf="usedChronoBillingCodes && usedChronoBillingCodes.length > 0 && activeChronoBillingCode !== -1"
							class="my-3">
							<span class="cp-table-orange p-2">
								<fa-icon (click)="selectChronoBillingCodeFilter(-2)" [icon]="['fas', 'times-circle']"
									ngbTooltip="Remove this filter">
								</fa-icon>
								Filter:
								<span class="fw-bold">
									{{ activeChronoBillingCodeLabel }}
								</span>
							</span>
						</div>

						<div *ngIf="timeMode === 'month'" class="cp-65">
							Click on a month's usage to get a day by day breakdown.
							<!-- <hr />
							Traffic information is for the last {{ ac.keyUsageMonthsBack }} months. -->
						</div>

						<table *ngIf="chronoProductsToShow.length > 0 && rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										Protocol
									</th>
									<th *ngFor="let ym of chronoTimeChunks" class="border text-center cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, true) }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let product of chronoProductsToShow">
									<td [class]="productClass(product) + ' text-start text-nowrap fw-bold'"
										[ngbPopover]="protocolPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
										container="body">
										{{ niceProtocol(product, true) }}
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-time') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-time') }}
										</sup>
									</td>
									<td *ngFor="let ym of chronoTimeChunks" [class]="productClass(product) + ' text-end text-nowrap'"
										(click)="selectChronoDayToFocus(product, ym.year, ym.month)">
										<span
											[ngbTooltip]="makeExpChronoTip(product, ym, getUsageForChronoForTimeChunk(product, ym.year, ym.month), true, true)"
											ngxClipboard [cbContent]="getUsageForChronoForTimeChunk(product, ym.year, ym.month)">
											{{ showChronoValue(getUsageForChronoForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>
									</td>
									<ng-template #protocolPopupContent>
										<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
										<div class="cp-pre-text">
											{{ getProtocolPopoverLines(product, 'protocol-time').join('\n') }}
										</div>
									</ng-template>
								</tr>
							</tbody>
						</table>

						<table *ngIf="chronoProductsToShow.length > 0 && !rotateAxis"
							class="table table-sm table-hover table-bordered cp-auto-width">
							<thead>
								<tr>
									<th class='border text-start'>
										{{ timeModeLabel }}
									</th>
									<th *ngFor="let product of chronoProductsToShow"
										[class]="productClass(product) + ' text-center cp-pre-text'" [ngbPopover]="protocolPopupContent"
										popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
										{{ niceProtocol(product, true) }}
										<sup *ngIf="getProtocolSuperScripts(product, 'protocol-time') !== ''"
											[ngClass]="{'text-warning': !product.startsWith('protocol-set:')}">
											{{ getProtocolSuperScripts(product, 'protocol-time') }}
										</sup>
										<ng-template #protocolPopupContent>
											<div class="cp-popover-banner">{{ niceProtocol(product, false) }}</div>
											<div class="cp-pre-text">
												{{ getProtocolPopoverLines(product, 'protocol-data').join('\n')
												}}
											</div>
										</ng-template>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let ym of chronoTimeChunks">
									<td class="cp-usage-left fw-bold cp-pre-text">
										{{ makeTimeChunkHeader(ym.month, ym.year, false) }}
									</td>
									<td *ngFor="let product of chronoProductsToShow"
										[class]="productClass(product) + ' text-end text-nowrap'"
										(click)="selectChronoDayToFocus(product, ym.year, ym.month)">
										<span
											[ngbTooltip]="makeExpChronoTip(product, ym, getUsageForChronoForTimeChunk(product, ym.year, ym.month), true, true)"
											ngxClipboard [cbContent]="getUsageForChronoForTimeChunk(product, ym.year, ym.month)">
											{{ showChronoValue(getUsageForChronoForTimeChunk(product, ym.year,
											ym.month)) }}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-auto">

						<app-loading-spinner *ngIf="byDayChronoLoading"></app-loading-spinner>

						<div *ngIf="!byDayChronoLoading && byDayChronoProduct !== ''" class="fw-bold">
							{{ niceProtocol(byDayChronoProduct, true) }} -
							{{ tt.getMonthName(byDayChronoMonth, true) }} {{ byDayChronoYear }}
							<div *ngIf="activeChronoBillingCodeShortLabel !== ''">
								{{ activeChronoBillingCodeShortLabel }}
							</div>
						</div>

						<table *ngIf="!byDayChronoLoading && byDayChronoUsage.length > 0" class="table table-sm table-bordered">
							<thead>
								<tr>
									<th class="cp-usage-center">
										Day
									</th>
									<th class="cp-usage-center">
										Used
									</th>
									<th class="cp-usage-center" title="% change from previous day">
										% Chg.
									</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let record of byDayChronoUsage; let i = index">
									<td [class]="productClass(byDayChronoProduct) + ' text-center text-nowrap'">
										{{ dayLabel(record) }}
									</td>
									<td [class]="productClass(byDayChronoProduct) + ' text-end text-nowrap'">
										<span [ngbTooltip]="makeChronoTip(record.used)" ngxClipboard [cbContent]="record.used">
											{{ showChronoValue(record.used) }}
										</span>
									</td>
									<td *ngIf="i === 0" [class]="productClass(byDayChronoProduct) + ' text-end text-nowrap'">-
									</td>
									<td *ngIf="i !== 0" [class]="productClass(byDayChronoProduct) + ' text-end text-nowrap'">
										{{ getDayToDayRatio('protocol-time', i) | percent:percFmt }}
									</td>
								</tr>

								<tr *ngIf="byDayChronoUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">
										Total
									</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeChronoTip(byDayChronoTotal)" ngxClipboard [cbContent]="byDayChronoTotal">
											{{ showChronoValue(byDayChronoTotal) }}
										</span>
									</td>
									<td class="cp-usage-left"></td>
								</tr>

								<tr *ngIf="byDayChronoUsage.length > 1" class="fw-bold">
									<th class="cp-usage-left">Avg.</th>
									<td class="cp-usage-right">
										<span [ngbTooltip]="makeChronoTip(byDayChronoTotal/byDayChronoUsage.length, false)">
											{{ showChronoValue(byDayChronoTotal/byDayChronoUsage.length) }}
										</span>
									</td>
									<td class="cp-usage-right">
										&plusmn; {{ byDayChronoAvgAbsChg | percent:percFmt }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="protocolSetsModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Protocol Sets (Data Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeProtocolSetsModalButton">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleSetsPosition()">
						<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon>
						Place Protocol Sets {{ protocolSetsPosition === 'bottom' ? 'Before' : 'After' }} Other Data
					</button>
				</div>

				<div *ngIf="supportsLinkedSet && (canManageSets || linkedProtocolSets.length > 0)" class="mt-2 border-top text-center">
					<span class="fw-bold">Linked Sets</span>
					<button *ngIf="canManageSets && linkedProtocolSets.length > 0" type="button"
						class="btn btn-outline-danger btn-sm mb-1 mt-1 ms-1" (click)="wipeLockedSets('protocol-data')" [disabled]="loading">
						<fa-icon [icon]="['fas', 'unlink']" size="sm"></fa-icon>
						Unlink Sets from this Key
					</button>

					<div *ngIf="linkedProtocolSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="linkedProtocolSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of linkedProtocolSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-secondary btn-sm w-100" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<app-card-protocol-set [object]="protocolSet" [forStaff]="false"></app-card-protocol-set>
								<!-- <div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div> -->
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet" class="mt-2 border-top text-center">
					<span class="fw-bold">Session Sets</span>
					<button *ngIf="supportsLinkedSet && canManageSets && sessionProtocolSets.length > 0" type="button"
						class="btn btn-outline-success btn-sm mb-1 mt-1 ms-1" (click)="pushToLockedSets('protocol-data')"
						[disabled]="loading">
						<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
						Link Session Sets to this Key
					</button>

					<div *ngIf="sessionProtocolSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="sessionProtocolSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of sessionProtocolSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-danger btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-data')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'minus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<app-card-protocol-set [object]="protocolSet" [forStaff]="false"></app-card-protocol-set>
								<!-- <div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div> -->
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet && filterOutSelected(selectedProtocolSets).length > 0" class="mt-2 border-top text-center">
					<span class="fw-bold">Available Sets</span>
					<div class="row">
						<div *ngFor="let protocolSet of filterOutSelected(selectedProtocolSets)" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-success btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-data')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<app-card-protocol-set [object]="protocolSet" [forStaff]="false"></app-card-protocol-set>
								<!-- <div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div> -->
							</ng-template>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<div class="modal" id="chronoSetsModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Chrono Sets (Time Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeChronoSetsModalButton">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleSetsPosition()">
						<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon>
						Place Protocol Sets {{ protocolSetsPosition === 'bottom' ? 'Before' : 'After' }} Other Data
					</button>
				</div>

				<div *ngIf="supportsLinkedSet && (canManageSets || linkedProtocolSets.length > 0)" class="mt-2 border-top text-center">
					<span class="fw-bold">Linked Sets</span>
					<button *ngIf="canManageSets && linkedChronoSets.length > 0" type="button"
						class="btn btn-outline-danger btn-sm mb-1 mt-1 ms-1" (click)="wipeLockedSets('protocol-time')" [disabled]="loading">
						<fa-icon [icon]="['fas', 'unlink']" size="sm"></fa-icon>
						Unlink Sets from this Key
					</button>

					<div *ngIf="linkedChronoSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="linkedChronoSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of linkedChronoSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-secondary btn-sm w-100" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<app-card-protocol-set [object]="protocolSet" [forStaff]="false"></app-card-protocol-set>
								<!-- <div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div> -->
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet" class="mt-2 border-top text-center">
					<span class="fw-bold">Session Sets</span>
					<button *ngIf="supportsLinkedSet && canManageSets && sessionChronoSets.length > 0" type="button"
						class="btn btn-outline-success btn-sm mb-1 mt-1 ms-1" (click)="pushToLockedSets('protocol-time')"
						[disabled]="loading">
						<fa-icon [icon]="['fas', 'link']" size="sm"></fa-icon>
						Link Session Sets to this Key
					</button>

					<div *ngIf="sessionChronoSets.length === 0" class="font-italic">
						None
					</div>

					<div *ngIf="sessionChronoSets.length !== 0" class="row">
						<div *ngFor="let protocolSet of sessionChronoSets" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-danger btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-time')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'minus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<app-card-protocol-set [object]="protocolSet" [forStaff]="false"></app-card-protocol-set>
								<!-- <div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div> -->
							</ng-template>
						</div>
					</div>
				</div>

				<div *ngIf="supportsSessionSet && filterOutSelected(selectedChronoSets).length > 0" class="mt-2 border-top text-center">
					<span class="fw-bold">Available Sets</span>
					<div class="row">
						<div *ngFor="let protocolSet of filterOutSelected(selectedChronoSets)" class="col-6 mb-1">
							<button type="button" class="btn btn-outline-success btn-sm w-100"
								(click)="toggleSessionSet(protocolSet.id, 'protocol-time')" [ngbPopover]="pSetPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
								{{ protocolSet.name }}
							</button>

							<ng-template #pSetPopupContent>
								<app-card-protocol-set [object]="protocolSet" [forStaff]="false"></app-card-protocol-set>
								<!-- <div class="cp-popover-banner">{{ protocolSet.name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getProtocolSetPopoverLines(protocolSet, true).join('\n')}}</div> -->
							</ng-template>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<div class="modal" id="protocolBillingCodesModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Billing Codes (Data Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeProtocolBillingCodesModal">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleBillingCodeDisplay()">
						Toggle Showing Code vs. Label
					</button>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeProtocolBillingCode === -1}">
					<button *ngIf="activeProtocolBillingCode === -1" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeProtocolBillingCode !== -1" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectProtocolBillingCodeFilter(-1)">
						Remove Filter
					</button>
					<span class="fw-bold">All usage data</span>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeProtocolBillingCode === 0}">
					<button *ngIf="activeProtocolBillingCode === 0" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeProtocolBillingCode !== 0" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectProtocolBillingCodeFilter(0)">
						Filter
					</button>
					<span class="fw-bold">Usage data with no billing code set</span>
				</div>

				<div *ngFor="let bc of usedProtocolBillingCodes" class="mt-1 mb-1 pt-1 pb-1 border-top"
					[ngClass]="{'table-primary text-white': activeProtocolBillingCode === bc.id}">
					<button *ngIf="activeProtocolBillingCode === bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1"
						disabled>
						Current
					</button>
					<button *ngIf="activeProtocolBillingCode !== bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectProtocolBillingCodeFilter(bc.id)">
						Filter
					</button>
					<span class="fw-bold">{{ makeBillingCodeLabel(bc) }}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="chronoBillingCodesModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Billing Codes (Time Usage)</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeChronoBillingCodesModal">

				</button>
			</div>

			<div class="modal-body">
				<div class="text-center">
					<button type="button" class="btn btn-outline-primary btn-sm" (click)="toggleBillingCodeDisplay()">
						Toggle Showing Code vs. Label
					</button>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeChronoBillingCode === -1}">
					<button *ngIf="activeChronoBillingCode === -1" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeChronoBillingCode !== -1" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectChronoBillingCodeFilter(-1)">
						Remove Filter
					</button>
					<span class="fw-bold">All usage data</span>
				</div>

				<div class="mt-1 mb-1 pt-1 pb-1 border-top" [ngClass]="{'table-primary text-white': activeChronoBillingCode === 0}">
					<button *ngIf="activeChronoBillingCode === 0" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeChronoBillingCode !== 0" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectChronoBillingCodeFilter(0)">
						Filter
					</button>
					<span class="fw-bold">Usage data with no billing code set</span>
				</div>

				<div *ngFor="let bc of usedChronoBillingCodes" class="mt-1 mb-1 pt-1 pb-1 border-top"
					[ngClass]="{'table-primary text-white': activeChronoBillingCode === bc.id}">
					<button *ngIf="activeChronoBillingCode === bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1" disabled>
						Current
					</button>
					<button *ngIf="activeChronoBillingCode !== bc.id" type="button" class="btn btn-outline-secondary btn-sm me-1"
						(click)="selectChronoBillingCodeFilter(bc.id)">
						Filter
					</button>
					<span class="fw-bold">{{ makeBillingCodeLabel(bc) }}</span>
				</div>
			</div>
		</div>
	</div>
</div>