import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-traffic-calc',
	templateUrl: './traffic-calc.component.html',
	styleUrls: ['./traffic-calc.component.scss']
})
export class TrafficCalcComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;

	theForm: UntypedFormGroup;
	loading = true;

	streams: number;
	bitrate: number;
	price: number;
	dataPerDay: number = 0;

	timeframes: any[] = [
		{ minutes: 1, label: 'Minute' },
		{ minutes: 15, label: '15 Minutes' },
		{ minutes: 30, label: '30 Minutes' },
		{ minutes: 60, label: 'Hour' },
		{ days: 1, label: 'Day' },
		{ days: 7, label: 'Week' },
		{ days: 31, label: '31 Days' },
		{ days: (365 / 4), label: 'Quarter' },
		{ days: 365, label: 'Year' }
	];

	errors: string[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService) {
	}

	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-trafficCalc.settings'))
				settings = JSON.parse(localStorage.getItem('cp-trafficCalc.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('streams')) this.streams = settings.streams;
		if (settingKeys.includes('bitrate')) this.bitrate = settings.bitrate;
		if (settingKeys.includes('price')) this.price = settings.price;

		this.initForm();
	}

	ngOnDestroy() { }

	async initForm() {
		const numKeys = 1; // at some point, this could come from template...
		this.theForm = new UntypedFormGroup({
			streams: new UntypedFormControl(this.streams),
			bitrate: new UntypedFormControl(this.bitrate),
			price: new UntypedFormControl(this.price)
		});

		this.recalc();
		this.loading = false;
	}

	onSettingChange() {
		this.streams = +this.theForm.value['streams'];
		this.bitrate = +this.theForm.value['bitrate'];
		this.price = +this.theForm.value['price'];

		let settings: any = {
			streams: this.streams,
			bitrate: this.bitrate,
			price: this.price
		};
		localStorage.setItem('cp-trafficCalc.settings', JSON.stringify(settings));

		this.recalc();
	}

	recalc() {
		if (!isNaN(this.streams) && !isNaN(this.bitrate) && this.streams > 0 && this.bitrate > 0) {
			this.dataPerDay = Math.floor(this.streams * this.bitrate * 24 * 60 * 60 / 8);
		} else {
			this.dataPerDay = 0;
		}
	}

	getMB(timeframe: any): number {
		let mb = 0;
		if (timeframe && !isNaN(this.streams) && !isNaN(this.bitrate) && this.streams > 0 && this.bitrate > 0) {
			if (timeframe['minutes'] && +timeframe['minutes'] !== 0)
				mb = this.streams * this.bitrate * 60 / 8 * timeframe['minutes'];

			if (timeframe['days'] && +timeframe['days'] !== 0)
				mb += this.streams * this.bitrate * 24 * 60 * 60 / 8 * timeframe['days'];

			mb = Math.floor(mb);
		} //
		return mb;
	}

	makeCostOld(days: number): number {
		let cost = 0;
		if (this.price && !isNaN(this.price) && this.dataPerDay > 0)
			cost = Math.floor(this.dataPerDay / 1000 * this.price / 100 * days);
		return cost;
	}

	makeCost(timeframe: any): number {
		let cost = 0;
		if (this.price && !isNaN(this.price))
			cost = Math.floor(this.getMB(timeframe) / 1000 * this.price / 100);
		return cost;
	}


	dumpFormErrors() {
		Object.keys(this.theForm.controls).forEach(key => {
			const controlErrors: ValidationErrors = this.theForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}
}
