<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.partneradmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Partners
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Partner</span>
			</button>
			<button type="button" class="btn btn-outline-secondary" (click)="loadData(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>

		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow" [ngClass]="{'text-danger': item.is_listed === 0}">
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_listed === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success" ngbTooltip="Listed"
						placement="end"></fa-icon>
					<fa-icon *ngIf="item.is_listed === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
						ngbTooltip="Hidden/Not listed" placement="end"></fa-icon>
				</td>
				<td>{{ item['__organization'] }}</td>
				<td><a [routerLink]="[item.id]" [ngbPopover]="partnerPopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ item.name }}</a>
				</td>
				<td>{{ item.extras.status }}</td>
				<td>{{ item.extras.dtype }}</td>
				<td>{{ item.extras.itype }}</td>
				<td>{{ item['__zm_certified'] }}</td>
				<td>{{ item.extras.accept_mode }}</td>
				<td class="text-center" [title]="tt.formatDateNiceUTC(item.added_on)">
					{{ item['__days_since_add'] | number }}
				</td>
				<td class="text-center">
					<span *ngIf="item.edited_on" [title]="tt.formatDateNiceUTC(item.edited_on)">
						{{ item['__days_since_update'] | number }}
					</span>
				</td>
				<ng-template #partnerPopupContent>
					<app-card-partnership [object]="item" [organizations]="organizations" [staffView]="true"></app-card-partnership>
					<!-- <div class="cp-popover-banner">{{ item.name }}</div>
					<div class="cp-pre-text">{{
						popOverTools.getPartnershipPopoverLines(item, []).join('\n')}}</div> -->
				</ng-template>
			</tr>
		</tbody>
	</table>
</div>