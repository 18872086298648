<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Templates</a></li>
			<li *ngIf="template" class="breadcrumb-item active" aria-current="page">
				{{ template.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!template">
				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['clone']" ngbTooltip="Copy/Clone"
					placement="start">
					<fa-icon icon="clone" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Clone</span> -->
				</button>

				<button *ngIf="canDelete" type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete"
					placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>


				<!-- <button *ngIf="organization.is_favorite && organization.is_favorite === 1" type="button"
					class="btn btn-outline-warning" (click)="toggleFavorite()" placement="start"
					ngbTooltip="Remove from Favorites">
					<fa-icon [icon]="starIcon" size="sm" [spin]="spinFavorite"></fa-icon>
					<span class="cp-action-button-text">-Fav</span>
				</button> -->
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-options-tab"
				aria-controls="nav-options" data-bs-target="#nav-options">
				<fa-icon [icon]="['fas', 'check-square']" size="sm"></fa-icon>
				Options
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-expiration-tab"
				aria-controls="nav-expiration" data-bs-target="#nav-expiration">
				<fa-icon [icon]="['fas', 'calendar-alt']" size="sm"></fa-icon>
				Expiry/Meters
			</button>
		</li>

		<li *ngIf="booleanPropsCount > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-features-tab"
				aria-controls="nav-features" data-bs-target="#nav-features">
				<fa-icon [icon]="['fas', 'clipboard-list-check']" size="sm"></fa-icon>
				Features
			</button>
		</li>

		<li *ngIf="numberPropsCount > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-limits-tab"
				aria-controls="nav-limits" data-bs-target="#nav-limits">
				<fa-icon [icon]="['fas', 'abacus']" size="sm"></fa-icon>
				Limits
			</button>
		</li>

		<li *ngIf="otherPropsCount > 0" class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-other-tab"
				aria-controls="nav-other" data-bs-target="#nav-other">
				<fa-icon [icon]="['fas', 'cog']" size="sm"></fa-icon>
				Other
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>


	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" role="tabpanel" id="nav-base" aria-labelledby="nav-base-tab">
			<div *ngIf="template" class="cp-property-list">

				<div class="cp-property-block">
					<span class="cp-property-label"># Keys Using this Template:</span>
					<span class="cp-property-value">
						{{ template.num_keys | number }}
						<button *ngIf="template.num_keys !== 0" type="button" class="btn btn-outline-primary btn-sm ms-1"
							(click)="runKeySearchForTemplate()">
							<fa-icon [icon]="['fas', 'search']" size="sm"></fa-icon>
							Find Keys Using Licensing/Search
						</button>

						<button *ngIf="userTemplateIDs.includes(id)" type="button" class="btn btn-outline-primary btn-sm ms-1"
							[routerLink]="['/' + ac.urls.licensing, 'new']" [queryParams]="{templateID: id}" ngbTooltip="Create new key"
							placement="bottom">
							<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
							Add Key
						</button>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Product:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + ac.urls.licensingadmin, 'products', this.product.id]" [ngbPopover]="keyProductPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ product.label }} ({{ product.name }})
						</a>
						<ng-template #keyProductPopupContent>
							<app-card-key-product [object]="product"></app-card-key-product>
						</ng-template>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ template.name }}</span>
				</div>

				<div *ngIf="template.description !== ''" class="cp-property-block">
					<span class="cp-property-label">Description:</span>
					<span class="cp-property-value cp-pre-text">{{ template.description }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Key Types:</span>
					<ul class='mb-0'>
						<li *ngFor="let keyType of template.settings.keyTypes" class="cp-property-value">
							{{ keyType | titlecase }}
						</li>
					</ul>
				</div>

				<div *ngIf="template.settings.defaultKeyType && template.settings.defaultKeyType !== ''" class="cp-property-block">
					<span class="cp-property-label">Default Key Type:</span>
					<span class="cp-property-value">
						{{ template.settings.defaultKeyType | titlecase }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Requires Organization:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.requiresOrganization) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Edit-Only:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.editOnly) }}</span>
				</div>

				<!-- <div class="cp-property-block">
					<span class="cp-property-label">Quick-Key:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.quickKey) }}</span>
				</div> -->

				<div *ngIf="users.length > 0" class="cp-property-block">
					<span class="cp-property-label">Users With Access:</span>
					<ul class='mb-0'>
						<li *ngFor="let user of users" class="cp-property-value">
							<a [routerLink]="['/' + ac.urls.users, user.id]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ user.name }}</a>
							<fa-icon *ngIf="user.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="User is not enabled"></fa-icon>
							<ng-template #userPopupContent>
								<app-card-user [object]="user"></app-card-user>
							</ng-template>
						</li>
					</ul>
				</div>

				<div *ngIf="userGroups.length > 0" class="cp-property-block">
					<span class="cp-property-label">User Groups With Access:</span>
					<ul class='mb-0'>
						<li *ngFor="let grp of userGroups" class="cp-property-value">
							<a [routerLink]="['/' + ac.urls.usergroups, grp.id]">{{ grp.name }}</a>
							<fa-icon *ngIf="grp.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
						</li>
					</ul>
				</div>

				<div *ngIf="limitOrgs.length > 0" class="cp-property-block">
					<span class="cp-property-label">Limited Organizations:</span>
					<ul class="mb-0">
						<li *ngFor="let organization of limitOrgs" class="cp-property-value">
							<a [routerLink]="['/' + ac.urls.organizations, organization.id]" [ngbPopover]="organizationPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ organization.name }}</a>
							<fa-icon *ngIf="organization.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Organization is not enabled"></fa-icon>
							<ng-template #organizationPopupContent>
								<app-card-organization [object]="organization"></app-card-organization>
							</ng-template>
						</li>
					</ul>
				</div>

				<div *ngIf="limitOrgGroups.length > 0" class="cp-property-block">
					<span class="cp-property-label">Limited Organization Groups:</span>
					<ul class="mb-0">
						<li *ngFor="let group of limitOrgGroups" class="cp-property-value">
							<a [routerLink]="['/' + ac.urls.organizationgroups, group.id]">
								{{ group.name }}</a>
							<fa-icon *ngIf="group.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
						</li>
					</ul>
				</div>

				<div *ngIf="excludeOrgs.length > 0" class="cp-property-block">
					<span class="cp-property-label">Excluded Organizations:</span>
					<ul class="mb-0">
						<li *ngFor="let organization of excludeOrgs" class="cp-property-value">
							<a [routerLink]="['/' + ac.urls.organizations, organization.id]" [ngbPopover]="organizationPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ organization.name }}</a>
							<fa-icon *ngIf="organization.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Organization is not enabled"></fa-icon>
							<ng-template #organizationPopupContent>
								<app-card-organization [object]="organization"></app-card-organization>
							</ng-template>
						</li>
					</ul>
				</div>

				<div *ngIf="excludeOrgGroups.length > 0" class="cp-property-block">
					<span class="cp-property-label">Excluded Organization Groups:</span>
					<ul class="mb-0">
						<li *ngFor="let group of excludeOrgGroups" class="cp-property-value">
							<a [routerLink]="['/' + ac.urls.organizationgroups, group.id]">
								{{ group.name }}</a>
							<fa-icon *ngIf="group.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Group is not enabled"></fa-icon>
						</li>
					</ul>
				</div>

				<div *ngIf="template.settings.orgTypes.length > 0" class="cp-property-block">
					<span class="cp-property-label">Limited Organization Types:</span>
					<ul class="mb-0">
						<li *ngFor="let orgType of template.settings.orgTypes" class="cp-property-value">
							{{ ac.organizationTypeLabels[orgType] }}
						</li>
					</ul>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ template.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="addedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="template.added_on !== template.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ template.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="editedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-options" aria-labelledby="nav-options-tab">
			<div *ngIf="template && product" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Requires a Salesforce Opportunity:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.requiresSalesforceOpportunity)
						}}</span>
				</div>

				<div *ngIf="template.settings.salesforceOpportunityMustMatch" class="cp-property-block">
					<span class="cp-property-label">Salesforce Opportunity must be tied to the Organization's Salesforce
						Account:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.salesforceOpportunityMustMatch)
						}}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Default # of Activations:</span>
					<span class="cp-property-value">{{ template.settings.defaultActivations | number }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Minimum # of Activations:</span>
					<span class="cp-property-value">{{ template.settings.minActivations | number }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Maximum # of Activations:</span>
					<span class="cp-property-value">{{ template.settings.maxActivations | number }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Maximum # of Keys to Generate at a Time:</span>
					<span class="cp-property-value">{{ template.settings.maxKeys | number }}</span>
				</div>

				<div *ngIf="template.settings.defaultCommercialType && template.settings.defaultCommercialType !== ''"
					class="cp-property-block">
					<span class="cp-property-label">Default Commercial Type:</span>
					<span class="cp-property-value">
						{{ defaultCommercialTypeToShow }}
					</span>
				</div>

				<div *ngIf="template.settings.defaultCommercialInfo && template.settings.defaultCommercialInfo !== ''"
					class="cp-property-block">
					<span class="cp-property-label">Default Commercial Info:</span>
					<span class="cp-property-value">
						{{ template.settings.defaultCommercialInfo }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Keys must have a commercial type set:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.requiresCommercialType) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Enabled Keys must be shared with at least one user:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.requiresLinkedUsers) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Default Value for "Add To Your Own Keys":</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.defaultShareWithSelf) }}</span>
				</div>

			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-expiration" aria-labelledby="nav-expiration-tab">
			<div *ngIf="template && product" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Expiry Modes:</span>
					<ul class="mb-0">
						<li *ngFor="let expiryMode of template.settings.expiryModes" class="cp-property-value">
							{{ mt.fetchLabel(ac.keyExpiryLabels, expiryMode) }}
						</li>
					</ul>
				</div>

				<div *ngIf="template.settings.defaultExpiryMode && template.settings.defaultExpiryMode !== ''" class="cp-property-block">
					<span class="cp-property-label">Default Expiry Mode:</span>
					<span class="cp-property-value">
						{{ mt.fetchLabel(ac.keyExpiryLabels, template.settings.defaultExpiryMode) }}
					</span>
				</div>

				<div *ngIf="template.settings.defaultExpirationCount > 0" class="cp-property-block">
					<span class="cp-property-label">Default Expiry:</span>
					<span class="cp-property-value">
						{{ template.settings.defaultExpirationCount | number }}
						{{ template.settings.defaultExpirationUnit }}
					</span>
				</div>

				<div *ngIf="template.settings.maxExpirationCount > 0" class="cp-property-block">
					<span class="cp-property-label">Maximum Expiry:</span>
					<span class="cp-property-value">
						{{ template.settings.maxExpirationCount | number }}
						{{ template.settings.maxExpirationUnit }}
					</span>
				</div>

				<div *ngIf="template.settings.warningDaysOfWeek.length > 0" class="cp-property-block">
					<span class="cp-property-label">Days of Week For Expiration Warning:</span>
					<ul class="mb-0">
						<li *ngFor="let warningDayOfWeek of template.settings.warningDaysOfWeek" class="cp-property-value">
							{{ warningDayOfWeek }}
						</li>
					</ul>
				</div>

				<div *ngIf="template.settings.limitMeterProducts.length > 0" class="cp-property-block">
					<span class="cp-property-label">Available Meter Type/Products:</span>
					<ul class="mb-0">
						<li *ngFor="let meterProduct of template.settings.limitMeterProducts" class="cp-property-value">
							{{ ac.meterTypeObjects[meterProduct].label }}
						</li>
					</ul>
				</div>

				<div *ngIf="template.settings.requiredMeterProducts.length > 0" class="cp-property-block">
					<span class="cp-property-label">Required Meter Type/Products:</span>
					<ul class="mb-0">
						<li *ngFor="let meterProduct of template.settings.requiredMeterProducts" class="cp-property-value">
							{{ ac.meterTypeObjects[meterProduct].label }}
						</li>
					</ul>
				</div>

				<div *ngIf="template.settings.limitMeterReset.length > 0" class="cp-property-block">
					<span class="cp-property-label">Meter Reset Options:</span>
					<ul class="mb-0">
						<li *ngFor="let meterReset of template.settings.limitMeterReset" class="cp-property-value">
							{{ mt.fetchLabel(ac.meterResetOptions, meterReset) }}
						</li>
					</ul>
				</div>

				<div *ngIf="template.settings.defaultMeterLimit && template.settings.defaultMeterLimit > 0" class="cp-property-block">
					<span class="cp-property-label">Default Meter Limit:</span>
					<span class="cp-property-value">{{ tt.formattedMB(template.settings.defaultMeterLimit)
						}}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Meters must have labels:</span>
					<span class="cp-property-value">{{ niceBoolean2(template.settings.requiresMeterLabels) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Default Meter Label:</span>
					<span class="cp-property-value">{{ template.settings.defaultMeterLabel }}</span>
				</div>

			</div>
		</div>


		<div class="tab-pane" role="tabpanel" id="nav-features" aria-labelledby="nav-features-tab">
			<div class="cp-general-width-limit">

				<div *ngIf="nonCheckAllFeatures && nonCheckAllFeatures.length > 0" class="cp-property-list">
					<div class="cp-property-block">
						<span class="cp-property-label">Features Excluded from Check All:</span>
						<span class="cp-property-value">{{ nonCheckAllFeatures.join(', ') }}</span>
					</div>
				</div>

				<table *ngIf="!loading" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th>Label (Name)</th>
							<th class='text-center'>Can<br />Change</th>
							<th class='text-center'>Def</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pp of getProductPropertiesByType('boolean')">
							<td class="border-end">
								<a [routerLink]="['/' + ac.urls.licensingadmin, 'properties', pp.property.id]"
									[ngbTooltip]="pp.property.label">
									{{ chopString(pp.property.label) }} ({{ pp.property.name }})
								</a>
							</td>
							<td class="border-end text-center">{{ niceBoolean(pp.can_be_changed) }}</td>
							<td class="border-end text-center">{{ niceDefault(pp) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-limits" aria-labelledby="nav-limits-tab">
			<div *ngIf="nonAllUnlimitedLimits && nonAllUnlimitedLimits.length > 0" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Limits Excluded from Check All Unlimited:</span>
					<span class="cp-property-value">{{ nonAllUnlimitedLimits.join(', ') }}</span>
				</div>
			</div>

			<div class="cp-general-width-limit">
				<table *ngIf="!loading" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th>Label (Name)</th>
							<th class='text-center'>Units</th>
							<th class='text-center'>Can<br />Change</th>
							<th class='text-end'>Def</th>
							<th class='text-end'>Min.</th>
							<th class='text-end'>Max.</th>
							<th class='text-center'>Allow<br />Unlim.</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pp of getProductPropertiesByType('number')">
							<td class="border-end">
								<a [routerLink]="['/' + ac.urls.licensingadmin, 'properties', pp.property.id]"
									[ngbTooltip]="pp.property.label">
									{{ chopString(pp.property.label) }} ({{ pp.property.name }})
								</a>
							</td>
							<td class="border-end">{{ pp.property.units }}</td>
							<td class="border-end text-center">{{ niceBoolean(pp.can_be_changed) }}</td>
							<td class="border-end text-end">{{ niceDefault(pp) }}</td>
							<td class="border-end text-end"> {{ pp.min_value }} </td>
							<td class="border-end text-end"> {{ pp.max_value }} </td>
							<td class="border-end text-center">
								<span *ngIf="pp.property.allow_unlimited === 0" class="font-italic">
									{{ niceBoolean(pp.property.allow_unlimited) }}
								</span>
								<span *ngIf="pp.property.allow_unlimited === 1">
									{{ niceBoolean(pp.allow_unlimited) }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-other" aria-labelledby="nav-other-tab">
			<div class="cp-general-width-limit">
				<table *ngIf="!loading" class="cp-std-table cp-auto-width">
					<thead>
						<tr>
							<th>Label (Name)</th>
							<th class='text-center'>Can<br />Change</th>
							<th>Def</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pp of getProductPropertiesByType('other')">
							<td class="border-end">
								<a [routerLink]="['/' + ac.urls.licensingadmin, 'properties', pp.property.id]"
									[ngbTooltip]="pp.property.label">
									{{ chopString(pp.property.label) }} ({{ pp.property.name }})
								</a>
							</td>
							<td class="border-end text-center">{{ niceBoolean(pp.can_be_changed) }}</td>
							<td class="border-end">{{ niceDefault(pp) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-admin-log" aria-labelledby="nav-admin-log-tab">
			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>