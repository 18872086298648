import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { PlatformsService } from 'client/app/components/app-sections/platforms/platforms.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-documents-table',
	templateUrl: './documents-table.component.html',
	styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;
	sharedLicenseTools = SharedLicenseTools;

	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	@Input() parentApi !: any;

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	showProduct: boolean = true;

	items: Models.Document[] = [];
	itemsToShow: Models.Document[] = [];

	platforms: Models.Platform[] = [];

	columnDefs: any[] = [
		{
			field: 'is_enabled',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Enabled/Disabled',
			icon: 'power-off'
		}, {
			field: '__has_url',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Link',
			icon: 'external-link-square-alt'
		}, {
			field: '__has_file',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'File',
			icon: 'book'
		}, {
			field: 'is_private',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Access',
			icon: 'lock'
		}, {
			field: 'prod_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: 'label',
			type: 'baseText',
			sortType: 'text',
			label: 'Label',
			toolTip: null,
			icon: null
		}, {
			field: '__num_orgs',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Organizations with Direct Access',
			icon: AppConstants.sectionIcons.organizations
		}, {
			field: '__num_org_groups',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Groups with Direct Access',
			icon: AppConstants.sectionIcons.files
		}, {
			field: '__num_packages',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Packages with Direct Access',
			icon: AppConstants.sectionIcons.packages
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}, {
			field: '__days_since_download',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days since last download',
			icon: 'file-download'
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private platformsService: PlatformsService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.platforms = this.platformsService.getAll();

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.Document[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('showProduct')) this.showProduct = options.showProduct;

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		if (!this.showProduct) {
			this.fieldsToShow.splice(this.fieldsToShow.indexOf('prod_name'), 1);
		}

		for (const item of this.items) {
			item['__has_url'] = item.link && item.link !== '' ? 1 : 0;
			item['__has_file'] = item.file_id !== 0 ? 1 : 0;
			item['__num_orgs'] = item.org_ids.length;
			item['__num_org_groups'] = item.group_ids.length;
			item['__num_packages'] = item.package_ids.length;
			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);
			if (item.last_download)
				item['__days_since_download'] = MiscTools.diffDays(this.now, item.last_download, true);
			else
				item['__days_since_download'] = 99999999;
		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'prod_name', 'asc');

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.Document[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.prod_name.toLowerCase().includes(w)
						|| (item.label && item.label.toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

}
