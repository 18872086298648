import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { MySettingsService } from './my-settings.service';
import { MyKeysService } from '../my-keys/my-keys.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-my-settings',
	templateUrl: './my-settings.component.html',
	styleUrls: ['./my-settings.component.scss']
})
export class MySettingsComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	user: Models.User;
	// myPackages: Models.Package[] = [];
	// availablePackages: Models.Package[] = [];
	// subscribing = false;
	loading = true;
	orgUsers: Models.User[] = [];
	// info = 'Being subscribed to a package gives you access to downloadable software, documentation and/or the ability to generate license keys.';
	stagedEmail = '';
	starPref = '';

	canAccessAllFiles: boolean = false;

	constructor(
		private mySettingsService: MySettingsService,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private myKeysService: MyKeysService) { }

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.authUser = authUser;

			this.getData();
			this.loadTables();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async getData() {
		this.loading = true;

		this.user = await this.mySettingsService.getUser();
		this.stagedEmail = TextTools.getUserPropValue(this.user, AppConstants.stagedEmailKey).trim();
		this.starPref = TextTools.getUserPropValue(this.user, AppConstants.starIconKey).trim();

		this.loading = false;
	}

	async loadTables() {
		this.loading = true;

		this.orgUsers = await this.mySettingsService.getOrgUsers();

		this.loading = false;
	}
}
