import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { MyQuizzesService } from '../my-quizzes.service';

@Component({
	selector: 'app-my-quiz-view',
	templateUrl: './my-quiz-view.component.html',
	styleUrls: ['./my-quiz-view.component.scss']
})

export class MyQuizViewComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	loading = true;
	loadingResults = false;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	quiz: Models.Quiz;
	private userSubscription: Subscription;

	canManageThisQuiz = false;
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	canTakeQuiz: boolean = false;

	myResponse: Models.QuizResponse = null;
	showMyAnswers: boolean = false;

	processedResults: any = {};

	currentQuestion: number = -1
	showAllQuestions: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private myQuizzesService: MyQuizzesService,
		private uiAlertsService: UiAlertsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.loadData();
			});
		});
	}

	// ************************************************************************************
	ngOnInit(): void {
		// changes

	}

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ************************************************************************************
	async loadData() {
		this.loading = true;

		await this.reloadResults();

		this.canManageThisQuiz = ValidationTools.checkAccess(this.authUser, 'manage-quizzes')
			|| this.quiz.added_by === this.authUser.id
			|| (this.quiz.config.adminIds && this.quiz.config.adminIds.includes(this.authUser.id));

		if (this.quiz.config.questions && this.quiz.config.questions.length > 0) {
			this.currentQuestion = 0;
			if (this.canManageThisQuiz)
				this.canTakeQuiz = this.quiz.status !== 'complete';
			else
				this.canTakeQuiz = this.quiz.status === 'active';
		} // if

		// this.addedByUser = null;
		// if (this.quiz.added_by && this.quiz.added_by !== 0)
		// 	this.addedByUser = this.usersService.getOne(this.quiz.added_by);

		// this.editedByUser = null;
		// if (this.quiz.edited_by && this.quiz.edited_by !== 0)
		// 	this.editedByUser = this.usersService.getOne(this.quiz.edited_by);

		this.loading = false;
	}

	// ************************************************************************************
	async reloadResults() {
		this.loadingResults = true;

		try {
			this.quiz = MiscTools.deepClone(await this.myQuizzesService.getOne(this.id));
		} catch (e) {
			this.router.navigate([AppConstants.urls.myquizzes]);
			return;
		}
		if (!this.quiz || this.quiz == null || this.quiz.id === 0) {
			this.router.navigate([AppConstants.urls.myquizzes]);
			return;
		} // if

		if (this.quiz.responses) {
			this.processedResults = {};

			let response: Models.QuizResponse = MiscTools.pickItem(this.quiz.responses, 'user_id', this.authUser.id);
			if (!response) response = new Models.QuizResponse(this.quiz.id, this.authUser.id, '', {});

			for (const q of this.quiz.config.questions) {
				let answersAsString: string = '';
				let isCorrect: boolean = false;
				try {
					if (q.answerType === 'multi-choice-multi') {
						let answers: string[] = [];
						if (response != null && response.answers && response.answers[q.id])
							answers = response.answers[q.id];

						let cleanAnswers: string[] = [];
						for (const answer of answers)
							cleanAnswers.push(answer.trim().toLowerCase());

						let numCorrect: number = 0;
						for (const correctAnswer of q.answers)
							if (cleanAnswers.includes(correctAnswer.toLowerCase().trim()))
								numCorrect++;
						isCorrect = numCorrect === q.answers.length;
						answersAsString = answers.join(', ');
					} else {
						if (response != null && response.answers && response.answers[q.id])
							answersAsString = response.answers[q.id];
						if (answersAsString) answersAsString = answersAsString.trim();
						for (const correctAnswer of q.answers)
							if (answersAsString && answersAsString.toLowerCase().trim() === correctAnswer.toLowerCase().trim())
								isCorrect = true;
					} // if
				} catch (e) { }
				if (this.quiz.config.quizType !== 'quiz') isCorrect = true;

				this.processedResults[q.id + '-' + response.user_id] = {
					answer: answersAsString,
					isCorrect
				};
			} // for

			this.myResponse = response;
			this.showMyAnswers = this.myResponse && this.quiz.status === 'complete' && this.quiz.config.quizType === 'quiz';
		} // if
		this.loadingResults = false;
	}

	// ************************************************************************************
	getButtonClass(qNum: number): string {
		let className: string = '';

		if (this.showMyAnswers) {
			const qId = this.quiz.config.questions[qNum].id;
			let isCorrect: boolean = false;
			if (this.processedResults[qId + '-' + this.authUser.id]
				&& this.processedResults[qId + '-' + this.authUser.id].isCorrect)
				isCorrect = true;

			if (isCorrect)
				if (this.currentQuestion === qNum)
					className = 'btn-success';
				else
					className = 'btn-outline-success';
			else
				if (this.currentQuestion === qNum)
					className = 'btn-danger';
				else
					className = 'btn-outline-danger';

		} else {
			if (this.currentQuestion === qNum)
				className = 'btn-primary';
			else
				className = 'btn-outline-primary';
		} // if


		// [ngClass]=" { 'btn-outline-primary': currentQuestion !== i, 'btn-primary': currentQuestion === i  }">

		return className;
	}

}
