import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { BuildsService } from '../builds.service';
import { ProductsService } from '../../products/products.service';
import { FilesService } from '../../files/files.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { PackagesService } from '../../packages/packages.service';
import { OrganizationGroupsService } from '../../organizations/organization-groups.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-build-form',
	templateUrl: './build-form.component.html',
	styleUrls: ['./build-form.component.scss']
})
export class BuildFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	mt = MiscTools;

	// 'standard' edit stuff
	id: number;
	build: Models.Build;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	productID = 0;
	product: Models.Product;
	releaseNotesFile: Models.File;

	allFiles: Models.File[] = [];
	unusedFiles: Models.File[] = [];
	fileMode: string = 'unused';

	docFiles: Models.File[] = [];
	installerFiles: Models.File[] = [];

	organizations: Models.Organization[] = [];
	groups: Models.OrganizationGroup[] = [];
	packages: Models.Package[] = [];
	platforms: Models.Platform[] = [];

	counter = 0;

	labelHelperText = 'Label - optional';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private buildsService: BuildsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private filesService: FilesService,
		private organizationsService: OrganizationsService,
		private organizationGroupsService: OrganizationGroupsService,
		private packagesService: PackagesService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.build = this.buildsService.getOne(this.id);
				if (!this.build || this.build == null || this.build.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.buildsService.refreshOne(this.id);
					this.productID = this.build.product_id;
					this.editMode = true;
				}
			} else {
				this.productID = +params.get('product_id');
				this.build = new Models.Build(0, this.productID, '', '', '', '', 0, 1, 1);
			}
		});
	}

	ngOnInit(): void {
		if (this.editMode) {
			this.listSubscription = this.buildsService.builds.subscribe(builds => {
				this.build = builds.find((build: Models.Build) => build.id === this.id);
				this.initForm();
			});
		} else {
			this.initForm();
		}
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	async initForm() {
		this.allFiles = this.filesService.getAll();

		this.allFiles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

		this.unusedFiles = await this.filesService.fetchUnassignedFromDB();
		this.rebuildSubLists();

		this.organizations = this.organizationsService.getAll();
		this.groups = this.organizationGroupsService.getAll();
		this.packages = this.packagesService.getAll();
		this.product = this.productsService.getOne(this.productID);
		if (this.build && !!this.build.release_notes_file_id) {
			this.releaseNotesFile = this.filesService.getOne(this.build.release_notes_file_id);
		}

		let labelValidators: any[] = [];
		if (ValidationTools.hasFlag(this.product, 'builds_require_label')) {
			this.labelHelperText = 'Label - required';
			labelValidators = [Validators.required];
		} // if

		const allPlatforms = this.platformsService.getAll();
		this.platforms = MiscTools.sortPlatforms(allPlatforms, this.product.platform_ids);

		const platformsArr = new UntypedFormArray([]);
		for (const pl of this.platforms) {
			const idx = MiscTools.findIndexGeneric(this.build.platform_files, 'platform_id', pl.id);
			if (idx !== -1) {
				const pFile = this.build.platform_files[idx];

				platformsArr.push(
					new UntypedFormGroup({
						platform_id: new UntypedFormControl(pFile.platform_id),
						file_id: new UntypedFormControl(pFile.file_id),
						label: new UntypedFormControl(pl.name)
					})
				);
			} else {
				platformsArr.push(
					new UntypedFormGroup({
						platform_id: new UntypedFormControl(pl.id),
						file_id: new UntypedFormControl(null),
						label: new UntypedFormControl(pl.name)
					})
				);
			}
		}

		// using form control names that match object's property names makes
		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(this.build.is_enabled),
			is_private: new UntypedFormControl(this.build.is_private),
			is_retired: new UntypedFormControl(this.build.is_retired),
			version: new UntypedFormControl(this.build.version, [Validators.required]),
			label: new UntypedFormControl(this.build.label, labelValidators),
			information: new UntypedFormControl(this.build.information),
			release_notes: new UntypedFormControl(this.build.release_notes),
			release_notes_file_id: new UntypedFormControl(this.build.release_notes_file_id),
			org_ids: new UntypedFormControl(this.build.org_ids),
			group_ids: new UntypedFormControl(this.build.group_ids),
			package_ids: new UntypedFormControl(this.build.package_ids),
			platforms: platformsArr
		});

		this.refreshCounter();
		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		let numFiles = 0;
		const platformFiles: Models.PlatformFile[] = [];
		for (const platformCtl of this.controls) {
			const platformId = +platformCtl.value.platform_id;
			const fileId = +platformCtl.value.file_id;
			const label = ''; // not using for now...
			if (fileId !== 0) numFiles++;
			// console.log('platformID = ' + platformId + ' fileID = ' + fileId);
			platformFiles.push({ platform_id: platformId, file_id: fileId, label });
		}

		if (!this.editMode) {
			this.build.product_id = this.productID;
		}

		if (numFiles === 0
			&& !confirm('This build does not have any platform files.  Do you wish to continue?')) {
			this.saving = false;
			return;
		}

		const preSaveOrgIds: number[] = this.build.org_ids.slice();
		const preSaveGroupIds: number[] = this.build.group_ids.slice();
		const preSavePkgIds: number[] = this.build.package_ids.slice();

		this.build.is_enabled = +this.theForm.value.is_enabled;
		this.build.is_private = +this.theForm.value.is_private;
		this.build.is_retired = +this.theForm.value.is_retired;
		this.build.version = this.theForm.value.version;
		this.build.label = this.theForm.value.label;
		this.build.information = this.theForm.value.information;
		this.build.release_notes = this.theForm.value.release_notes;
		this.build.release_notes_file_id = +this.theForm.value.release_notes_file_id;
		this.build.org_ids = this.theForm.value.org_ids;
		this.build.group_ids = this.theForm.value.group_ids;
		this.build.package_ids = this.theForm.value.package_ids;
		this.build.platform_files = platformFiles;

		if (ValidationTools.hasFlag(this.product, 'private_only') && this.build.is_private === 0)
			this.errors.push('This product only supports private builds.');

		if (this.build.label == null) this.build.label = '';

		if (ValidationTools.hasFlag(this.product, 'builds_require_label') && (!this.build.label || this.build.label.trim() === ''))
			this.errors.push('This product (' + this.product.name + ') requires builds to have labels.');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		try {
			let retBuild: Models.Build;
			if (this.editMode)
				retBuild = await this.buildsService.updateOne(this.build);
			else
				retBuild = await this.buildsService.addOne(this.build);

			if (retBuild) {
				const orgIDsToUpdate = [];
				// find orgs that have been added
				for (const orgID of this.build.org_ids)
					if (preSaveOrgIds.indexOf(orgID) === -1)
						orgIDsToUpdate.push(orgID);

				// find orgs that have been removed
				for (const orgID of preSaveOrgIds)
					if (this.build.org_ids.indexOf(orgID) === -1)
						orgIDsToUpdate.push(orgID);

				for (const orgID of orgIDsToUpdate)
					this.organizationsService.refreshOne(orgID);

				// ******************************************
				const groupIDsToUpdate = [];
				// find groups that have been added
				for (const groupID of this.build.group_ids)
					if (preSaveGroupIds.indexOf(groupID) === -1)
						groupIDsToUpdate.push(groupID);

				// find groups that have been removed
				for (const groupID of preSaveGroupIds)
					if (this.build.group_ids.indexOf(groupID) === -1)
						groupIDsToUpdate.push(groupID);

				for (const groupID of groupIDsToUpdate)
					this.organizationGroupsService.refreshOne(groupID);

				// ******************************************
				const pkgIDsToUpdate = [];
				// find pkgs that have been added
				for (const pkgID of this.build.package_ids)
					if (preSavePkgIds.indexOf(pkgID) === -1)
						pkgIDsToUpdate.push(pkgID);

				// find pkgs that have been removed
				for (const pkgID of preSavePkgIds)
					if (this.build.package_ids.indexOf(pkgID) === -1)
						pkgIDsToUpdate.push(pkgID);

				for (const pkgID of pkgIDsToUpdate)
					this.packagesService.refreshOne(pkgID);

				// ******************************************
				this.router.navigate([AppConstants.urls.builds + '/' + retBuild.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}

		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		if (this.editMode) {
			this.router.navigate(['..'], { relativeTo: this.route });
		} else {
			// this.router.navigate(['..'], {relativeTo: this.route});
			this.router.navigate([AppConstants.urls.builds]);
		}
	}

	async refreshFiles(justAvailable = true) {
		if (justAvailable)
			this.unusedFiles = await this.filesService.fetchUnassignedFromDB();
		else
			this.filesService.refreshAll().subscribe(availableFiles => this.unusedFiles = availableFiles);
		this.rebuildSubLists();
	}

	onPrivateChange() {
		const isPrivate = +this.theForm.value.is_private;
		const orgIDs: number[] = this.theForm.value.org_ids;
		const groupIDs: number[] = this.theForm.value.group_ids;
		const packageIDs: number[] = this.theForm.value.package_ids;

		if (isPrivate === 0
			&& ((orgIDs && orgIDs.length > 0)
				|| (groupIDs && groupIDs.length > 0)
				|| (packageIDs && packageIDs.length > 0)))
			this.uiAlertsService.addMsg('If you are making this build public, do you still need to assign direct access?',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);

	}

	toggleFileMode() {
		if (this.fileMode === 'all')
			this.fileMode = 'unused';
		else
			this.fileMode = 'all';

		this.rebuildSubLists();
	}

	rebuildSubLists() {
		this.docFiles = [];
		this.installerFiles = [];

		if (this.fileMode === 'all') {
			for (const f of this.allFiles) {
				const ext = TextTools.getExtension(f.name, true);
				if (AppConstants.docExtensions.indexOf(ext) !== -1) this.docFiles.push(f);
				if (AppConstants.installerExtensions.indexOf(ext) !== -1) this.installerFiles.push(f);
			} // for
		} else {
			for (const f of this.unusedFiles) {
				const ext = TextTools.getExtension(f.name, true);
				if (AppConstants.docExtensions.indexOf(ext) !== -1) this.docFiles.push(f);
				if (AppConstants.installerExtensions.indexOf(ext) !== -1) this.installerFiles.push(f);
			} // for
		} // for


		if (this.build.release_notes_file_id && this.build.release_notes_file_id !== 0) {
			const theFile = this.filesService.getOne(this.build.release_notes_file_id);
			this.docFiles.push(theFile);
		} // if

		for (const platformFile of this.build.platform_files) {
			if (platformFile.file_id && platformFile.file_id !== 0) {
				const theFile = this.filesService.getOne(platformFile.file_id);
				this.installerFiles.push(theFile);
			} // if
		}
	}

	refreshCounter() {
		this.counter = 0;
		for (const platformCtl of this.controls) {
			const fileId = +platformCtl.value.file_id;
			if (fileId !== 0) this.counter++;
		}
	}

	hasFlag = (obj: Models.Organization | Models.User | Models.Product, flag: string): boolean => {
		return ValidationTools.hasFlag(obj, flag);
	};

	get controls() { // a getter!
		return (this.theForm.get('platforms') as UntypedFormArray).controls;
	}

	get version() { return this.theForm.get('version'); }
}
