import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
// import ValidationTools from 'appshared/validation-tools';
// import SharedLicenseTools from 'appshared/shared-license-tools';
// import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-card-user',
	templateUrl: './card-user.component.html',
	styleUrls: ['./card-user.component.scss']
})
export class CardUserComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.User = null;
	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.User = null;

	// options


	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];


	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.User) {

		this.objectToShow = MiscTools.deepClone(object);

		if (!this.objectToShow) return;

		this.nameToShow = this.objectToShow.name;
		this.typeToShow = 'User';
		this.iconToShow = AppConstants.sectionIcons.users;

		this.infoLines = [];

		// enabled
		if (this.objectToShow.is_enabled === 1)
			this.infoLines.push('Enabled: Yes');
		else
			this.infoLines.push('Enabled: No');

		this.infoLines.push('Role: ' + AppConstants.userRoleLabels[this.objectToShow.role]);

		if (this.objectToShow.org_name)
			this.infoLines.push('Organization: ' + this.objectToShow.org_name);
		else
			this.infoLines.push('Organization: not linked to an organization');

		this.infoLines.push('E-Mail: ' + this.objectToShow.email);

		this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.added_on));
		if (this.objectToShow.last_login) this.infoLines.push('Last Login: ' + TextTools.formatDateNiceUTC(this.objectToShow.last_login));
		if (this.objectToShow.last_download) this.infoLines.push('Last Download: ' + TextTools.formatDateNiceUTC(this.objectToShow.last_download));

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
