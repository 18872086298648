import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import TrackRecent from '../../../../helpers/track-recent';

import { UsersService } from '../users.service';
import { AuthService } from 'client/app/services/auth.service';
import { PackagesService } from '../../packages/packages.service';
import { ReportsService } from '../../reports/reports.service';
import { OrganizationsService } from '../../organizations/organizations.service';

import { UsersTableComponent } from 'client/app/components/shared/users-table/users-table.component';

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;
	now = new Date();

	@ViewChild('usersTable1') usersTable1: UsersTableComponent = null;

	// 'standard' list stuff
	users: Models.User[];
	usersToShow: Models.User[];

	loading = true;
	refreshing = false;

	// other stuff
	private userSubscription: Subscription;

	canAdd = false;

	showFilters = false;
	theForm: UntypedFormGroup;

	allOrgs: Models.Organization[] = [];

	// things to choose from
	enabledChoices = [];
	roleChoices = [];
	issueChoices = [];
	specialChoices = [
		{ value: 'org_user', label: 'Linked to organization' },
		{ value: 'standalone_user', label: 'Not linked to organization' },
		{ value: 'has_packages', label: 'Uses packages' },
		{ value: 'no_packages', label: 'Does not user packages' },
		{ value: 'has_keys', label: 'Has license keys' },
		{ value: 'no_keys', label: 'No license keys' },
		{ value: 'has_logins', label: 'Has logged in' },
		{ value: 'no_logins', label: 'Has not logged in' },
		{ value: 'has_downloads', label: 'Has downloaded a file' },
		{ value: 'no_downloads', label: 'No downloads' },
		{ value: 'uses_sso', label: 'Single sign on user' },
		{ value: 'not_sso', label: 'Not single sign on' },
		{ value: 'notify_configured', label: 'Has configured notifications' },
		{ value: 'notify_not_configured', label: 'Has not configured notifications' },
		{ value: 'has_api_keys', label: 'Has API Key(s)' },

		{ value: 'linked_to_sflead', label: 'Linked to a Salesforce lead' },
		{ value: 'not_linked_to_sflead', label: 'Not linked to a Salesforce lead' },
		{ value: 'linked_to_sfcontact', label: 'Linked to a Salesforce contact' },
		{ value: 'not_linked_to_sfcontact', label: 'Not linked to a Salesforce contact' },

		{ value: 'never_welcomed', label: 'User wasn\'t welcomed after being added' },
		{ value: 'upcoming_deletion', label: 'User will be deleted in the next 30 days' },

		{ value: 'probable_org', label: 'User not linked to an Organization, but might be part of one' },
		{ value: 'billing_code_admin', label: 'User is billing code admin for their organization' }
	];

	packageChoices = [];

	// filters
	enabledFilter: number = null;
	roleFilters = [];
	specialFilters = [];
	packageFilters = [];

	recentUsers: Models.User[] = [];

	constructor(
		private usersService: UsersService,
		private packagesService: PackagesService,
		private reportsService: ReportsService,
		private organizationsService: OrganizationsService,
		private authService: AuthService,
	) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		let settings: any = {};
		try {
			if (localStorage.getItem('cp-userList.settings'))
				settings = JSON.parse(localStorage.getItem('cp-userList.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('enabledFilter')) this.enabledFilter = settings.enabledFilter;
		if (settingKeys.includes('roleFilters')) this.roleFilters = settings.roleFilters;
		if (settingKeys.includes('packageFilters')) this.packageFilters = settings.packageFilters;
		if (settingKeys.includes('specialFilters')) this.specialFilters = settings.specialFilters;

		this.showFilters = (
			this.enabledFilter != null
			|| this.roleFilters.length !== 0
			|| this.packageFilters.length !== 0
			|| this.specialFilters.length !== 0
		);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {
				this.users = this.usersService.getAll();

				this.canAdd = ValidationTools.checkAccess(authUser, 'manage-basic-users-no-org');
				if (!this.canAdd)
					for (const organizationType of AppConstants.organizationTypes)
						if (ValidationTools.checkAccess(authUser, 'manage-basic-users-' + organizationType))
							this.canAdd = true;
			}
			this.initForm();
			this.filterUsers();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async refresh() {
		this.refreshing = true;
		try {
			await this.usersService.refreshAll().toPromise();
			this.users = this.usersService.getAll();
			this.filterUsers();
		} catch (err) {
			// console.error('Caught an error refreshing list');
		}
		this.refreshing = false;
	}

	// ------------------------------------------------------------------------
	initForm() {
		const recentIds = TrackRecent.getRecent('user');
		recentIds.reverse();

		this.recentUsers = [];
		for (const recentId of recentIds) {
			const user = this.usersService.getOne(recentId);
			if (user) this.recentUsers.push(user);
		} // for


		// setup choices...

		// make a new/bad org and then get the issues - these are the selections
		const badUser = new Models.User(0, 0, '', '', '', 'basic', '', '', '', 1, 0);
		const userIssues = ValidationTools.getUserIssues(badUser, true);

		this.issueChoices = [];
		for (const userIssue of userIssues)
			this.issueChoices.push({ value: userIssue, label: userIssue });
		this.issueChoices.push({ value: 'Not welcomed.', label: 'Not welcomed.' });

		this.enabledChoices = [];
		this.enabledChoices.push({ value: 1, label: 'Enabled/Online' });
		this.enabledChoices.push({ value: 0, label: 'Disabled/Offline' });

		this.roleChoices = [];
		for (const role of AppConstants.userRoles)
			this.roleChoices.push({ value: role, label: AppConstants.userRoleLabels[role] });

		// do package choices...
		const packages: Models.Package[] = this.packagesService.getAll();
		this.packageChoices = [];
		for (const pkg of packages)
			this.packageChoices.push({ value: pkg.id, label: pkg.name });

		this.theForm = new UntypedFormGroup({
			enabledFilter: new UntypedFormControl(this.enabledFilter),
			roleFilters: new UntypedFormControl(this.roleFilters),
			packageFilters: new UntypedFormControl(this.packageFilters),
			specialFilters: new UntypedFormControl(this.specialFilters)
		});
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.enabledFilter = this.theForm.value.enabledFilter;
		this.roleFilters = this.theForm.value.roleFilters;
		this.packageFilters = this.theForm.value.packageFilters;
		this.specialFilters = this.theForm.value.specialFilters;

		let settings: any = {
			enabledFilter: this.enabledFilter,
			roleFilters: this.roleFilters,
			packageFilters: this.packageFilters,
			specialFilters: this.specialFilters
		};
		localStorage.setItem('cp-userList.settings', JSON.stringify(settings));

		this.filterUsers();
	}

	// ------------------------------------------------------------------------
	async filterUsers() {
		this.loading = true;

		if ((this.specialFilters.includes('probable_org') || this.specialFilters.includes('billing_code_admin')) && this.allOrgs.length === 0)
			this.allOrgs = this.organizationsService.getAll();

		this.usersToShow = [];
		for (const user of this.users) {
			let passedEnabledFilter = false;
			let passedRoleFilters = false;
			let passedPackageFilters = false;
			let passedSpecialFilters = false;

			if (this.enabledFilter == null || this.enabledFilter === user.is_enabled)
				passedEnabledFilter = true;

			if (!this.roleFilters || this.roleFilters.length === 0 || this.roleFilters.includes(user.role))
				passedRoleFilters = true;

			if (!this.packageFilters || this.packageFilters.length === 0) {
				passedPackageFilters = true;
			} else {
				let foundOne = false;
				for (const pkgID of this.packageFilters)
					if (user.package_ids.includes(pkgID)) foundOne = true;
				passedPackageFilters = foundOne;
			}

			passedSpecialFilters = true;
			if (!this.specialFilters || this.specialFilters.length === 0) {
				passedSpecialFilters = true;
			} else {
				passedSpecialFilters = true;
				if (this.specialFilters.includes('org_user') && (!user.org_id || user.org_id === 0))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('standalone_user') && user.org_id !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_packages') && user.package_ids.length === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_packages') && user.package_ids.length !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_keys') && user.num_keys === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_keys') && user.num_keys !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_logins') && user.last_login == null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_logins') && user.last_login !== null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_downloads') && user.last_download == null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_downloads') && user.last_download !== null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('uses_sso') && user.use_sso === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('not_sso') && user.use_sso === 1)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('notify_configured') && user.notifications == null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('notify_not_configured') && user.notifications != null)
					passedSpecialFilters = false;

				const salesforceLeadID = TextTools.getUserPropValue(user, AppConstants.salesforceLeadIDKey);
				if (this.specialFilters.includes('linked_to_sflead') && salesforceLeadID === '')
					passedSpecialFilters = false;

				if (this.specialFilters.includes('not_linked_to_sflead') && salesforceLeadID !== '')
					passedSpecialFilters = false;

				const salesforceContactID = TextTools.getUserPropValue(user, AppConstants.salesforceContactIDKey);
				if (this.specialFilters.includes('linked_to_sfcontact') && salesforceContactID === '')
					passedSpecialFilters = false;

				if (this.specialFilters.includes('not_linked_to_sfcontact') && salesforceContactID !== '')
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_api_keys') && user.num_api_keys === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('upcoming_deletion')) {
					const cleanupInfo = ValidationTools.workOutUserCleanUp(user);
					if (!cleanupInfo || !cleanupInfo.cleanupDate || MiscTools.diffDays(cleanupInfo.cleanupDate, new Date()) > 30)
						passedSpecialFilters = false;
				} // if

				if (this.specialFilters.includes('never_welcomed')) {
					let welcomed = false;
					if (user.use_sso === 1 || user.last_login != null || user.reset_password_expires != null
						|| !user.added_by || user.added_by === user.id || user.edited_by === user.id)
						welcomed = true;
					if (welcomed) passedSpecialFilters = false;
				} // if

				if (this.specialFilters.includes('probable_org')) {
					if (user.org_id && user.org_id !== 0) {
						passedSpecialFilters = false;
					} else {
						let match: boolean = false;
						const domain = TextTools.getEmailDomain(user.email).toLowerCase();
						for (const org of this.allOrgs) {
							const splitDomains: string[] = org.email_domains.split(',');
							for (const splitDomain of splitDomains)
								if (splitDomain.trim().toLowerCase() === domain)
									match = true;
						} // for
						if (!match) passedSpecialFilters = false;
					} // if
				} // if

				if (this.specialFilters.includes('billing_code_admin')) {
					passedSpecialFilters = false;
					if (user.org_id && user.org_id !== 0) {
						const theirOrg: Models.Organization = MiscTools.pickItem(this.allOrgs, 'id', user.org_id);
						if (theirOrg && theirOrg.billing_code_admins && theirOrg.billing_code_admins.includes(user.id))
							passedSpecialFilters = true;
					} // if
				} // if
			} // if

			if (passedEnabledFilter
				&& passedRoleFilters
				&& passedPackageFilters
				&& passedSpecialFilters) this.usersToShow.push(user);
		} // for

		await MiscTools.delay(100);
		if (this.usersTable1) this.usersTable1.updateContent(this.usersToShow, 'cp-users-list', { showOrganization: true });

		this.loading = false;
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	async openBigReport() {
		let args = '';
		await this.reportsService.runReport('PortalUsersReport', args);
	}
}
