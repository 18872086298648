// tslint:disable: no-string-literal
import * as yaml from 'js-yaml';

import * as Models from './shared-models';
import AppConstants from './app-constants';

import MiscTools from './misc-tools';
import TextTools from './text-tools';

class SharedLicenseTools {
	// ************************************************************************************************
	// build url args for key search
	static buildKeySearchArgs = (searchSettings: Models.KeySearchSettings): string => {
		const argsArr = [];

		if (searchSettings.productsFilter && searchSettings.productsFilter.length > 0)
			argsArr.push('products=' + encodeURIComponent(searchSettings.productsFilter.join(',')));

		if (searchSettings.typesFilter && searchSettings.typesFilter.length > 0)
			argsArr.push('types=' + encodeURIComponent(searchSettings.typesFilter.join(',')));

		if (searchSettings.keyFilter && searchSettings.keyFilter !== '')
			argsArr.push('keys=' + encodeURIComponent(searchSettings.keyFilter));

		if (searchSettings.hostidFilter && searchSettings.hostidFilter !== '')
			argsArr.push('hostids=' + encodeURIComponent(searchSettings.hostidFilter));

		if (searchSettings.textFilter && searchSettings.textFilter !== '')
			argsArr.push('text=' + encodeURIComponent(searchSettings.textFilter));

		if (searchSettings.reportDays && searchSettings.reportDays !== 0)
			argsArr.push('reportDays=' + encodeURIComponent(searchSettings.reportDays));

		if (searchSettings.usageDays && searchSettings.usageDays !== 0)
			argsArr.push('usageDays=' + encodeURIComponent(searchSettings.usageDays));

		if (searchSettings.touchedDays && searchSettings.touchedDays !== 0)
			argsArr.push('touchedDays=' + encodeURIComponent(searchSettings.touchedDays));

		if (searchSettings.notTouchedDays && searchSettings.notTouchedDays !== 0)
			argsArr.push('notTouchedDays=' + encodeURIComponent(searchSettings.notTouchedDays));

		if (searchSettings.updatedDays && searchSettings.updatedDays !== 0)
			argsArr.push('updatedDays=' + encodeURIComponent(searchSettings.updatedDays));

		if (searchSettings.userIDs && searchSettings.userIDs.length > 0)
			argsArr.push('userIDs=' + encodeURIComponent(searchSettings.userIDs.join(',')));

		if (searchSettings.protocolsFilter && searchSettings.protocolsFilter.length > 0)
			argsArr.push('protocols=' + encodeURIComponent(searchSettings.protocolsFilter.join(',')));

		if (searchSettings.brandsFilter && searchSettings.brandsFilter.length > 0)
			argsArr.push('brands=' + encodeURIComponent(searchSettings.brandsFilter.join(',')));

		if (searchSettings.specialFilter && searchSettings.specialFilter.length > 0)
			argsArr.push('special=' + encodeURIComponent(searchSettings.specialFilter.join(',')));

		if (searchSettings.acctOwnerIDs && searchSettings.acctOwnerIDs.length > 0)
			argsArr.push('acctOwnerIDs=' + encodeURIComponent(searchSettings.acctOwnerIDs.join(',')));

		if (searchSettings.techRepIDs && searchSettings.techRepIDs.length > 0)
			argsArr.push('techRepIDs=' + encodeURIComponent(searchSettings.techRepIDs.join(',')));

		if (searchSettings.andBooleanKeyProperties && searchSettings.andBooleanKeyProperties.length > 0)
			argsArr.push('andBooleanKeyProperties=' + encodeURIComponent(searchSettings.andBooleanKeyProperties.join(',')));

		if (searchSettings.orBooleanKeyProperties && searchSettings.orBooleanKeyProperties.length > 0)
			argsArr.push('orBooleanKeyProperties=' + encodeURIComponent(searchSettings.orBooleanKeyProperties.join(',')));

		if (searchSettings.zcpTouchedBy && searchSettings.zcpTouchedBy.length > 0)
			argsArr.push('zcpTouchedBy=' + encodeURIComponent(searchSettings.zcpTouchedBy.join(',')));

		if (searchSettings.orgTypeFilter && searchSettings.orgTypeFilter.length > 0)
			argsArr.push('orgTypeFilter=' + encodeURIComponent(searchSettings.orgTypeFilter.join(',')));

		if (searchSettings.orgIDs && searchSettings.orgIDs.length > 0)
			argsArr.push('orgIDs=' + encodeURIComponent(searchSettings.orgIDs.join(',')));

		if (searchSettings.billingCodeFilter && searchSettings.billingCodeFilter.length > 0)
			argsArr.push('billingCodeFilter=' + encodeURIComponent(searchSettings.billingCodeFilter.join(',')));

		if (searchSettings.commercialTypeFilter && searchSettings.commercialTypeFilter.length > 0)
			argsArr.push('commercialTypeFilter=' + encodeURIComponent(searchSettings.commercialTypeFilter.join(',')));

		if (searchSettings.keyTemplateFilter && searchSettings.keyTemplateFilter.length > 0)
			argsArr.push('keyTemplateFilter=' + encodeURIComponent(searchSettings.keyTemplateFilter.join(',')));

		if (searchSettings.protocolSetFilter && searchSettings.protocolSetFilter.length > 0)
			argsArr.push('protocolSetFilter=' + encodeURIComponent(searchSettings.protocolSetFilter.join(',')));


		return argsArr.join('&');
	}

	// ************************************************************************************************
	// parse url parameters for license search
	static parseKeySearchSettings = (queryString: string): Models.KeySearchSettings => {
		const returnObj: Models.KeySearchSettings = new Models.KeySearchSettings();
		if (queryString && queryString !== '') {
			// console.log('queryString');
			// console.log(queryString);
			const parts: string[] = queryString.split('&');
			// console.log(parts);
			for (const part of parts) {
				const key: string = TextTools.getLabel(part, '=');
				const value: string = decodeURIComponent(TextTools.getValue(part, '='));
				// console.log(key + '=' + value);
				if (value.trim() !== '') {
					switch (key) {
						case 'products':
							returnObj.productsFilter = value.split(',');
							break;
						case 'types':
							returnObj.typesFilter = value.split(',');
							break;
						case 'keys':
							returnObj.keyFilter = value;
							break;
						case 'hostids':
							returnObj.hostidFilter = value;
							break;
						case 'text':
							returnObj.textFilter = value;
							break;
						case 'reportDays':
							returnObj.reportDays = +value;
							break;
						case 'usageDays':
							returnObj.usageDays = +value;
							break;
						case 'touchedDays':
							returnObj.touchedDays = +value;
							break;
						case 'notTouchedDays':
							returnObj.notTouchedDays = +value;
							break;
						case 'expiryDays':
							returnObj.expiryDays = +value;
							break;
						case 'usagePercent':
							returnObj.usagePercent = +value;
							break;
						case 'updatedDays':
							returnObj.updatedDays = +value;
							break;
						case 'userIDs':
							returnObj.userIDs = TextTools.splitStringToNumbers(value);
							break;
						case 'protocols':
							returnObj.protocolsFilter = value.split(',');
							break;
						case 'brands':
							returnObj.brandsFilter = value.split(',');
							break;
						case 'special':
							returnObj.specialFilter = value.split(',');
							break;
						case 'orgIDs':
							returnObj.orgIDs = TextTools.splitStringToNumbers(value);
							break;
						case 'acctOwnerIDs':
							returnObj.acctOwnerIDs = TextTools.splitStringToNumbers(value);
							break;
						case 'techRepIDs':
							returnObj.techRepIDs = TextTools.splitStringToNumbers(value);
							break;
						case 'andBooleanKeyProperties':
							returnObj.andBooleanKeyProperties = value.split(',');
							break;
						case 'orBooleanKeyProperties':
							returnObj.orBooleanKeyProperties = value.split(',');
							break;
						case 'zcpTouchedBy':
							returnObj.zcpTouchedBy = TextTools.splitStringToNumbers(value);
							break;
						case 'orgTypeFilter':
							returnObj.orgTypeFilter = value.split(',');
							break;
						case 'billingCodeFilter':
							returnObj.billingCodeFilter = value.split(',');
							break;
						case 'commercialTypeFilter':
							returnObj.commercialTypeFilter = value.split(',');
							break;
						case 'keyTemplateFilter':
							returnObj.keyTemplateFilter = TextTools.splitStringToNumbers(value);
							break;
						case 'protocolSetFilter':
							returnObj.protocolSetFilter = TextTools.splitStringToNumbers(value);
							break;

					} // switch
				} // if
			} // for
		} // if

		// these search options are deprecated...
		if (returnObj.specialFilter && returnObj.specialFilter.length !== 0) {
			if (returnObj.specialFilter.includes('about_to_expire_include') && !returnObj.specialFilter.includes('about_to_expire'))
				returnObj.specialFilter.push('about_to_expire');

			if (returnObj.specialFilter.includes('recently_expired_include') && !returnObj.specialFilter.includes('recently_expired'))
				returnObj.specialFilter.push('recently_expired');
		} // if

		return returnObj;
	};

	// ************************************************************************************************
	static makeSafeCustomer = (customer: string, info: string, org: Models.Organization) => {
		const maxLength = 64;
		let safeCustomer = '';
		if (org && org.name !== '') {
			const extra = ' id=' + org.id;
			safeCustomer = TextTools.chopString(org.name, maxLength - extra.length, '') + extra;
		} else if (customer && customer !== '') {
			safeCustomer = TextTools.chopString(customer, maxLength, '');
		} else if (info && info !== '') {
			safeCustomer = TextTools.chopString(info, maxLength, '');
		}
		return safeCustomer;
	};

	// ************************************************************************************************
	static getExpiryMode = (activation: Models.LPActivation) => {
		// figure out this.expireMode
		if (activation.meters && activation.meters.length > 0)
			return 'meter';
		else if (activation.expires_at)
			return 'date';
		else if (!activation.duration || activation.duration === 0)
			return 'never';
		else if (activation.duration && activation.duration !== 0)
			return 'duration';
		return ''; // assumes date expiration as default...
	};

	// ************************************************************************************************
	static meterDescription = (meter: Models.LPMeter, withType: boolean = false) => {
		if (!meter) return '';

		let now = new Date();
		let starts = new Date();
		if (meter.starts_at) starts = new Date(meter.starts_at);
		const exp = new Date(meter.expires_at);

		let desc = '';
		if (meter.label && meter.label.trim() !== '')
			desc = meter.label + '. ';

		if (meter.resets === 'monthly') desc += 'Monthly';
		if (meter.resets === 'never') desc += 'No reset';

		if (withType) desc += ' ' + AppConstants.meterTypeObjects[meter.product].label;
		desc += ' meter.';

		if (meter.expires_at && meter.expires_at != null) desc += ' Expires ' + TextTools.formatDateNiceUTC(meter.expires_at) + '.';

		if (meter.projected && !isNaN(meter.projected) && +meter.projected > 0)
			desc += ' Projected usage threshold is ' + TextTools.formattedMB(meter.projected) + '.';

		desc += ' Meter maximum is ' + TextTools.formattedMB(meter.limit) + '.';
		if (meter.used && !isNaN(meter.used) && +meter.used > 0) {
			desc += ' Current usage is ' + TextTools.formattedMB(meter.used) + '.';
			if (meter.projected && !isNaN(meter.projected) && +meter.projected > 0)
				desc += ' (' + (+meter.used / +meter.projected * 100).toFixed(1) + '% Projected - '
					+ (+meter.used / +meter.limit * 100).toFixed(1) + '% Max.)';
			else
				desc += ' (' + (+meter.used / +meter.limit * 100).toFixed(1) + '% Max.)';
		} // if

		if (exp)
			if (exp.getTime() < now.getTime())
				desc += ' (Expired)';
			else if (starts.getTime() > now.getTime())
				desc += ' (Has Not Started)';

		return desc;
	};

	// *********************************************************
	static buildMeterSummary = (meters: Models.LPMeter[]): any => {
		const meterSummaryInfo: any = {};
		const now = new Date();

		const metersByType: any = {};
		for (const meter of meters) {
			let starts = new Date(now);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const exp = new Date(meter.expires_at);
			if (meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
				if (!metersByType[meter.product]) metersByType[meter.product] = [];
				metersByType[meter.product].push(meter);
			} // if
		} // for

		for (const product of Object.keys(metersByType)) {
			let neverLimit = 0;
			let neverProjected = 0;
			let neverUsed = 0;

			let monthlyLimit = 0;
			let monthlyProjected = 0;
			let monthlyUsed = 0;

			let neverCount: number = 0;
			let monthlyCount: number = 0;

			for (const meter of metersByType[product]) {
				if (meter.resets === 'monthly') {
					monthlyUsed += +meter.used;
					monthlyLimit += +meter.limit;
					monthlyProjected += +meter.projected;
					monthlyCount++;
				} // if
				if (meter.resets === 'never') {
					neverUsed += +meter.used;
					neverLimit += +meter.limit;
					neverProjected += +meter.projected;
					neverCount++;
				}  // if
			} // for

			meterSummaryInfo[product + '.never'] = { resets: 'never', product, used: neverUsed, limit: neverLimit, projected: neverProjected, count: neverCount };
			meterSummaryInfo[product + '.monthly'] = { resets: 'monthly', product, used: monthlyUsed, limit: monthlyLimit, projected: monthlyProjected, count: monthlyCount };
		} // for

		return meterSummaryInfo;
	} // buildMeterSummary

	// ************************************************************************************************
	static niceKeyExpiryMode = (expiryMode: string, shortLabel = false) => {
		if (shortLabel) {
			const idx = MiscTools.findIndexGeneric(AppConstants.keyExpiryShortLabels, 'value', expiryMode);
			if (idx !== -1) return AppConstants.keyExpiryShortLabels[idx].label;
		} else {
			const idx = MiscTools.findIndexGeneric(AppConstants.keyExpiryLabels, 'value', expiryMode);
			if (idx !== -1) return AppConstants.keyExpiryLabels[idx].label;
		}

		return '??'
	};

	// ************************************************************************************************
	static niceKeyDuration = (duration: number) => {
		const idx = MiscTools.findIndexGeneric(AppConstants.keyDurationOptionsExtras, 'value', duration);
		if (idx !== -1) return AppConstants.keyDurationOptionsExtras[idx].label;
		return '??'
	};

	// ************************************************************************************************
	static protocolSort = (a: string, b: string) => {
		// protocolSort(a: string, b: string) {
		const aRank = SharedLicenseTools.protocolSortRank(a);
		const bRank = SharedLicenseTools.protocolSortRank(b);

		if (aRank < bRank) {
			return -1;
		} else if (aRank > bRank) {
			return 1;
		} else {
			if (a < b) {
				return -1;
			} else if (a > b) {
				return 1;
			}
		}
		return 0;
	};

	// ************************************************************************************************
	static protocolSortRank = (protocol: string) => {
		// protocolRank(product: string) {
		if (protocol.includes('transcoded'))
			return 2;
		else if (protocol.endsWith('_in'))
			return 0;
		else if (protocol.endsWith('_out'))
			return 3;
		else if (protocol.endsWith('_in_secs'))
			return 1;
		else if (protocol.endsWith('_out_secs'))
			return 4;
		else
			return 5;
	};

	// ************************************************************************************************
	static getSortedProtocolFields = (fields: string[], classification: string) => {
		if (!fields || !classification || classification === '')
			return;

		const filtered: string[] = [];

		for (const field of fields) {
			if (classification === 'private') {
				if (field.startsWith('private_'))
					filtered.push(field);

			} else if (classification === 'mediaconnect') {
				if (field.startsWith('mediaconnect_'))
					filtered.push(field);

			} else if (classification === 'transcoded') {
				if (field.includes('transcoded'))
					filtered.push(field);

			} else if (classification === 'public') {
				if (!field.startsWith('private_')
					&& !field.startsWith('mediaconnect_')
					&& !field.includes('transcoded'))
					filtered.push(field);
			} // if
		} // for

		filtered.sort(SharedLicenseTools.altProtocolSort);

		return filtered;
	};

	// ************************************************************************************************
	static altProtocolSort = (a: string, b: string) => {
		let aRank = SharedLicenseTools.altProtocolSortRank(a);
		let bRank = SharedLicenseTools.altProtocolSortRank(b);

		if (aRank !== bRank)
			return aRank > bRank ? 1 : -1;
		else
			return a > b ? 1 : -1;
	};


	// ************************************************************************************************
	static altProtocolSortRank = (protocol: string) => {
		const rankings = [
			'zixi_push_in',
			'zixi_pull_in',
			'zixi_other_in',
			'zixi_push_out',
			'zixi_pull_out',
			'zixi_other_out',

			'rist_in',
			'rist_out',

			'srt_in',
			'srt_out',

			'rtmp_push_in',
			'rtmp_pull_in',
			'rtmp_push_out',
			'rtmp_pull_out',

			'rtp_fec_in',
			'rtp_fec_out',

			'udp_in',
			'udp_out',

			'rtsp_pull_in',

			'http_in',
			'http_out',

			'ndi_in',
			'ndi_out',

			'webrtc_out',

			'intel_transcoded',
			'nvidia_transcoded',
			'x264_transcoded',
			'transcoded',
		];

		let rankVersion = protocol;
		if (rankVersion.startsWith('private_')) rankVersion = rankVersion.substring('private_'.length);
		else if (rankVersion.startsWith('mediaconnect_')) rankVersion = rankVersion.substring('mediaconnect_'.length);

		let rank = rankings.indexOf(rankVersion);
		if (rank === -1)
			rank = rankings.length + 100;

		return rank;
	};

	// ************************************************************************************************
	// can pass in either a meter type (output_mb, output_mb_meter, protected_mb)
	// or an influx protocol field - zixi_pull_in, srt_out, etc.
	static niceProtocol = (protocol: string, useShort: boolean) => {
		const acronyms = ['asi', 'fec', 'http', 'mmt', 'ndi', 'rist', 'rtmp', 'rtp', 'rtsp', 'srt', 'udp', 'zm', 'spts', 'st2110', 'jpegxs', 'cdi', 'webrtc'];

		if (AppConstants.meterTypeObjects[protocol]) {
			if (useShort)
				return (AppConstants.meterTypeObjects[protocol].label);
			else
				return (AppConstants.meterTypeObjects[protocol].fullLabel);
		} else {
			let ret = protocol;
			if (protocol === 'total_in' || protocol === 'total_in' + AppConstants.timeProtocolSuffix) {
				ret = 'Total (In)';
			} else if (protocol === 'total_out' || protocol === 'total_out' + AppConstants.timeProtocolSuffix) {
				ret = 'Total (Out)';
			} else if (protocol === 'total_transcoded' || protocol === 'total_transcoded' + AppConstants.timeProtocolSuffix) {
				ret = 'Total (Transcoded)';
			} else if (protocol === 'total' || protocol === 'total' + AppConstants.timeProtocolSuffix) {
				ret = 'Total';
			} else if (protocol === 'total_inout' || protocol === 'total_inout' + AppConstants.timeProtocolSuffix) {
				ret = 'Total (In+Out)';
			} else {
				ret = '';
				const parts = protocol.split(/_/g);
				for (const part of parts)
					if (part === 'mediaconnect')
						ret += 'MediaConnect ';
					else if (acronyms.includes(part))
						ret += part.toUpperCase() + ' ';
					else
						ret += part.charAt(0).toUpperCase() + part.slice(1) + ' ';

				ret = ret.trim();
				if (ret.endsWith(' Secs')) {
					ret = ret.substring(0, ret.length - 5);
					if (!useShort) ret += ' (time)';
				} // if
			} // if

			if (useShort) {
				ret = ret.replace('Private', 'Priv.');
				ret = ret.replace('Transcoded', 'Trans.');
				ret = ret.replace('MediaConnect', 'Mcon.');
			} // if

			return ret;
		} // if
	};

	// ************************************************************************************************
	static licenseRecentlyReported = (license: Models.LPLicense, numDays: number = AppConstants.recentUsedHostsDays): boolean => {
		if (license.last_meter_report && MiscTools.daysSince(license.last_meter_report) < numDays)
			return true;

		if (license.last_protocol_report && MiscTools.daysSince(license.last_protocol_report) < numDays)
			return true;

		return false;
	};

	// ************************************************************************************************
	static licenseRecentlyUsed = (license: Models.LPLicense): boolean => {
		if (license.last_meter_usage && MiscTools.daysSince(license.last_meter_usage) < AppConstants.recentUsedHostsDays)
			return true;

		if (license.last_protocol_usage && MiscTools.daysSince(license.last_protocol_usage) < AppConstants.recentUsedHostsDays)
			return true;

		return false;
	};

	// ************************************************************************************************
	static licenseNeedsUpgrade = (license: Models.LPLicense): boolean => {
		if (license.first_meter_report
			&& MiscTools.daysSince(license.last_meter_report) < AppConstants.recentUsedHostsDays
			&& (!license.first_protocol_report
				|| MiscTools.daysSince(license.last_protocol_report) > AppConstants.recentUsedHostsDays))
			return true;
		return false;
	};

	// ************************************************************************************************
	static hostNotCommunicating = (license: Models.LPLicense): boolean => {
		if (!license.updated_at) return true;

		let daysSinceUpdate = -1;
		if (license.updated_at)
			daysSinceUpdate = MiscTools.daysSince(license.updated_at, false);

		// when looking at the last report times, compare to the start of today (UTC)
		// instead of now()
		const startOfToday = new Date();
		startOfToday.setUTCHours(0);
		startOfToday.setUTCMinutes(0);
		startOfToday.setUTCSeconds(0);
		if (license.last_meter_report && (MiscTools.diffDays(startOfToday, license.last_meter_report, false) < daysSinceUpdate || daysSinceUpdate === -1))
			daysSinceUpdate = MiscTools.diffDays(startOfToday, license.last_meter_report, false);

		if (license.last_protocol_report && (MiscTools.diffDays(startOfToday, license.last_protocol_report, false) < daysSinceUpdate || daysSinceUpdate === -1))
			daysSinceUpdate = MiscTools.diffDays(startOfToday, license.last_protocol_report, false);

		return daysSinceUpdate >= AppConstants.hostOfflineMinDays && daysSinceUpdate <= AppConstants.hostOfflineMaxDays;
	};

	// ************************************************************************************************
	// NEW CODE FOR SEPT 2021 LICENSE BACKEND RE-WRITE
	// Get the expiration for a new license or a license that's being refreshed (via meters)
	// ************************************************************************************************
	static getLicenseExpiration = (activation: Models.LPActivation, renewalDays: number = 3, bufferDays: number = 1): Date => {
		let expiration: Date = null;

		// either a permanent (no expiry) key , a fixed expiry or uses duration to issue licenses
		if (activation.meters.length === 0) {
			if (activation.expires_at == null) {
				// if duration, work out expiry and return it
				// if no duration, permanent key - will return null
				if (activation.duration != null && +activation.duration !== 0)
					if (activation.duration === -1)
						expiration = MiscTools.dateIntervalAdd(new Date(), 13, 'month');
					else if (activation.duration === 365)
						expiration = MiscTools.dateIntervalAdd(new Date(), 1, 'year');
					else if (activation.duration === 90)
						expiration = MiscTools.dateIntervalAdd(new Date(), 3, 'month');
					else
						expiration = MiscTools.dateIntervalAdd(new Date(), +activation.duration, 'day');

			} else {  // if the key has a fixed expiration
				expiration = new Date(activation.expires_at);
			} //if

			return expiration;
		} // if

		if (!activation.parsedParameters)
			activation.parsedParameters = SharedLicenseTools.parseRubyHash(activation.parameters);

		const enforcedMeters = !(activation.parsedParameters.dont_enforce_meters && +activation.parsedParameters.dont_enforce_meters === 1);

		// get the key's overall expiration taking into account meter expiration(s)
		// and possible gaps in meter timeframes
		const keyExpiration = SharedLicenseTools.getKeyExpiration(activation);

		// how many days before expiration (+ve) or after expiration (-ve)
		const daysToExpiration = MiscTools.diffDays(keyExpiration, new Date(), false);
		if (daysToExpiration < renewalDays || !enforcedMeters) {
			// if the key's expiration has passed 
			// of if it's less than the #renewal days (typically 3)
			// or if meters aren't enforced
			// then return the key's expiry + #buffer days (typically 1)
			// console.log('Either expired, close to expired or not enforced meters');

			// expiration = MiscTools.dateIntervalAdd(keyExpiration, bufferDays, 'day');

			// no buffer
			if (keyExpiration !== null)
				expiration = new Date(keyExpiration);

		} else {
			// otherwise return now + #renewal days (typically 3)
			expiration = MiscTools.dateIntervalAdd(new Date(), renewalDays, 'day');
		} // if

		// last, but not least, if key isn't enabled....
		// and there is an expiration in the future, make it expire yesterday..

		if (activation.enabled === 0 && (expiration == null || expiration.getTime() > (new Date()).getTime()))
			expiration = MiscTools.dateIntervalAdd(new Date(), -1, 'day');

		return expiration;
	};

	// ************************************************************************************************
	// NEW CODE FOR SEPT 2021 LICENSE BACKEND RE-WRITE
	// Get the expiration for the key (if fixed exp date) or based on meter(s)
	// ************************************************************************************************
	static getKeyExpiration = (activation: Models.LPActivation, focusedMeterProduct: string = '', ignoreDisabled: boolean = false): Date => {
		// Something to note is that there are cases where, if the key has a meter that's disabled, things behave a certain way.
		// The database (as of Sept 2021) doesn't have any disabled meters and no UI currently supports disabling a meter.
		// Nonetheless, it is supported functionility here and in the license refresh code (which pushes/enforces expiration)

		// If focusedMeterProduct has a value, then only evaluate meters of that particular type to determine expiry (used to find possible issues)

		if (!activation) return null;

		// const keyProperties = parseRubyHash(activation.parameters);
		if (!activation.parsedParameters)
			activation.parsedParameters = SharedLicenseTools.parseRubyHash(activation.parameters);

		const enforcedMeters = !(activation.parsedParameters.dont_enforce_meters && +activation.parsedParameters.dont_enforce_meters === 1);

		// if the key has a set expiration (which it shouldn't have if it has meters) return that expiration
		if (activation.expires_at)
			return new Date(activation.expires_at);

		// if the key uses duration based expiries (licenses issued that expire in a fixed amount of time
		// from the time they're issued), they won't have meters and so the expiration should always be
		// null

		let expiration: Date = null;
		if ((ignoreDisabled || activation.enabled === 1) && activation.meters.length > 0) { // for enabled keys with meters
			// get unique products (meter types) assigned to this key
			const meterProducts = [];
			if (focusedMeterProduct != null && focusedMeterProduct !== '') {
				meterProducts.push(focusedMeterProduct);
			} else {
				for (const meter of activation.meters)
					if (!meterProducts.includes(meter.product))
						meterProducts.push(meter.product);
			} // if

			// for each meter type/product, go through meter(s) for that type/product
			// to determine the expiry based on just that type (checking for gaps)
			// and then track the overall earliest expiration
			for (const meterProduct of meterProducts) {
				let expiryForThisProduct: Date = null;

				// get the enabled meters of this type/product
				const enabledProductMeters: Models.LPMeter[] = [];
				for (const meter of activation.meters)
					if (meter.product === meterProduct && meter.enabled === 1)
						enabledProductMeters.push(meter);

				if (enabledProductMeters.length === 0) {
					// the only way this could happen is if there's at least one
					// meter of this type/product, but they're all disabled.
					// In that case, have the expiration set to yesterday.

					expiryForThisProduct = MiscTools.dateIntervalAdd(new Date(), -1, 'days');

				} else if (enabledProductMeters.length === 1) {

					// If there's only one meter of this type/product, the expiry is simply
					// the expiry for that meter.  No need to look for gaps.
					expiryForThisProduct = new Date(enabledProductMeters[0].expires_at);

				} else {
					// If the key has multiple enabled meters of a specific type/product
					// then we need to look for gaps (periods of time with no enabled meter active).
					// Gaps that have already passed can be ignored.

					const gaps = SharedLicenseTools.findGaps(activation, meterProduct, true);
					if (gaps.length === 0) { // if no current or future gaps, then use the last expiration
						for (const meter of enabledProductMeters) {
							const expiry = new Date(meter.expires_at);
							if (expiryForThisProduct == null || expiry.getTime() > expiryForThisProduct.getTime())
								expiryForThisProduct = expiry;
						} // for
					} else {
						// there's at least one current or future gap
						// so the expiry is the start of the first gap
						expiryForThisProduct = new Date(gaps[0].start);
					} // if
				} // if

				// if the experation for this type/product of meter is earlier than any others
				// make that the overall expiration of the key
				if (expiryForThisProduct && (expiration == null || expiration.getTime() > expiryForThisProduct.getTime()))
					expiration = expiryForThisProduct;
			} // for

			// shouldn't happen because all meters must have an expiration
			// and there's no way (currently) to disable a meter, but just in case
			// a way is added, this means that there are meters, but they're
			// all disabled, so set expiry to yesterday
			if (expiration == null) expiration = MiscTools.dateIntervalAdd(new Date(), -1, 'days');

		} // if the key's enabled, has meters

		return expiration;
	};

	// ************************************************************************************************
	// NEW CODE FOR SEPT 2021 LICENSE BACKEND RE-WRITE
	// findGaps - for a particular key and particular meter type/product, look for gaps in time
	// where the meter might not have coverage
	// if skipOldGaps is true, this won't return gaps with an end date in the past
	// note that this asssumes a minimum granularity of days for meter starts and expirations
	// ************************************************************************************************
	// scenario with 3 meters and no gap between 1st and 2nd, but there is a gap between 2nd and 3rd
	// 1 s--------------e
	// 2                s----------e
	// 3                                  s-------------e
	// ************************************************************************************************
	// scenario with 4 meters and no gap between 1st and 2nd, but there is a gap between 2nd and 3rd
	// but the 4th meter covers the gap
	// 1 s--------------e
	// 2                s----------e
	// 3                                  s-------------e
	// 4                   s------------------e
	// ************************************************************************************************
	static findGaps = (activation: Models.LPActivation, meterProduct: string, skipOldGaps = false): any[] => {
		// get the enabled meters of this type/product
		const enabledProductMeters: Models.LPMeter[] = [];
		for (const meter of activation.meters)
			if (meter.product === meterProduct && meter.enabled === 1)
				enabledProductMeters.push(meter);

		// short cut to exit - if there are no eanbled meters (of this type)
		// or just one, there can't be any gaps
		if (enabledProductMeters.length <= 1) return [];

		// figure out the earliest start date and latest expiry date
		let earliestStart: Date = null;
		let latestExpiry: Date = null;
		for (const meter of enabledProductMeters) {
			let start = new Date(meter.created_at);
			if (meter.starts_at) start = new Date(meter.starts_at);
			const expiry = new Date(meter.expires_at);

			if (earliestStart == null || start.getTime() < earliestStart.getTime())
				earliestStart = start;

			if (latestExpiry == null || expiry.getTime() > latestExpiry.getTime())
				latestExpiry = expiry;
		} // for

		// create an object to track each day's coverage
		const dayTracker: any = {};

		// go through each day from the earliest start 
		// to the latest expiry and seed with false's
		// could probably just skip this and look
		// for undefined, but this feels cleaner
		let dateCounter: Date = new Date(earliestStart);
		while (dateCounter.getTime() <= latestExpiry.getTime()) {
			dayTracker[TextTools.formatDateUTC(dateCounter)] = false;
			dateCounter = MiscTools.dateIntervalAdd(dateCounter, 1, 'day');
		} // while

		// go through each meter
		for (const meter of enabledProductMeters) {
			let start = new Date(meter.created_at);
			if (meter.starts_at) start = new Date(meter.starts_at);
			const expiry = new Date(meter.expires_at);
			// and mark of days between its start and expiration with true's
			let subCounter: Date = new Date(start);
			while (subCounter.getTime() <= expiry.getTime()) {
				dayTracker[TextTools.formatDateUTC(subCounter)] = true;
				subCounter = MiscTools.dateIntervalAdd(subCounter, 1, 'day');
			} // while
		} // for

		// now go through all days and look for ranges of days that aren't covered
		// if skipOldGaps = true, ignore gaps that have already ended in the past
		const now = new Date();
		const gaps: any[] = [];
		const trackDates = Object.keys(dayTracker);
		let startOfGap = null;
		let prevDate = null;
		for (const trackDate of trackDates) {
			if (dayTracker[trackDate] === true) { // the day is covered by a meter
				if (startOfGap && prevDate) { // if prev day(s) were part of a gap, add gap to array
					// skipOldGaps = true means only return current/new gaps
					if (!skipOldGaps || (new Date(prevDate)).getTime() > now.getTime())
						gaps.push({
							start: startOfGap,
							end: prevDate
						});
					startOfGap = null;
				} // if
			} else { // the day isn't covered by a meter
				// if not currently tracking a gap, capture the startDate
				if (!startOfGap)
					startOfGap = trackDate;
			} // if
			prevDate = trackDate;
		} // for

		return gaps;
	};

	// *********************************************************************************************
	// new way using YAML
	static parseRubyHash = (parameters: string): any => {
		if (!parameters || parameters.trim() === '') return {};

		const rubyHashStrings = [
			'!ruby/hash:ActiveSupport::HashWithIndifferentAccess',
			'!ruby/hash-with-ivars:ActionController::Parameters',
		];

		let workingString = parameters;
		for (const rubyHashString of rubyHashStrings) {
			if (workingString.startsWith('--- ' + rubyHashString))
				workingString = workingString.substring(rubyHashString.length + 4);

			while (workingString.includes(rubyHashString))
				workingString = workingString.replace(rubyHashString, '');
		} // for

		// workingString = workingString.trim();

		// console.log('workingString');
		// console.log('\"' + workingString + '\"');

		const parsedParams = yaml.load(workingString.trim());
		if (parsedParams) {
			const keys = Object.keys(parsedParams);
			if (keys.includes('elements')) {
				if (parsedParams.elements == null)
					return {};
				else
					return parsedParams.elements;
			} else {
				return parsedParams;
			} // if
		} // if
		// 	if (parsedParams.elements)
		// 		return parsedParams.elements;
		// 	else
		// // else
		// // 	console.log('no parsedParams.elements');


		return {};
	} //

	// *********************************************************************************************
	// new way supporting extra stuff
	static encodeRubyHash = (productProperties: Models.LicenseProductProperty[], props: any,
		extraFlat: string[] = [], extraComplex: string[] = []): string => {

		if (!props) return '';
		const propNames = Object.keys(props);
		if (propNames.length === 0) return '';

		const rubyHashString = '!ruby/hash-with-ivars:ActionController::Parameters';

		let encoded = '--- ' + rubyHashString + '\n';
		encoded += 'elements:\n';

		const linePrefix = '  ';

		// order to do them...
		const propTypes = ['other', 'number', 'boolean'];
		for (const propType of propTypes) {
			for (const pp of productProperties) {
				if (pp.property.ptype === propType && propNames.includes(pp.property.name)) {
					// console.log(pp.property.name);
					let val = '';

					if (pp.property.ptype === 'boolean') {
						const numVal = +props[pp.property.name];
						if (!isNaN(numVal))
							val = '\'' + numVal + '\'';

					} else if (pp.property.ptype === 'number') {
						if (props[pp.property.name] === 'unlimited') {
							val = props[pp.property.name];
						} else {
							const numVal = +props[pp.property.name];
							if (!isNaN(numVal))
								val = '\'' + numVal + '\'';
							else
								val = '\'\'';
						}

					} else if (pp.property.ptype === 'other') {
						if (props[pp.property.name] !== '')
							val = props[pp.property.name];
						else
							val = '\'\'';
					} // if

					encoded += linePrefix + pp.property.name + ': ' + val + '\n';
				} // if
			} // for
		} // for

		for (const prop of extraFlat)
			if (props[prop])
				encoded += linePrefix + prop + ': ' + props[prop] + '\n';

		for (const prop of extraComplex)
			if (props[prop]) {
				const subProps = Object.keys(props[prop]);
				if (subProps.length > 0) {
					encoded += linePrefix + prop + ': ' + rubyHashString + '\n';
					encoded += linePrefix.repeat(2) + 'elements:\n';
					for (const subProp of subProps)
						encoded += linePrefix.repeat(3) + subProp + ': ' + props[prop][subProp] + '\n';
					encoded += linePrefix.repeat(2) + 'ivars:\n';
					encoded += linePrefix.repeat(3) + ':@permitted: false\n';
				} // if
			} // if

		encoded += 'ivars:\n';
		encoded += linePrefix + ':@permitted: false\n';

		return encoded;
	};

	// ************************************************************************************************
	static isSpecialKey = (activation: Models.LPActivation) => {
		if (activation.type.startsWith(AppConstants.offlinePrefix))
			return true;

		for (const prefix of AppConstants.specialKeyPrefixes)
			if (activation.key.startsWith(prefix))
				return true;
		return false;
	};

	// ************************************************************************************************
	static licenseProductAcronym = (productLabel: string): string => {
		// SharedLicenseTools.licenseProductAcronym
		if (productLabel.toLowerCase().startsWith("zixi broadcaster")
			|| productLabel.toLowerCase().startsWith("special broadcaster"))
			return 'Bx';
		else if (productLabel.toLowerCase().startsWith("zixi feeder"))
			return 'Fx';
		else if (productLabel.toLowerCase().startsWith("zixi receiver"))
			return 'Rx';
		else if (productLabel.toLowerCase().startsWith("zec"))
			return 'ZEC';
		else if (productLabel.toLowerCase().startsWith("zen master mediaconnect usage tracking"))
			return 'ZMMC';
		else if (productLabel.toLowerCase().startsWith("zen master generic usage tracking"))
			return 'ZMG';
		else if (productLabel.toLowerCase().startsWith("mediaconnect feeder"))
			return 'MFx';
		else if (productLabel.toLowerCase().startsWith("mediaconnect receiver"))
			return 'MRx';

		return TextTools.acronym(productLabel, true);
	};


	// ************************************************************************************************
	static meterSort = (a: Models.LPMeter, b: Models.LPMeter) => {

		if (a.product !== b.product)
			return a.product > b.product ? 1 : -1;

		const resetsSortOrder: string[] = ['monthly', 'never'];

		const aRank = resetsSortOrder.indexOf(a.resets);
		const bRank = resetsSortOrder.indexOf(b.resets);

		let aExpire = new Date(a.expires_at);
		if (isNaN(aExpire.getTime())) aExpire = new Date();
		let bExpire = new Date(b.expires_at);
		if (isNaN(bExpire.getTime())) bExpire = new Date();

		if (aRank !== bRank)
			return aRank > bRank ? 1 : -1;
		else if (aExpire.getTime() !== bExpire.getTime())
			return aExpire.getTime() > bExpire.getTime() ? 1 : -1;
		else
			return a.id > b.id ? 1 : -1;
	};


	// ************************************************************************************************
	static chopKey = (activation: Models.LPActivation): string => {
		let chopped = TextTools.chopString(activation.key, AppConstants.keyChopLength, '...');
		chopped += activation.key.substring(activation.key.length - 3);
		return chopped;
	};

	// ************************************************************************************************
	static makeKeySummaryHTML = (activation: Models.LPActivation): string => {
		const now = new Date();

		let showMoreInfoMessage = true;

		const keyExpiry = SharedLicenseTools.getKeyExpiration(activation);

		let name = '';
		let version = '';
		let date: Date;

		// const licenseProducts = await (new LicensingAdminDao()).getProducts();
		// const idx = MiscTools.findIndexGeneric(licenseProducts, 'name', activation.product);
		// if (idx !== -1 && licenseProducts[idx].build_product_id && licenseProducts[idx].build_product_id !== 0) {
		// 	const buildProduct = await (new ProductsDao()).getOne(licenseProducts[idx].build_product_id, true);
		// 	if (buildProduct && buildProduct.build_ids) {
		// 		name = buildProduct.name;
		// 		const builds = await (new BuildsDao()).getAll(true, buildProduct.build_ids, true);

		// 		// builds.sort();
		// 		builds.sort((a, b) => a.version < b.version ? 1 : -1);
		// 		for (const build of builds) {
		// 			if (version === '' && build.is_enabled === 1 && build.is_private === 0 && build.is_retired === 0) {
		// 				version = build.version;
		// 				date = build.added_on;
		// 			}
		// 		}
		// 	}
		// }

		const activeMeterTypes: string[] = [];
		let hasMonthlyMeters: boolean = false;
		let hasNeverMeters: boolean = false;
		let usesProjected: boolean = false;
		for (const meter of activation.meters) {
			let starts = new Date(now);
			if (meter.starts_at) starts = new Date(meter.starts_at);
			const exp = new Date(meter.expires_at);
			if (meter.enabled === 1 && exp.getTime() > now.getTime() && starts.getTime() <= now.getTime()) {
				if (!activeMeterTypes.includes(meter.product)) activeMeterTypes.push(meter.product);
				if (meter.projected && +meter.projected !== 0) usesProjected = true;
				if (meter.resets === 'monthly') hasMonthlyMeters = true;
				if (meter.resets === 'never') hasNeverMeters = true;
			} // if
		} // for

		const meterSummaryInfo: any = SharedLicenseTools.buildMeterSummary(activation.meters);

		let html = `<html>
<head>
<link rel="icon" href="data:,">
	<style>
		body { background-color: #fff; font-family: Arial, Trebuchet MS, sans-serif; font-size: 85.5%; }
		.outer{ width: 800px; height: 99%; }
		.inner{ width: 95%; border: 1px solid #AAA; padding: 3px; }
		.banner{ padding: 5px; color: white; background-color:#337ab7; }
		.banner-link-box { float:right; margin: 0px; padding: 1px 3px 1px 3px; color: #000; background-color:#ddd; }
		.content{ padding-left: 5px; margin-top: 2px; }
		.warning{ color: #fd7f20; }
		.danger{ color: red; }
		.vertical-center { margin: 0; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%); }
		a{ text-decoration: none; color: #0000ff; }
		a:hover{ text-decoration: underline; }
		table {	border: 1px solid; border-collapse: collapse;  }
		th { border: 1px solid black; font-size: 75%; padding: 3px; color: white; background-color:#337ab7; }
		td { border: 1px solid; font-size: 75%; padding: 3px; }
		.numCell{ text-align: right; white-space: nowrap; }
		.text-center { text-align: center; }
		.margin-center { margin-left: auto; margin-right: auto; },
	</style>
</head>
<body>
<div class="outer">
<div class="inner">
`;

		html += '<div class="banner">\n'
			+ '<span class="banner-link-box"><a href="https://portal.zixi.com/my-keys/' + encodeURIComponent(activation.key) + '" target="_blank">Full License Details</a> (requires account).</span>\n'
			+ '<strong>Zixi License Status</strong>\n'
			+ '</div>\n'
			;

		if (+activation.enabled === 0) {
			html += '<div class="content text-center">This license key has been disabled.</div>\n';

		} else {
			if (keyExpiry != null) {
				let extraLabel = '';
				if (activation.meters.length > 0) extraLabel = ' from Meter(s)';

				let extraClass = '';
				let extraText = '';
				// const daysToExp = MiscTools.daysSince(keyExpiry) * -1;

				const daysSince = MiscTools.daysSince(keyExpiry, false);
				const intVal = Math.floor(Math.abs(daysSince));

				if (daysSince > 0) { // has expired
					extraClass = ' danger';
					extraText = ' - Expired ' + TextTools.niceDaysText(keyExpiry) + '.';
				} else {
					if (intVal <= AppConstants.keyExpiryWarningDays) extraClass = ' warning';
					extraText = ' - Expires ' + TextTools.niceDaysText(keyExpiry) + '.';
				} // if

				// if (daysToExp >= 0) {
				// 	if (daysToExp < AppConstants.keyExpiryWarningDays)
				// 		extraClass = ' warning';
				// 	if (daysToExp === 1)
				// 		extraText = ' - Expires in ' + TextTools.formatNumber(daysToExp) + ' day';
				// 	else
				// 		extraText = ' - Expires in ' + TextTools.formatNumber(daysToExp) + ' days';

				// } else if (daysToExp < 0) {
				// 	extraClass = ' danger';
				// 	extraText = ' - Expired';
				// } // if

				// extraText += ' d=' + daysSinceExp;

				html += '<div class="content text-center' + extraClass + '">Expiry' + extraLabel + ': <strong>' + TextTools.formatDateNiceUTC(keyExpiry, false) + '</strong> ' + extraText + '</div>\n';
			} // if

			if (hasMonthlyMeters || hasNeverMeters) {
				let bannerColumns: number = 3;
				if (usesProjected)
					bannerColumns += 2;

				// html += '<table style="margin-left: 5px; margin-top: 5px" class="margin-center">\n';
				html += '<table class="margin-center" style="margin-top: 5px">\n';
				html += '<thead>\n';

				// html += '<tr>\n';
				// html += '<th colspan="' + (hasMonthlyMeters && hasNeverMeters ? (bannerColumns * 2 + 1) : (bannerColumns + 1)) + '">Summary of Active Meters</th>\n';
				// html += '</tr>\n';

				html += '<tr>\n';
				html += '<th>Meter Type</th>\n';

				if (hasMonthlyMeters) {
					html += '<th>Resets Monthly</th>\n';
				} // if
				if (hasNeverMeters) {
					html += '<th>No Reset</th>\n';
				} // if
				html += '</tr>\n';

				// html += '<tr>\n';
				// if (hasMonthlyMeters) {
				// 	html += '<th>Used</th>\n';
				// 	if (usesProjected) {
				// 		html += '<th title="Projected Usage Threshold">Proj.</th>\n';
				// 		html += '<th title="Percent Used of Projected Usage Threshold">%-Proj.</th>\n';
				// 	} // if
				// 	html += '<th title="Maximum Limit">Max.</th>\n';
				// 	html += '<th title="Percent Used of Maximum Limit">%-Max.</th>\n';
				// } // if
				// if (hasNeverMeters) {
				// 	html += '<th>Used</th>\n';
				// 	if (usesProjected) {
				// 		html += '<th title="Projected Usage Threshold">Proj.</th>\n';
				// 		html += '<th title="Percent Used of Projected Usage Threshold">%-Proj.</th>\n';
				// 	} // if
				// 	html += '<th title="Maximum Limit">Max.</th>\n';
				// 	html += '<th title="Percent Used of Maximum Limit">%-Max.</th>\n';
				// } // if
				// html += '</tr>\n';

				html += '</thead>\n';

				html += '<tbody>\n';
				for (const type of activeMeterTypes) {
					html += '<tr>\n';

					html += '<td>' + SharedLicenseTools.niceProtocol(type, false) + '</td>\n';
					if (hasMonthlyMeters) {
						const resetsSuffix: string = '.monthly';
						let percUsed = 0;
						if (meterSummaryInfo[type + resetsSuffix].limit && meterSummaryInfo[type + resetsSuffix].limit !== 0)
							percUsed = (+meterSummaryInfo[type + resetsSuffix].used / +meterSummaryInfo[type + resetsSuffix].limit * 100)

						let extraClass = '';
						if (percUsed >= 100)
							extraClass = ' danger';
						else if (percUsed >= AppConstants.keyWarningUsageThreshold)
							extraClass = ' warning';

						html += '<td>';
						if (meterSummaryInfo[type + resetsSuffix].limit && meterSummaryInfo[type + resetsSuffix].limit > 0) {
							html += '<div class="' + extraClass + '">';
							html += 'Used: ' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].used) + '\n';
							if (usesProjected) {
								html += ' : ';
								html += '<span title="Projected Usage Threshold">Proj: ' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].projected) + '</span>\n';
								html += ' : ';
								html += '<span title="Percent Used of Projected Usage Threshold">' + SharedLicenseTools.projectedPerc(meterSummaryInfo[type + resetsSuffix]) + '%</span>\n';
							} // if
							html += ' : ';
							html += '<span title="Maximum Limit">Max: ' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].limit) + '</span>\n';
							html += ' : ';
							html += '<span title="Percent Used of Maximum Limit">' + SharedLicenseTools.usagePerc(meterSummaryInfo[type + resetsSuffix]) + '%</span>\n';
							html += '</div>';
						} // if
						html += '</td>';
					} // if

					if (hasNeverMeters) {
						const resetsSuffix: string = '.never';
						let percUsed = 0;
						if (meterSummaryInfo[type + resetsSuffix].limit && meterSummaryInfo[type + resetsSuffix].limit !== 0)
							percUsed = (+meterSummaryInfo[type + resetsSuffix].used / +meterSummaryInfo[type + resetsSuffix].limit * 100)

						let extraClass = '';
						if (percUsed >= 100)
							extraClass = ' danger';
						else if (percUsed >= AppConstants.keyWarningUsageThreshold)
							extraClass = ' warning';

						html += '<td>';
						if (meterSummaryInfo[type + resetsSuffix].limit && meterSummaryInfo[type + resetsSuffix].limit > 0) {
							html += '<div class="' + extraClass + '">';
							html += 'Used: ' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].used) + '\n';
							if (usesProjected) {
								html += ' : ';
								html += '<span title="Projected Usage Threshold">Proj: ' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].projected) + '</span>\n';
								html += ' : ';
								html += '<span title="Percent Used of Projected Usage Threshold">' + SharedLicenseTools.projectedPerc(meterSummaryInfo[type + resetsSuffix]) + '%</span>\n';
							} // if
							html += ' : ';
							html += '<span title="Maximum Limit">Max: ' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].limit) + '</span>\n';
							html += ' : ';
							html += '<span title="Percent Used of Maximum Limit">' + SharedLicenseTools.usagePerc(meterSummaryInfo[type + resetsSuffix]) + '%</span>\n';
							html += '</div>';
						} // if
						html += '</td>';

						// html += '<td class="little numCell' + extraClass + '">Used:' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].used) + '</td>\n';
						// if (usesProjected) {
						// 	html += '<td class="little numCell' + extraClass + '" title="Projected Usage Threshold">Proj:' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].projected) + '</td>\n';
						// 	html += '<td class="little numCell' + extraClass + '" title="Percent Used of Projected Usage Threshold">' + SharedLicenseTools.projectedPerc(meterSummaryInfo[type + resetsSuffix]) + '%</td>\n';
						// } // if
						// html += '<td class="little numCell' + extraClass + '" title="Maximum Limit">Max' + TextTools.formattedMB(meterSummaryInfo[type + resetsSuffix].limit) + '</td>\n';
						// html += '<td class="little numCell' + extraClass + '" title="Percent Used of Maximum Limit">' + SharedLicenseTools.usagePerc(meterSummaryInfo[type + resetsSuffix]) + '%</td>\n';
					} // if

					html += '</tr>\n';
				} // for

				html += '</tbody>\n';
				html += '</table>\n';
			} // if
		} // if

		if (version !== '')
			html += '<hr /><div class="content">The latest generally available build for ' + name + ' is <strong>' + version + '</strong>'
				+ ' (' + TextTools.formatDateNiceUTC(date) + ')</div>';
		html += `</div> <!-- 99% inner --></div> <!-- 100% outer --></body></html>`;

		return html;
	};

	// ************************************************************************************************
	static usagePerc = (meter: Models.LPMeter): string => {
		if (+meter.limit > 0)
			return (+meter.used / +meter.limit * 100).toFixed(1);
		else
			return '-';
	};

	// ************************************************************************************************
	static projectedPerc = (meter: Models.LPMeter): string => {
		if (+meter.projected > 0)
			return (+meter.used / +meter.projected * 100).toFixed(1);
		else
			return '-';
	};

	// ************************************************************************************************
	static makeKeySummaryJSON = (activation: Models.LPActivation): any => {
		const now = new Date();

		const meters: any[] = [];
		for (const meter of activation.meters) {
			let resets: Date = null;
			if (meter.resets === 'monthly') {
				// work out first day of next month...
				const firstDayOfMonth = new Date(now.getUTCFullYear() + '/' + (now.getUTCMonth() + 1).toString().padStart(2, '0') + '/01 00:00:00 UTC');
				resets = MiscTools.dateIntervalAdd(firstDayOfMonth, 1, 'month');
			} // if

			meters.push({
				product: meter.product,
				expires_at: meter.expires_at,
				starts_at: meter.expires_at,
				limit: meter.limit,
				used: meter.used,
				enabled: meter.enabled === 1 ? true : false,
				resets_at: resets != null ? TextTools.formatDateUTC(resets) : resets
			});
		} // for

		const activations: any[] = [];
		for (const license of activation.licenses) {
			activations.push({
				hostid: license.hostid,
				created_at: license.created_at,
				expires_at: license.expires_at
			});
		} // for

		let parsedLicenseParams: any = {};
		if (activation.parameters && activation.parameters !== '')
			parsedLicenseParams = SharedLicenseTools.parseRubyHash(activation.parameters);


		const keyObj: any = {
			key: activation.key,
			product: activation.product,
			count: activation.count,
			max: activation.max,
			expires_at: activation.expires_at,
			duration: activation.duration,
			enabled: activation.enabled === 1 ? true : false,
			parameters: parsedLicenseParams,
			meters,
			activations
		};

		return keyObj;
	};


	// ************************************************************************************************************************
	static getMeterIconClass = (item: Models.LPActivation | Models.LPLicense): string => {
		let lastUsageDays: number = -1;
		if (item.last_meter_usage != null) lastUsageDays = MiscTools.daysSince(item.last_meter_usage);
		if (lastUsageDays === -1)
			return 'cp-icon-color-reported';
		else if (lastUsageDays <= 7)
			return 'cp-icon-color-usage';
		else
			return 'cp-icon-color-dormant';
	};

	// ************************************************************************************************************************
	static getProtocolIconClass = (item: Models.LPActivation | Models.LPLicense): string => {
		let lastUsageDays: number = -1;
		if (item.last_protocol_usage != null) lastUsageDays = MiscTools.daysSince(item.last_protocol_usage);
		if (lastUsageDays === -1)
			return 'cp-icon-color-reported';
		else if (lastUsageDays <= 7)
			return 'cp-icon-color-usage';
		else
			return 'cp-icon-color-dormant';
	};

	// ************************************************************************************************************************
	static getMeterIconToolTip = (item: Models.LPActivation | Models.LPLicense): string => {
		if (item.last_meter_usage)
			return 'Last reported via meters with NON-ZERO traffic on ' + TextTools.formatDateNiceUTC(item.last_meter_usage);
		else if (item.last_meter_report)
			return 'Last reported via meters with NO traffic on ' + TextTools.formatDateNiceUTC(item.last_meter_report);
		else
			return 'Has never reported via meters';
	};

	// ************************************************************************************************************************
	static getProtocolIconToolTip = (item: Models.LPActivation | Models.LPLicense): string => {
		if (item.last_protocol_usage)
			return 'Last reported via protocols with NON-ZERO traffic on ' + TextTools.formatDateNiceUTC(item.last_protocol_usage);
		else if (item.last_protocol_report)
			return 'Last reported via protocols with NO traffic on ' + TextTools.formatDateNiceUTC(item.last_protocol_report);
		else
			return 'Has never reported via protocols';
	};

	// ************************************************************************************************************************
	static getCommericalTypeAcronym = (activation: Models.LPActivation): string => {
		if (activation.commercial_type && activation.commercial_type !== '') {
			const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', activation.commercial_type);
			if (cIdx !== -1)
				return AppConstants.keyCommercialTypes[cIdx].acronym;
		} // if
		return '';
	};

	// ************************************************************************************************************************
	static getCommericalTypeLabel = (activation: Models.LPActivation): string => {
		if (activation.commercial_type && activation.commercial_type !== '') {
			const cIdx = MiscTools.findIndexGeneric(AppConstants.keyCommercialTypes, 'value', activation.commercial_type);
			if (cIdx !== -1)
				return AppConstants.keyCommercialTypes[cIdx].label;
		} // if
		return '';
	};

	// *********************************************************
	static getSubsetOfLimits = (limits: Models.LicenseProductProperty[], type: string): Models.LicenseProductProperty[] => {
		const subset: Models.LicenseProductProperty[] = [];
		for (const prop of limits) {
			let thisType: string = 'other';
			if (prop.property.name === 'inputs' || prop.property.name.startsWith('input_') || prop.property.name.endsWith('_in') || prop.property.name.includes('_in_'))
				thisType = 'in';
			else if (prop.property.name === 'outputs' || prop.property.name.startsWith('output_') || prop.property.name.endsWith('_out') || prop.property.name.includes('_out_'))
				thisType = 'out';
			if (thisType === type) subset.push(prop);
		} // for
		return subset;
	};

	// *********************************************************
	static getMeterProjectedForMonth = (activation: Models.LPActivation, meterType: string, month: number, year: number): number => {
		let amt: number = 0;

		const startOfRecordsMonth: Date = new Date(year + '/' + month + '/01 00:00:00 UTC');
		const endOfRecordsMonth: Date = MiscTools.dateIntervalAdd(startOfRecordsMonth, 1, 'month');

		// console.log('----------')
		// console.log('startOfRecordsMonth=' + TextTools.formatDateTimeNiceUTC(startOfRecordsMonth)
		// 	+ ' endOfRecordsMonth=' + TextTools.formatDateTimeNiceUTC(endOfRecordsMonth))

		for (const meter of activation.meters) {
			let meterStart = new Date(meter.starts_at);
			if (meter.starts_at == null) meterStart = new Date(meter.created_at);
			const meterEnd = new Date(meter.expires_at);

			if (meter.resets === 'monthly' && meter.product === meterType) {
				// console.log('meterStart=' + TextTools.formatDateTimeNiceUTC(meterStart)
				// 	+ ' meterEnd=' + TextTools.formatDateTimeNiceUTC(meterEnd))

				if ((meterStart.getTime() >= startOfRecordsMonth.getTime() && meterStart.getTime() < endOfRecordsMonth.getTime())
					||
					(meterEnd.getTime() > startOfRecordsMonth.getTime() && meterEnd.getTime() < endOfRecordsMonth.getTime())
					||
					(meterStart.getTime() <= startOfRecordsMonth.getTime() && meterEnd.getTime() >= endOfRecordsMonth.getTime())) {
					amt += +meter.projected;
					// console.log('    yep');
				} else {
					// console.log('    nope');
				}
			} // if
		} // for

		return amt;
	};

	// *********************************************************************************************
	static getMeterTypesFromParameters = (parameters: string): string[] => {
		const meterTypes: string[] = [];

		const parsedParameters = SharedLicenseTools.parseRubyHash(parameters);
		for (const meterType of AppConstants.meterProducts)
			if (parsedParameters[meterType])
				meterTypes.push(meterType);

		return meterTypes;
	};

	// *********************************************************************************************
	static getLicenseFileInfo = (fulfillment: string): Models.LPLicenseFileInfo => {
		const meterTypes: string[] = [];
		let format: string = '';
		let zlmId: string = '';
		let hasFileSignature: boolean = false;
		let hasLineSignatures: boolean = false;
		let hasLineHostId: boolean = false;

		// Oct 2023
		// also need to pull key and host id from file
		let key: string = '';
		let hostId: string = '';

		const validProps: string[] = ['customer', 'issued', 'options', '_ck', 'sig',];
		const lines: string[] = fulfillment.split('\n');

		// go through and put lines back together
		const cleanLines: string[] = [];
		let lineBuffer: string = '';
		for (const line of lines) {
			if (line.startsWith('#')) {
				if (lineBuffer !== '') {
					cleanLines.push(lineBuffer);
					lineBuffer = '';
				} // if
				cleanLines.push(line);
			} else {
				if (line.startsWith('LICENSE ')) {
					if (lineBuffer !== '') {
						cleanLines.push(lineBuffer);
						lineBuffer = '';
					} // if
					lineBuffer = line;
				} else {
					// check to see if the line starts a new property
					// if it does, then add a space before appending
					let startsProp: boolean = false;
					for (const prop of validProps)
						if (line.trim().startsWith(prop + '='))
							startsProp = true;
					if (startsProp)
						lineBuffer += ' ' + line.trim();
					else
						lineBuffer += line.trim();
				} // if
			} // if
		} // for
		if (lineBuffer !== '') cleanLines.push(lineBuffer);

		// console.log(cleanLines.join('\n'));

		// now look for stuff
		for (const line of cleanLines) {
			// now look at parts that indicate the format
			if (line.startsWith('#zlm_id=')) zlmId = TextTools.getValue(line, '=');
			if (line.startsWith('#zlm_signature=')) hasFileSignature = true;

			if (line.includes(' hostid=')) {
				hasLineHostId = true;
				const idx1 = line.indexOf(' hostid=');
				if (idx1 !== -1) {
					const idx2 = line.indexOf(' ', idx1 + 8);
					if (idx2 != -1) {
						const lineHostid: string = line.substring(idx1 + 8, idx2).trim();
						if (hostId !== '' && hostId !== lineHostid) {
							console.log('line host id (' + lineHostid + ') is not previous host id (' + hostId + ')');
						} else if (hostId === '') {
							hostId = lineHostid;
						} // if
					} // if
				} // if
			} // if
			if (line.includes('sig="')) hasLineSignatures = true;

			if (line.startsWith('LICENSE zixi refresh_key')) {
				const idx1 = line.indexOf(' options=');
				if (idx1 !== -1) {
					const idx2 = line.indexOf(' ', idx1 + 9);
					if (idx2 != -1) {
						key = line.substring(idx1 + 9, idx2).trim();
					} else {
						key = line.substring(idx1 + 9).trim();
					} // if
				} // if
			} // if
		} // for

		if (zlmId !== '' && hasFileSignature)
			if (hasLineSignatures)
				format = 'both';
			else
				format = 'zlm';
		else
			format = 'rlm';

		if (fulfillment.trim().toLowerCase().startsWith('error')) format = 'error';

		// look for active meter types
		for (const meterType of AppConstants.meterProducts) {
			let foundStart: boolean = false;
			let meterLine: string = '';
			for (const line of lines) {
				if (line.startsWith('LICENSE zixi ' + meterType + ' '))
					foundStart = true;
				else if (foundStart && line.startsWith('LICENSE '))
					foundStart = false;

				if (foundStart) meterLine += ' ' + line.trim();
			} // for

			if (meterLine !== '' && !meterLine.includes('options=a=0')) meterTypes.push(meterType);
			// console.log(meterType + ' : ' + meterLine);
		} // for

		meterTypes.sort();


		const meterInitials = [];
		const meterNames = [];
		for (const licenseMeterType of meterTypes) {
			const inits = AppConstants.meterTypeObjects[licenseMeterType].initials;
			const niceName = SharedLicenseTools.niceProtocol(licenseMeterType, true);
			if (!meterInitials.includes(inits)) meterInitials.push(inits);
			if (!meterNames.includes(niceName)) meterNames.push(niceName);
		} // for
		meterNames.sort();
		meterInitials.sort();

		const info: Models.LPLicenseFileInfo = new Models.LPLicenseFileInfo();
		info.meterTypes = meterTypes;
		info.meterNames = meterNames;
		info.meterInitials = meterInitials;
		info.zlmid = zlmId;
		info.format = format;
		info.key = key;
		info.hostid = hostId;

		return info;
	};

	// *********************************************************************************************
	static sortMeterTypesBasedOnCommercialType = (commercialType: string, meterTypes: string[]): string[] => {
		let sorted: string[] = [];
		if (meterTypes.length <= 1 || !commercialType || commercialType === '') {
			sorted = meterTypes;
		} else {
			const commTypeObj = MiscTools.pickItem(AppConstants.keyCommercialTypes, 'value', commercialType);
			const primary: string[] = [];
			const secondary: string[] = [];
			for (const meterType of meterTypes) {
				if (commTypeObj && commTypeObj.meterTypes.includes(meterType))
					primary.push(meterType);
				else
					secondary.push(meterType);
			} // for
			sorted = primary.concat(secondary);
		} // if
		return sorted;
	};

	// *********************************************************
	static niceLicenseExtraProperties = (props: any): string => {
		if (!props) return '';
		const propsToShow: any[] = [
			{
				key: 'ip',
				label: 'IP address manually set during activation'
			},
			{
				key: 'activation-request-ip',
				label: 'IP address that would normally be used for activation'
			},

			{
				key: 'original-ip',
				label: 'When a system reports its meter usage and the IP of that request doesn\'t match the IP of license, this is the original IP of the license'
			},

			{
				key: 'previous-ip',
				label: 'When a system reports its meter usage and the IP of that request doesn\'t match the IP of license, this is the previous IP of the license'
			},
			// {
			// 	key: 'deployment',
			// 	label: 'Which license backend processed this request (this is normally production)'
			// }
		];

		const lines: string[] = [];

		const keys: string[] = Object.keys(props);
		keys.sort();

		for (const k of keys)
			if (props[k] && props[k] !== '')
				lines.push(k.split('-').join(' ').toUpperCase().trim() + ': ' + props[k]);

		// for (const p of propsToShow){
		// 	if (props[p.key] && props[p.key] !== '')
		// 		lines.push(props[p.key] + ': ' + p.label);
		// } // for


		return lines.join('\n');
	}

	// *********************************************************
	static isKey = (key: string): boolean => {
		if (!key || key.trim() === '') return false;

		const splitKey: string[] = key.split('-');
		if ([4, 5, 6].includes(splitKey.length)) {
			return true;
		} else if (splitKey.length === 3 && (key.startsWith('mediaconnect-') || key.startsWith('zm-'))) {
			return true;
		} // if

		return false;
	} //

	// *********************************************************
	static hasNotStarted = (someDate: any): boolean => {
		// hasNotStarted(someDate: any) {
		const now = new Date();
		let theDate = new Date(someDate);
		if (!theDate || isNaN(theDate.getTime()))
			theDate = now;
		return theDate.getTime() > now.getTime();
	}

	// *********************************************************
	static getVersionToUse = (license: Models.LPLicense): string => {
		let version: string = null;
		if (license.last_protocol_bx_version && license.last_protocol_bx_version !== '') {
			version = license.last_protocol_bx_version;
		} else if (license.last_meter_bx_version && license.last_meter_bx_version !== '') {
			version = license.last_meter_bx_version;
		} else if (license.extraProperties) {
			if (license.extraProperties['refresh-version'] && license.extraProperties['refresh-version'] !== '')
				version = license.extraProperties['refresh-version'];
			else if (license.extraProperties['activate-version'] && license.extraProperties['activate-version'] !== '')
				version = license.extraProperties['activate-version'];
			// console.log('version from extras - ' + version);
		} // if

		// strip out OS info
		if (version) {
			version = version.replace(/\%20/g, ' ');
			if (version.includes(' '))
				version = version.substring(0, version.indexOf(' ')).trim();
		} // if

		return version;
	}

	// *********************************************************
	static makeUserNotificationSummary = (user: Models.User): string => {
		let summary: string = '';

		if (user.is_enabled === 0) {
			summary = 'User account is disabled.'

		} else if (!user.notifications) {
			summary = 'Notifications not configured.'

		} else {
			const settings: string[] = [];
			if (user.notifications.receiveKeyExpirationMessages)
				settings.push(AppConstants.notificationPropLabels.receiveKeyExpirationMessages + ' <span class="text-success">enabled</span>.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveKeyExpirationMessages + ' <span class="text-danger">NOT enabled</span>.');


			if (user.notifications.receiveKeyUsageMessages)
				settings.push(AppConstants.notificationPropLabels.receiveKeyUsageMessages + ' <span class="text-success">enabled</span>.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveKeyUsageMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveProjectedKeyUsageMessages)
				settings.push(AppConstants.notificationPropLabels.receiveProjectedKeyUsageMessages + ' <span class="text-success">enabled</span>.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveProjectedKeyUsageMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveProtocolKeyUsageMessages)
				settings.push(AppConstants.notificationPropLabels.receiveProtocolKeyUsageMessages + ' <span class="text-success">enabled</span>.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveProtocolKeyUsageMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveOfflineHostIDsMessages)
				settings.push(AppConstants.notificationPropLabels.receiveOfflineHostIDsMessages + ' <span class="text-success">enabled</span>.');
			else
				settings.push(AppConstants.notificationPropLabels.receiveOfflineHostIDsMessages + ' <span class="text-danger">NOT enabled</span>.');

			if (user.notifications.receiveKeysReportMessages)
				settings.push(AppConstants.notificationPropLabels.receiveKeysReportMessages + ' <span class="text-success">enabled</span> (' + user.notifications.keysReportFrequency + ').');
			else
				settings.push(AppConstants.notificationPropLabels.receiveKeysReportMessages + ' <span class="text-danger">NOT enabled</span>.');

			summary = '\u2022 ' + settings.join('\n\u2022 ');
		} // if

		return summary;
	}

	// *********************************************************
	static buildChunkedUsage = (timeMode: string, monthlyUsage: Models.LPMeterReportByMonth[], uniqueProducts: string[], timeChunks: any[],
		selectedSets: Models.ProtocolSet[], phantomSets: Models.ProtocolSet[], usedSets: number[], activeBillingCode: number, billingCodeToSearch: string,
		doBrandFiltering: boolean = false, brandFilter: string = AppConstants.brandFilterNoFilter): Models.LPMeterReportByMonth[] => {

		const chunkedUsage: Models.LPMeterReportByMonth[] = [];
		for (const record of monthlyUsage) {
			let passedFilter = true;
			if (activeBillingCode >= 0) {
				if (activeBillingCode === 0 && record.billing_code != null && record.billing_code !== '')
					passedFilter = false;
				if (activeBillingCode > 0 && billingCodeToSearch !== record.billing_code)
					passedFilter = false;
			} // if

			if (doBrandFiltering && brandFilter !== AppConstants.brandFilterNoFilter && record.brand !== brandFilter) {
				passedFilter = false;
			} // if

			if (passedFilter && record.used > 0) {
				// based on view-mode : monthly, quarterly, annually
				// setup the my
				const my = {
					month: +record.month,
					year: +record.year
				};

				// if (['quarter', 'year'].includes(this.timeMode)) {
				if (timeMode === 'quarter')
					my.month = Math.ceil(+record.month / 3);
				else if (timeMode === 'year')
					my.month = -1;

				// do the sub-total for the protocol
				const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', record.product, 'year', +my.year, 'month', +my.month);
				if (idx === -1)
					chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', record.product, +my.year, +my.month, +record.used));
				else
					chunkedUsage[idx].used += +record.used;

				// track used protocols
				if (!uniqueProducts.includes(record.product))
					uniqueProducts.push(record.product);

				if (MiscTools.findIndexGenericDouble(timeChunks, 'month', +my.month, 'year', +my.year) === -1)
					timeChunks.push(my);

				// do overall total
				const idxT = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'total', 'year', +my.year, 'month', +my.month);
				if (idxT === -1)
					chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'total', +my.year, +my.month, +record.used));
				else
					chunkedUsage[idxT].used += +record.used;

				// sub total for all in & out
				if (!record.product.includes('transcoded') && (record.product.endsWith('_in') || record.product.endsWith('_out'))) {
					const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'total_inout', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_inout', +my.year, +my.month, +record.used));
					else
						chunkedUsage[idx].used += +record.used;
				} // if

				// do sub-totals broken down by in/transcode/out
				if (record.product.includes('transcoded')) {
					// this.hasTranscodeProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'total_transcoded', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_transcoded', +my.year, +my.month, +record.used));
					else
						chunkedUsage[idx].used += +record.used;

				} else if (record.product.endsWith('_in')) {
					// this.hasIncomingProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'total_in', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_in', +my.year, +my.month, +record.used));
					else
						chunkedUsage[idx].used += +record.used;
				} else if (record.product.endsWith('_out')) {
					// this.hasOutgoingProtocolData = true;
					const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'total_out', 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'total_out', +my.year, +my.month, +record.used));
					else
						chunkedUsage[idx].used += +record.used;
				} // if

				// do protocol set totals...
				for (const protocolSet of selectedSets) {
					if (protocolSet.protocolsArr.includes(record.product)) {
						if (!usedSets.includes(protocolSet.id)) usedSets.push(protocolSet.id);
						const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'protocol-set:' + protocolSet.id, 'year', +my.year, 'month', +my.month);
						if (idx === -1)
							chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'protocol-set:' + protocolSet.id, +my.year, +my.month, +record.used));
						else
							chunkedUsage[idx].used += +record.used;
					} // if
				} // for

				// do phantom protocol set totals...
				if (phantomSets) {
					for (const protocolSet of phantomSets) {
						// check to see if this set is part of selectedProtocolSets
						if (MiscTools.findIndex(selectedSets, protocolSet.id) == -1 && protocolSet.protocolsArr.includes(record.product)) {
							const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'protocol-set:' + protocolSet.id, 'year', +my.year, 'month', +my.month);
							if (idx === -1)
								chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'protocol-set:' + protocolSet.id, +my.year, +my.month, +record.used));
							else
								chunkedUsage[idx].used += +record.used;
						} // if
					} // for
				} // for

				// do billing-code based totals...
				if (activeBillingCode === -1 && record.billing_code != null && record.billing_code !== '') {
					const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'billing-code:' + record.billing_code, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + record.billing_code, +my.year, +my.month, +record.used));
					else
						chunkedUsage[idx].used += +record.used;
				} else {
					const idx = MiscTools.findIndexGenericTriple(chunkedUsage, 'product', 'billing-code:' + AppConstants.noBillingCodeToken, 'year', +my.year, 'month', +my.month);
					if (idx === -1)
						chunkedUsage.push(new Models.LPMeterReportByMonth(0, '', 'billing-code:' + AppConstants.noBillingCodeToken, +my.year, +my.month, +record.used));
					else
						chunkedUsage[idx].used += +record.used;
				} // if
			} // if
		} // for
		uniqueProducts.sort(SharedLicenseTools.protocolSort);

		return chunkedUsage;
	} // 

	// ************************************************************************************************
	static getProtocolSetPopoverLines = (protocolSet: Models.ProtocolSet, forStaff: boolean = true): string[] => {
		if (!protocolSet) return [];

		const lines: string[] = [];

		if (forStaff) {
			lines.push('Added: ' + TextTools.formatDateNiceUTC(protocolSet.added_on));
			if (protocolSet.edited_on && protocolSet.edited_on !== protocolSet.added_on) lines.push('Edited: ' + TextTools.formatDateNiceUTC(protocolSet.edited_on));
		} // if

		lines.push('Name: ' + protocolSet.name);
		if (protocolSet.description && protocolSet.description !== '')
			lines.push('Description: ' + protocolSet.description);
		// if (forStaff) lines.push('Sharing Mode: ' + protocolSet.share_mode);

		const protocols: string[] = MiscTools.deepClone(protocolSet.protocolsArr);
		protocols.sort(SharedLicenseTools.protocolSort);

		const bxProtocols: string[] = [];
		const privProtocols: string[] = [];
		const transcodeProtocols: string[] = [];
		const mcProtocols: string[] = [];
		const zmProtocols: string[] = [];

		for (const protocol of protocols) {
			if (protocol.startsWith('mediaconnect_')) {
				mcProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('MediaConnect ', ''));
			} else if (protocol.startsWith('zm_')) {
				zmProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('ZM ', ''));
			} else {
				if (protocol.includes('transcode'))
					transcodeProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else if (protocol.startsWith('private_'))
					privProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else
					bxProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
			} // if
		} // for

		const protocolBlocks: any[] = [];
		if (bxProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Non-Private)',
				protocols: bxProtocols
			});

		if (privProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Private)',
				protocols: privProtocols
			});

		if (transcodeProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Transcode)',
				protocols: transcodeProtocols
			});

		if (mcProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (ZEN Master Tracking - MediaConnect)',
				protocols: mcProtocols
			});

		if (zmProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (ZEN Master Tracking - Non-MediaConnect)',
				protocols: zmProtocols
			});

		for (const protocolBlock of protocolBlocks) {
			lines.push(protocolBlock.label + ' [' + protocolBlock.protocols.length + ']:');
			lines.push(AppConstants.bullet + ' ' + protocolBlock.protocols.join('; '));
			// for (const p of protocolBlock.protocols)
			// 	lines.push(AppConstants.bullet + ' ' + p);
		} // for

		if (lines.length > 15) {
			lines.splice(15);
			lines.push('...');
		} // if

		return lines;
	};


}

export default SharedLicenseTools;
