<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Properties</a></li>
			<li *ngIf="property" class="breadcrumb-item active" aria-current="page">
				{{ tt.chopString(property.label, 20) }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!property">
				<button *ngIf="canManage" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>
				<button *ngIf="canManage" type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete"
					placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!property" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Label:</span>
					<span class="cp-property-value">{{ property.label }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ property.name }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Type:</span>
					<span class="cp-property-value">{{ ac.keyPropertyTypeLabels[property.ptype] }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!property.description && property.description !== ''">
					<span class="cp-property-label">Description:</span>
					<span class="cp-property-value cp-pre-text">{{ property.description }}</span>
				</div>

				<div class="cp-property-block" *ngIf="property.ptype === 'number' && !!property.units && property.units !== ''">
					<span class="cp-property-label">Units:</span>
					<span class="cp-property-value">{{ property.units }}</span>
				</div>

				<div class="cp-property-block" *ngIf="property.ptype === 'number'">
					<span class="cp-property-label">Allow Unlimited:</span>
					<span class="cp-property-value">{{ niceBoolean(property.allow_unlimited) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label" placement="end"
						ngbTooltip="If set to yes, do not show this property with other properties of the same type.  It will be handled separately.">
						Special Property:
					</span>
					<span class="cp-property-value">{{ niceBoolean(property.special_property) }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">License Property Name:</span>
					<span class="cp-property-value">{{ property.fulfillment_name }}</span>
				</div>

				<!-- <div class="cp-property-block" *ngIf="!!pariedPropertyLabel && pariedPropertyLabel !== ''">
					<span class="cp-property-label">Paired With:</span>
					<span class="cp-property-value">{{ pariedPropertyLabel }}</span>
				</div> -->

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ property.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="addedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="property.added_on !== property.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ property.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="editedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>
			</div>

			<div *ngIf="productProperties.length > 0" class="mt-2">
				Product(s)
			</div>

			<table *ngIf="!loading && property.ptype === 'boolean'" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Product</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th>Def</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of productProperties">
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'products', pp.product_id]"
								[ngbPopover]="keyProductPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
								container="body">
								{{ pp.product.label }} ({{ pp.product.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end">{{ niceDefault(pp) }}</td>
						<ng-template #keyProductPopupContent>
							<app-card-key-product [object]="pp.product"></app-card-key-product>
						</ng-template>
					</tr>
				</tbody>
			</table>

			<table *ngIf="!loading && property.ptype === 'number'" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Product</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th class='text-end'>Def</th>
						<th class='text-end'>Min.</th>
						<th class='text-end'>Max.</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of productProperties">
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'products', pp.product_id]"
								[ngbPopover]="keyProductPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
								container="body">
								{{ pp.product.label }} ({{ pp.product.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end text-end">{{ niceDefault(pp) }}</td>
						<td class="border-end text-end"> {{ pp.min_value }} </td>
						<td class="border-end text-end"> {{ pp.max_value }} </td>
						<ng-template #keyProductPopupContent>
							<app-card-key-product [object]="pp.product"></app-card-key-product>
						</ng-template>
					</tr>
				</tbody>
			</table>

			<table *ngIf="!loading && property.ptype === 'other'" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Product</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th>Def</th>
						<th>Selections</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of productProperties">
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'products', pp.product_id]"
								[ngbPopover]="keyProductPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
								container="body">
								{{ pp.product.label }} ({{ pp.product.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end">{{ niceDefault(pp) }}</td>
						<td class="border-end">
							{{ pp.selections }}
						</td>
						<ng-template #keyProductPopupContent>
							<app-card-key-product [object]="pp.product"></app-card-key-product>
						</ng-template>
					</tr>
				</tbody>
			</table>

			<div *ngIf="productProperties.length > 0" class="mt-2">
				Templates(s)
			</div>

			<table *ngIf="!loading && property.ptype === 'boolean'" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Template</th>
						<th>Product</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th>Def</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of templateProperties">
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'templates', pp['templateId']]">
								{{ pp['templateName'] }}
							</a>
						</td>
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'products', pp.product_id]">
								{{ pp.product.label }} ({{ pp.product.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end">{{ niceDefault(pp) }}</td>
					</tr>
				</tbody>
			</table>

			<table *ngIf="!loading && property.ptype === 'number'" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Template</th>
						<th>Product</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th class='text-end'>Def</th>
						<th class='text-end'>Min.</th>
						<th class='text-end'>Max.</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of templateProperties">
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'templates', pp['templateId']]">
								{{ pp['templateName'] }}
							</a>
						</td>
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'products', pp.product_id]">
								{{ pp.product.label }} ({{ pp.product.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end text-end">{{ niceDefault(pp) }}</td>
						<td class="border-end text-end"> {{ pp.min_value }} </td>
						<td class="border-end text-end"> {{ pp.max_value }} </td>
					</tr>
				</tbody>
			</table>

			<table *ngIf="!loading && property.ptype === 'other'" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<!-- <th>#</th> -->
						<th>Template</th>
						<th>Product</th>
						<th>Can<br />Change</th>
						<th>Show<br />User</th>
						<th>Def</th>
						<th>Selections</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let pp of templateProperties">
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'templates', pp['templateId']]">
								{{ pp['templateName'] }}
							</a>
						</td>
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.licensingadmin, 'products', pp.product_id]">
								{{ pp.product.label }} ({{ pp.product.name }})
							</a>
						</td>
						<td class="border-end">{{ niceBoolean(pp.can_be_changed) }}</td>
						<td class="border-end">{{ niceShowUser(pp.show_user) }}</td>
						<td class="border-end">{{ niceDefault(pp) }}</td>
						<td class="border-end">
							{{ pp.selections }}
						</td>
					</tr>
				</tbody>
			</table>



		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">

			<div *ngIf="!loading && (!!property && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this License Property.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>