import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { MyPartnersService } from '../my-partners.service';
import { LinkLogsService } from '../../link-logs/link-logs.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-my-partners',
	templateUrl: './my-partners.component.html',
	styleUrls: ['./my-partners.component.scss']
})

export class MyPartnersComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	// 'standard' list stuff
	items: Models.PartnerDirectoryItem[] = [];
	itemsToShow: Models.PartnerDirectoryItem[] = [];
	// partners: Models.PartnerDirectoryItem[];

	partnersToShow: Models.PartnerDirectoryItem[] = [];
	zmPartnersToShow: Models.PartnerDirectoryItem[] = [];

	loading = true;
	refreshing = false;

	// other stuff
	showExtras = false;
	canShowExtras = false;

	orgLetters: string[] = [];

	organizations: string[] = [];
	dtypes: string[] = [];
	zmDtypes: string[] = [];

	maxPartner: number = 10;
	maxName: number = 10;
	maxIntType: number = 10;
	maxSDKType: number = 10;

	byTypeFilter: string = '';
	byLetterFilter: string = '';
	zmByTypeFilter: string = '';

	private userSubscription: Subscription;

	now = new Date();

	lastTab: string = 'TBD';

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-my-partners';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'org_name',
			type: 'baseText',
			sortType: 'text',
			label: 'Partner',
			toolTip: null,
			icon: null
		}, {
			field: '__product',
			type: 'baseText',
			sortType: 'text',
			label: 'Product/Service',
			toolTip: null,
			icon: null
		}, {
			field: '__link',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'Info',
			toolTip: null,
			icon: null
		}, {
			field: '__doc_link',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'More Info',
			toolTip: null,
			icon: null
		}, {
			field: '__dtype',
			type: 'baseText',
			sortType: 'text',
			label: 'Integration Type',
			toolTip: null,
			icon: null
		}, {
			field: '__itype',
			type: 'baseText',
			sortType: 'text',
			label: 'SDK Type',
			toolTip: null,
			icon: null
		}, {
			field: '__zm_certified',
			type: 'baseText',
			sortType: 'text',
			label: 'ZEN\nMaster\nCert.',
			toolTip: null,
			icon: null
		}, {
			field: '__info',
			type: 'baseText',
			sortType: 'text',
			label: 'Features',
			toolTip: null,
			icon: null
		}
	];

	maintenance: boolean = true;
	isAdmin: boolean = false;

	constructor(
		private myPartnersService: MyPartnersService,
		private authService: AuthService,
		private linkLogsService: LinkLogsService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		if (localStorage.getItem('CLEAN.myPartners.lastTab') != null)
			this.lastTab = localStorage.getItem('CLEAN.myPartners.lastTab');

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canShowExtras = authUser && ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
			this.isAdmin = authUser && ValidationTools.checkRole(authUser.role, AppConstants.adminUserRole);
			this.showExtras = this.canShowExtras && localStorage.getItem('cp-myPartners.showExtras')
				&& localStorage.getItem('cp-myPartners.showExtras') === 'yes';


			this.getData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async getData() {
		this.loading = true;

		const allPartners = await this.myPartnersService.getPartners();

		this.partnersToShow = [];
		this.zmPartnersToShow = [];
		this.organizations = [];

		const tmpP1: Models.PartnerDirectoryItem[] = [];
		const tmpP2: Models.PartnerDirectoryItem[] = [];

		// scrape alpha buttons... then show in by org list and break out by letter
		for (const integration of allPartners) {
			if (integration.partnership.is_listed === 1 || (this.canShowExtras && this.showExtras)) {
				tmpP1.push(integration);

				if (integration.partnership.name.length > this.maxName) this.maxName = integration.partnership.name.length;
				if (integration.org_name.length > this.maxPartner) this.maxPartner = integration.org_name.length;
				if (integration.partnership.extras.dtype && integration.partnership.extras.dtype.length > this.maxIntType) this.maxIntType = integration.partnership.extras.dtype.length;
				if (integration.partnership.extras.itype && integration.partnership.extras.itype.length > this.maxSDKType) this.maxSDKType = integration.partnership.extras.itype.length;

				if (!this.organizations.includes(integration.org_name))
					this.organizations.push(integration.org_name);

				if (integration.partnership.extras.dtype === '')
					integration.partnership.extras.dtype = 'Other';

				if (!this.dtypes.includes(integration.partnership.extras.dtype))
					this.dtypes.push(integration.partnership.extras.dtype);

				if (integration.partnership.extras && integration.partnership.extras.zm_certified === 1) {
					tmpP2.push(integration);
					if (!this.zmDtypes.includes(integration.partnership.extras.dtype))
						this.zmDtypes.push(integration.partnership.extras.dtype);
				} // if
			} // if
		} // for

		this.items = tmpP1;
		this.partnersToShow = tmpP1;
		this.zmPartnersToShow = tmpP2;

		// this.organizations.sort();
		this.organizations.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);
		this.dtypes.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);
		this.zmDtypes.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);

		for (const org of this.organizations) {
			let firstLetter: string = org.substring(0, 1).toUpperCase();
			if (!TextTools.startsWithLetter(firstLetter)) firstLetter = '#';
			if (!this.orgLetters.includes(firstLetter))
				this.orgLetters.push(firstLetter);
		} // for

		const idx = this.dtypes.indexOf('Other');
		if (idx !== -1) {
			this.dtypes.splice(idx, 1);
			this.dtypes.push('Other');
		} // if

		const idx2 = this.zmDtypes.indexOf('Other');
		if (idx2 !== -1) {
			this.zmDtypes.splice(idx2, 1);
			this.zmDtypes.push('Other');
		} // if

		/*
		__zm_certified
		__info
		*/
		for (const item of this.items) {
			item['__product'] = item.partnership.name;

			if (item.partnership.extras.link && item.partnership.extras.link !== '')
				item['__link'] = 1;
			else
				item['__link'] = 0;

			if (item.partnership.extras.document_link && item.partnership.extras.document_link !== '')
				item['__doc_link'] = 1;
			else
				item['__doc_link'] = 0;

			item['__dtype'] = item.partnership.extras.dtype;
			item['__itype'] = item.partnership.extras.itype;

			item['__zm_certified'] = '';
			if (item.partnership && item.partnership.extras && item.partnership.extras.zm_certified && item.partnership.extras.zm_certified === 1)
				item['__zm_certified'] = 'Yes';

			item['__info'] = item.partnership.information;
			if (item.partnership.extras.sdk_features && item.partnership.extras.sdk_features !== '')
				item['__info'] += ' Zixi Features ' + item.partnership.extras.sdk_features;
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);

		// if (this.lastTab != null) this.clickButton(this.lastTab);

		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'org_name', 'asc');

	}

	// ------------------------------------------------------------------------
	organizationsByLetter(letter: string) {
		const sublist: string[] = [];

		for (const org of this.organizations) {
			let firstLetter: string = org.substring(0, 1).toUpperCase();
			if (!TextTools.startsWithLetter(firstLetter)) firstLetter = '#';
			if (firstLetter === letter)
				sublist.push(org);
		} // for

		return sublist;
	}

	// ------------------------------------------------------------------------
	integrationsByOrg(orgName: string) {
		const sublist: Models.PartnerDirectoryItem[] = [];

		for (const integration of this.partnersToShow)
			if (integration.org_name === orgName)
				sublist.push(integration);
		sublist.sort((a, b) => (a.partnership.name > b.partnership.name) ? 1 : -1);

		return sublist;
	}

	// ------------------------------------------------------------------------
	integrationsByType(dtype: string) {
		const sublist: Models.PartnerDirectoryItem[] = [];

		for (const integration of this.partnersToShow)
			if (integration.partnership.extras.dtype === dtype)
				sublist.push(integration);
		sublist.sort((a, b) => (a.org_name > b.org_name) ? 1 : -1);

		return sublist;
	}

	// ------------------------------------------------------------------------
	zmIntegrationsByType(dtype: string) {
		const sublist: Models.PartnerDirectoryItem[] = [];

		for (const integration of this.zmPartnersToShow)
			if (integration.partnership.extras.dtype === dtype)
				sublist.push(integration);
		sublist.sort((a, b) => (a.org_name > b.org_name) ? 1 : -1);

		return sublist;
	}

	// ------------------------------------------------------------------------
	openLink(url: string) {
		window.open(url, '_blank');
		this.linkLogsService.addOne(new Models.LinkLog(0, null, url, ''));
	}

	// ------------------------------------------------------------------------
	openReport() {
		const url = AppConstants.apiUrl + AppConstants.apiUrls.partners + '/report';
		window.open(url, '_blank');
	}

	// ------------------------------------------------------------------------
	toggleExtras() {
		if (this.canShowExtras) {
			this.showExtras = !this.showExtras;
			if (this.showExtras)
				localStorage.setItem('cp-myPartners.showExtras', 'yes');
			else
				localStorage.setItem('cp-myPartners.showExtras', 'no');
			this.getData();
		}
	}

	// ------------------------------------------------------------------------
	scrollToSpot(id: string) {
		const el: HTMLElement | null = document.getElementById(id);
		if (el) {
			setTimeout(() =>
				el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 0);
		}
	}

	// ------------------------------------------------------------------------
	setByTypeFilter(dType: string) {
		if (this.byTypeFilter === dType)
			this.byTypeFilter = '';
		else
			this.byTypeFilter = dType;
	}

	// ------------------------------------------------------------------------
	setZMByTypeFilter(dType: string) {
		if (this.zmByTypeFilter === dType)
			this.zmByTypeFilter = '';
		else
			this.zmByTypeFilter = dType;
	}

	// ------------------------------------------------------------------------
	setByLetterFilter(firstLetter: string) {
		if (this.byLetterFilter === firstLetter)
			this.byLetterFilter = '';
		else
			this.byLetterFilter = firstLetter;
	}

	// ------------------------------------------------------------------------
	saveLastTab(id: string) {
		this.lastTab = id;
		localStorage.setItem('CLEAN.myPartners.lastTab', id);
	}

	// ------------------------------------------------------------------------
	clickButton(id: string) {
		if (document.getElementById(id))
			document.getElementById(id).click();
	} // clickButton

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.PartnerDirectoryItem[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.partnership.name.toLowerCase().includes(w)
						|| (item.org_name && item.org_name.toLowerCase().includes(w))
						|| (item['__dtype'] && item['__dtype'].toLowerCase().includes(w))
						|| (item['__itype'] && item['__itype'].toLowerCase().includes(w))
						|| (item['__info'] && item['__info'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}


}
