<div class="border border-2 mx-auto">
	<div class="mt-0 mb-0 py-1 px-2 clearfix cp-object-card-banner-bg">
		<div class="float-end ps-2">
			{{ typeToShow }}
			<fa-icon *ngIf="iconToShow" [icon]="iconToShow" [fixedWidth]="true"></fa-icon>
		</div>
		<span [innerHTML]="nameToShow"></span>
	</div>

	<div>
		<div *ngIf="infoLines.length > 0" class="px-2 py-1 clearfix border-black text-black">
			<div *ngFor="let line of infoLines; let i = index" class="lh-sm" [style]="i > 0 ? 'margin-top: 5px;' : ''" [innerHTML]="line">
			</div>
		</div>

		<div *ngIf="extraLines.length > 0" class="px-2 py-1 clearfix border-black text-black border-top">
			<div *ngFor="let line of extraLines; let i = index" class="lh-sm" [style]="i > 0 ? 'margin-top: 5px;' : ''" [innerHTML]="line">
			</div>
		</div>

	</div>
</div>