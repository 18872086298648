import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { FilesService } from '../files.service';
import { AuthService } from 'client/app/services/auth.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-file-list',
	templateUrl: './file-list.component.html',
	styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;
	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	// 'standard' list stuff
	items: Models.File[] = [];
	itemsToShow: Models.File[] = [];

	loading = true;
	refreshing = false;
	private userSubscription: Subscription;
	canAdd = false;

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-documents-list';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'free_access_token',
			type: 'baseText',
			sortType: 'text',
			label: 'Free Token',
			toolTip: null,
			icon: null
		}, {
			field: '__uses',
			type: 'baseText',
			sortType: 'text',
			label: 'Used',
			toolTip: null,
			icon: null
		}, {
			field: 'size',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'Size',
			toolTip: null,
			icon: null
		}, {
			field: 'downloads',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Downloads',
			icon: 'hashtag'
		}, {
			field: '__days_since_add',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days Since Added',
			icon: 'clock'
		}, {
			field: '__days_since_download',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: 'Days since last download',
			icon: 'file-download'
		}
	];

	constructor(
		private authService: AuthService,
		private filesService: FilesService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.canAdd = authUser && ValidationTools.checkAccess(authUser, 'manage-files');
			this.items = this.filesService.getAll();
			this.setupItems();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async refresh() {
		this.refreshing = true;
		try {
			await this.filesService.refreshAll().toPromise();
			this.items = this.filesService.getAll();
			this.setupItems();
			this.setSortBy(null);
		} catch (err) {
			// console.error('Caught an error refreshing list');
		}
		this.refreshing = false;
	}

	// ------------------------------------------------------------------------
	async setupItems() {
		for (const item of this.items) {
			item['__uses'] = '';
			if (item.uses) item['__uses'] = item.uses.join('. ');

			item['__days_since_add'] = MiscTools.diffDays(this.now, item.added_on, true);

			if (item.last_download)
				item['__days_since_download'] = MiscTools.diffDays(this.now, item.last_download, true);
			else
				item['__days_since_download'] = 99999999;
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'prod_name', 'asc');
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.File[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.name.toLowerCase().includes(w)
						|| (item.free_access_token && item.free_access_token.toLowerCase().includes(w))
						|| (item['__uses'] && item['__uses'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}
}

