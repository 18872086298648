<div class="mt-2">
	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<div *ngIf="items.length > 0">
		<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
			<thead>
				<tr>
					<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-start cp-pre-text cp-pointer"
						[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
						[hidden]="!fieldsToShow.includes(cd.field)">
						<span *ngIf="cd.label" [innerHTML]="cd.label"
							[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
							[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
						<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
					</th>
				</tr>
			</thead>

			<tbody *ngIf="!loading && itemsToShow?.length > 0">
				<tr *ngFor="let item of itemsToShow" [ngClass]="{
					'text-danger': item.is_enabled === 0,
					'text-warning': item.is_enabled === 1 && item['issues'] && item['issues'] !== ''
				}">
					<td *ngIf="showFavorite" class="cp-icon-cell">
						<fa-icon *ngIf="item.is_favorite === 1" [icon]="starIcon" size="sm" class="text-success" ngbTooltip="Favorite"
							placement="end"></fa-icon>
					</td>
					<td class="cp-icon-cell">
						<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
							ngbTooltip="Enabled - Downloads available" placement="end"></fa-icon>
						<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
							ngbTooltip="Disabled - Downloads not available" placement="end"></fa-icon>
					</td>

					<td class="cp-icon-cell">
						<fa-icon *ngIf="item['__issues'] && item['__issues'] !== ''" [icon]="['fas', 'exclamation-triangle']" size="sm"
							placement="end" class="text-danger" [ngbTooltip]="item['__issues']">
						</fa-icon>
						<fa-icon *ngIf="!item['__issues'] || item['__issues'] === ''" [icon]="['fas', 'check']" size="sm" placement="end"
							class="text-success" ngbTooltip="No Issues">
						</fa-icon>
					</td>

					<td class="cp-chopped-column-long">
						<a [routerLink]="['/' + ac.urls.organizations, item.id]" [ngbPopover]="organizationPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ item.name }}
						</a>
					</td>
					<td class="cp-chopped-column">{{ ac.organizationTypeLabels[item.otype] }}</td>
					<td *ngIf="showSalesRep" class="cp-icon-cell text-center" [title]="item.salesforce_account_owner">
						{{ item['__short_owner'] }}
					</td>
					<td *ngIf="showSalesEng" class="cp-icon-cell text-center" [title]="item.salesforce_se">
						{{ item['__short_se'] }}
					</td>
					<td *ngIf="showSalesEng" class="cp-icon-cell text-center" [title]="item.salesforce_tam">
						{{ item['__short_tam'] }}
					</td>
					<td class="text-center">{{ item.num_users | number }}</td>

					<td class="text-center">{{ item.group_ids.length | number }}</td>
					<td class="text-center">{{ item.product_platforms.length | number }}</td>
					<td class="text-center">{{ item.build_ids.length | number }}</td>
					<td class="text-center">{{ item.document_ids.length | number }}</td>

					<td class="text-center">{{ item.num_partnerships | number }}</td>
					<td class="text-center">{{ item.num_active_zen_domains | number }}</td>
					<td class="text-center">{{ item.num_keys | number }}</td>
					<td class="text-center">{{ item.num_billing_codes | number }}</td>

					<td class="text-center" [title]="tt.formatDateNiceUTC(item.added_on)">
						{{ item['__days_since_add'] | number }}
					</td>
					<td class="text-center">
						<span *ngIf="item.last_download">
							{{ item['__days_since_download'] | number }}
						</span>
					</td>
					<ng-template #organizationPopupContent>
						<app-card-organization [object]="item"></app-card-organization>
					</ng-template>
				</tr>
			</tbody>
		</table>
	</div>

</div>