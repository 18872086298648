import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { BundlesService } from '../bundles.service';
import { MySettingsService } from '../../my-settings/my-settings.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-bundle-list',
	templateUrl: './bundle-list.component.html',
	styleUrls: ['./bundle-list.component.scss']
})
export class BundleListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	mt = MiscTools;
	tt = TextTools;

	@ViewChild('pagingSearchBar1') pagingSearchBar1: PagingSearchBarComponent = null;
	@ViewChild('pagingSearchBar2') pagingSearchBar2: PagingSearchBarComponent = null;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;
	loading = true;
	refreshing = false;
	canAdd = false;

	items1: Models.Bundle[] = [];
	itemsToShow1: Models.Bundle[] = [];

	items2: Models.Bundle[] = [];
	itemsToShow2: Models.Bundle[] = [];

	displayOptions1: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey1: string = 'cp-key-shared-files-sharing';

	fieldsToShow1: string[] = [];

	columnDefs1: any[] = [
		{
			field: 'id',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'ID',
			toolTip: null,
			icon: null
		}, {
			field: '__from',
			type: 'baseText',
			sortType: 'text',
			label: 'From',
			toolTip: null,
			icon: null
		}, {
			field: '__to',
			type: 'baseText',
			sortType: 'text',
			label: 'To',
			toolTip: null,
			icon: null
		}, {
			field: 'information',
			type: 'baseText',
			sortType: 'text',
			label: 'Information',
			toolTip: null,
			icon: null
		}, {
			field: '__num_files',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Files',
			icon: 'hashtag'
		}, {
			field: 'added_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Added',
			toolTip: null,
			icon: null
		}, {
			field: 'lock_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Locks',
			toolTip: null,
			icon: null
		}, {
			field: 'delete_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Deletes',
			toolTip: null,
			icon: null
		}
	];

	displayOptions2: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey2: string = 'cp-key-shared-files-reports';

	fieldsToShow2: string[] = [];

	columnDefs2: any[] = [
		{
			field: 'id',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: 'ID',
			toolTip: null,
			icon: null
		}, {
			field: '__to',
			type: 'baseText',
			sortType: 'text',
			label: 'To',
			toolTip: null,
			icon: null
		}, {
			field: 'information',
			type: 'baseText',
			sortType: 'text',
			label: 'Information',
			toolTip: null,
			icon: null
		}, {
			field: '__num_files',
			type: 'baseNumeric',
			sortType: 'numeric',
			label: null,
			toolTip: '# Files',
			icon: 'hashtag'
		}, {
			field: 'added_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Added',
			toolTip: null,
			icon: null
		}, {
			field: 'lock_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Locks',
			toolTip: null,
			icon: null
		}, {
			field: 'delete_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Deletes',
			toolTip: null,
			icon: null
		}
	];

	constructor(
		private bundlesService: BundlesService,
		private mySettingsService: MySettingsService,
		private authService: AuthService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (authUser) {
				this.authUser = authUser;
				for (const btype of AppConstants.bundleTypes)
					if (ValidationTools.checkAccess(authUser, 'create-' + btype.value + '-bundle'))
						this.canAdd = true;

				this.getData();
			} // if
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async getData(forceRefresh = false) {
		this.loading = true;
		const bundles = await this.bundlesService.getAll();

		this.items1 = [];
		this.items2 = [];

		for (const bundle of bundles) {
			bundle['__from'] = this.getFrom(bundle);
			bundle['__to'] = this.getTo(bundle);
			bundle['__num_files'] = bundle.files.length;

			if (bundle.sub_type === 'report')
				this.items2.push(bundle);
			else
				this.items1.push(bundle);
		} // if

		this.fieldsToShow1 = [];
		for (const cd of this.columnDefs1)
			this.fieldsToShow1.push(cd.field);

		this.fieldsToShow2 = [];
		for (const cd of this.columnDefs2)
			this.fieldsToShow2.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar1)
			this.pagingSearchBar1.setupPaging(this.items1, this.columnDefs1, this.storageKey1, 'id', 'asc');

		if (this.pagingSearchBar2)
			this.pagingSearchBar2.setupPaging(this.items2, this.columnDefs2, this.storageKey2, 'id', 'asc');
	}

	// ------------------------------------------------------------------------
	getTo(bundle: Models.Bundle): string {
		if (bundle.btype.endsWith('-send'))
			if (bundle.org_id && bundle.org_id !== 0)
				return bundle.org_name;
			else
				return bundle.user_names.join(', ');
		else
			return bundle.staff_name;

		return '?';
	}

	// ------------------------------------------------------------------------
	getFrom(bundle: Models.Bundle): string {
		if (bundle.btype.endsWith('-receive'))
			if (bundle.org_id && bundle.org_id !== 0)
				return bundle.org_name;
			else
				return bundle.user_names.join(', ');
		else
			return bundle.staff_name;

		return '?';
	}


	// ------------------------------------------------------------------------
	filterItems1(matchWords: string[]): any[] {
		const tmpList: Models.Bundle[] = [];
		for (const item of this.items1) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.information.toLowerCase().includes(w)
						|| (item['__from'] && item['__from'].toLowerCase().includes(w))
						|| (item['__to'] && item['__to'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy1(field: string) {
		this.pagingSearchBar1.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod1(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems1(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow1 = itemsToShow;
				this.displayOptions1 = displayOptions;
			}
		}
	}

	// ------------------------------------------------------------------------
	filterItems2(matchWords: string[]): any[] {
		const tmpList: Models.Bundle[] = [];
		for (const item of this.items2) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.information.toLowerCase().includes(w)
						|| (item['__to'] && item['__to'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy2(field: string) {
		this.pagingSearchBar2.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod2(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems2(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow2 = itemsToShow;
				this.displayOptions2 = displayOptions;
			}
		}
	}
}
