import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import ValidationTools from 'appshared/validation-tools';

import { PartnerAdminService } from '../partner-admin.service';
import { AuthService } from 'client/app/services/auth.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import MiscTools from 'appshared/misc-tools';

@Component({
	selector: 'app-partner-form',
	templateUrl: './partner-form.component.html',
	styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	id: number;
	partnership: Models.Partnership;
	errors: string[] = [];
	editMode = false;
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;
	private listSubscription: Subscription;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	organizations: any[] = [];
	listMode = true;

	statuses: string[] = [];
	itypes: string[] = [];
	dtypes: string[] = [];
	acceptModes: string[] = ['None', 'Type 0', 'Type 1', 'Type 2'];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private partnerAdminService: PartnerAdminService,
		private organizationsService: OrganizationsService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.partnership = this.partnerAdminService.getOne(this.id);
				if (!this.partnership || this.partnership == null || this.partnership.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.partnerAdminService.refreshOne(this.id);
					this.editMode = true;
				}
			} else {
				this.partnership = new Models.Partnership(0, 0, '', '', 0);
				this.partnership.extras = new Models.PartnershipExtras();
			}
			this.loading = false;
		});
	}

	ngOnInit(): void {

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			if (this.editMode) {
				this.listSubscription = this.partnerAdminService.partnerships.subscribe(messages => {
					this.partnership = messages.find((message: Models.Partnership) => message.id === this.id);
					this.initForm();
				});
			} else {
				this.initForm();
			}
		});
	}

	ngOnDestroy() {
		if (this.listSubscription) this.listSubscription.unsubscribe();
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForm() {
		this.statuses = this.partnerAdminService.getPartnershipFieldValues('status');
		this.itypes = this.partnerAdminService.getPartnershipFieldValues('itype');
		this.dtypes = this.partnerAdminService.getPartnershipFieldValues('dtype');

		const allOrgs = this.organizationsService.getAll();
		this.organizations = [];
		for (const org of allOrgs)
			if (this.partnership.org_id === org.id || !ValidationTools.hasFlag(org, 'no_partnerships'))
				this.organizations.push({
					id: org.id,
					name: org.name + ' [' + org.otype + '] - ' + org.salesforce_account_owner
				});
		// this.organizations = this.organizationsService.getAll();

		if (+this.partnership.org_id === 0) this.partnership.org_id = null;
		if (!this.partnership.extras.accept_mode) this.partnership.extras.accept_mode = this.acceptModes[0];

		this.theForm = new UntypedFormGroup({
			org_id: new UntypedFormControl(this.partnership.org_id, [Validators.required]),
			name: new UntypedFormControl(this.partnership.name, [Validators.required]),
			information: new UntypedFormControl(this.partnership.information),
			is_listed: new UntypedFormControl(this.partnership.is_listed),

			link: new UntypedFormControl(this.partnership.extras.link),
			document_link: new UntypedFormControl(this.partnership.extras.document_link),
			version: new UntypedFormControl(this.partnership.extras.version),
			status: new UntypedFormControl(this.partnership.extras.status ? this.partnership.extras.status : null),
			build_version: new UntypedFormControl(this.partnership.extras.build_version),
			itype: new UntypedFormControl(this.partnership.extras.itype ? this.partnership.extras.itype : null),
			dtype: new UntypedFormControl(this.partnership.extras.dtype ? this.partnership.extras.dtype : null),
			zm_certified: new UntypedFormControl(this.partnership.extras.zm_certified),
			sdk_features: new UntypedFormControl(this.partnership.extras.sdk_features),
			accept_mode: new UntypedFormControl(this.partnership.extras.accept_mode)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const partnershipToSave: Models.Partnership = MiscTools.deepClone(this.partnership);

		partnershipToSave.org_id = +this.theForm.value.org_id;
		partnershipToSave.name = this.theForm.value.name;
		partnershipToSave.information = this.theForm.value.information;
		partnershipToSave.information = this.theForm.value.information;
		partnershipToSave.is_listed = +this.theForm.value.is_listed;

		partnershipToSave.extras.link = this.theForm.value.link;
		partnershipToSave.extras.document_link = this.theForm.value.document_link;
		partnershipToSave.extras.version = this.theForm.value.version;
		partnershipToSave.extras.status = this.theForm.value.status;
		partnershipToSave.extras.build_version = this.theForm.value.build_version;
		partnershipToSave.extras.itype = this.theForm.value.itype;
		partnershipToSave.extras.dtype = this.theForm.value.dtype;
		partnershipToSave.extras.zm_certified = +this.theForm.value.zm_certified;
		partnershipToSave.extras.sdk_features = this.theForm.value.sdk_features;
		partnershipToSave.extras.accept_mode = this.theForm.value.accept_mode;

		if (partnershipToSave.extras.status == null) partnershipToSave.extras.status = '';
		if (partnershipToSave.extras.sdk_features == null) partnershipToSave.extras.sdk_features = '';

		try {
			let ret: Models.Partnership;
			if (this.editMode)
				ret = await this.partnerAdminService.updateOne(partnershipToSave);
			else
				ret = await this.partnerAdminService.addOne(partnershipToSave);

			if (ret) {
				// ******************************************
				this.router.navigate([AppConstants.urls.partneradmin + '/' + ret.id]);
				this.saving = false;
			} else {
				this.saving = false;
				this.uiAlertsService.addMsg('Something went wrong,', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	toggleListMode() {
		this.listMode = !this.listMode;
	}

	get name() { return this.theForm.get('name'); }
	get org_id() { return this.theForm.get('org_id'); }

}
