<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.organizations" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Organizations
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm" ngbTooltip="New Organization" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">New Organization</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" [routerLink]="['/' + ac.urls.organizationgroups]">
				<fa-icon [icon]="ac.sectionIcons.organizationgroups" size="sm"></fa-icon>
				<span class="cp-action-button-text">Groups</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="openBigReport()">
				<fa-icon icon="file-excel" size="sm"></fa-icon>
				<span class="cp-action-button-text">Report</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm" ngbTooltip="Filter" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Filter</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-2">
				<ng-select [items]="favoriteChoices" bindValue="value" bindLabel="label" id="favoriteFilter"
					formControlName="favoriteFilter" [multiple]="false" (change)="onFormChange()" placeholder="Favorite">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="enabledChoices" bindValue="value" bindLabel="label" id="enabledFilter" formControlName="enabledFilter"
					[multiple]="false" (change)="onFormChange()" placeholder="Enabled/Disabled">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="typeChoices" bindValue="value" bindLabel="label" id="typeFilters" formControlName="typeFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="Type">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="accountOwnerChoices" bindValue="value" bindLabel="label" id="accountOwnerFilters"
					formControlName="accountOwnerFilters" [multiple]="true" (change)="onFormChange()" placeholder="Account Owner"
					[closeOnSelect]="false">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="seChoices" bindValue="value" bindLabel="label" id="seFilters" formControlName="seFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="SE" [closeOnSelect]="false">
				</ng-select>
			</div>

			<div class="cp-filter-block-2">
				<ng-select [items]="specialChoices" bindValue="value" bindLabel="label" id="specialFilters" formControlName="specialFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="Special">
				</ng-select>
			</div>

		</div>
	</form>

	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="recentOrganizations && recentOrganizations.length > 0" class="cp-light-message">
		Recently Viewed:
		<span *ngFor="let item of recentOrganizations" class="me-1">
			<a [routerLink]="[item.id]" [ngbPopover]="orgPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
				container="body">
				{{ item.name }}</a>;
			<ng-template #orgPopupContent>
				<app-card-organization [object]="item"></app-card-organization>
			</ng-template>
		</span>
	</div>

	<app-organizations-table #orgsTable1></app-organizations-table>

</div>