import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { MyDocumentsService } from '../my-documents.service';
import { AuthService } from 'client/app/services/auth.service';
import { LinkLogsService } from '../../link-logs/link-logs.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-my-documents-list',
	templateUrl: './my-documents-list.component.html',
	styleUrls: ['./my-documents-list.component.scss']
})
export class MyDocumentsListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	authUser: Models.AuthUser = null;
	items: Models.DownloadableDocument[] = [];
	itemsToShow: Models.DownloadableDocument[] = [];
	// documents: Models.DownloadableDocument[];
	loading = true;

	// other stuff
	showIsPrivate = false;
	private userSubscription: Subscription;
	products: Models.Product[] = [];

	recentDocuments: Models.DownloadableDocument[];
	recentProducts: Models.Product[] = [];

	lastTab: string = 'nav-by-product-tab';
	hidePrivate = false;

	byProductFilter: number = 0;
	byNewProductFilter: number = 0;

	now = new Date();

	// new table stuff
	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-my-docs';

	fieldsToShow: string[] = [];

	columnDefs: any[] = [
		{
			field: '__product',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: '__label',
			type: 'baseText',
			sortType: 'text',
			label: 'Label',
			toolTip: null,
			icon: null
		}, {
			field: '__links',
			type: 'baseText',
			sortType: 'text',
			label: 'File/Link',
			toolTip: null,
			icon: null
		}, {
			field: '__updated',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Updated',
			toolTip: null,
			icon: null
		}
	];


	constructor(
		private myDocumentsService: MyDocumentsService,
		private authService: AuthService,
		private linkLogsService: LinkLogsService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		if (localStorage.getItem('CLEAN.downloadDocs.lastTab') != null)
			this.lastTab = localStorage.getItem('CLEAN.downloadDocs.lastTab');

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			this.showIsPrivate = authUser && authUser.role !== AppConstants.basicUserRole;
			this.getData();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async getData(forceRefresh: boolean = false) {
		this.loading = true;

		const userDocuments = await this.myDocumentsService.getDocuments(forceRefresh);
		this.items = [];
		for (const document of userDocuments)
			if (!this.hidePrivate || document.document.is_private === 0)
				this.items.push(document);

		// go through all of the downloads and get unique products/platforms
		const products = [];
		for (const dl of this.items)
			if (MiscTools.findIndex(products, dl.document.product_id) === -1)
				products.push(dl.product);

		products.sort((a, b) => (a.name > b.name) ? 1 : -1);
		products.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
		this.products = products;

		// this.products = [];
		// for (const ptype of AppConstants.productTypes)
		// 	for (const product of products)
		// 		if (product.ptype === ptype)
		// 			this.products.push(product);

		// recent...
		this.recentDocuments = [];
		this.recentProducts = [];
		for (const dl of this.items)
			if (MiscTools.daysSince(dl.document.added_on) < AppConstants.dashboardRecentItemsDays)
				this.recentDocuments.push(dl);

		// go through all of the downloads and get unique products/platforms
		const recProducts = [];
		for (const dl of this.recentDocuments)
			if (MiscTools.findIndex(recProducts, dl.document.product_id) === -1)
				recProducts.push(dl.product);

		recProducts.sort((a, b) => (a.name > b.name) ? 1 : -1);
		recProducts.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);
		this.recentProducts = recProducts;

		// for (const ptype of AppConstants.productTypes)
		// 	for (const product of recProducts)
		// 		if (product.ptype === ptype)
		// 			this.recentProducts.push(product);

		if (this.recentDocuments.length > 0) {
			if (localStorage.getItem('CLEAN.downloadDocs.lastTab') == null)
				this.saveLastTab('nav-recent-tab');

		} else {
			if (this.lastTab === 'nav-recent-tab')
				this.saveLastTab('nav-by-product-tab');
		} // if

		if (this.lastTab != null) this.clickButton(this.lastTab);

		for (const item of this.items) {
			item['__product'] = item.document.prod_name;
			item['__label'] = item.document.label;

			item['__links'] = '';

			item['__updated'] = this.getDateToShow(item);
		} // for

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.pagingSearchBar)
			this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, '__updated', 'desc');
	}

	// ------------------------------------------------------------------------
	async openFile(id: number, filename: string) {
		const url = '/open-file/' + id + '/' + filename;
		// const url = 'http://localhost:3000/open-file/' + id + '/' + filename;
		// if (url && url !== '')
		window.open(url, '_blank');
	}

	// ------------------------------------------------------------------------
	async openDownload(id: number, dltype: string = '', refid: number = 0) {
		const dlObj = await this.myDocumentsService.getDownloadLink(id, dltype, refid);
		if (dlObj)
			window.open(dlObj.url, '_blank');
	}

	// ------------------------------------------------------------------------
	openLink(document: Models.DownloadableDocument) {
		window.open(document.document.link, '_blank');
		this.linkLogsService.addOne(new Models.LinkLog(0, null, document.document.link, ''));
	}

	// ------------------------------------------------------------------------
	getDateToShow(document: Models.DownloadableDocument) {
		let dateToShow: Date = new Date(document.document.added_on);

		try {
			if (document.document.added_on !== document.document.edited_on)
				dateToShow = new Date(document.document.edited_on);

			if (document.file && document.file.edited_on) {
				const d: Date = new Date(document.file.edited_on);
				if (d.getTime() > dateToShow.getTime())
					dateToShow = d;
			}
		} catch (error) {
		}

		return dateToShow;
	}

	// ------------------------------------------------------------------------
	getFileName(theDoc: Models.DownloadableDocument): string {
		if (theDoc.file) return theDoc.file.name;
		return '';
	}

	// ------------------------------------------------------------------------
	getFileSize(theDoc: Models.DownloadableDocument): number {
		if (theDoc.file) return theDoc.file.size;
		return 0;
	}

	// ------------------------------------------------------------------------
	scrollToSpot(id: string) {
		const el: HTMLElement | null = document.getElementById(id);
		if (el) {
			setTimeout(() =>
				el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }), 0);
		}
	}

	// ------------------------------------------------------------------------
	chopString(str: string) {
		return TextTools.chopString(str, 30, '...');
	}

	// ------------------------------------------------------------------------
	saveLastTab(id: string) {
		this.lastTab = id;
		localStorage.setItem('CLEAN.downloadDocs.lastTab', id);
	}

	// ------------------------------------------------------------------------
	clickButton(id: string) {
		if (document.getElementById(id))
			document.getElementById(id).click();
	} // clickButton

	// ------------------------------------------------------------------------
	async toggleHidePrivate() {
		this.hidePrivate = !this.hidePrivate;
		await this.getData();
	}

	// ------------------------------------------------------------------------
	setByProductFilter(productId: number) {
		if (this.byProductFilter === productId)
			this.byProductFilter = 0;
		else
			this.byProductFilter = productId;
	}

	// ------------------------------------------------------------------------
	setByNewProductFilter(productId: number) {
		if (this.byNewProductFilter === productId)
			this.byNewProductFilter = 0;
		else
			this.byNewProductFilter = productId;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.DownloadableDocument[] = [];
		for (const item of this.items) {
			let match: boolean = true;

			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if ((item['__product'] && item['__product'].toLowerCase().includes(w))
						|| (item['__label'] && item['__label'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}


}
