<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.packages" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Packages
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Package</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>
	<table class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th class="cp-icon-header">
					<fa-icon [icon]="['fas', 'power-off']" size="sm" placement="end" ngbTooltip="Enabled/Disabled">
					</fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="['fas', 'check']" size="sm" placement="end" ngbTooltip="Users may subscribe to this package after
					registration">
					</fa-icon>
				</th>
				<th>Name</th>
				<th>Code</th>
				<th>Type</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="['fas', 'key']" size="sm" ngbTooltip="Activation Key Rules" placement="bottom">
					</fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.users" size="sm" ngbTooltip="Subscribed Users" placement="bottom">
					</fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.platforms" size="sm" ngbTooltip="Products/Platforms" placement="bottom"></fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.builds" size="sm" ngbTooltip="Direct Access Builds" placement="bottom"></fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.documents" size="sm" ngbTooltip="Direct Access Documents" placement="bottom"></fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="!loading">
			<tr *ngFor="let item of packages" [ngClass]="{'text-danger': item.is_enabled === 0}">
				<td class="cp-icon-cell text-center">
					<span class="cp-hidden-content">{{ item.is_enabled }}</span>
					<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success"
						ngbTooltip="Enabled - Downloads available" placement="end">
					</fa-icon>
					<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
						ngbTooltip="Disabled - Downloads not available" placement="end"></fa-icon>
				</td>
				<td class="cp-icon-cell">
					<span *ngIf="item.allow_subscribe === 1">Y</span>
					<span *ngIf="item.allow_subscribe === 0">N</span>
				</td>
				<td class="cp-chopped-column-long"><a [routerLink]="[item.id]">{{ item.name }}</a></td>
				<td>{{ item.access_code }}</td>
				<td class="cp-chopped-column">{{ ac.packageTypeLabels[item.ptype] }}</td>
				<td class="text-center">{{ item.key_templates.length | number }}</td>
				<td class="text-center">{{ item.num_users | number }}</td>
				<td class="text-center">{{ item.product_platforms.length | number }}</td>
				<td class="text-center">{{ item.build_ids.length | number }}</td>
				<td class="text-center">{{ item.document_ids.length | number }}</td>
			</tr>
		</tbody>
	</table>
</div>