<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.partneradmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Partners</a>
			</li>
			<li *ngIf="organization" class="breadcrumb-item active" aria-current="page">
				{{ organization.name }}
			</li>
			<li *ngIf="partnership" class="breadcrumb-item active" aria-current="page">
				{{ partnership.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div *ngIf="canManage" class="btn-group">
				<button type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit" placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="partnership.is_listed === 1" type="button" class="btn btn-outline-warning" (click)="disable()"
					placement="bottom" ngbTooltip="Disabling a partnership will remove it from the partners list that customers can see">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="partnership.is_listed === 0" type="button" class="btn btn-outline-success" (click)="enable()"
					ngbTooltip="Enabling a partnership will make it visibile to customers" placement="bottom">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-journal-tab"
				aria-controls="nav-journal" data-bs-target="#nav-journal">
				<fa-icon [icon]="ac.sectionIcons.journals" size="sm"></fa-icon>
				<!-- Journal -->
				<span *ngIf="journals && journals.length > 0" class="cp-tab-badge ms-1">
					{{ journals.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!partnership" class="cp-property-list">

				<span *ngIf="partnership.extras && partnership.extras.zm_certified && +partnership.extras.zm_certified == 1"
					class="float-end">
					<img src="assets/images/zm-certified.png" class="cp-zm-cert-small" />
				</span>

				<div class="cp-property-block">
					<span *ngIf="partnership.is_listed === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Listed in Partners Directory
					</span>
					<span *ngIf="partnership.is_listed === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Not Listed in Partners Directory
					</span>
				</div>

				<div *ngIf="organization" class="cp-property-block">
					<span class="cp-property-label">Organization:</span>
					<span class="cp-property-value">
						<a [routerLink]="['/' + ac.urls.organizations, organization.id ]" [ngbPopover]="organizationPopupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ organization.name }}</a>
						<ng-template #organizationPopupContent>
							<app-card-organization [object]="organization"></app-card-organization>
						</ng-template>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ partnership.name }}</span>
				</div>

				<div class="cp-property-block" *ngIf="partnership.information && partnership.information !== ''">
					<span class="cp-property-label">Information/Features:</span><br />
					<span class="cp-property-value cp-pre-text">{{ partnership.information }}</span>
				</div>

				<div class="cp-property-block"
					*ngIf="partnership.extras && partnership.extras.sdk_features && partnership.extras.sdk_features !== ''">
					<span class="cp-property-label">Zixi Features:</span><br />
					<span class="cp-property-value cp-pre-text">{{ partnership.extras.sdk_features }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">ZEN Master Certified:</span>
					<span class="cp-property-value"
						*ngIf="!partnership.extras || !partnership.extras.zm_certified || +partnership.extras.zm_certified == 0">
						No</span>
					<span class="cp-property-value"
						*ngIf="partnership.extras && partnership.extras.zm_certified && +partnership.extras.zm_certified == 1">
						Yes</span>
				</div>

				<div class="cp-property-block" *ngIf="partnership.extras && partnership.extras.link && partnership.extras.link !== ''">
					<span class="cp-property-label">Product/Company Link:</span>
					<span class="cp-property-value">
						<a href="{{ partnership.extras.link }}" target="_blank">
							<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
							{{ tt.chopString(partnership.extras.link, 50) }}
						</a>
					</span>
				</div>

				<div class="cp-property-block"
					*ngIf="partnership.extras && partnership.extras.document_link && partnership.extras.document_link !== ''">
					<span class="cp-property-label">More Info Link:</span>
					<span class="cp-property-value">
						<a href="{{ partnership.extras.document_link }}" target="_blank">
							<fa-icon [icon]="['fas', 'external-link-square-alt']"></fa-icon>
							{{ tt.chopString(partnership.extras.document_link, 50) }}
						</a>
					</span>
				</div>

				<div class="cp-property-block"
					*ngIf="partnership.extras && partnership.extras.version && partnership.extras.version !== ''">
					<span class="cp-property-label">Version:</span>
					<span class="cp-property-value">{{ partnership.extras.version }}</span>
				</div>

				<div class="cp-property-block" *ngIf="partnership.extras && partnership.extras.status && partnership.extras.status !== ''">
					<span class="cp-property-label">Status:</span>
					<span class="cp-property-value">{{ partnership.extras.status }}</span>
				</div>

				<div class="cp-property-block"
					*ngIf="partnership.extras && partnership.extras.build_version && partnership.extras.build_version !== ''">
					<span class="cp-property-label">Build:</span>
					<span class="cp-property-value">{{ partnership.extras.build_version }}</span>
				</div>

				<div class="cp-property-block" *ngIf="partnership.extras && partnership.extras.itype && partnership.extras.itype !== ''">
					<span class="cp-property-label">SDK Type:</span>
					<span class="cp-property-value">{{ partnership.extras.itype }}</span>
				</div>

				<div class="cp-property-block" *ngIf="partnership.extras && partnership.extras.dtype && partnership.extras.dtype !== ''">
					<span class="cp-property-label">Device/Integration/Partnership Type:</span>
					<span class="cp-property-value">{{ partnership.extras.dtype }}</span>
				</div>

				<div class="cp-property-block"
					*ngIf="partnership.extras && partnership.extras.accept_mode && partnership.extras.accept_mode !== ''">
					<span class="cp-property-label">Accept Mode:</span>
					<span class="cp-property-value">{{ partnership.extras.accept_mode }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ partnership.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="addedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="partnership.added_on !== partnership.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ partnership.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="editedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">
			<div *ngIf="!loading && adminLogs.length === 0" class="cp-light-message">
				No system/change Logs for this Partnership.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

		<div class="tab-pane" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">
			<div *ngIf="!loading && (!journals || journals.length === 0)" class="cp-light-message">
				No journal entries for this Partnership.
			</div>

			<button *ngIf="canAddJournals" type="button" class="btn btn-outline-primary btn-sm mb-2 me-1"
				[routerLink]="['/' + ac.urls.adminlogs, 'journal', 'partnership', id]">
				<fa-icon [icon]="ac.sectionIcons.journals"></fa-icon> Add Journal Entry
			</button>

			<app-journals-table #journalsTable1></app-journals-table>
		</div>

	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>