<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.quizzes" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				Quizzes + Polls
			</li>
			<li *ngIf="quiz" class="breadcrumb-item active" aria-current="page">
				Take the {{ mt.fetchLabel(ac.quizTypeSelections, quiz.config.quizType, 'value',
				'shortLabel') }}
			</li>
		</ol>
	</nav>
</div>

<div *ngIf="quiz" class="page-wrapper">
	<!-- name/information  -->

	<form *ngIf="theForm && !loading" [formGroup]="theForm">

		<div class="cp-property-list">
			<div class="cp-property-block">
				<span class="cp-property-value">
					{{ quiz.name }}
					- {{ quiz.config.questions.length | number }} Questions
					- {{ answerCount | number }} Answered
				</span>
			</div>

			<div *ngIf="quiz.description !== ''" class="cp-property-block">
				<span class="cp-property-value cp-pre-text">{{ quiz.description }}</span>
			</div>

			<!-- <div class="cp-property-block">
				<span class="cp-property-label">Number of Questions</span>
				<span class="cp-property-value cp-pre-text">{{ quiz.config.questions.length | number }}</span>
			</div> -->

		</div>

		<!-- 'abbrev' mode - next/previous -->
		<div *ngIf="pageMode === 'abbrev'" class="cp-property-list">
			<button [disabled]="currentQuestion === 0" class="btn btn-primary me-1" type="button" (click)="shiftToQuestion(-1)">
				<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
				Previous Question
			</button>

			<button [disabled]="currentQuestion >= (quiz.config.questions.length - 1)" class="btn btn-primary me-1" type="button"
				(click)="shiftToQuestion(1)">
				Next Question
				<fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
			</button>

			<button type="button" class="btn btn-primary me-1" (click)="finished()">
				<!-- <fa-icon [icon]="['fas', 'octagon']"></fa-icon> -->
				I'm Done!
			</button>
		</div>

		<!-- 'full' mode - page buttons -->
		<div *ngIf="pageMode === 'full' && !showAllQuestions" class="cp-property-list">
			<!-- <button class="btn btn-outline-primary me-1" type="button" (click)="showAllQuestions = true"
				ngbTooltip="Add Question">
				Show All
			</button> -->

			<button *ngFor="let q of quiz.config.questions; let i = index" [class]="'btn mb-1 me-1 ' + getButtonClass(i)" type="button"
				(click)="jumpToQuestion(i)">
				{{ (i + 1) }}
			</button>

			<button type="button" class="btn btn-primary me-1" (click)="finished()">
				<!-- <fa-icon [icon]="['fas', 'octagon']"></fa-icon> -->
				I'm Done!
			</button>
		</div>

		<!-- 'full' mode - all  -->
		<div *ngIf="pageMode === 'full' && showAllQuestions" class="cp-property-list">
			<button class="btn btn-outline-primary me-1" type="button" (click)="showAllQuestions = false" ngbTooltip="Add Question">
				Show One At A Time
			</button>

			<button type="button" class="btn btn-primary me-1" (click)="finished()">
				<!-- <fa-icon [icon]="['fas', 'octagon']"></fa-icon> -->
				I'm Done!
			</button>
		</div>

		<!-- question -->

		<div *ngFor="let q of quiz.config.questions; let i = index" class="cp-property-list" [hidden]="currentQuestion !== i">
			<div class="fw-bold">
				Question #{{ (i + 1) }} of {{ quiz.config.questions.length }}
			</div>

			<div class="cp-property-block">
				<span class="cp-125 cp-pre-text">
					{{ q.text }}
					<fa-icon *ngIf="q.moreInfo && q.moreInfo !== ''" [icon]="['fas', 'question-circle']" class="ms-1" placement="end"
						[ngbTooltip]="q.moreInfo">
					</fa-icon>
				</span>
			</div>

			Your Answer
			<div *ngIf="q.answerType === 'text'" class="cp-compact-form-group">
				<input type="text" [id]="'answer' + q.id" [formControlName]="'answer' + q.id" class="form-control"
					(blur)="saveResponses()" />
			</div>

			<div *ngIf="q.answerType === 'long-text'" class="cp-compact-form-group">
				<textarea [id]="'answer' + q.id" [formControlName]="'answer' + q.id" class="form-control" rows="4"
					(blur)="saveResponses()"></textarea>
			</div>

			<div *ngIf="q.answerType === 'multi-choice-single'" class="cp-compact-form-group">
				<div *ngFor="let c of q.choices; let i = index" class="form-check ms-1 me-1">
					<input class="form-check-input" type="radio" [id]="'answer' + q.id + '_' + i" [formControlName]="'answer' + q.id"
						[value]="c" (change)="saveResponses()" />
					<label class="form-check-label" [for]="'answer' + q.id + '_' + i">
						{{ c }}
					</label>
				</div>
			</div>

			<div *ngIf="q.answerType === 'multi-choice-multi'" class="cp-compact-form-group">
				<div *ngFor="let c of q.choices; let i = index" class="form-check ms-1 me-1">
					<input class="form-check-input" type="checkbox" [id]="'answer' + q.id + '_' + i"
						[formControlName]="'answer' + q.id + '_' + i" [value]="c" (change)="saveResponses()" />
					<label class="form-check-label" [for]="'answer' + q.id + '_' + i">
						{{ c }}
					</label>
				</div>
			</div>
		</div>

		<div *ngIf="pageMode === 'full' && !showAllQuestions" class="cp-property-list">
			<button [disabled]="currentQuestion === 0" class="btn btn-primary me-1" type="button" (click)="shiftToQuestion(-1)">
				<fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
				Previous Question
			</button>

			<button [disabled]="currentQuestion >= (quiz.config.questions.length - 1)" class="btn btn-primary me-1" type="button"
				(click)="shiftToQuestion(1)">
				Next Question
				<fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
			</button>
		</div>
	</form>
</div>