<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.dashboard" class="me-1" size="sm"></fa-icon>
				<a routerLink="..">Dashboard</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Top Usage</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<!-- <button type="button" class="btn btn-outline-secondary ml-2"
				[disabled]="broadcastersRefreshing || keysRefreshing" (click)="toggleForm()">
				<fa-icon icon="calendar-alt" size="sm"></fa-icon>
				<span class="cp-action-button-text">Change Timeframe/Key Type</span>
			</button> -->

			<button *ngIf="showRawData" type="button" class="btn btn-outline-secondary ms-2" (click)="toggleShowRaw()"
				ngbTooltip="Show Compact Data Sizes" placement="bottom-right">
				<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
			</button>

			<button *ngIf="!showRawData" type="button" class="btn btn-outline-secondary ms-2" (click)="toggleShowRaw()"
				ngbTooltip="Show Expanded Data Sizes in Base Unit (megabytes)" placement="bottom-right">
				<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
				<fa-icon [icon]="['fas', 'hashtag']" size="sm"></fa-icon>
			</button>

		</div>
	</div>
</div>

<div class="tab-container">
	<form *ngIf="theForm && !loading" [formGroup]="theForm" class="m-1">
		<div class="row">
			<div class="form-group col-3">
				<select id="timeFrame" formControlName="timeFrame" class="form-control" (change)="updateTopUsage()">
					<option *ngFor="let timeFrame of ac.topUsageTimePeriods" [value]="timeFrame.value">
						{{ timeFrame.label }}
					</option>
				</select>
			</div>

			<div class="form-group col-3">
				<ng-select [items]="keyTypeOptions" bindValue="value" bindLabel="label" id="keyType" formControlName="keyType"
					placeholder="Filter by Key Type" (change)="updateTopUsage()">
				</ng-select>
			</div>

			<!-- <div class="col-lg-4 col-md-4">
				<button class="btn btn-primary" type="button" (click)="updateTopUsage()">
					<fa-icon [icon]="['fas', 'check']"></fa-icon> Apply Changes
				</button>
			</div> -->
		</div>
	</form>


	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-organizations-tab" aria-controls="nav-organizations" data-bs-target="#nav-organizations">
				<fa-icon [icon]="ac.sectionIcons.organizations" size="sm" [spin]="orgsRefreshing"></fa-icon>
				By Organization
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-activations-tab"
				aria-controls="nav-activations" data-bs-target="#nav-activations">
				<fa-icon [icon]="['fas', 'key']" size="sm" [spin]="keysRefreshing"></fa-icon>
				By Key
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-hostids-tab"
				aria-controls="nav-hostids" data-bs-target="#nav-hostids">
				<fa-icon [icon]="['fas', 'server']" size="sm" [spin]="broadcastersRefreshing"></fa-icon>
				By Host ID (Bx)
			</button>
		</li>
	</ul>

	<div class="tab-content" id="nav-tabContent">

		<div class="tab-pane show active" role="tabpanel" id="nav-organizations" aria-labelledby="nav-organizations-tab">

			<div *ngIf="topOrganizations && topOrganizations.length > 0" class='cp-125 mb-2'>
				Top Usage by Organization {{ bannerExtra }}
			</div>

			<!-- @extend .table,
			.table-hover,
			.table-bordered,
			.border,
			.cell-border !optional
 -->

			<table *ngIf="topOrganizations && topOrganizations.length > 0" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>#</th>
						<th>
							Traffic
							<span *ngIf="!showRawData">(MB)</span>
						</th>
						<th>Organization</th>
						<th>Type</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fab', 'salesforce']" size="sm" ngbTooltip="Account Owner" placement="bottom">
							</fa-icon>
						</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fas', 'user-graduate']" size="sm" ngbTooltip="SE" placement="bottom">
							</fa-icon>
						</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fas', 'user-ninja']" size="sm" ngbTooltip="TAM" placement="bottom">
							</fa-icon>
						</th>
					</tr>
				</thead>

				<tr *ngFor="let entry of topOrganizations; let i = index">
					<td class="text-center">
						{{ (i + 1) }}
					</td>
					<td class="text-nowrap text-end">
						{{ showDataValue(entry.used) }}
					</td>
					<td class="border-left">
						<a *ngIf="entry['org']" [routerLink]="['/' + ac.urls.organizations, entry.object_id]"
							[ngbPopover]="organizationPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
							container="body">
							{{ entry['org'].name }}
						</a>
					</td>
					<td class="text-nowrap">
						<span *ngIf="entry['org']">
							{{ ac.organizationTypeLabels[entry['org'].otype] }}
						</span>
					</td>
					<td class="text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_account_owner && entry['org'].salesforce_account_owner !==''"
							[ngbTooltip]="entry['org'].salesforce_account_owner">
							{{ tt.acronym(entry['org'].salesforce_account_owner) }}
						</span>
					</td>
					<td class="text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_se && entry['org'].salesforce_se !==''"
							[ngbTooltip]="entry['org'].salesforce_se">
							{{ tt.acronym(entry['org'].salesforce_se) }}
						</span>
					</td>
					<td class="text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_tam && entry['org'].salesforce_tam !==''"
							[ngbTooltip]="entry['org'].salesforce_tam">
							{{ tt.acronym(entry['org'].salesforce_tam) }}
						</span>
					</td>
					<ng-template #organizationPopupContent>
						<app-card-organization [object]="entry['org']"></app-card-organization>
					</ng-template>
				</tr>
			</table>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-activations" aria-labelledby="nav-activations-tab">
			<div *ngIf="topActivations && topActivations.length > 0" class='cp-125 mb-2'>
				Top Usage by License Key {{ bannerExtra }}
			</div>

			<table *ngIf="topActivations && topActivations.length > 0" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>#</th>
						<th>
							Traffic
							<span *ngIf="!showRawData">(MB)</span>
						</th>
						<th>Organization</th>
						<th>Type</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fab', 'salesforce']" size="sm" ngbTooltip="Account Owner" placement="bottom">
							</fa-icon>
						</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fas', 'user-graduate']" size="sm" ngbTooltip="SE" placement="bottom">
							</fa-icon>
						</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fas', 'user-ninja']" size="sm" ngbTooltip="TAM" placement="bottom">
							</fa-icon>
						</th>
						<th>{{ ac.keyInfoFieldLabel }}</th>
						<th>Key</th>
					</tr>
				</thead>

				<tr *ngFor="let entry of topActivations; let i = index">
					<td class="text-center">
						{{ (i + 1) }}
					</td>
					<td class="text-nowrap text-end">
						{{ showDataValue(entry.used) }}
					</td>
					<td class="border-left">
						<a *ngIf="entry['org']" [routerLink]="['/' + ac.urls.organizations, entry.activation.zcp_org_id]"
							[ngbPopover]="organizationPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
							container="body">
							{{ entry['org'].name }}
						</a>
					</td>

					<td class="text-nowrap">
						<span *ngIf="entry['org']">
							{{ ac.organizationTypeLabels[entry['org'].otype] }}
						</span>
					</td>
					<td class="text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_account_owner && entry['org'].salesforce_account_owner !==''"
							[ngbTooltip]="entry['org'].salesforce_account_owner">
							{{ tt.acronym(entry['org'].salesforce_account_owner) }}
						</span>
					</td>
					<td class="text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_se && entry['org'].salesforce_se !==''"
							[ngbTooltip]="entry['org'].salesforce_se">
							{{ tt.acronym(entry['org'].salesforce_se) }}
						</span>
					</td>

					<td class="text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_tam && entry['org'].salesforce_tam !==''"
							[ngbTooltip]="entry['org'].salesforce_tam">
							{{ tt.acronym(entry['org'].salesforce_tam) }}
						</span>
					</td>

					<td class="border-left">
						<span *ngIf="entry.activation">
							{{ entry.activation.info }}
						</span>
					</td>

					<td class="text-nowrap cp-key">
						<a [routerLink]="['/' + ac.urls.licensing,  'activation', entry.object_id]" [ngbPopover]="keyPopup"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ entry.object_name }}</a>
					</td>
					<ng-template #keyPopup>
						<app-card-key [object]="entry.activation" [keyProducts]="keyProducts" [organizations]="organizations"
							[forBasic]="false"></app-card-key>
					</ng-template>

					<ng-template #organizationPopupContent>
						<app-card-organization [object]="entry['org']"></app-card-organization>
					</ng-template>
				</tr>
			</table>
		</div>

		<div class="tab-pane" role="tabpanel" id="nav-hostids" aria-labelledby="nav-hostids-tab">

			<div *ngIf="topHostIDs && topHostIDs.length > 0" class='cp-125 mb-2'>
				Top Usage by Host ID {{ bannerExtra }}
			</div>
			<table *ngIf="topHostIDs && topHostIDs.length > 0" class="cp-std-table cp-auto-width">
				<thead>
					<tr>
						<th>#</th>
						<th>
							Traffic
							<span *ngIf="!showRawData">(MB)</span>
						</th>
						<th>Organization</th>
						<th>Type</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fab', 'salesforce']" size="sm" ngbTooltip="Account Owner" placement="bottom">
							</fa-icon>
						</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fas', 'user-graduate']" size="sm" ngbTooltip="SE" placement="bottom">
							</fa-icon>
						</th>
						<th class="cp-icon-header text-center">
							<fa-icon [icon]="['fas', 'user-ninja']" size="sm" ngbTooltip="TAM" placement="bottom">
							</fa-icon>
						</th>
						<th>{{ ac.keyInfoFieldLabel }}</th>
						<th>Key</th>
						<th>Host ID</th>
					</tr>
				</thead>

				<tr *ngFor="let entry of topHostIDs; let i = index">
					<td class="border-left text-center">
						{{ (i + 1) }}
					</td>
					<td class="border-left text-nowrap text-end">
						{{ showDataValue(entry.used) }}
					</td>
					<td class="border-left">
						<a *ngIf="entry['org']" [routerLink]="['/' + ac.urls.organizations, entry.activation.zcp_org_id]"
							[ngbPopover]="organizationPopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
							container="body">
							{{ entry['org'].name }}
						</a>
					</td>

					<td class="border-left text-nowrap">
						<span *ngIf="entry['org']">
							{{ ac.organizationTypeLabels[entry['org'].otype] }}
						</span>
					</td>
					<td class="border-left text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_account_owner && entry['org'].salesforce_account_owner !==''"
							[ngbTooltip]="entry['org'].salesforce_account_owner">
							{{ tt.acronym(entry['org'].salesforce_account_owner) }}
						</span>
					</td>
					<td class="border-left text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_se && entry['org'].salesforce_se !==''"
							[ngbTooltip]="entry['org'].salesforce_se">
							{{ tt.acronym(entry['org'].salesforce_se) }}
						</span>
					</td>

					<td class="text-center text-nowrap">
						<span *ngIf="entry['org'] && entry['org'].salesforce_tam && entry['org'].salesforce_tam !==''"
							[ngbTooltip]="entry['org'].salesforce_tam">
							{{ tt.acronym(entry['org'].salesforce_tam) }}
						</span>
					</td>

					<td class="border-left">
						<span *ngIf="entry.activation">
							{{ entry.activation.info }}
						</span>
					</td>

					<td class="border-left text-nowrap cp-key">
						<a *ngIf="entry.activation" [routerLink]="['/' + ac.urls.licensing,  'activation', entry.activation_id]"
							[ngbPopover]="keyPopup" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
							{{ entry.activation.key }}</a>
					</td>

					<td class="border-left text-nowrap">
						<a [routerLink]="['/' + ac.urls.licensing, 'hostid', entry.object_name, entry.activation_id]">
							{{ entry.object_name }}</a>
					</td>

					<ng-template #keyPopup>
						<app-card-key [object]="entry.activation" [keyProducts]="keyProducts" [organizations]="organizations"
							[forBasic]="false"></app-card-key>
					</ng-template>

					<ng-template #organizationPopupContent>
						<app-card-organization [object]="entry['org']"></app-card-organization>
					</ng-template>
				</tr>
			</table>
		</div>
	</div>
</div>