import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { BuildsService } from '../builds.service';
import { AuthService } from 'client/app/services/auth.service';
import { ProductsService } from '../../products/products.service';
import { PlatformsService } from '../../platforms/platforms.service';

import { BuildsTableComponent } from 'client/app/components/shared/builds-table/builds-table.component';

@Component({
	selector: 'app-build-list',
	templateUrl: './build-list.component.html',
	styleUrls: ['./build-list.component.scss']
})
export class BuildListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;
	now = new Date();

	@ViewChild('buildsTable1') buildsTable1: BuildsTableComponent = null;

	// 'standard' list stuff
	items: Models.Build[] = [];
	itemsToShow: Models.Build[] = [];

	loading = true;
	refreshing = false;

	// other stuff
	products: Models.Product[] = [];
	platforms: Models.Platform[] = [];

	productsForNew: Models.Product[] = [];

	private userSubscription: Subscription;
	canAdd = false;

	showFilters = false;
	theForm: UntypedFormGroup;

	// things to choose from
	enabledChoices = [];
	retiredChoices = [];
	visibiltyChoices = [];
	productChoices = [];
	platformChoices = [];

	// filters
	enabledFilters = [];
	retiredFilters = [];
	visibiltyFilters = [];
	productFilters = [];
	platformFilters = [];

	constructor(
		private authService: AuthService,
		private buildsService: BuildsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		this.loading = true;

		let settings: any = {};
		try {
			if (localStorage.getItem('cp-buildsList.settings'))
				settings = JSON.parse(localStorage.getItem('cp-buildsList.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('enabledFilters')) this.enabledFilters = settings.enabledFilters;
		if (settingKeys.includes('retiredFilters')) this.retiredFilters = settings.retiredFilters;
		if (settingKeys.includes('visibiltyFilters')) this.visibiltyFilters = settings.visibiltyFilters;
		if (settingKeys.includes('productFilters')) this.productFilters = settings.productFilters;
		if (settingKeys.includes('platformFilters')) this.platformFilters = settings.platformFilters;

		this.showFilters = (
			this.enabledFilters.length !== 0
			|| this.retiredFilters.length !== 0
			|| this.visibiltyFilters.length !== 0
			|| this.productFilters.length !== 0
			|| this.platformFilters.length !== 0
		);

		this.userSubscription = this.authService.user.subscribe((authUser) => {
			this.canAdd = authUser && ValidationTools.checkAccess(authUser, 'manage-builds');
			this.products = this.productsService.getAll();
			this.platforms = this.platformsService.getAll();
			this.items = this.buildsService.getAll();

			this.productsForNew = [];
			for (const productType of AppConstants.productTypes)
				for (const product of this.products)
					if (product.ptype === productType && ValidationTools.hasFlag(product, 'may_have_builds'))
						this.productsForNew.push(product);

			this.initForm();
		});
	}

	// ------------------------------------------------------------------------
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// ------------------------------------------------------------------------
	async initForm() {
		// setup choices...
		const productIDsUsed: number[] = [];
		const platformIDsUsed: number[] = [];

		for (const build of this.items) {
			if (!productIDsUsed.includes(build.product_id))
				productIDsUsed.push(build.product_id);

			for (const pf of build.platform_files)
				if (!platformIDsUsed.includes(pf.platform_id))
					platformIDsUsed.push(pf.platform_id);
		} // for

		this.productChoices = [];
		for (const productType of AppConstants.productTypes)
			for (const product of this.products)
				if (product.ptype === productType && productIDsUsed.includes(product.id))
					this.productChoices.push({ value: product.id, label: product.name });

		this.platformChoices = [];
		for (const platform of this.platforms)
			if (platformIDsUsed.includes(platform.id))
				this.platformChoices.push({ value: platform.id, label: platform.name });

		this.enabledChoices = [];
		this.enabledChoices.push({ value: 1, label: 'Enabled/Online' });
		this.enabledChoices.push({ value: 0, label: 'Disabled/Offline' });

		this.visibiltyChoices = [];
		this.visibiltyChoices.push({ value: 0, label: 'Public/GA' });
		this.visibiltyChoices.push({ value: 1, label: 'Private' });

		this.retiredChoices = [];
		this.retiredChoices.push({ value: 0, label: 'Not Retired/Archived' });
		this.retiredChoices.push({ value: 1, label: 'Retired/Archived' });

		this.theForm = new UntypedFormGroup({
			enabledFilters: new UntypedFormControl(this.enabledFilters),
			visibiltyFilters: new UntypedFormControl(this.visibiltyFilters),
			retiredFilters: new UntypedFormControl(this.retiredFilters),
			productFilters: new UntypedFormControl(this.productFilters),
			platformFilters: new UntypedFormControl(this.platformFilters)
		});

		this.filterItems();
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.enabledFilters = this.theForm.value.enabledFilters;
		this.retiredFilters = this.theForm.value.retiredFilters;
		this.visibiltyFilters = this.theForm.value.visibiltyFilters;
		this.productFilters = this.theForm.value.productFilters;
		this.platformFilters = this.theForm.value.platformFilters;

		let settings: any = {
			enabledFilters: this.enabledFilters,
			retiredFilters: this.retiredFilters,
			visibiltyFilters: this.visibiltyFilters,
			platformFilters: this.platformFilters
		};
		localStorage.setItem('cp-buildsList.settings', JSON.stringify(settings));

		this.filterItems();
	}

	// ------------------------------------------------------------------------
	async refresh() {
		this.refreshing = true;
		try {
			await this.buildsService.refreshAll().toPromise();
			this.items = this.buildsService.getAll();
			this.filterItems();
		} catch (err) {
			// console.error('Caught an error refreshing list');
		}
		this.refreshing = false;
	}
	// ------------------------------------------------------------------------
	async filterItems() {
		this.loading = true;
		this.itemsToShow = [];
		for (const item of this.items) {
			let passedEnabledFilters = false;
			let passedVisibiltyFilters = false;
			let passedRetiredFilters = false;
			let passedProductFilters = false;
			let passedPlatformFilters = false;

			if (!this.enabledFilters || this.enabledFilters.length === 0 || this.enabledFilters.includes(item.is_enabled))
				passedEnabledFilters = true;

			if (!this.visibiltyFilters || this.visibiltyFilters.length === 0 || this.visibiltyFilters.includes(item.is_private))
				passedVisibiltyFilters = true;

			if (!this.retiredFilters || this.retiredFilters.length === 0 || this.retiredFilters.includes(item.is_retired))
				passedRetiredFilters = true;

			if (!this.productFilters || this.productFilters.length === 0 || this.productFilters.includes(item.product_id))
				passedProductFilters = true;

			if (!this.platformFilters || this.platformFilters.length === 0) {
				passedPlatformFilters = true;
			} else {
				for (const pf of item.platform_files)
					if (this.platformFilters.includes(pf.platform_id))
						passedPlatformFilters = true;
			}

			let match: boolean = passedEnabledFilters
				&& passedVisibiltyFilters
				&& passedRetiredFilters
				&& passedProductFilters
				&& passedPlatformFilters;

			if (match) this.itemsToShow.push(item);
		} // for

		await MiscTools.delay(100);
		if (this.buildsTable1) this.buildsTable1.updateContent(this.itemsToShow, 'cp-builds-list', { showProduct: true });

		this.loading = false;
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	getFileCount(bld: Models.Build): number {
		let count = 0;
		if (bld.platform_files) count += bld.platform_files.length;
		if (bld.release_notes_file_id && bld.release_notes_file_id !== 0) count++;
		return count;
	}

	// ------------------------------------------------------------------------
	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}
}

