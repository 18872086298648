import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { BuildsService } from '../builds.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { FilesService } from '../../files/files.service';

@Component({
	selector: 'app-auto-build-form',
	templateUrl: './auto-build-form.component.html',
	styleUrls: ['./auto-build-form.component.scss']
})
export class AutoBuildFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	// 'standard' edit stuff
	errors: string[] = [];
	theForm: UntypedFormGroup;
	saving = false;

	// other stuff
	results: Models.AutoBuildResponse[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private filesService: FilesService,
		private buildsService: BuildsService) {
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
	}

	initForm() {
		this.theForm = new UntypedFormGroup({
			is_enabled: new UntypedFormControl(1),
			is_private: new UntypedFormControl(1),
			test_mode: new UntypedFormControl(1)
		});
	}

	async onSubmit() {
		this.saving = true;
		this.results = [];
		this.errors = [];

		const isEnabled = +this.theForm.value.is_enabled === 1;
		const isPrivate = +this.theForm.value.is_private === 1;
		const testMode = +this.theForm.value.test_mode === 1;

		try {
			this.results = await this.buildsService.autoBuild(!isEnabled, isPrivate, testMode);
			this.saving = false;
		} catch (e) {
			this.saving = false;
			this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		}
	}

	onCancel() {
		this.router.navigate([AppConstants.urls.builds]);
	}

	async disableAutoBuild(id: number) {
		if (confirm('Are you sure you want to disable Auto-Build for this file?')) {
			const result = await this.filesService.toggleAutoBuild(id);
			if (result) {
				const idx = MiscTools.findIndexGeneric(this.results, 'fileID', id);
				if (idx !== -1) this.results.splice(idx, 1);
			} else {
				return false;
			}
		}
	}

}
