<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.licensing" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Key Search
			</li>
			<!-- <li class="breadcrumb-item active" aria-current="page">
				Search
			</li> -->
			<app-loading-spinner *ngIf="loading || searching"></app-loading-spinner>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="showAddButton" type="button" class="btn btn-outline-primary me-2"
				[routerLink]="['/' + ac.urls.licensing, 'new']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New License Key</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" [routerLink]="['/' + ac.urls.licensing, 'aggregate']"
				ngbTooltip="View Usage Information Across a Set of Keys and/or Host IDs">
				<fa-icon [icon]="['fas', 'binoculars']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Aggregate</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" [routerLink]="['/' + ac.urls.licensing, 'offline']">
				<fa-icon [icon]="['fas', 'lightbulb-slash']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Offline Licenses</span>
			</button>

		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>

	<form *ngIf="!loading && theSearchForm" [formGroup]="theSearchForm" class="container m-auto">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-4">
				<ng-select [items]="ac.searchablProductSelections" bindValue="value" bindLabel="label" id="productsFilter"
					formControlName="productsFilter" [multiple]="true" placeholder="Product(s)" placement="bottom"
					ngbTooltip="Filter results by one or more key product" [closeOnSelect]="false"
					[ngClass]=" { 'border border-success' : productsFilter.value !=null && productsFilter.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="ac.searchableKeyTypesSelections" bindValue="value" bindLabel="label" id="typesFilter"
					formControlName="typesFilter" [multiple]="true" placeholder="Type(s)" placement="bottom"
					ngbTooltip="Filter results by one or more key type" [closeOnSelect]="false"
					[ngClass]=" { 'border border-success' : typesFilter.value !=null && typesFilter.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<input type="text" id="keyFilter" formControlName="keyFilter" class="form-control" placeholder="Key(s)" placement="bottom"
					ngbTooltip="Enter one or more (space separated) keys"
					[ngClass]="{ 'border border-success': keyFilter.value != null && keyFilter.value !== '' }" />
			</div>

			<div class="cp-filter-block-4">
				<input type="text" id="hostidFilter" formControlName="hostidFilter" class="form-control" placeholder="Host ID(s)/IP(s)"
					placement="bottom" ngbTooltip="Enter one or more (space separated) host IDs and/or host IPs"
					[ngClass]="{ 'border border-success': hostidFilter.value != null && hostidFilter.value !== '' }" />
			</div>

			<div class="cp-filter-block-4">
				<input type="text" id="textFilter" formControlName="textFilter" class="form-control"
					placeholder="Text - Info/Purpose/Label, Notes & Commercial Notes" placement="bottom"
					ngbTooltip="Text to search for in Info/Purpose/Label, Notes & Commercial Notes fields"
					[ngClass]="{ 'border border-success': textFilter.value != null && textFilter.value !== '' }" />
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="orgTypeSelections" bindValue="value" bindLabel="label" id="orgTypeFilter"
					formControlName="orgTypeFilter" [multiple]="true" placeholder="Organizations Type(s)" placement="bottom"
					ngbTooltip="Filter results by one or more Organizations type" [closeOnSelect]="false"
					[ngClass]=" { 'border border-success' : orgTypeFilter.value !=null && orgTypeFilter.value.length > 0 }">
				</ng-select>
			</div>
		</div>

		<div class="text-center">
			<span (click)="toggleAdvanced()" class="cp-pointer"
				[ngbTooltip]=" (advancedSearchMode ? 'Hide' : 'Show') + ' Advanced Filters'">
				<fa-icon *ngIf="advancedSearchMode" [icon]="['fas', 'plus-square']" size="sm"></fa-icon>
				<fa-icon *ngIf="!advancedSearchMode" [icon]="['fas', 'minus-square']" size="sm"></fa-icon>
				Advanced Filters - {{ advancedCount }} set
			</span>
		</div>

		<div *ngIf="advancedSearchMode" class="row ps-2 pe-2">
			<div class="cp-filter-block-4">
				<ng-select [items]="usageDaySelections" bindValue="value" bindLabel="label" id="reportDays" formControlName="reportDays"
					[multiple]="false" placeholder="Reported in the last..." placement="bottom"
					ngbTooltip="Any activation of this key has reported in (with or without traffic)." (change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : reportDays.value !=null && +reportDays.value !== 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="usageDaySelections" bindValue="value" bindLabel="label" id="usageDays" formControlName="usageDays"
					[multiple]="false" placeholder="Used in the last..." placement="bottom"
					ngbTooltip="Any activation of this key has reported in with non-zero traffic." (change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : usageDays.value !=null && +usageDays.value !== 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="usageDaySelections" bindValue="value" bindLabel="label" id="touchedDays" formControlName="touchedDays"
					[multiple]="false" placeholder="Touched in the last..." placement="bottom"
					ngbTooltip="Touched is if the key was added/updated, an activation was added/updated or any activation reported in."
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : touchedDays.value !=null && +touchedDays.value !== 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="usageDaySelections" bindValue="value" bindLabel="label" id="notTouchedDays"
					formControlName="notTouchedDays" [multiple]="false" placeholder="Not touched in the last..." placement="bottom"
					ngbTooltip="Not touched is if the key was not added/updated, an activation was added/updated or any activation reported in."
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : notTouchedDays.value !=null && +notTouchedDays.value !== 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="usageDaySelections" bindValue="value" bindLabel="label" id="updatedDays" formControlName="updatedDays"
					[multiple]="false" placeholder="Added/Edited in the last..." placement="bottom"
					ngbTooltip="The key was added or edited in the last X days.." (change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : updatedDays.value !=null && +updatedDays.value !== 0 }">
				</ng-select>
			</div>

			<!-- <div class="cp-filter-block-4">
				<ng-select [items]="lpusers" bindValue="id" bindLabel="email" id="userIDs" formControlName="userIDs"
					[multiple]="true" placeholder="Last Touched by..." placement="bottom" [closeOnSelect]="false"
					ngbTooltip="Last user from legacy license portal to either add or update the key">
				</ng-select>
			</div> -->

			<div class="cp-filter-block-4">
				<ng-select [items]="staffSelections" bindValue="id" bindLabel="name" id="zcpTouchedBy" formControlName="zcpTouchedBy"
					[multiple]="true" placeholder="Added/Edited Edited by..." placement="bottom"
					ngbTooltip="User that created the key via this Portal" [closeOnSelect]="false" (change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : zcpTouchedBy.value !=null && +zcpTouchedBy.value !== 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="templateSelections" bindValue="value" bindLabel="label" id="keyTemplateFilter"
					formControlName="keyTemplateFilter" [multiple]="true" placeholder="Key Template(s)" placement="bottom"
					ngbTooltip="Filter results by one or more key templates" [closeOnSelect]="false" (change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : keyTemplateFilter.value !=null && keyTemplateFilter.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="accountOwnerSelections" bindValue="id" bindLabel="name" id="acctOwnerIDs" formControlName="acctOwnerIDs"
					[multiple]="true" placeholder="Account Owner(s)" placement="bottom"
					ngbTooltip="Account Owner of Organization linked to key" [closeOnSelect]="false" (change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : acctOwnerIDs.value !=null && acctOwnerIDs.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="seSelections" bindValue="id" bindLabel="name" id="techRepIDs" formControlName="techRepIDs"
					[multiple]="true" placeholder="SE(s)" placement="bottom" ngbTooltip="SE(s) of Organization linked to key"
					[closeOnSelect]="false" (change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : techRepIDs.value !=null && techRepIDs.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="orgSelections" bindValue="value" bindLabel="label" id="orgIDs" formControlName="orgIDs"
					[multiple]="true" placeholder="Organization(s)" placement="bottom"
					ngbTooltip="Filter search results to one or more specific organizations" [closeOnSelect]="false"
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : orgIDs.value !=null && orgIDs.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="billingCodeSelections" bindValue="value" bindLabel="label" id="billingCodeFilter"
					formControlName="billingCodeFilter" [multiple]="true" placeholder="Billing Code(s)" placement="bottom"
					ngbTooltip="Filter search results to keys reporting against one or more billing codes" [closeOnSelect]="false"
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : billingCodeFilter.value !=null && billingCodeFilter.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="featureChoices" bindValue="value" bindLabel="label" id="orBooleanKeyProperties"
					formControlName="orBooleanKeyProperties" [multiple]="true" placeholder="Keys with any of these features"
					placement="bottom" [closeOnSelect]="false" ngbTooltip="Find keys that have at least one of these features enabled."
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : orBooleanKeyProperties.value !=null && orBooleanKeyProperties.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="protocolChoices" bindValue="value" bindLabel="label" id="protocolsFilter"
					formControlName="protocolsFilter" [multiple]="true" placeholder="Protocol traffic type" placement="bottom"
					ngbTooltip="Find keys reporting non-zero traffic in one of these protocols" [closeOnSelect]="false"
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : protocolsFilter.value !=null && protocolsFilter.value.length > 0 }">
				</ng-select>
			</div>

			<!-- <div class="cp-filter-block-4">
				<ng-select [items]="brandChoices" bindValue="value" bindLabel="label" id="brandsFilter" formControlName="brandsFilter"
					[multiple]="true" placeholder="Remote brand" placement="bottom"
					ngbTooltip="Find keys reporting non-zero traffic in one of these brands" [closeOnSelect]="false"
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : brandsFilter.value !=null && brandsFilter.value.length > 0 }">
				</ng-select>
			</div> -->

			<div class="cp-filter-block-4">
				<ng-select [items]="protocolSetSelections" bindValue="value" bindLabel="label" id="protocolSetFilter"
					formControlName="protocolSetFilter" [multiple]="true" placeholder="Linked Protocol Set(s)" placement="bottom"
					ngbTooltip="Filter results where key is linked to selected protocol set(s)" [closeOnSelect]="false"
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : protocolSetFilter.value !=null && protocolSetFilter.value.length > 0 }">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="commercialTypeSelections" bindValue="value" bindLabel="label" id="commercialTypeFilter"
					formControlName="commercialTypeFilter" [multiple]="true" placeholder="Commercial Type(s)" placement="bottom"
					ngbTooltip="Filter search results to keys with a specific commercial type" [closeOnSelect]="false"
					(change)="updateAdvancedCount()"
					[ngClass]=" { 'border border-success' : commercialTypeFilter.value !=null && commercialTypeFilter.value.length > 0 }">
				</ng-select>
			</div>

		</div>

		<div class="text-center">
			<span (click)="toggleSpecialized()" class="cp-pointer"
				[ngbTooltip]=" (advancedSearchMode ? 'Hide' : 'Show') + ' Special Filters'">
				<fa-icon *ngIf="showSpecializedFilters" [icon]="['fas', 'plus-square']" size="sm"></fa-icon>
				<fa-icon *ngIf="!showSpecializedFilters" [icon]="['fas', 'minus-square']" size="sm"></fa-icon>
				Special Filters - {{ selectedSpecials.length }} selected
			</span>
		</div>

		<div *ngIf="showSpecializedFilters" class="border mb-2 p-2">
			<div class="row">
				<div class="cp-keysearch-specials">
					<div class="text-center fw-bold">Available</div>
					<div *ngFor="let o of deselectedSpecials; let i = index" class="mb-1">
						<button type="button" class="btn btn-sm btn-outline-primary me-1" (click)="selectSpecialFilter(o.value)">
							<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
							<!-- Select -->
						</button>
						<fa-icon *ngIf="o.info && o.info !== ''" [icon]="['fas', 'question-circle']" size="sm" [ngbPopover]="popupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body"></fa-icon>
						{{ o.label }}

						<ng-template #popupContent>
							<div class="cp-popover-banner">{{ o.label }}</div>
							<div class="cp-pre-text">{{ o.info}}</div>
						</ng-template>
					</div>
				</div>

				<div class="cp-keysearch-specials" [ngClass]=" { 'border border-success' : selectedSpecials.length > 0 }">
					<div class="text-center fw-bold">Selected</div>
					<div *ngFor="let o of selectedSpecials; let i = index" class="mb-1">
						<button type="button" class="btn btn-sm btn-outline-primary me-1" (click)="deselectSpecialFilter(o.value)">
							<fa-icon [icon]="['fas', 'minus']" size="sm"></fa-icon>
							<!-- Remove -->
						</button>
						<fa-icon *ngIf="o.info && o.info !== ''" [icon]="['fas', 'question-circle']" size="sm" [ngbPopover]="popupContent"
							popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body"></fa-icon>
						{{ o.label }}

						<ng-template #popupContent>
							<div class="cp-popover-banner">{{ o.label }}</div>
							<div class="cp-pre-text">{{ o.info}}</div>
						</ng-template>
					</div>

				</div>
			</div>

			<!-- <div class="row ps-4 pe-4">
				<div *ngFor="let o of ac.specialKeySearches; let i = index" class="form-check col-4">
					<input class="form-check-input" type="checkbox" [id]="'special_' + o.value"
						[formControlName]="'special_' + o.value" (change)="updateSpecialCounter()">
					<label class="form-check-label px-1" [for]="'special_' + o.value"
						[ngClass]="{'cp-selected-primary': isSpecialChecked(o.value)}" style="width: 100%">
						<fa-icon *ngIf="o.info && o.info !== ''" [icon]="['fas', 'question-circle']" size="sm"
							[ngbPopover]="popupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
							container="body"></fa-icon>
						{{ o.label }}
					</label>

					<ng-template #popupContent>
						<div class="cp-popover-banner">{{ o.label }}</div>
						<div class="cp-pre-text">{{ o.info}}</div>
					</ng-template>
				</div>
			</div> -->
		</div>

		<div class="text-center mb-1" *ngIf="!searching && formMode === 'searchForm'">
			<button class="btn btn-primary" type="button" (click)="doSearch()">
				<fa-icon [icon]="['fas', 'search']"></fa-icon> Search for License Keys
			</button>

			<button *ngIf="showResetButton" class="btn btn-outline-primary ms-1" type="button" (click)="resetForm()">
				Reset Search Parameters
			</button>
		</div>

		<div class="text-center" *ngIf="!searching && formMode === 'searchForm'">
			<button class="btn btn-outline-primary ms-1" type="button" (click)="showSaveForm()"
				ngbTooltip="Save these search settings to run the search again in the future or to use with an automated report">
				<fa-icon [icon]="['fas', 'save']"></fa-icon>
				Save Search
			</button>

			<button *ngIf="savedSearches.length !== 0" class="btn btn-outline-primary ms-1" type="button" (click)="formMode='savedSearches'"
				ngbTooltip="Select/Manage one of your saved searches">
				<fa-icon [icon]="['fas', 'list']"></fa-icon>
				Saved Searches
			</button>

			<button class="btn btn-outline-primary ms-1" type="button" [routerLink]="['/' + ac.urls.licensing, 'search-filter-info']"
				ngbTooltip="Help info on search fields">
				<fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
			</button>

			<div *ngIf="savedSearches.length > 0" class="text-center small mt-1">
				<select id="savedSearchPicker" class="form-control cp-auto-width mx-auto small" (change)="runSavedSearchFromPicker()">
					<option value="">Run a Saved Search</option>
					<option *ngFor="let savedSearch of savedSearches" [value]="savedSearch.id">
						{{ savedSearch.name }}
					</option>
				</select>
			</div>
		</div>
	</form>

	<div *ngIf="showNoParamsMessage" class="cp-warning-message mt-4">
		You must supply at least one search parameter
	</div>

	<!-- stretches panel down enough so that autocomplete popups have space -->
	<div [ngStyle]="{'display': formMode === 'saveForm' ? '' : 'none' }"
		class="mb-4 mt-2 p-2 border cp-general-width-limit m-auto text-center">
		<form *ngIf="!loading && theSaveForm" [formGroup]="theSaveForm">
			<div class="form-group">
				<input type="text" id="name" formControlName="name" class="form-control" placeholder="Name/label for saved search" />
			</div>

			<div class="form-group">
				<button class="btn btn-primary me-1" type="button" [disabled]="!theSaveForm.valid" (click)="saveSearch()">
					<fa-icon [icon]="['fas', 'check']"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="formMode='searchForm'">
					<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
				</button>
			</div>
		</form>

		<div *ngIf="savedSearches.length > 0">
			Or overwrite another saved search
			<div *ngFor="let savedSearch of savedSearches" class="border p-2 m-2 text-start">
				<button class="btn btn-primary btn-sm" type="button" (click)="overwriteSavedSearch(savedSearch.id)">
					<fa-icon [icon]="['fas', 'save']"></fa-icon> Overwrite
				</button>
				{{ savedSearch.name }}
			</div>
		</div>
	</div>

	<div [ngStyle]="{'display': formMode === 'savedSearches' ? '' : 'none' }"
		class="mb-4 mt-2 p-2 border cp-general-width-limit m-auto text-center">
		Saved Searches
		<div class="text-center">
			<button class="btn btn-outline-secondary btn-sm" type="button" (click)="formMode='searchForm'">
				<fa-icon [icon]="['fas', 'undo']"></fa-icon> Back to Manual Search
			</button>
		</div>
		<div *ngFor="let savedSearch of savedSearches" class="border p-2 m-2 text-start">
			<span class="float-end" type="button" (click)="deleteSavedSearch(savedSearch.id)">
				<fa-icon [icon]="['fas', 'times-circle']" class="text-danger" ngbTooltip="Delete this saved search">
				</fa-icon>
			</span>
			<button class="btn btn-primary btn-sm" type="button" (click)="runSavedSearch(savedSearch.id)">
				<fa-icon [icon]="['fas', 'search']"></fa-icon> Run Search
			</button>
			{{ savedSearch.name }}
		</div>

	</div>

	<div *ngIf="!showResults" style="height:300px"></div>

	<!-- <div *ngIf="showRecentMessage" class="cp-info-message mt-4">Recently Viewed Keys</div> -->
	<div [ngStyle]="{'display': !showResults ? 'none' : '' }">
		<!-- <div *ngIf="maxMessage !== ''" class="cp-warning-message mt-4">{{ maxMessage }}</div> -->

		<div *ngIf="showResults" class="container">
			<div class="row mb-2">
				<div class="col-12 p-0 mt-1 text-center align-middle mt-2">
					<!-- style="float: right; text-align: right;" -->
					<span class="float-end text-end">
						<button *ngIf="expandWarnings" class="btn btn-outline-secondary" type="button" (click)="toggleWarnings()">
							<fa-icon [icon]="['fas', 'toggle-on']"></fa-icon> Collapse Warnings
						</button>
						<button *ngIf="!expandWarnings" class="btn btn-outline-secondary" type="button" (click)="toggleWarnings()">
							<fa-icon [icon]="['fas', 'toggle-off']"></fa-icon> Expand Warnings
						</button>
					</span>

					<span class="fw-bold large border p-1">
						<span *ngIf="!activations || activations.length === 0" class="text-warning">
							No Keys Found
						</span>
						<span *ngIf="activations && activations.length === 1">
							One Key Found
						</span>
						<span *ngIf="activations && activations.length > 1 && maxMessage === ''">
							{{ activations.length }} Keys Found
						</span>
						<span *ngIf="activations && activations.length > 0 && maxMessage !== ''" class="text-warning">
							{{ maxMessage }}
						</span>
					</span>

					<span class="float-start text-start">
						<button [disabled]="!activations || activations.length === 0 || activations.length > ac.maxAggregateKeys"
							class="btn btn-outline-secondary me-1" type="button" (click)="doAggregate()"
							[ngbTooltip]="'View Aggregate Stats Across All Keys.  Must be ' + ac.maxAggregateKeys + ' keys or less.'"
							placement="end">
							<fa-icon [icon]="['fas', 'binoculars']" size="sm"></fa-icon>
						</button>

						<button [disabled]="!activations || activations.length === 0" class="btn btn-outline-secondary" type="button"
							(click)="saveAsExcel()">
							<fa-icon [icon]="['fas', 'file-excel']"></fa-icon> Export Results
						</button>
					</span>
				</div>
			</div>
		</div>

		<app-keys-table #keysTable1></app-keys-table>

	</div>
</div>