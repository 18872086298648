<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.quizzes" class="cp-user-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Quizzes + Polls</a>
			</li>
			<li *ngIf="quiz" class="breadcrumb-item active" aria-current="page">
				{{ quiz.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group" *ngIf="!!quiz">
				<button type="button" class="btn btn-outline-secondary" (click)="loadData()" [disabled]="loading">
					<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">

	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-my-responses-tab" aria-controls="nav-my-responses" data-bs-target="#nav-my-responses">
				<fa-icon [icon]="['fas', 'comment-dots']" size="sm"></fa-icon>
				My Responses
				<!-- <span *ngIf="!loading || (quiz && quiz.config.questions.length > 0)" class="cp-tab-badge">
					{{ quiz.config.questions.length | number}}
				</span> -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" role="tabpanel" id="nav-base" aria-labelledby="nav-base-tab">
			<div *ngIf="quiz" class="cp-property-list">

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ quiz.name }}</span>
				</div>

				<div *ngIf="quiz.description !== ''" class="cp-property-block">
					<span class="cp-property-label">Description:</span>
					<span class="cp-property-value cp-pre-text">{{ quiz.description }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Type:</span>
					<span class="cp-property-value">
						{{ mt.fetchLabel(ac.quizTypeSelections, quiz.config.quizType, 'value',
						'shortLabel') }}
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span class="cp-property-value">
						{{ mt.fetchLabel(ac.quizStatusSelections, quiz.status, 'value', 'shortLabel')
						}}
					</span>
				</div>

				<!-- <div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ quiz.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="addedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="quiz.added_on !== quiz.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ quiz.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]"
								[ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
								triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="editedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div> -->
			</div>

			<div *ngIf="canTakeQuiz" class="mt-1">
				<button class="btn btn-primary me-1" type="button" [routerLink]="['take']">
					<fa-icon [icon]="['fas', 'play-circle']"></fa-icon>
					Take the {{ mt.fetchLabel(ac.quizTypeSelections, quiz.config.quizType, 'value',
					'shortLabel') }}
				</button>
			</div>

			<div *ngIf="canManageThisQuiz" class="mt-1">
				<button class="btn btn-primary me-1" type="button" [routerLink]="['/' + ac.urls.quizzes, this.id]">
					<fa-icon [icon]="['fas', 'cog']"></fa-icon>
					Manage this Quiz
				</button>
			</div>

		</div>

		<div class="tab-pane" role="tabpanel" id="nav-my-responses" aria-labelledby="nav-my-responses-tab">

			<div *ngIf="quiz">

				<div *ngIf="!showAllQuestions" class="cp-property-list">
					<button class="btn btn-outline-primary me-1" type="button" (click)="showAllQuestions = true"
						ngbTooltip="Show All Questions">
						Show All
					</button>

					<button *ngFor="let q of quiz.config.questions; let i = index" [class]="'btn mb-1 me-1 ' + getButtonClass(i)"
						type="button" (click)="currentQuestion = i">
						{{ (i + 1) }}
					</button>

					<button *ngIf="canTakeQuiz" class="btn btn-primary me-1" type="button" [routerLink]="['take']">
						<fa-icon [icon]="['fas', 'play-circle']"></fa-icon>
						Take the {{ mt.fetchLabel(ac.quizTypeSelections, quiz.config.quizType, 'value',
						'shortLabel') }}
					</button>
				</div>

				<div *ngIf="showAllQuestions" class="cp-property-list">
					<button class="btn btn-outline-primary me-1" type="button" (click)="showAllQuestions = false" ngbTooltip="Add Question">
						Show One At A Time
					</button>

					<button *ngIf="canTakeQuiz" class="btn btn-primary me-1" type="button" [routerLink]="['take']">
						<fa-icon [icon]="['fas', 'play-circle']"></fa-icon>
						Take the {{ mt.fetchLabel(ac.quizTypeSelections, quiz.config.quizType, 'value',
						'shortLabel') }}
					</button>
				</div>

				<div *ngFor="let q of quiz.config.questions; let i = index" class="cp-property-list"
					[hidden]="!showAllQuestions && currentQuestion !== i">
					<div class="fw-bold">
						Question #{{ (i + 1) }}
					</div>

					<div class="cp-property-block">
						<span class="cp-125 cp-pre-text">
							{{ q.text }}
							<fa-icon *ngIf="q.moreInfo && q.moreInfo !== ''" [icon]="['fas', 'question-circle']" class="ms-1"
								placement="end" [ngbTooltip]="q.moreInfo">
							</fa-icon>
						</span>
					</div>

					<div class="fw-bold">Your Answer</div>
					<div *ngIf="processedResults[q.id + '-' + authUser.id] && processedResults[q.id + '-' + authUser.id].answer !== ''"
						class="cp-125">
						<fa-icon *ngIf="showMyAnswers && processedResults[q.id + '-' + authUser.id].isCorrect" [icon]="['fas', 'check']"
							class="text-success">
						</fa-icon>
						<fa-icon *ngIf="showMyAnswers && !processedResults[q.id + '-' + authUser.id].isCorrect" [icon]="['fas', 'times']"
							class="text-danger">
						</fa-icon>
						{{ processedResults[q.id + '-' + authUser.id].answer }}
					</div>
					<div *ngIf="!processedResults[q.id + '-' + authUser.id] || processedResults[q.id + '-' + authUser.id].answer === ''"
						class="cp-125 font-italic">
						<fa-icon *ngIf="showMyAnswers" [icon]="['fas', 'times']" class="text-danger">
						</fa-icon>
						No Answer Provided
					</div>
					<div *ngIf="showMyAnswers && !processedResults[q.id + '-' + authUser.id].isCorrect && q.answers.length !== 0">
						<div class="fw-bold">Correct Answer{{ q.answers.length > 1 ? 's' : ''}}:</div>
						'{{ q.answers.join('\' or \'') }}'
					</div>

					<div *ngIf="showMyAnswers && q.explanation && q.explanation !== ''">
						<div class="fw-bold">Explanation:</div>
						<div class="cp-pre-text">{{ q.explanation }}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>