import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-card-protocol-set',
	templateUrl: './card-protocol-set.component.html',
	styleUrls: ['./card-protocol-set.component.scss']
})
export class CardProtocolSetComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.ProtocolSet = null;
	@Input() forStaff: boolean = false;
	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.ProtocolSet = null;

	// options


	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];


	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.ProtocolSet) {

		this.objectToShow = MiscTools.deepClone(object);

		if (!this.objectToShow) return;

		this.nameToShow = this.objectToShow.name;
		this.typeToShow = 'Protocol Set';
		this.iconToShow = 'tasks';

		this.infoLines = [];
		// this.infoLines.push('Name: ' + this.objectToShow.name);
		if (this.objectToShow.description && this.objectToShow.description !== '')
			this.infoLines.push('Description: ' + this.objectToShow.description);
		// if (forStaff) this.infoLines.push('Sharing Mode: ' + this.objectToShow.share_mode);

		const protocols: string[] = MiscTools.deepClone(this.objectToShow.protocolsArr);
		protocols.sort(SharedLicenseTools.protocolSort);

		const bxProtocols: string[] = [];
		const privProtocols: string[] = [];
		const transcodeProtocols: string[] = [];
		const mcProtocols: string[] = [];
		const zmProtocols: string[] = [];

		for (const protocol of protocols) {
			if (protocol.startsWith('mediaconnect_')) {
				mcProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('MediaConnect ', ''));
			} else if (protocol.startsWith('zm_')) {
				zmProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('ZM ', ''));
			} else {
				if (protocol.includes('transcode'))
					transcodeProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else if (protocol.startsWith('private_'))
					privProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else
					bxProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
			} // if
		} // for

		const protocolBlocks: any[] = [];
		if (bxProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Non-Private)',
				protocols: bxProtocols
			});

		if (privProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Private)',
				protocols: privProtocols
			});

		if (transcodeProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (Broadcaster - Transcode)',
				protocols: transcodeProtocols
			});

		if (mcProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (ZEN Master Tracking - MediaConnect)',
				protocols: mcProtocols
			});

		if (zmProtocols.length > 0)
			protocolBlocks.push({
				label: 'Protocols (ZEN Master Tracking - Non-MediaConnect)',
				protocols: zmProtocols
			});

		for (const protocolBlock of protocolBlocks) {
			this.infoLines.push(protocolBlock.label + ' [' + protocolBlock.protocols.length + ']:');
			this.infoLines.push(AppConstants.bullet + ' ' + protocolBlock.protocols.join('; '));
			// for (const p of protocolBlock.protocols)
			// 	this.infoLines.push(AppConstants.bullet + ' ' + p);
		} // for

		if (this.infoLines.length > 15) {
			this.infoLines.splice(15);
			this.infoLines.push('...');
		} // if

		if (this.forStaff) {
			this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.added_on));
			if (this.objectToShow.edited_on && this.objectToShow.edited_on !== this.objectToShow.added_on) this.infoLines.push('Edited: ' + TextTools.formatDateNiceUTC(this.objectToShow.edited_on));
		} // if

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
