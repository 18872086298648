<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.dashboard" class="me-1" size="sm"></fa-icon>
				Dashboard
			</li>
			<li *ngIf="refreshing">
				<app-loading-spinner></app-loading-spinner>
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">

			<!-- <fa-icon *ngIf="audioLevel > 0" [icon]="['fas', 'volume-up']" id="setVolumeButton"
				[ngbTooltip]="'Change the Volume (current level is ' + audioLevel + ')'" class="dropdown-toggle" data-bs-toggle="dropdown"
				aria-haspopup="true" aria-expanded="false">
			</fa-icon>
			<fa-icon *ngIf="audioLevel === 0" [icon]="['fas', 'volume-mute']" class="dropdown-toggle text-danger" id="setVolumeButton"
				[ngbTooltip]="'Change the Volume (current level is ' + audioLevel + ')'" data-bs-toggle="dropdown" aria-haspopup="true"
				aria-expanded="false"></fa-icon>

			<div class="dropdown-menu" aria-labelledby="setVolumeButton" style="width: 200px">
				<input type="range" class="form-range" min="0" max="10" id="volumeSlider" [value]="audioLevel"
					(change)="setVolumeFromRange()" />
			</div> -->

			<button *ngIf="recentAckedMessages.length !== 0" type="button" class="btn btn-outline-secondary" [disabled]="refreshing"
				(click)="unAckRecentMessages()" [ngbTooltip]="restoreButtonPopup" placement="bottom">
				<fa-icon icon="trash-undo" size="sm"></fa-icon>
				<span class="cp-action-button-text">Recover Messages</span>
			</button>

			<button *ngIf="isStaff" type="button" class="btn btn-outline-secondary ms-2" routerLink="usage">
				<fa-icon [icon]="['fas', 'chart-line']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Top Usage</span>
			</button>

			<button *ngIf="isStaff" type="button" class="btn btn-outline-secondary ms-2" routerLink="change-log">
				<fa-icon [icon]="['fas', 'scroll']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Change Log</span>
			</button>

			<button type="button" class="btn btn-outline-secondary ms-2" [disabled]="refreshing" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<div class="page-wrapper">

	<div class="container-xs">
		<div class="row">
			<div class="border col-10 mb-2 ms-2">
				<div class="cp-dashboard-banner">
					<span *ngIf="showWelcome" placement="start" class="cp-pointer float-end">
						&nbsp; <fa-icon (click)="setShowWelcome(false)" [icon]="['fas', 'minus-square']"></fa-icon>
					</span>
					<span *ngIf="!showWelcome" placement="start" class="cp-pointer float-end">
						&nbsp; <fa-icon (click)="setShowWelcome(true)" [icon]="['fas', 'plus-square']"></fa-icon>
					</span>
					Welcome to the Zixi Customer Portal
				</div>

				<div *ngIf="showWelcome">
					This site provides the following:

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.mybuilds]">
								<fa-icon [icon]="ac.sectionIcons.mybuilds" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								Software:</a>
						</span>
						<div class="ms-4 mb-1">
							This section lets you download builds of our software. Access to
							software is generally granted to based on your organization and/or packages (see below)
							you're subscribed to.
						</div>
					</div>

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.mydocuments]">
								<fa-icon [icon]="ac.sectionIcons.mydocuments" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								Documentation:</a>
						</span>
						<div class="ms-4 mb-1">
							This section lets you download or link to documentation. Access to
							documentation is generally granted to based on your organization and/or packages (see below)
							you're subscribed to.
						</div>
					</div>

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.mypartners]">
								<fa-icon [icon]="ac.sectionIcons.mypartners" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								Partners:</a>
						</span>
						<div class="ms-4 mb-1">
							This section is a directory of the members of our partner program.
						</div>
					</div>

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.mykeys]">
								<fa-icon [icon]="ac.sectionIcons.mykeys" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								License Keys:</a>
						</span>
						<div class="ms-4 mb-1">
							This section lets you view information about license keys attached to your user account.
							Keys can be self-generated from packages or created by staff.
						</div>
					</div>

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.myfilesharing]">
								<fa-icon [icon]="ac.sectionIcons.myfilesharing" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								File Sharing:</a>
						</span>
						<div class="ms-4 mb-1">
							This section allows for staff members to send you files (typically not available via
							"Software" or "Documentation") and/or allow you to send us files (typically for
							troubleshooting).
						</div>
					</div>

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.mysettings]">
								<fa-icon [icon]="ac.sectionIcons.mysettings" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								My Settings:</a>
						</span>
						<div class="ms-4 mb-1">
							This section lets you update your name, e-mail address and/or password.
						</div>
					</div>

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.mypackages]">
								<fa-icon [icon]="ac.sectionIcons.mypackages" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								Packages:</a>
						</span>
						<div class="ms-4 mb-1">
							This section lets you subscribe/unsubscribe to/from packages. Being subscribed to a
							package gives you access to software, documentation and, in some cases, the ability to
							self-generate license keys.
						</div>
					</div>

					<div>
						<span class="fw-bold">
							<a [routerLink]="['/' + ac.urls.mynotifications]">
								<fa-icon [icon]="ac.sectionIcons.mynotifications" class="me-1 cp-user-nav-item" size="sm"></fa-icon>
								Notifications:</a>
						</span>
						<div class="ms-4 mb-1">
							This section lets you configure a variety of e-mail notifications that this portal can
							send.
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div *ngIf="messagesToShow && messagesToShow.length !== 0" class="cp-dashboard-block-msgs">
				<div class="cp-dashboard-banner">
					<span *ngIf="canAckAll" ngbTooltip="Acknowledge/Dismiss all messages." placement="start" class="cp-pointer float-end">
						&nbsp; <fa-icon (click)="ackAll()" [icon]="['fas', 'times-circle']"></fa-icon>
					</span>

					<fa-icon [icon]="ac.sectionIcons.systemmessages" class="cp-user-nav-item"></fa-icon>
					Messages
				</div>

				<div *ngFor="let systemMessage of messagesToShow" class="cp-dashboard-msg">
					<span *ngIf="systemMessage.can_ack === 1" ngbTooltip="Acknowledge/Dismiss this message." placement="start"
						class="cp-pointer float-end">
						&nbsp; <fa-icon (click)="ackMessage(systemMessage)" [icon]="['fas', 'times-circle']"></fa-icon>
					</span>

					<span *ngIf="systemMessage.can_ack === 0" ngbTooltip="This message cannot be dismissed." placement="start"
						class="float-end">
						&nbsp; <fa-icon [icon]="['fas', 'circle']"></fa-icon>
					</span>

					{{ systemMessage.added_on | date: ac.shortPageDateFmt:'UTC' }}:
					<fa-icon *ngIf="systemMessage.staff_only === 1" [icon]="['fas', 'lock']" size="sm" class="text-warning me-1"
						ngbTooltip="This is a staff-only message"></fa-icon>
					<span class="fw-bold">{{ systemMessage.message }}</span>

					<button *ngIf="systemMessage.url && systemMessage.url !== ''" class="btn btn-outline-primary btn-sm ms-2"
						(click)="openLink(systemMessage.url)">
						<fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
						More Info
					</button>
				</div>
			</div>
		</div>

		<div class="row">
			<div *ngIf="generalWarnings && generalWarnings.length !== 0" class="cp-dashboard-block-other">
				<div class="cp-dashboard-banner">
					<fa-icon [icon]="['fas', 'exclamation-triangle']" class="text-danger"></fa-icon>
					System Warnings
				</div>

				<div *ngFor="let generalWarning of generalWarnings" class="cp-dashboard-msg fw-bold">
					<fa-icon [icon]="['fas', 'exclamation-triangle']" class="text-danger"></fa-icon>
					{{ generalWarning }}
				</div>
			</div>

			<div *ngIf="keysWithWarnings && keysWithWarnings.length !== 0" class="cp-dashboard-block-other">
				<div class="cp-dashboard-banner">
					<fa-icon [icon]="ac.sectionIcons.mykeys" class="cp-user-nav-item"></fa-icon>
					License Key Alerts
				</div>

				<div *ngFor="let userKey of keysWithWarnings" class="cp-dashboard-msg">
					<a [routerLink]="['/' + ac.urls.mykeys, userKey.id]" [ngbPopover]="keyPopup" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						<span class="cp-key">{{ userKey.activation_key }}</span>
						<span *ngIf="userKey.label && userKey.label !== ''"> ({{ userKey.label }})</span>
					</a>:
					<div *ngFor="let warning of keyMessages[userKey.id]" class="fw-bold">
						<fa-icon [icon]="['fas', 'exclamation-triangle']" class="text-warning"></fa-icon>
						{{ warning }}
					</div>

					<ng-template #keyPopup>
						<app-card-key [object]="userKey.activation" [keyProducts]="keyProducts" [forBasic]="true"></app-card-key>
					</ng-template>
				</div>
			</div>

			<div *ngIf="generalNotifications && generalNotifications.length !== 0" class="cp-dashboard-block-other">
				<div class="cp-dashboard-banner">
					<fa-icon [icon]="['fas', 'info-circle']" class="cp-user-nav-item"></fa-icon>
					System Info
				</div>

				<div *ngFor="let generalNotification of generalNotifications" class="cp-dashboard-item fw-bold">
					{{ generalNotification }}
				</div>
			</div>

			<div *ngIf="buildsToShow && buildsToShow.length !== 0" class="cp-dashboard-block-other">
				<div class="cp-dashboard-banner">
					<fa-icon [icon]="ac.sectionIcons.mybuilds" class="cp-user-nav-item"></fa-icon>
					Recent Software Builds
				</div>
				<div *ngFor="let dBuild of buildsToShow" class="cp-dashboard-item">
					{{ dBuild.build.added_on | date }}:
					<a [routerLink]="['/' + ac.urls.mybuilds, dBuild.build.id]" [ngbPopover]="buildPopup" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						<fa-icon *ngIf="isStaff && dBuild.build.is_private === 1" [icon]="['fas', 'lock']" size="sm" class="text-warning"
							ngbTooltip="Private - Limited Access" placement="end">
						</fa-icon>
						<fa-icon *ngIf="isStaff && dBuild.build.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success"
							ngbTooltip="Public - Generally Available" placement="end">
						</fa-icon>

						{{ dBuild.product.name }} -
						{{ dBuild.build.version }}
						<span *ngIf="dBuild.build.label && dBuild.build.label !== ''">
							({{ dBuild.build.label }})</span>
					</a>

					<fa-icon
						*ngFor="let pl of mt.sortPlatforms(dBuild.platforms, getPlatformIDsFromPlatformFiles(dBuild.build.platform_files))"
						[icon]="mt.getPlatformIcon(pl.name)" [ngbTooltip]="pl.name" placement="start" class="ms-1">
					</fa-icon>

					<ng-template #buildPopup>
						<app-card-build-download [object]="dBuild"></app-card-build-download>
					</ng-template>

				</div>
			</div>

			<div *ngIf="documentsToShow && documentsToShow.length !== 0" class="cp-dashboard-block-other">
				<div class="cp-dashboard-banner">
					<fa-icon [icon]="ac.sectionIcons.mydocuments" class="cp-user-nav-item">
					</fa-icon>
					Recent Documentation
				</div>

				<div *ngFor="let dDoc of documentsToShow" class="cp-dashboard-item">
					{{ dDoc.document.edited_on | date: ac.shortPageDateFmt:'UTC' }}:
					<a [routerLink]="['/' + ac.urls.mydocuments, dDoc.document.id]" [ngbPopover]="docPopup" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						<fa-icon *ngIf="isStaff && dDoc.document.is_private === 1" [icon]="['fas', 'lock']" size="sm" class="text-warning"
							ngbTooltip="Private - Limited Access" placement="end">
						</fa-icon>
						<fa-icon *ngIf="isStaff && dDoc.document.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success"
							ngbTooltip="Public - Generally Available" placement="end">
						</fa-icon>
						{{ dDoc.document.prod_name }} -
						{{ dDoc.document.label }}
					</a>

					<ng-template #docPopup>
						<app-card-document-download [object]="dDoc"></app-card-document-download>
					</ng-template>
				</div>
			</div>
		</div>
	</div>

</div>