<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.packages" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Packages</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ package.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canEdit && package.is_enabled === 1" type="button" class="btn btn-outline-warning" (click)="disable()"
					ngbTooltip="Disabling an package will prevent its users from downloading files it has access to" placement="bottom">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="canEdit && package.is_enabled === 0" type="button" class="btn btn-outline-success" (click)="enable()"
					ngbTooltip="Enable" placement="start">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button *ngIf="canDelete" type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete"
					placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="tab-container">
	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				<fa-icon [icon]="['fas', 'info-circle']" size="sm"></fa-icon>
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-users-tab"
				aria-controls="nav-users" data-bs-target="#nav-users">
				<fa-icon [icon]="ac.sectionIcons.users" size="sm"></fa-icon>
				Users
				<span *ngIf="!loading || users.length > 0" class="cp-tab-badge">
					{{ users.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-pp-tab"
				aria-controls="nav-pp" data-bs-target="#nav-pp">
				<fa-icon [icon]="ac.sectionIcons.products" size="sm"></fa-icon>
				Products
				<span class="cp-tab-badge">
					{{ package.product_platforms.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-builds-tab" aria-controls="nav-downloads-builds" data-bs-target="#nav-downloads-builds"
				ngbTooltip="Software Builds Available to Subscribers of this Package">
				<fa-icon [icon]="ac.sectionIcons.mybuilds" size="sm"></fa-icon>
				SW
				<span *ngIf="!loading || buildDownloads.length > 0" class="cp-tab-badge">
					{{ buildDownloads.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false"
				id="nav-downloads-documents-tab" aria-controls="nav-downloads-documents" data-bs-target="#nav-downloads-documents"
				ngbTooltip="Documentation Available to Subscribers of this Package">
				<fa-icon [icon]="ac.sectionIcons.mydocuments" size="sm"></fa-icon>
				Docs
				<span *ngIf="!loading || documentDownloads.length > 0" class="cp-tab-badge">
					{{ documentDownloads.length | number }}
				</span>
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-admin-log-tab"
				aria-controls="nav-admin-log" data-bs-target="#nav-admin-log">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" size="sm"></fa-icon>
				<!-- Log -->
			</button>
		</li>

		<app-loading-spinner *ngIf="loading"></app-loading-spinner>
	</ul>

	<div class="tab-content" id="nav-tabContent">
		<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
			<div *ngIf="!!package" class="cp-property-list">
				<div class="cp-property-block">
					<span class="cp-property-label">Status:</span>
					<span *ngIf="package.is_enabled === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled -
						Downloads available
					</span>
					<span *ngIf="package.is_enabled === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> Disabled - Downloads
						not available
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Allow Subscription:</span>
					<span *ngIf="package.allow_subscribe === 1" class="badge bg-success">
						<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Yes -
						Users may subscribe to this package after registration
					</span>
					<span *ngIf="package.allow_subscribe === 0" class="badge bg-danger">
						<fa-icon [icon]="['fas', 'ban']"></fa-icon> No - Users may not subscribe to this package after
						registration
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Name:</span>
					<span class="cp-property-value">{{ package.name }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Access Code:</span>
					<span class="cp-property-value">{{ package.access_code }}</span>
				</div>

				<div class="cp-property-block" *ngIf="registrationUrl && registrationUrl !== ''">
					<span class="cp-property-label">Registration Link:</span>
					<span class="cp-property-value">
						<a [href]="registrationUrl" target="_blank">{{ registrationUrl }}</a>
						&nbsp;<fa-icon [icon]="['fas', 'copy']" ngxClipboard (click)="copyToClipboardAlert(registrationUrl)"
							[cbContent]="registrationUrl" ngbTooltip="Copy to Clipboard"></fa-icon>
					</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Type:</span>
					<span class="cp-property-value">{{ ac.packageTypeLabels[package.ptype] }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!package.information && package.information !== ''">
					<span class="cp-property-label">Information (Internal):</span>
					<span class="cp-property-value cp-pre-text">{{ package.information }}</span>
				</div>

				<div class="cp-property-block" *ngIf="!!package.user_description && package.user_description !== ''">
					<span class="cp-property-label">Description:</span>
					<span class="cp-property-value cp-pre-text">{{ package.user_description }}</span>
				</div>

				<div class="cp-property-block">
					<span class="cp-property-label">Added:</span>
					<span class="cp-property-value">
						{{ package.added_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="addedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ addedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="addedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="package.added_on !== package.edited_on">
					<span class="cp-property-label">Edited:</span>
					<span class="cp-property-value">
						{{ package.edited_on | date: ac.pageDateTimeFmt }}
						<span *ngIf="editedByUser"> by
							<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ editedByUser.name }}</a>
							<ng-template #userPopupContent>
								<app-card-user [object]="editedByUser"></app-card-user>
							</ng-template>
						</span>
					</span>
				</div>

				<div class="cp-property-block" *ngIf="builds.length !== 0">
					<span class="cp-property-label">Direct Access Builds:</span>
					<ul class="mb-0">
						<li *ngFor="let build of builds">
							<fa-icon *ngIf="build.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="build.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>

							<fa-icon *ngIf="build.is_retired === 1" [icon]="['fas', 'snooze']" class="text-info me-1"
								ngbTooltip="Archived - no longer downloadable without direct access">
							</fa-icon>
							<fa-icon *ngIf="build.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Build is not enabled"></fa-icon>
							<a [routerLink]="['/' + ac.urls.builds, build.id]" [ngbPopover]="buildPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ build.prod_name }} -
								{{ build.version }}
								<span *ngIf="build.label && build.label !== ''">({{ build.label }})</span>
							</a>
							<ng-template #buildPopupContent>
								<app-card-build [object]="build"></app-card-build>
							</ng-template>
						</li>
					</ul>
				</div>

				<div class="cp-property-block" *ngIf="documents.length !== 0">
					<span class="cp-property-label">Direct Access Documents:</span>
					<ul class="mb-0">
						<li *ngFor="let document of documents">
							<fa-icon *ngIf="document.is_private === 1" [icon]="['fas', 'lock']" class="text-warning me-1"
								ngbTooltip="Private - Limited Access"></fa-icon>
							<fa-icon *ngIf="document.is_private === 0" [icon]="['fas', 'globe']" size="sm" class="text-success me-1"
								ngbTooltip="Public - Generally Available"></fa-icon>
							<fa-icon *ngIf="document.is_enabled === 0" [icon]="['fas', 'ban']" class="text-danger ms-1"
								ngbTooltip="Document is not enabled"></fa-icon>
							<a [routerLink]="['/' + ac.urls.documents, document.id]" [ngbPopover]="documentPopupContent"
								popoverClass="cp-popover-std" triggers="mouseenter:mouseleave" container="body">
								{{ document.label }}</a>
							<ng-template #documentPopupContent>
								<app-card-document [object]="document"></app-card-document>
							</ng-template>
						</li>
					</ul>
				</div>
			</div>

			<div *ngIf="!loading && supportsKeys()" class="p-2 mb-2">
				<div class="fw-bold mb-1">
					Package Key Templates
					<button *ngIf="canEdit" type="button" class="btn btn-outline-primary btn-sm ms-1"
						[routerLink]="['key-templates', 'new']" ngbTooltip="Allow subscribers of this package to generate their own keys.">
						<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon> New Key Template
					</button>
				</div>

				<div class="row">
					<div *ngFor="let item of package.key_templates" class="col-4 border pt-2 me-1 mb-1">
						<div *ngIf="canEdit" class="btn-group mb-1">
							<button type="button" class="btn btn-outline-primary btn-sm" [routerLink]="['key-templates', item.id]">
								<fa-icon icon="pencil" size="sm"></fa-icon> Edit
							</button>
							<button type="button" class="btn btn-outline-danger btn-sm" (click)="deleteKeyTemplates(item.id)">
								<fa-icon icon="trash-alt" size="sm"></fa-icon> Delete
							</button>
						</div>

						<div class="cp-property-block">
							<span class="cp-property-label">Name:</span>
							<span class="cp-property-value">{{ item.name }}</span>
						</div>

						<div class="cp-property-block">
							<span class="cp-property-label">Key Template:</span>
							<span class="cp-property-value">
								<a [routerLink]="['/' + ac.urls.licensingadmin, 'templates', item.template_id]"
									[ngbPopover]="templatePopupContent" popoverClass="cp-popover-std" triggers="mouseenter:mouseleave"
									container="body">
									{{ templatesById[item.id].name }}</a>
							</span>

							<ng-template #templatePopupContent>
								<app-card-key-template [object]="templatesById[item.id]"></app-card-key-template>
								<!-- <div class="cp-popover-banner">{{ templatesById[item.id].name }}</div>
								<div class="cp-pre-text">{{
									popOverTools.getKeyTemplatePopoverLines(templatesById[item.id]).join('\n')}}
								</div> -->
							</ng-template>

						</div>

						<div class="cp-property-block">
							<span class="cp-property-label">Let User add activations and/or create additional
								keys:</span>
							<span *ngIf="item.allow_multiple === 1" class="cp-property-value">Yes</span>
							<span *ngIf="item.allow_multiple === 0" class="cp-property-value">No</span>
						</div>

					</div>
				</div>
			</div>

		</div>

		<div class="tab-pane" id="nav-pp" role="tabpanel" aria-labelledby="nav-pp-tab">
			<div *ngIf="!!package && package.product_platforms.length === 0" class="cp-light-message">
				No Products/Platforms selected for this Package.
			</div>

			<div *ngIf="!!package  && package.product_platforms.length !== 0" class="cp-property-list">
				<div *ngFor="let productType of productTypesToShow">
					<strong>{{ ac.productTypeLabels[productType] }} Products</strong>
					<ul class="mb-0">
						<li *ngFor="let product of subListOfUsedProducts(productType)">
							<span class="fw-bold">{{ product.name }}</span><br />
							<span
								*ngFor="let pl of mt.sortPlatforms(allPlatforms, getPlatformIdsForProduct(product.id, package.product_platforms))">
								<fa-icon [icon]="mt.getPlatformIcon(pl.name)"></fa-icon>
								{{ pl.name }}&nbsp;
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="tab-pane" id="nav-downloads-builds" role="tabpanel" aria-labelledby="nav-downloads-builds-tab">
			<div *ngIf="!loading && (!buildDownloads || buildDownloads.length === 0)" class="cp-light-message">
				No software available for this Package.
			</div>

			<app-downloadable-builds-table #dBuildsTable1></app-downloadable-builds-table>
		</div>

		<div class="tab-pane" id="nav-downloads-documents" role="tabpanel" aria-labelledby="nav-downloads-documents-tab">
			<div *ngIf="!loading && (!documentDownloads || documentDownloads.length === 0)" class="cp-light-message">
				No documentation available for this Package.
			</div>

			<app-downloadable-documents-table #dDocsTable1></app-downloadable-documents-table>
		</div>

		<div class="tab-pane" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">
			<div *ngIf="!loading && (!!package && users.length === 0)" class="cp-light-message">
				No Users subscribed to this Package.
			</div>

			<app-users-table #usersTable1></app-users-table>
		</div>

		<div class="tab-pane" id="nav-admin-log" role="tabpanel" aria-labelledby="nav-admin-log-tab">

			<div *ngIf="!loading && (!!package && adminLogs.length === 0)" class="cp-light-message">
				No system/change Logs for this Package.
			</div>

			<app-logs-table #logsTable1 [expandNames]="true"></app-logs-table>
		</div>

	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>