import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-licenses-table',
	templateUrl: './licenses-table.component.html',
	styleUrls: ['./licenses-table.component.scss']
})
export class LicensesTableComponent implements OnInit {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	now = new Date();

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			users 
			storageKey
			options
			- showOrganization : true
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';
	key: string = '';

	staffMode: boolean = true;
	showMeters: boolean = false;
	protocolReady: boolean = false;

	// linkUser: boolean = false;

	items: Models.LPLicense[] = [];
	itemsToShow: Models.LPLicense[] = [];

	showFirstDates: boolean = false;

	recentHostsPopup = 'Number of Activations (host IDs) reporting non-zero traffic (either via meter or protocol) over the last '
		+ AppConstants.recentUsedHostsDays + ' days';
	recentHostIconPopup = 'Reporting non-zero traffic over the last ' + AppConstants.recentUsedHostsDays + ' days';
	offlineHostIconPopup = 'Might be offline or unable to communicate with license server.  Hasn\'t reported in last day, but did report in within the last ' + AppConstants.hostOfflineMaxDays + ' days.';
	upgradeIconPopup = 'Reporting in over the last ' + AppConstants.recentUsedHostsDays + ' days via meter(s), but not protocols.  Protocol reporting is blocked or may need upgrade to v13 or later.';

	columnDefs: any[] = [
		{
			field: 'created_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Issued',
			toolTip: null,
			icon: null
		}, {
			field: '__label',
			type: 'baseText',
			sortType: 'text',
			label: 'Label',
			toolTip: null,
			icon: null
		}, {
			field: 'hostid',
			type: 'baseText',
			sortType: 'text',
			label: 'Host ID',
			toolTip: null,
			icon: null
		}, {
			field: 'ip',
			type: 'baseText',
			sortType: 'text',
			label: 'IP',
			toolTip: AppConstants.hostIDIPNote,
			icon: 'question-circle'
		}, {
			field: '__format',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'License File Format',
			icon: 'file'
		}, {
			field: '__meterNames',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Meter Type(s)',
			icon: 'tachometer-alt'
		}, {
			field: '__meterReporting',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Meter Reporting',
			icon: 'tachometer-alt'
		}, {
			field: '__protocolReporting',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Protocol Reporting',
			icon: 'chart-line'
		}, {
			field: '__upgrade',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Issue(s)',
			icon: 'exclamation-triangle'
		}, {
			field: '__penTestLabel',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Pen Test Result',
			icon: 'lock-open'
		}, {
			field: 'first_meter_report',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'First\nMeter\nReport',
			toolTip: null,
			icon: null
		}, {
			field: 'first_meter_usage',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'First\nMeter\nUsage',
			toolTip: null,
			icon: null
		}, {
			field: 'first_protocol_report',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'First\nProtocol\nReport',
			toolTip: null,
			icon: null
		}, {
			field: 'first_protocol_usage',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'First\nProtocol\nUsage',
			toolTip: null,
			icon: null
		}, {
			field: 'last_meter_report',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Last\nMeter\nReport',
			toolTip: null,
			icon: null
		}, {
			field: 'last_meter_usage',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Last\nMeter\nUsage',
			toolTip: null,
			icon: null
		}, {
			field: 'last_protocol_report',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Last\nProtocol\nReport',
			toolTip: null,
			icon: null
		}, {
			field: 'last_protocol_usage',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Last\nProtocol\nUsage',
			toolTip: null,
			icon: null
		}, {
			field: 'last_protocol_bx_version',
			type: 'baseText',
			sortType: 'text',
			label: 'Last\nReported\nVersion',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.LPLicense[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('staffMode')) this.staffMode = options.staffMode;
		if (optionKeys.includes('showMeters')) this.showMeters = options.showMeters;
		if (optionKeys.includes('protocolReady')) this.protocolReady = options.protocolReady;
		if (optionKeys.includes('key')) this.key = options.key;

		this.items.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);

		this.setupFieldsToShow();

		const hostIdCounts: any = {};

		// set from outside
		// item['__penTest'] = penTestsById[item.id]
		// item['__penTestCode'] = penTestCodeById[item.id]

		for (const item of this.items) {
			if (!hostIdCounts[item.hostid]) hostIdCounts[item.hostid] = 0;
			hostIdCounts[item.hostid]++;

			const licenseFileInfo = SharedLicenseTools.getLicenseFileInfo(item.fulfillment);
			item['__format'] = licenseFileInfo.format;
			item['__formatAcronym'] = TextTools.acronym(item['__format']);
			item['__meterNames'] = licenseFileInfo.meterNames.join(',');
			item['__meterInitials'] = licenseFileInfo.meterInitials.join(',');

			item['__meterReporting'] = SharedLicenseTools.getMeterIconToolTip(item);
			item['__meterReportingClass'] = SharedLicenseTools.getMeterIconClass(item);

			item['__protocolReporting'] = SharedLicenseTools.getProtocolIconToolTip(item);
			item['__protocolReportingClass'] = SharedLicenseTools.getProtocolIconClass(item);

			item['__upgrade'] = SharedLicenseTools.licenseNeedsUpgrade(item);

			item['__cleanVersion'] = TextTools.cleanVersion(SharedLicenseTools.getVersionToUse(item))
			// item['__cleanVersion'] = TextTools.cleanVersion(item.last_protocol_bx_version)

		} // for

		const hostCounters: any = {};
		for (const item of items) {
			if (hostIdCounts[item.hostid] > 1) {
				if (!hostCounters[item.hostid]) hostCounters[item.hostid] = 0;
				hostCounters[item.hostid]++;
				item['__counter'] = hostCounters[item.hostid];
			}  // if
		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'created_at', 'desc');

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	setupFieldsToShow() {
		this.fieldsToShow = [];
		this.fieldsToShow.push('created_at');
		if (!this.staffMode) this.fieldsToShow.push('__label');
		this.fieldsToShow.push('hostid');
		this.fieldsToShow.push('ip');
		if (this.staffMode) this.fieldsToShow.push('__format');
		if (this.staffMode) this.fieldsToShow.push('__meterNames');
		this.fieldsToShow.push('__meterReporting');
		this.fieldsToShow.push('__protocolReporting');
		if (this.staffMode) this.fieldsToShow.push('__upgrade');
		if (this.staffMode) this.fieldsToShow.push('__penTestLabel');
		if (this.showFirstDates) {
			this.fieldsToShow.push('first_meter_report');
			this.fieldsToShow.push('first_meter_usage');
			this.fieldsToShow.push('first_protocol_report');
			this.fieldsToShow.push('first_protocol_usage');
		} // if
		if (!this.showFirstDates) {
			this.fieldsToShow.push('last_meter_report');
			this.fieldsToShow.push('last_meter_usage');
			this.fieldsToShow.push('last_protocol_report');
			this.fieldsToShow.push('last_protocol_usage');
		} // if
		this.fieldsToShow.push('last_protocol_bx_version');
	}

	// ------------------------------------------------------------------------
	toggleFirstDates() {
		this.showFirstDates = !this.showFirstDates;
		this.setupFieldsToShow();

		if (!this.fieldsToShow.includes(this.displayOptions.sortBy)) {
			this.setSortBy('created_at');
		} else {
			this.setSortBy(null);
		} // if
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.LPLicense[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.hostid.toLowerCase().includes(w)
						|| (item.ip && item.ip.toLowerCase().includes(w))
						|| (item['__cleanVersion'] && item['__cleanVersion'].toLowerCase().includes(w))
						|| (item['__format'] && item['__format'].toLowerCase().includes(w))
						|| (item['__meterNames'] && item['__meterNames'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for

		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}

}
