<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.products" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Products
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" routerLink="new">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon><span class="cp-action-button-text">New
					Product</span>
			</button>

			<button *ngIf="canAdd" type="button" class="btn btn-outline-primary me-2" [routerLink]="['sort-products']">
				<fa-icon [icon]="['fas', 'sort-alt']" size="sm"></fa-icon>
				<span class="cp-action-button-text">Sort-Order</span>
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="refresh()">
				<fa-icon icon="sync-alt" size="sm" [spin]="refreshing" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<table class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th>Sort</th>
				<th>Product</th>
				<th>Type</th>
				<th>File Prefix</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.platforms" size="sm" ngbTooltip="Number of Platforms" placement="bottom"></fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.builds" size="sm" ngbTooltip="Number of Builds" placement="bottom">
					</fa-icon>
				</th>
				<th class="cp-icon-header">
					<fa-icon [icon]="ac.sectionIcons.documents" size="sm" ngbTooltip="Number of Documents" placement="bottom"></fa-icon>
				</th>
				<th>Added</th>
				<th>Edited</th>
			</tr>
		</thead>
		<tbody *ngIf="!loading">
			<tr *ngFor="let item of products">
				<td class="text-center">{{ item.sort_order }}</td>
				<td>
					<a [routerLink]="[item.id]">{{ item.name }}</a>
					<fa-icon *ngIf="hasFlag(item, 'may_have_builds')" [icon]="ac.sectionIcons.builds" size="sm" class="ms-1"
						ngbTooltip="This Product may be used for Builds" placement="end">
					</fa-icon>
					<fa-icon *ngIf="hasFlag(item, 'may_have_documents')" [icon]="ac.sectionIcons.documents" size="sm" class="ms-1"
						ngbTooltip="This Product may be used for Documents" placement="end">
					</fa-icon>
					<fa-icon *ngIf="hasFlag(item, 'private_only')" [icon]="['fas', 'lock']" size="sm" class="text-warning ms-1"
						ngbTooltip="This Product will only have private Builds and Documents" placement="end"></fa-icon>
				</td>
				<td>{{ ac.productTypeLabels[item.ptype] }}</td>
				<td>{{ item.filename_prefix }}</td>
				<td class="text-center">{{ item.platform_ids.length }}</td>
				<td class="text-center">{{ item.build_ids.length }}</td>
				<td class="text-center">{{ item.document_ids.length }}</td>
				<td class="text-nowrap" [attr.data-order]="tt.formatDateTimeUTC(item.added_on)">
					{{ item.added_on | date: ac.tableDateFmt }}
				</td>
				<td class="text-nowrap" [attr.data-order]="tt.formatDateTimeUTC(item.edited_on)">
					{{ item.edited_on | date: ac.tableDateFmt }}
				</td>
			</tr>
		</tbody>
	</table>
</div>