<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.loginlogs" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Logins
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="btn-group">
			<button type="button" class="btn btn-outline-secondary" (click)="loadLogs()">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading || refreshing" ngbTooltip="Refresh" placement="start">
				</fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-4">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>
					<input class="form-control" placeholder="Not Before yyyy-mm-dd" id="notBefore" formControlName="notBefore"
						firstDayOfWeek="7" ngbDatepicker #d1="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>

			<div class="cp-filter-block-4">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>
					<input class="form-control" placeholder="Not After yyyy-mm-dd" id="notAfter" formControlName="notAfter" ngbDatepicker
						firstDayOfWeek="7" #d2="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>
		</div>
	</form>

	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="maxMessage !== ''" class="cp-warning-message">{{ maxMessage }}</div>

	<app-logins-table #loginsTable1></app-logins-table>

</div>