import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import { LicensingService } from '../licensing.service';
import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-search-filter-info',
	templateUrl: './search-filter-info.component.html',
	styleUrls: ['./search-filter-info.component.scss']
})
export class SearchFilterInfoComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	sharedLicenseTools = SharedLicenseTools;

	private userSubscription: Subscription;

	protocolBlocks: any[] = [];

	keyTypes: string[] = [];

	constructor(
		private router: Router,
		private authService: AuthService,
		private licensingService: LicensingService) {
	}

	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {
				this.setup();
			} // if
		});

	}

	ngOnDestroy() {

	}

	async setup() {

		this.keyTypes = [];
		for (const searchableKeyTypesSelection of AppConstants.searchableKeyTypesSelections)
			this.keyTypes.push(searchableKeyTypesSelection.label);

		const allProtocols: string[] = await this.licensingService.getProtocols();
		const bxProtocols: string[] = [];
		const privProtocols: string[] = [];
		const transcodeProtocols: string[] = [];
		const mcProtocols: string[] = [];
		const zmProtocols: string[] = [];

		for (const protocol of allProtocols) {
			if (protocol.startsWith('mediaconnect_')) {
				mcProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('MediaConnect ', ''));
			} else if (protocol.startsWith('zm_')) {
				zmProtocols.push(SharedLicenseTools.niceProtocol(protocol, false).replace('ZM ', ''));
			} else {
				if (protocol.includes('transcode'))
					transcodeProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else if (protocol.startsWith('private_'))
					privProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
				else
					bxProtocols.push(SharedLicenseTools.niceProtocol(protocol, false));
			} // if
		} // for

		if (bxProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster\nNon-Private',
				protocols: bxProtocols
			});

		if (privProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster\nPrivate',
				protocols: privProtocols
			});

		if (transcodeProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'Broadcaster\nTranscode',
				protocols: transcodeProtocols
			});

		if (mcProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'ZEN Master Tracking\n MediaConnect',
				protocols: mcProtocols
			});

		if (zmProtocols.length > 0)
			this.protocolBlocks.push({
				label: 'ZEN Master Tracking\nNon-MediaConnect',
				protocols: zmProtocols
			});
	}

}
