<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.builds" class="cp-admin-nav-item me-1" size="sm"></fa-icon>
				<a href="javascript:void(0)" routerLink="..">Builds</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Auto-Build
			</li>
		</ol>
	</nav>
</div>

<form [formGroup]="theForm" class="tab-form">
	<div *ngIf="saving" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="!saving" class="form-group form-check">
		<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
		<label class="form-check-label" for="is_enabled">New builds will be enabled/active (its files can be
			downloaded).</label>
	</div>

	<div *ngIf="!saving" class="form-group form-check">
		<input type="checkbox" class="form-check-input" id="is_private" formControlName="is_private" />
		<label class="form-check-label" for="is_private">New builds will be private (only Organizations directly assign
			can download its files).</label>
	</div>

	<div *ngIf="!saving" class="form-group form-check">
		<input type="checkbox" class="form-check-input" id="test_mode" formControlName="test_mode" />
		<label class="form-check-label" for="test_mode">Do not create/update builds, just show how files could be
			processed. (TEST MODE)</label>
	</div>
	<!-- 
	<div class="form-group mb-0" *ngIf="!saving">
		<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
			<fa-icon [icon]="['fas', 'check']"></fa-icon> Run Auto-Build
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
			<fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
		</button>
	</div> -->

	<div class="border-black mt-2">
		<div *ngFor="let item of results" class="cp-{{ item.msgClass }}-message mb-2">
			<div *ngIf="item.fileObj" class="fw-bold">
				<button *ngIf="item.msgClass==='warning' || item.msgClass==='danger'" type="button"
					class="btn btn-sm btn-outline-secondary float-end" (click)="disableAutoBuild(item.fileObj.id)"
					ngbTooltip="Disabling auto-build will exclude this file from future auto-build runs">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
				</button>

				<a [routerLink]="['/' + ac.urls.files, item.fileObj.id]" [ngbPopover]="filePopupContent" popoverClass="cp-popover-std"
					triggers="mouseenter:mouseleave" container="body">
					<fa-icon [icon]="ac.sectionIcons.files" size="sm"></fa-icon>
					{{ item.fileObj.name }}
				</a>
				<ng-template #filePopupContent>
					<app-card-file [object]="item"></app-card-file>
				</ng-template>
			</div>
			{{ item.msg }}
			<a *ngIf="item.buildID !== 0" [routerLink]="['/' + ac.urls.builds, item.buildID]">
				<fa-icon [icon]="ac.sectionIcons.builds" size="sm"></fa-icon> Build
			</a>
		</div>
	</div>
</form>

<div class="cp-form-padding"></div>

<div *ngIf="theForm" class="cp-form-buttons">
	<div class="cp-form-buttons-inner">
		<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
			<fa-icon [icon]="['fas', 'check']"></fa-icon> Run Auto-Build
		</button>
		<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
			<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
		</button>
	</div>
</div>