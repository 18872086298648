import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import { RouterModule } from '@angular/router';

// Ng Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Ng Select
import { NgSelectModule } from '@ng-select/ng-select';

// Clipboard
import { ClipboardModule } from 'ngx-clipboard';

// XXXXX
import { DragDropModule } from '@angular/cdk/drag-drop';

// Forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// Other Modules
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FontAwesome } from '../font-awesome/font-awesome.module';

// My Modules/Components
import { LoadingSpinnerComponent } from '../../components/shared/loading-spinner/loading-spinner.component';

import { PagingSearchBarComponent } from '../../components/shared/paging-search-bar/paging-search-bar.component';

import { LogsTableComponent } from '../../components/shared/logs-table/logs-table.component';
import { JournalsTableComponent } from '../../components/shared/journals-table/journals-table.component';
import { LoginsTableComponent } from '../../components/shared/logins-table/logins-table.component';
import { DownloadsTableComponent } from '../../components/shared/downloads-table/downloads-table.component';
import { ClicksTableComponent } from '../../components/shared/clicks-table/clicks-table.component';
import { NotificationsTableComponent } from '../../components/shared/notifications-table/notifications-table.component';
import { DownloadableBuildsTableComponent } from '../../components/shared/downloadable-builds-table/downloadable-builds-table.component';
import { DownloadableDocumentsTableComponent } from '../../components/shared/downloadable-documents-table/downloadable-documents-table.component';
import { UsersTableComponent } from '../../components/shared/users-table/users-table.component';
import { NotificationUsersTableComponent } from '../../components/shared/notification-users-table/notification-users-table.component';
import { OrganizationsTableComponent } from '../../components/shared/organizations-table/organizations-table.component';
import { KeysTableComponent } from '../../components/shared/keys-table/keys-table.component';
import { LicensesTableComponent } from '../../components/shared/licenses-table/licenses-table.component';
import { BuildsTableComponent } from '../../components/shared/builds-table/builds-table.component';
import { DocumentsTableComponent } from '../../components/shared/documents-table/documents-table.component';
import { MarketplaceLogsTableComponent } from '../../components/shared/marketplace-logs-table/marketplace-logs-table.component';
import { KeyFeaturesComponent } from '../../components/shared/key-features/key-features.component';
import { KeyMetersComponent } from '../../components/shared/key-meters/key-meters.component';
import { PopupBoxComponent } from '../../components/shared/popup-box/popup-box.component';

import { CardKeyComponent } from '../../components/shared/card-key/card-key.component';
import { CardLicenseComponent } from '../../components/shared/card-license/card-license.component';
import { CardUserComponent } from '../../components/shared/card-user/card-user.component';
import { CardOrganizationComponent } from '../../components/shared/card-organization/card-organization.component';
import { CardZenMasterComponent } from '../../components/shared/card-zen-master/card-zen-master.component';
import { CardPartnershipComponent } from '../../components/shared/card-partnership/card-partnership.component';
import { CardBuildDownloadComponent } from '../../components/shared/card-build-download/card-build-download.component';
import { CardDocumentDownloadComponent } from '../../components/shared/card-document-download/card-document-download.component';
import { CardBillingCodeComponent } from '../../components/shared/card-billing-code/card-billing-code.component';
import { CardProtocolSetComponent } from '../../components/shared/card-protocol-set/card-protocol-set.component';
import { CardKeyTemplateComponent } from '../../components/shared/card-key-template/card-key-template.component';
import { CardKeyProductComponent } from '../../components/shared/card-key-product/card-key-product.component';
import { CardBuildComponent } from '../../components/shared/card-build/card-build.component';
import { CardDocumentComponent } from '../../components/shared/card-document/card-document.component';
import { CardFileComponent } from '../../components/shared/card-file/card-file.component';

// Pipes
import { BytesPipe } from 'client/app/pipes/bytes.pipe';
import { AmpPipe } from 'client/app/pipes/amp.pipe';

@NgModule({
	declarations: [
		LoadingSpinnerComponent,
		PagingSearchBarComponent,
		LogsTableComponent,
		JournalsTableComponent,
		LoginsTableComponent,
		DownloadsTableComponent,
		ClicksTableComponent,
		NotificationsTableComponent,
		DownloadableBuildsTableComponent,
		DownloadableDocumentsTableComponent,
		UsersTableComponent,
		NotificationUsersTableComponent,
		OrganizationsTableComponent,
		KeysTableComponent,
		LicensesTableComponent,
		BuildsTableComponent,
		DocumentsTableComponent,
		MarketplaceLogsTableComponent,
		KeyFeaturesComponent,
		KeyMetersComponent,
		PopupBoxComponent,

		CardKeyComponent,
		CardLicenseComponent,
		CardUserComponent,
		CardOrganizationComponent,
		CardZenMasterComponent,
		CardPartnershipComponent,
		CardBuildDownloadComponent,
		CardDocumentDownloadComponent,
		CardBillingCodeComponent,
		CardProtocolSetComponent,
		CardKeyTemplateComponent,
		CardKeyProductComponent,
		CardBuildComponent,
		CardDocumentComponent,
		CardFileComponent,

		BytesPipe,
		AmpPipe
	],
	imports: [
		CommonModule,
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		NgSelectModule,
		ClipboardModule,
		FontAwesome,
		DragDropModule,
		RouterModule
	],
	exports: [
		LoadingSpinnerComponent,
		PagingSearchBarComponent,
		LogsTableComponent,
		JournalsTableComponent,
		LoginsTableComponent,
		DownloadsTableComponent,
		ClicksTableComponent,
		NotificationsTableComponent,
		DownloadableBuildsTableComponent,
		DownloadableDocumentsTableComponent,
		UsersTableComponent,
		NotificationUsersTableComponent,
		OrganizationsTableComponent,
		KeysTableComponent,
		LicensesTableComponent,
		BuildsTableComponent,
		DocumentsTableComponent,
		MarketplaceLogsTableComponent,
		KeyFeaturesComponent,
		KeyMetersComponent,
		PopupBoxComponent,

		CardKeyComponent,
		CardLicenseComponent,
		CardUserComponent,
		CardOrganizationComponent,
		CardZenMasterComponent,
		CardPartnershipComponent,
		CardBuildDownloadComponent,
		CardDocumentDownloadComponent,
		CardBillingCodeComponent,
		CardProtocolSetComponent,
		CardKeyTemplateComponent,
		CardKeyProductComponent,
		CardBuildComponent,
		CardDocumentComponent,
		CardFileComponent,

		CommonModule,
		DecimalPipe,
		BytesPipe,
		AmpPipe
	],
	providers: [
		DecimalPipe,
		BytesPipe,
		AmpPipe
	]
})

export class SharedModule { }
