<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.systemmessages" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Dashboard Messages</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ choppedMessage }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div *ngIf="canManage" class="btn-group">
				<button type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit" placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="systemMessage.is_enabled === 1" type="button" class="btn btn-outline-warning" (click)="disable()"
					placement="bottom" ngbTooltip="Disabling a message will hide it from Users">
					<fa-icon [icon]="['fas', 'ban']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Disable</span> -->
				</button>

				<button *ngIf="systemMessage.is_enabled === 0" type="button" class="btn btn-outline-success" (click)="enable()"
					ngbTooltip="Enable" placement="start">
					<fa-icon [icon]="['fas', 'power-off']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Enable</span> -->
				</button>

				<button type="button" class="btn btn-outline-danger" (click)="delete()" ngbTooltip="Delete" placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="page-wrapper">
	<div *ngIf="!!systemMessage" class="cp-property-list">
		<div class="cp-property-block">
			<span class="cp-property-label">Status:</span>
			<span *ngIf="systemMessage.is_enabled === 1" class="badge bg-success">
				<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Enabled - Visible to Users
			</span>
			<span *ngIf="systemMessage.is_enabled === 0" class="badge bg-danger">
				<fa-icon [icon]="['fas', 'ban']"></fa-icon> Disabled - No shown to Users
			</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Users Can Acknowledge:</span>
			<span *ngIf="systemMessage.can_ack === 1" class="badge bg-success">
				<fa-icon [icon]="['fas', 'power-off']"></fa-icon> Yes
			</span>
			<span *ngIf="systemMessage.can_ack === 0" class="badge bg-danger">
				<fa-icon [icon]="['fas', 'ban']"></fa-icon> No
			</span>
		</div>

		<div *ngIf="systemMessage.staff_only === 1" class="cp-property-block">
			<span class="cp-property-label">Staff Only:</span>
			<span class="badge bg-warning">
				<fa-icon [icon]="['fas', 'lock']" size="sm"></fa-icon> Yes
			</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Message:</span>
			<div class="cp-property-value cp-inline-release-notes-long border p-2">
				{{ systemMessage.message.trim() }}
			</div>
		</div>

		<div class="cp-property-block" *ngIf="systemMessage.url && systemMessage.url !== ''">
			<span class="cp-property-label">More Info URL:</span>
			<span class="cp-property-value">{{ systemMessage.url }}</span>
		</div>

		<div class="cp-property-block" *ngIf="systemMessage.start_at">
			<span class="cp-property-label">Don't Show Before:</span>
			<span class="cp-property-value" [ngClass]="{'text-danger': !mt.hasExpired(systemMessage.start_at) }">
				{{ systemMessage.start_at | date: ac.pageDateFmt }}
			</span>
		</div>

		<div class="cp-property-block" *ngIf="systemMessage.end_at">
			<span class="cp-property-label">Don't Show After:</span>
			<span class="cp-property-value" [ngClass]="{'text-danger': mt.hasExpired(systemMessage.end_at) }">
				{{ systemMessage.end_at | date: ac.pageDateFmt }}
			</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Added:</span>
			<span class="cp-property-value">
				{{ systemMessage.added_on | date: ac.pageDateTimeFmt }}
				<span *ngIf="addedByUser"> by
					<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ addedByUser.name }}</a>
					<ng-template #userPopupContent>
						<app-card-user [object]="addedByUser"></app-card-user>
					</ng-template>
				</span>
			</span>
		</div>

		<div class="cp-property-block" *ngIf="systemMessage.added_on !== systemMessage.edited_on">
			<span class="cp-property-label">Edited:</span>
			<span class="cp-property-value">
				{{ systemMessage.edited_on | date: ac.pageDateTimeFmt }}
				<span *ngIf="editedByUser"> by
					<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ editedByUser.name }}</a>
					<ng-template #userPopupContent>
						<app-card-user [object]="editedByUser"></app-card-user>
					</ng-template>
				</span>
			</span>
		</div>
	</div>
</div>