import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { MyBuildsService } from '../my-builds.service';
import { MyDocumentsService } from '../../my-documents/my-documents.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-my-build',
	templateUrl: './my-build.component.html',
	styleUrls: ['./my-build.component.scss']
})

export class MyBuildComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	mt = MiscTools;

	private userSubscription: Subscription;
	private paramSub: Subscription;
	id: number;
	downloadableBuild: Models.DownloadableBuild;
	documents: Models.DownloadableDocument[];
	downloadLink: Models.DownloadLink;

	allowDownloads = true;
	isStaff = false;
	retiredMessage = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private myBuildsService: MyBuildsService,
		private myDocumentsService: MyDocumentsService,
		private authService: AuthService
	) { }

	ngOnInit(): void {
		this.paramSub = this.route.params.subscribe((params: Params) => {
			this.id = +params.id;

			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.isStaff = ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole);
				this.getData();
			});
		});
	}

	async getData(forceRefresh: boolean = false) {
		await this.myBuildsService.getBuilds(forceRefresh);
		this.downloadableBuild = await this.myBuildsService.getOneBuild(this.id);

		if (!this.downloadableBuild || this.downloadableBuild == null) {
			this.router.navigate([AppConstants.urls.notfound]);
		} else {
			this.documents = await this.myDocumentsService.getProductDocuments(this.downloadableBuild.build.product_id);

			if (this.downloadableBuild.build.is_retired === 1) {
				if (this.downloadableBuild.is_direct) {
					this.retiredMessage = 'This build has been archived.  We recommend that you use a newer build of this product.';
				} else {
					this.retiredMessage = 'This build has been archived and its files are no longer available.\n'
						+ 'We recommend that you use a newer build of this product.\n'
						+ 'If you feel that you need this version, please contact your account manager, technical contact'
						+ ' or use the \"Need Help\" link.';
					this.allowDownloads = false;
				}
			}
		}

	} // 

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.paramSub) this.paramSub.unsubscribe();
	}

	async openDownload(id: number, dltype: string = '', refid: number = 0) {
		const dlObj = await this.myBuildsService.getDownloadLink(id, dltype, refid);
		if (dlObj)
			window.open(dlObj.url, '_blank');
	}

	async grabDownloadInfo(id: number, dltype: string = '', refid: number = 0) {
		const dlObj = await this.myBuildsService.getDownloadLink(id, dltype, refid);
		if (dlObj) this.downloadLink = dlObj;
	}

	openLink(document: Models.DownloadableDocument) {
		window.open(document.document.link, '_blank');
	}

	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	getFileIDForPlatform(platformID: number): number {
		const idx = MiscTools.findIndexGeneric(this.downloadableBuild.build.platform_files, 'platform_id', platformID);
		if (idx !== -1) {
			return this.downloadableBuild.build.platform_files[idx].file_id;
		}
		return 0;
	}

	getFileName(fileID: number): string {
		const idx = MiscTools.findIndexGeneric(this.downloadableBuild.files, 'id', fileID);
		if (idx !== -1) {
			return this.downloadableBuild.files[idx].name;
		}
		return '??? - ' + fileID;
	}

	getFileSize(fileID: number): number {
		const idx = MiscTools.findIndexGeneric(this.downloadableBuild.files, 'id', fileID);
		if (idx !== -1) {
			return this.downloadableBuild.files[idx].size;
		}
		return 0;
	}
	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}

}
