import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';

import { AdminLogsService } from '../admin-logs.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { UserGroupsService } from '../../users/user-groups.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';

@Component({
	selector: 'app-search-logs',
	templateUrl: './search-logs.component.html',
	styleUrls: ['./search-logs.component.scss']
})
export class SearchLogsComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	logs: Models.AdminLog[];
	loading = true;
	refreshing = false;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;

	// other stuff...
	maxMessage = '';
	showFilters = false;
	theForm: UntypedFormGroup;
	objTypes: string[] = [];
	actions: string[] = [];
	userIDs: number[] = [];
	notBefore: NgbDateStruct = null;
	notAfter: NgbDateStruct = null;

	private userSubscription: Subscription;
	role = '';
	// users: Models.User[] = [];
	userSelections: any[] = [];
	userGroupSelections: any[] = [];

	expandNames = false;

	adminLogObjTypes: any[] = AppConstants.adminLogObjTypes;


	constructor(
		private router: Router,
		private adminLogsService: AdminLogsService,
		private usersService: UsersService,
		private userGroupsService: UserGroupsService,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) { }

	ngOnInit(): void {
		// this.dtOptions = Object.assign({}, AppConstants.dtOptPersist);
		// this.dtOptions.order = [[0, 'desc']];

		let settings: any = {};
		try {
			if (localStorage.getItem('cp-adminlogs.settings'))
				settings = JSON.parse(localStorage.getItem('cp-adminlogs.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('objTypes')) this.objTypes = settings.objTypes;
		if (settingKeys.includes('actions')) this.actions = settings.actions;
		if (settingKeys.includes('userIDs')) this.userIDs = settings.userIDs;
		if (settingKeys.includes('notBefore')) this.notBefore = settings.notBefore;
		if (settingKeys.includes('notAfter')) this.notAfter = settings.notAfter;

		this.showFilters = (this.objTypes.length !== 0 || this.actions.length !== 0 || this.userIDs.length !== 0
			|| this.notBefore != null || this.notAfter != null
		);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser)
				this.role = authUser.role;
		});

		this.setup();
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	setup() {

		this.adminLogObjTypes.sort((a, b) => (a.label > b.label) ? 1 : -1);

		const users = this.usersService.getAll();
		this.userSelections = [];
		for (const user of users)
			if (user.role !== AppConstants.basicUserRole && user.is_enabled === 1)
				this.userSelections.push({
					id: user.id,
					name: user.name
				});

		for (const user of users)
			if (user.role !== AppConstants.basicUserRole && user.is_enabled === 0)
				this.userSelections.push({
					id: user.id,
					name: user.name + ' (disabled)'
				});

		const userGroups = this.userGroupsService.getAll();
		this.userGroupSelections = [];
		for (const userGroup of userGroups)
			if (userGroup.user_ids && userGroup.user_ids.length !== 0)
				this.userGroupSelections.push({
					ids: userGroup.user_ids.join(','),
					name: userGroup.name
				});

		this.theForm = new UntypedFormGroup({
			objTypes: new UntypedFormControl(this.objTypes),
			actions: new UntypedFormControl(this.actions),
			userIDs: new UntypedFormControl(this.userIDs),
			userGroups: new UntypedFormControl(null),
			notBefore: new UntypedFormControl(this.notBefore),
			notAfter: new UntypedFormControl(this.notAfter),
		});

		this.loadLogs();
	}

	async pickGroupsUsers() {
		const userIDs: string = this.theForm.value.userGroups;
		this.theForm.controls.userIDs.setValue(TextTools.splitStringToNumbers(userIDs, ','));
		this.theForm.controls.userGroups.setValue(null);
		this.onFormChange();
	}

	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		this.objTypes = this.theForm.value.objTypes;
		this.actions = this.theForm.value.actions;
		this.userIDs = this.theForm.value.userIDs;
		this.notBefore = this.theForm.value.notBefore;
		this.notAfter = this.theForm.value.notAfter;

		let settings: any = {
			objTypes: this.objTypes,
			actions: this.actions,
			userIDs: this.userIDs,
			notBefore: this.notBefore,
			notAfter: this.notAfter
		};
		localStorage.setItem('cp-adminlogs.settings', JSON.stringify(settings));

		this.loadLogs();
	}

	async loadLogs() {
		this.loading = true;

		let startDate: Date = null;
		if (this.notBefore) {
			startDate = new Date(this.notBefore.year + '/' + this.notBefore.month + '/' + this.notBefore.day);
			if (isNaN(startDate.getTime())) startDate = null;
		}

		let endDate: Date = null;
		if (this.notAfter) {
			endDate = new Date(this.notAfter.year + '/' + this.notAfter.month + '/' + this.notAfter.day);
			if (isNaN(endDate.getTime())) endDate = null;
		}

		this.logs = await this.adminLogsService.getLogs(this.objTypes, 0, this.actions, startDate, endDate, this.userIDs);

		if (this.logs && this.logs.length === AppConstants.maxLogsToFetch)
			this.maxMessage = 'Maximum logs (' + AppConstants.maxLogsToFetch + ') retrieved.  Setting a filter will reduce the number of logs retrieved.';
		else
			this.maxMessage = '';

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.logs, 'cp-admin-logs',
				{
					showUserInfo: true,
					showObjectInfo: true,
					linkObject: true,
					linkUser: false,
				}
			);
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	routeRow(objType: string, action: string, id: number) {
		const route = MiscTools.getRoute(objType, action, id);
		if (route && route.length !== 0) this.router.navigate(route);
	}

	// --------------------------------------------------------------------
	copyToClipboardAlert(item: string = '') {
		this.uiAlertsService.copyToClipboardAlert(item);
	}


}
