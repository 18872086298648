import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { DashboardService } from '../dashboard.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { LicensingService } from '../../licensing/licensing.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';

@Component({
	selector: 'app-usage-dashboard',
	templateUrl: './usage-dashboard.component.html',
	styleUrls: ['./usage-dashboard.component.scss']
})
export class UsageDashboardComponent implements OnInit {
	ac = AppConstants;
	tt = TextTools;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	organizations: Models.Organization[] = [];
	// keys: Models.LPActivation[] = [];

	showForm = false;
	theForm: UntypedFormGroup;

	loading = true;

	orgsRefreshing = true;
	keysRefreshing = true;
	broadcastersRefreshing = true;
	// dailyRefreshing = true;

	topCache: Models.TopUsageCacheEntry[] = [];

	topOrganizations: Models.TopUsageCacheEntry[] = [];
	topActivations: Models.TopUsageCacheEntry[] = [];
	topHostIDs: Models.TopUsageCacheEntry[] = [];
	// dailyTotals: Models.DailyUsageEntry[] = [];

	timeFrame: string = AppConstants.topUsageTimePeriods[0].value;
	keyType: string = '';

	keyProducts: Models.LicenseProduct[] = [];
	keyTypeOptions: any[] = [];

	bannerExtra = '';
	showRawData: boolean = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private organizationsService: OrganizationsService,
		private licensingService: LicensingService,
		private licensingAdminService: LicensingAdminService,
		private dashboardService: DashboardService
	) { }

	// *********************************************************
	ngOnInit(): void {
		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;
			if (!authUser || !ValidationTools.checkRole(authUser.role, AppConstants.staffUserRole)) {
				this.router.navigate([AppConstants.urls.notfound]);
				return;
			}

			let settings: any = {};
			try {
				if (localStorage.getItem('cp-topUsage.settings'))
					settings = JSON.parse(localStorage.getItem('cp-topUsage.settings'));
			} catch (e) { }
			const settingKeys: string[] = Object.keys(settings);

			if (settingKeys.includes('timeFrame')) this.timeFrame = settings.timeFrame;
			if (settingKeys.includes('keyType')) this.keyType = settings.keyType;
			if (settingKeys.includes('showRawData')) this.showRawData = settings.showRawData;

			this.initForm();
			this.loadData();
		});
	}

	// *********************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	// *********************************************************
	initForm() {
		this.keyTypeOptions = [];
		for (const keyType of AppConstants.topUsageKeyTypes)
			this.keyTypeOptions.push({ value: keyType, label: TextTools.capitalizeFirstLetter(keyType) });

		const idx = MiscTools.findIndexGeneric(AppConstants.topUsageTimePeriods, 'value', this.timeFrame);
		if (idx === -1) this.timeFrame = AppConstants.topUsageTimePeriods[0].value;

		if (!this.keyType || !AppConstants.topUsageKeyTypes.includes(this.keyType))
			this.keyType = AppConstants.topUsageKeyTypes[0];

		this.theForm = new UntypedFormGroup({
			timeFrame: new UntypedFormControl(this.timeFrame),
			keyType: new UntypedFormControl(this.keyType),
		});
	} // 

	// *********************************************************
	async loadData() {
		this.loading = true;

		this.orgsRefreshing = true;
		this.broadcastersRefreshing = true;
		this.keysRefreshing = true;

		this.organizations = this.organizationsService.getAll();

		this.keyProducts = await this.licensingAdminService.getProducts();

		// get whatever's been already fetched or get latest day
		this.topCache = await this.dashboardService.getTopUsageCache();

		// const keyIdsToFetch: number[] = [];
		// for (const entry of this.topCache) {
		// 	// console.log(entry.time_period + ' ' + entry.key_type + ' ' + entry.object_type);
		// 	let keyId: number = 0;
		// 	if (entry.object_type === 'activation' && !keyIdsToFetch.includes(entry.object_id))
		// 		keyIdsToFetch.push(entry.object_id);
		// 	else if (entry.object_type === 'hostid' && !keyIdsToFetch.includes(entry.activation_id))
		// 		keyIdsToFetch.push(entry.activation_id);
		// } // for

		// this.keys = await this.licensingService.getLots(keyIdsToFetch);
		this.loading = false;

		await this.filterData();
	}

	// *********************************************************
	async filterData() {
		this.bannerExtra = '';
		const idx = MiscTools.findIndexGeneric(AppConstants.topUsageTimePeriods, 'value', this.timeFrame);
		if (idx !== -1) this.bannerExtra = ' - For the ' + AppConstants.topUsageTimePeriods[idx].label;
		this.bannerExtra += ' (' + TextTools.capitalizeFirstLetter(this.keyType) + ' Keys)';

		this.orgsRefreshing = true;
		this.broadcastersRefreshing = true;
		this.keysRefreshing = true;

		this.topOrganizations = [];
		this.topActivations = [];
		this.topHostIDs = [];

		const tmpArr1: Models.TopUsageCacheEntry[] = []
		const tmpArr2: Models.TopUsageCacheEntry[] = []
		const tmpArr3: Models.TopUsageCacheEntry[] = []

		for (const entry of this.topCache) {
			// console.log(entry.time_period + ' ' + entry.key_type + ' ' + entry.object_type);
			if (entry.time_period === this.timeFrame && this.keyType === entry.key_type && entry.object_type === 'organization')
				tmpArr1.push(entry);
			else if (entry.time_period === this.timeFrame && this.keyType === entry.key_type && entry.object_type === 'activation')
				tmpArr2.push(entry);
			else if (entry.time_period === this.timeFrame && this.keyType === entry.key_type && entry.object_type === 'hostid')
				tmpArr3.push(entry);
		} // for

		// sort....
		tmpArr1.sort((a, b) => (+a.used < +b.used) ? 1 : -1);
		tmpArr2.sort((a, b) => (+a.used < +b.used) ? 1 : -1);
		tmpArr3.sort((a, b) => (+a.used < +b.used) ? 1 : -1);

		this.topOrganizations = tmpArr1;
		this.orgsRefreshing = false;

		this.topActivations = tmpArr2;
		this.keysRefreshing = false;

		this.topHostIDs = tmpArr3;
		this.broadcastersRefreshing = false;
	}

	// *********************************************************
	async updateTopUsage() {
		this.showForm = false;

		this.timeFrame = this.theForm.value.timeFrame;
		this.keyType = this.theForm.value.keyType;

		this.saveSettings();

		await this.filterData();
	}

	// *********************************************************
	saveSettings() {
		let settings: any = {
			timeFrame: this.timeFrame,
			keyType: this.keyType,
			showRawData: this.showRawData
		};
		localStorage.setItem('cp-topUsage.settings', JSON.stringify(settings));
	}

	// *********************************************************
	toggleForm() {
		this.showForm = !this.showForm;
	}

	// *********************************************************
	toggleShowRaw() {
		this.showRawData = !this.showRawData;
		this.saveSettings();
	}

	// *********************************************************
	showDataValue(amt: number) {
		if (!amt || amt < 0.1) return '0';

		// limit decimal places
		if (this.showRawData)
			return TextTools.formatNumber(+Math.max(amt, 0.1).toFixed(1));
		else
			return TextTools.formattedMB(amt);
	}
}
