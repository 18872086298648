import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';

import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';

@Component({
	selector: 'app-view-errors',
	templateUrl: './view-errors.component.html',
	styleUrls: ['./view-errors.component.scss']
})
export class ViewErrorsComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	logs: Models.MailFailure[];
	loading = true;

	constructor(
		private router: Router,
		private adminLogsService: AdminLogsService,
		private usersService: UsersService,
		private authService: AuthService) { }

	ngOnInit(): void {
		// this.loadUsers();
		this.loadLogs();
	}

	ngOnDestroy() {
	}

	async loadLogs() {
		this.loading = true;
		this.logs = await this.adminLogsService.getEmailFailureLogs();

		this.loading = false;
	}
}
