import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import TextTools from 'appshared/text-tools';
import ValidationTools from 'appshared/validation-tools';

import { UsersService } from '../../users/users.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { SystemMessagesService } from '../../system-messages/system-messages.service';
import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

@Component({
	selector: 'app-organization-notification-form',
	templateUrl: './organization-notification-form.component.html',
	styleUrls: ['./organization-notification-form.component.scss']
})
export class OrganizationNotificationFormComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;

	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	// 'standard' edit stuff
	id: number;
	organization: Models.Organization;
	errors: string[] = [];
	theForm: UntypedFormGroup;
	loading = true;
	saving = false;

	// other stuff
	users: Models.User[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService,
		private systemMessagesService: SystemMessagesService,
		private organizationsService: OrganizationsService,
		private usersService: UsersService) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			if (this.id && this.id !== 0) {
				this.organization = this.organizationsService.getOne(this.id);
				if (!this.organization || this.organization == null || this.organization.id === 0) {
					this.router.navigate([AppConstants.urls.notfound]);
				} else {
					this.userSubscription = this.authService.user.subscribe((authUser) => {
						this.authUser = authUser;
						if (authUser) {
							// this.canSendNotifications = ValidationTools.checkAccess(authUser, 'send-build-notifications');
							this.initForm();
						}
					});
				}
			} else {
				this.router.navigate([AppConstants.urls.notfound]);
			}
		});
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async initForm() {
		const allUsers = this.usersService.getAll();
		this.users = [];

		for (const user of allUsers)
			if (user.is_enabled === 1 && user.org_id && user.org_id === this.id)
				this.users.push(user);

		// saving easier down the line...
		this.theForm = new UntypedFormGroup({
			subject: new UntypedFormControl(null, [Validators.required]),
			message: new UntypedFormControl(null, [Validators.required]),
			select_all: new UntypedFormControl(false, [])
		});

		for (const u of this.users)
			if (u.notifications)
				this.theForm.addControl('user_' + u.id, new UntypedFormControl(true, []));
			else
				this.theForm.addControl('user_' + u.id, new UntypedFormControl(false, []));

		this.loading = false;
	}

	async onSubmit() {
		this.saving = true;
		this.errors = [];

		const userIds: number[] = [];
		for (const u of this.users)
			if (this.theForm.value['user_' + u.id])
				userIds.push(u.id);

		const subject = this.theForm.value.subject;
		const message = this.theForm.value.message;

		if (!subject || subject === '') this.errors.push('You must provide a subject');
		if (!message || message === '') this.errors.push('You must provide a message');
		if (userIds.length === 0) this.errors.push('You must choose at least one user');

		if (this.errors.length > 0) {
			this.uiAlertsService.addMsgs(this.errors, 'warning', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.saving = false;
			return;
		} // if

		if (confirm('Are you sure you want to send a notification to ' + userIds.length + ' users(?)')) {
			try {
				// await this.productsService.sendNotification(this.id, subject, message);
				await this.systemMessagesService.sendNotification(subject, message, userIds);

				this.uiAlertsService.addMsg('The notification has been queued.',
					'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
				this.onCancel();
			} catch (e) {
				this.saving = false;
				this.uiAlertsService.addMsg(e.message, 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		} else {
			this.saving = false;
		}
	}

	onCancel() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	workoutNotificationSendTime(user: Models.User): Date {
		return ValidationTools.workoutNotificationSendTime(user);
	}

	// ***************************************************************
	checkAllToggleEmails() {
		const currCheckAllValue = this.theForm.value['select_all'];

		// uncheck everything
		for (const u of this.users)
			this.theForm.controls['user_' + u.id].setValue(false);

		if (currCheckAllValue)
			for (const u of this.users)
				this.theForm.controls['user_' + u.id].setValue(true);
	}


}
