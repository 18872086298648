import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject, interval, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';

import TrackRecent from '../../../../helpers/track-recent';

import { OrganizationsService } from '../organizations.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { ReportsService } from '../../reports/reports.service';

import { OrganizationsTableComponent } from 'client/app/components/shared/organizations-table/organizations-table.component';

@Component({
	selector: 'app-organization-list',
	templateUrl: './organization-list.component.html',
	styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;
	now = new Date();

	@ViewChild('orgsTable1') orgsTable1: OrganizationsTableComponent = null;

	// 'standard' list stuff
	organizations: Models.Organization[];
	organizationsToShow: Models.Organization[];

	loading = true;
	refreshing = false;

	// other stuff
	private userSubscription: Subscription;
	authUser: Models.AuthUser;

	canAdd = false;

	// starIcon = ['fas', 'star'];

	showFilters = false;
	theForm: UntypedFormGroup;

	// things to choose from
	enabledChoices: any[] = [];
	favoriteChoices: any[] = [];
	typeChoices: any[] = [];
	accountOwnerChoices: any[] = [];
	seChoices: any[] = [];
	specialChoices: any[] = [
		{ value: 'sales_engineer', label: 'I am the SE/tech-rep' },
		{ value: 'account_owner', label: 'I am the account owner' },

		{ value: 'no_account_owner', label: 'No account owner set' },
		{ value: 'no_sales_engineer', label: 'No SE/tech-rep set' },

		{ value: 'has_sf_id', label: 'Linked to a Salesforce Account' },
		{ value: 'no_sf_id', label: 'No Salesforce Account' },

		{ value: 'has_users', label: 'Has at least one user' },
		{ value: 'no_users', label: 'No users' },

		{ value: 'is_partner', label: 'Has at least one partner entry' },
		{ value: 'not_partner', label: 'No partner entries' },

		{ value: 'has_zenmaster', label: 'Has at least one active ZEN Master site' },
		{ value: 'no_zenmaster', label: 'Doesn\'t have any active ZEN Master sites' },

		{ value: 'has_keys', label: 'Has license keys' },
		{ value: 'no_keys', label: 'No license keys' },

		{ value: 'has_downloads', label: 'Has downloaded a file' },
		{ value: 'no_downloads', label: 'No downloads' },

		{ value: 'has_special_builds', label: 'Has access to special/private builds' },
		{ value: 'no_special_builds', label: 'No special/private builds' },

		{ value: 'has_email_domains', label: 'Has e-mail domain(s) set' },
		{ value: 'no_email_domains', label: 'No e-mail domain set' },

		{ value: 'no_products', label: 'No Products/Platforms assigned' },
		{ value: 'no_welcomes', label: 'No welecomed users' },
	];

	// filters
	enabledFilter: number = null;
	favoriteFilter: number = null;
	typeFilters: any[] = [];
	accountOwnerFilters: any[] = [];
	seFilters: any[] = [];
	specialFilters: any[] = [];

	recentOrganizations: Models.Organization[] = [];

	constructor(
		private organizationsService: OrganizationsService,
		private usersService: UsersService,
		private reportsService: ReportsService,
		private authService: AuthService,
	) { }

	ngOnInit(): void {
		this.loading = true;

		let settings: any = {};
		try {
			if (localStorage.getItem('cp-orgsList.settings'))
				settings = JSON.parse(localStorage.getItem('cp-orgsList.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('enabledFilter')) this.enabledFilter = settings.enabledFilter;
		if (settingKeys.includes('favoriteFilter')) this.favoriteFilter = settings.favoriteFilter;
		if (settingKeys.includes('typesettings')) this.typeFilters = settings.typeFilters;
		if (settingKeys.includes('accountOwnerFilters')) this.accountOwnerFilters = settings.accountOwnerFilters;
		if (settingKeys.includes('seFilters')) this.seFilters = settings.seFilters;
		if (settingKeys.includes('specialFilters')) this.specialFilters = settings.specialFilters;

		this.showFilters = (
			this.enabledFilter != null
			|| this.favoriteFilter != null
			|| this.typeFilters.length !== 0
			|| this.accountOwnerFilters.length !== 0
			|| this.seFilters.length !== 0
			|| this.specialFilters.length !== 0
		);

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.authUser = authUser;

			this.organizations = this.organizationsService.getAll();

			const otypes = [];
			for (const organizationType of AppConstants.organizationTypes)
				if (ValidationTools.checkAccess(authUser, 'manage-organizations-' + organizationType))
					otypes.push(organizationType);

			this.canAdd = authUser && otypes.length > 0;

			this.initForm();
			this.filterOrganizations();
		});
	}

	ngOnDestroy() {
	}

	async refresh() {
		this.refreshing = true;
		try {
			await this.organizationsService.refreshAll().toPromise();
			this.organizations = this.organizationsService.getAll();
			// this.prepOrgs();
			this.filterOrganizations();
		} catch (err) {
			// console.error('Caught an error refreshing list');
		}
		this.refreshing = false;
	}

	initForm() {
		const recentIds = TrackRecent.getRecent('organization');
		recentIds.reverse();

		this.recentOrganizations = [];
		for (const recentId of recentIds) {
			const org = this.organizationsService.getOne(recentId);
			if (org) this.recentOrganizations.push(org);
		} // for

		// setup choices...
		this.enabledChoices = [];
		this.enabledChoices.push({ value: 1, label: 'Enabled/Online' });
		this.enabledChoices.push({ value: 0, label: 'Disabled/Offline' });

		this.typeChoices = [];
		for (const type of AppConstants.organizationTypes)
			this.typeChoices.push({ value: type, label: AppConstants.organizationTypeLabels[type] });

		this.favoriteChoices = [];
		this.favoriteChoices.push({ value: 1, label: 'Marked as Favorite' });
		this.favoriteChoices.push({ value: 0, label: 'Not Marked as Favorite' });

		const staffUsers = this.usersService.getForRole('staff');
		this.accountOwnerChoices = [];
		this.seChoices = [];
		for (const org of this.organizations) {
			if (org.salesforce_account_owner_id && org.salesforce_account_owner_id !== 0) {
				const idx1 = MiscTools.findIndexGeneric(this.accountOwnerChoices, 'value', org.salesforce_account_owner_id);
				if (idx1 === -1) {
					const idx2 = MiscTools.findIndex(staffUsers, org.salesforce_account_owner_id);
					if (idx2 !== -1)
						this.accountOwnerChoices.push({ value: staffUsers[idx2].id, label: staffUsers[idx2].name });
				} // if
			} // if

			if (org.salesforce_se_id && org.salesforce_se_id !== 0) {
				const idx1 = MiscTools.findIndexGeneric(this.seChoices, 'value', org.salesforce_se_id);
				if (idx1 === -1) {
					const idx2 = MiscTools.findIndex(staffUsers, org.salesforce_se_id);
					if (idx2 !== -1)
						this.seChoices.push({ value: staffUsers[idx2].id, label: staffUsers[idx2].name });
				} // if
			} // if
		} // for

		this.accountOwnerChoices.sort((a, b) => (a.label > b.label) ? 1 : -1);
		this.seChoices.sort((a, b) => (a.label > b.label) ? 1 : -1);

		this.theForm = new UntypedFormGroup({
			enabledFilter: new UntypedFormControl(this.enabledFilter),
			typeFilters: new UntypedFormControl(this.typeFilters),
			favoriteFilter: new UntypedFormControl(this.favoriteFilter),
			accountOwnerFilters: new UntypedFormControl(this.accountOwnerFilters),
			seFilters: new UntypedFormControl(this.seFilters),
			specialFilters: new UntypedFormControl(this.specialFilters)
		});
	}

	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.enabledFilter = this.theForm.value.enabledFilter;
		this.favoriteFilter = this.theForm.value.favoriteFilter;
		this.typeFilters = this.theForm.value.typeFilters;
		this.accountOwnerFilters = this.theForm.value.accountOwnerFilters;
		this.seFilters = this.theForm.value.seFilters;
		this.specialFilters = this.theForm.value.specialFilters;

		let settings: any = {
			enabledFilter: this.enabledFilter,
			favoriteFilter: this.favoriteFilter,
			typeFilters: this.typeFilters,
			accountOwnerFilters: this.accountOwnerFilters,
			seFilters: this.seFilters,
			specialFilters: this.specialFilters
		};
		localStorage.setItem('cp-orgsList.settings', JSON.stringify(settings));

		this.filterOrganizations();
	}

	async filterOrganizations() {
		this.loading = true;

		this.organizationsToShow = [];
		for (const organization of this.organizations) {
			let passedEnabledFilter = false;
			let passedTypeFilters = false;
			let passedFavoriteFilter = false;
			let passedAccountOwnerFilters = false;
			let passedTechRepFilters = false;
			let passedSpecialFilters = false;

			if (this.enabledFilter == null || this.enabledFilter === organization.is_enabled)
				passedEnabledFilter = true;

			if (this.favoriteFilter == null || this.favoriteFilter === organization.is_favorite)
				passedFavoriteFilter = true;

			if (!this.typeFilters || this.typeFilters.length === 0 || this.typeFilters.includes(organization.otype))
				passedTypeFilters = true;

			if (!this.accountOwnerFilters || this.accountOwnerFilters.length === 0 || this.accountOwnerFilters.includes(organization.salesforce_account_owner_id))
				passedAccountOwnerFilters = true;

			if (!this.seFilters || this.seFilters.length === 0 || this.seFilters.includes(organization.salesforce_se_id))
				passedTechRepFilters = true;

			if (!this.specialFilters || this.specialFilters.length === 0) {
				passedSpecialFilters = true;
			} else {
				passedSpecialFilters = true;
				if (this.specialFilters.includes('account_owner') && this.authUser && organization.salesforce_account_owner_id !== this.authUser.id)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_account_owner') && organization.salesforce_account_owner_id && organization.salesforce_account_owner_id !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('sales_engineer') && this.authUser && organization.salesforce_se_id !== this.authUser.id)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_sales_engineer') && organization.salesforce_se_id && organization.salesforce_se_id !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_sf_id') && (!organization.salesforce_account_id || organization.salesforce_account_id === ''))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_sf_id') && organization.salesforce_account_id && organization.salesforce_account_id !== '')
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_users') && organization.num_users === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_users') && organization.num_users !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_keys') && organization.num_keys === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_keys') && organization.num_keys !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('is_partner') && organization.num_partnerships === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('not_partner') && organization.num_partnerships !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_zenmaster') && organization.num_active_zen_domains === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_zenmaster') && organization.num_active_zen_domains !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_downloads') && organization.last_download == null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_downloads') && organization.last_download !== null)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_special_builds') && organization.build_ids.length === 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_special_builds') && organization.build_ids.length !== 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('has_email_domains') && (!organization.email_domains || organization.email_domains === ''))
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_email_domains') && organization.email_domains && organization.email_domains !== '')
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_products') && organization.product_platforms && organization.product_platforms.length > 0)
					passedSpecialFilters = false;

				if (this.specialFilters.includes('no_welcomes') && ((organization.num_users === 0 && organization.num_welcomed == 0) || organization.num_welcomed > 0))
					passedSpecialFilters = false;
			}

			if (passedEnabledFilter
				&& passedTypeFilters
				&& passedFavoriteFilter
				&& passedAccountOwnerFilters
				&& passedTechRepFilters
				&& passedSpecialFilters) this.organizationsToShow.push(organization);
		}

		await MiscTools.delay(100);
		if (this.orgsTable1)
			this.orgsTable1.updateContent(this.organizationsToShow, 'cp-organizations-list', { showFavorite: true, showSalesEng: true, showSalesRep: true });

		this.loading = false;
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	async openBigReport() {
		let args = '';
		await this.reportsService.runReport('PortalOrganizationsReport', args);
	}

}
