<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li *ngIf="!editMode" class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.builds" class="cp-admin-nav-item me-1" size="sm"></fa-icon>
				<a href="javascript:void(0)" routerLink="../..">Builds</a>
			</li>
			<li *ngIf="editMode" class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.builds" class="cp-admin-nav-item me-1" size="sm"></fa-icon>
				<a href="javascript:void(0)" routerLink="..">Builds</a>
			</li>
			<li *ngIf="editMode && !loading" class="breadcrumb-item active" aria-current="page">
				{{ product.name }} - {{ build.version }}
				<span *ngIf="build.label && build.label !== ''">({{ build.label }})</span>
			</li>
			<li *ngIf="!editMode && !loading" class="breadcrumb-item active" aria-current="page">
				New Build of {{ product.name }}
			</li>
		</ol>
	</nav>
</div>

<div class="tab-container">

	<ul class="nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab active" data-bs-toggle="tab" role="tab" aria-selected="true" id="nav-base-tab"
				aria-controls="nav-base" data-bs-target="#nav-base">
				Info
			</button>
		</li>

		<li class="nav-item" role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-release-tab"
				aria-controls="nav-release" data-bs-target="#nav-release">
				Release Notes
			</button>
		</li>

		<li *ngIf="product && (hasFlag(product, 'may_have_builds') || (build && build.platform_files.length !== 0))" class="nav-item"
			role="presentation">
			<button type="button" class="nav-link cp-std-tab" data-bs-toggle="tab" role="tab" aria-selected="false" id="nav-files-tab"
				aria-controls="nav-files" data-bs-target="#nav-files">
				Platform Files
				<span class="cp-tab-badge">{{ counter | number }}</span>
			</button>
		</li>
		<app-loading-spinner *ngIf="loading || saving"></app-loading-spinner>
	</ul>

	<form *ngIf="!loading && theForm" [formGroup]="theForm" class="tab-form">

		<div class="tab-content" id="nav-tabContent">
			<div class="tab-pane show active" id="nav-base" role="tabpanel" aria-labelledby="nav-base-tab">
				<div class="cp-general-width-limit">
					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="is_enabled" formControlName="is_enabled" />
						<label class="form-check-label" for="is_enabled">
							This build is enabled/active (its files can be downloaded).
						</label>
					</div>

					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="is_private" formControlName="is_private"
							(change)="onPrivateChange()" />
						<label class="form-check-label" for="is_private">
							This build is private (only Organizations directly assign can download its files).
						</label>
					</div>


					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="is_retired" formControlName="is_retired" />
						<label class="form-check-label" for="is_retired">
							Archive/Retire this Build. Installer files will no longer be available for download. Release
							notes will still be available.
						</label>
					</div>

					<div class="form-group">
						<label for="version">Version*</label>
						<input type="text" id="version" formControlName="version" class="form-control" pattern="\d+\.\d+\.\d+"
							placeholder="#.#.#" />
					</div>

					<div class="form-group">
						<label for="label">Label</label>
						<input type="text" id="label" formControlName="label" class="form-control" [placeholder]="labelHelperText" />
					</div>

					<div class="form-group">
						<label for="information">Information</label>
						<textarea id="information" formControlName="information" class="form-control" rows="4"
							placeholder="Internal notes."></textarea>
					</div>

					<div class="form-group">
						<label for="org_ids">Organizations - for direct access to private builds</label>
						<ng-select [items]="organizations" bindValue="id" bindLabel="name" id="org_ids" formControlName="org_ids"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="form-group">
						<label for="group_ids">Organization Groups - for direct access to private builds</label>
						<ng-select [items]="groups" bindValue="id" bindLabel="name" id="group_ids" formControlName="group_ids"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div class="form-group">
						<label for="package_ids">Packages - for direct access to private builds</label>
						<ng-select [items]="packages" bindValue="id" bindLabel="name" id="package_ids" formControlName="package_ids"
							[multiple]="true" [closeOnSelect]="false">
						</ng-select>
					</div>

					<div *ngIf="version.invalid && (version.dirty || version.touched)" class="cp-form-error">
						<div *ngIf="version.errors.pattern">
							Version must be #.#.#.
						</div>
					</div>


				</div>
			</div>

			<div class="tab-pane" id="nav-release" role="tabpanel" aria-labelledby="nav-release-tab">
				<div class="cp-general-width-limit">
					<div class="form-group">
						<label for="release_notes_file_id">Release Notes (file)</label>
						<ng-select [items]="docFiles" bindValue="id" bindLabel="name" id="release_notes_file_id"
							formControlName="release_notes_file_id">
						</ng-select>
					</div>

					<div class="form-group">
						<label for="release_notes">Release Notes (inline)</label>
						<textarea id="release_notes" formControlName="release_notes" class="form-control" rows="20"></textarea>
					</div>
				</div>
			</div>

			<div class="tab-pane" id="nav-files" role="tabpanel" aria-labelledby="nav-files-tab">
				<div class="cp-general-width-limit">

					<div *ngIf="fileMode !== 'all'" class="mb-2">
						Currently only showing files not currently used with any other build.<br />
						<button class="btn btn-outline-secondary" type="button" (click)="toggleFileMode()">
							Show All {{ allFiles.length }} Files
						</button>

					</div>

					<div *ngIf="fileMode === 'all'" class="mb-2">
						Currently showing all files.<br />
						<button class="btn btn-outline-secondary" type="button" (click)="toggleFileMode()">
							Just Show {{ unusedFiles.length }} Unused Files
						</button>
					</div>

					<div formArrayName="platforms">
						<div class="form-group" *ngFor="let pl of platforms; let i = index" [formGroupName]="i">
							<input type="hidden" formControlName="platform_id" class="form-control" />
							<input type="hidden" formControlName="label" class="form-control" />
							<div class="form-group">
								<label for="file_id">
									<fa-icon [icon]="mt.getPlatformIcon(pl.name)"></fa-icon>
									{{ pl.name }}
								</label>
								<ng-select [items]="installerFiles" bindValue="id" bindLabel="name" id="file_id" formControlName="file_id"
									(change)="refreshCounter()">
								</ng-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="bottom-bar">
			<div class="form-group mb-2 small">* Required</div>
			<div class="form-group mb-0" *ngIf="!saving">
				<button class="btn btn-primary me-1" type="submit" [disabled]="!theForm.valid">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>
			</div>
		</div> -->

		<div class="cp-form-padding"></div>

		<div *ngIf="!loading && theForm" class="cp-form-buttons">
			<div class="cp-form-buttons-inner">
				<button class="btn btn-primary me-1" type="button" (click)="onSubmit()" [disabled]="!theForm.valid || saving">
					<fa-icon [icon]="ac.actionIcons.submit"></fa-icon> Save
				</button>
				<button class="btn btn-outline-secondary" type="button" (click)="onCancel()" [disabled]="saving">
					<fa-icon [icon]="ac.actionIcons.cancel"></fa-icon> Cancel
				</button>

				<span class="gj-75 ps-2">* Required</span>
			</div>
		</div>
	</form>
</div>