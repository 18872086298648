<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="../..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Reports</a></li>
			<li class="breadcrumb-item active" aria-current="page">
				Cloud Clusters
			</li>

		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm"></fa-icon>
				<span class="cp-action-button-text">Filter</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="loadZenObjects(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>

		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-3">
				<ng-select [items]="enabledChoices" bindValue="value" bindLabel="label" id="enabledFilter" formControlName="enabledFilter"
					[multiple]="false" (change)="onFormChange()" placeholder="Enabled/Disabled">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="zmTypeChoices" bindValue="value" bindLabel="label" id="zmTypeFilters" formControlName="zmTypeFilters"
					[multiple]="true" [closeOnSelect]="false" (change)="onFormChange()" placeholder="Filter by Type">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="assessmentChoices" bindValue="value" bindLabel="value" id="assessmentFilters"
					formControlName="assessmentFilters" [multiple]="true" [closeOnSelect]="false" (change)="onFormChange()"
					placeholder="Filter by Assessment">
				</ng-select>
			</div>
		</div>
	</form>

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow" [routerLink]="getSiteRoute(item)" class="cp-pointer">
				<td>
					<a [routerLink]="getSiteRoute(item)">{{ item['__prefix'] }}</a>
				</td>
				<td>
					<span *ngIf="item.zen_customer.zcp_type && item.zen_customer.zcp_type !== ''">
						{{ item['__type'] }}
					</span>
				</td>
				<td>{{ item.zen_customer.assessment }}</td>
				<td class="cp-chopped-column">{{ item.name }}</td>
				<td>{{ item.cloud_type }}</td>
				<td class="cp-chopped-column">{{ item.config_name }}</td>
				<td>{{ item.instance_type }}</td>
				<td>{{ item['__version'] }}</td>
				<td>{{ item['__numBx'] }}</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item['has_build']" [icon]="['fas', 'check']" size="sm" class="text-success"
						ngbTooltip="ZCP Build Exists" placement="start"></fa-icon>
					<fa-icon *ngIf="!item['has_build']" [icon]="['fas', 'exclamation-triangle']" size="sm" class="text-danger"
						ngbTooltip="ZCP Build DOES NOT Exist" placement="start"></fa-icon>
				</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item['has_build'] && item['build_enabled']" [icon]="['fas', 'power-off']" size="sm" class="text-success"
						ngbTooltip="Enabled" placement="start"></fa-icon>
					<fa-icon *ngIf="item['has_build'] && !item['build_enabled']" [icon]="['fas', 'power-off']" size="sm" class="text-danger"
						ngbTooltip="Disabled - not available" placement="start"></fa-icon>
				</td>
				<td class="cp-icon-cell">
					<span *ngIf="item['has_build']">
						<span class="cp-hidden-content">
							{{ item['has_build'] && item['build_private'] }}
						</span>
						<fa-icon *ngIf="!item['build_private']" [icon]="['fas', 'check']" size="sm" class="text-success"
							ngbTooltip="GA Build - Organization likely has access" placement="start">
						</fa-icon>
						<fa-icon *ngIf="item['build_private']" [icon]="['fas', 'lock']" size="sm" class="text-warning"
							ngbTooltip="Private Build - check Site/Organization to verify access" placement="start">
						</fa-icon>
					</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>