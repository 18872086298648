<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="../..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Reports</a></li>
			<li class="breadcrumb-item active" aria-current="page">
				Users
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm"></fa-icon>
				<span class="cp-action-button-text">Filter</span>
			</button>

			<button type="button" class="btn btn-outline-secondary me-2" (click)="loadZenObjects(true)">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading" ngbTooltip="Refresh" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>

		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-3">
				<ng-select [items]="enabledChoices" bindValue="value" bindLabel="label" id="enabledFilter" formControlName="enabledFilter"
					[multiple]="false" (change)="onFormChange()" placeholder="Site Enabled/Disabled">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="zmTypeChoices" bindValue="value" bindLabel="label" id="zmTypeFilters" formControlName="zmTypeFilters"
					[multiple]="true" [closeOnSelect]="false" (change)="onFormChange()" placeholder="Filter by Type">
				</ng-select>
			</div>

			<div class="cp-filter-block-6">
				<ng-select [items]="specialChoices" bindValue="value" bindLabel="label" id="specialFilters" formControlName="specialFilters"
					[multiple]="true" (change)="onFormChange()" placeholder="Special">
				</ng-select>
			</div>

		</div>
	</form>

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<app-paging-search-bar [parentApi]="getParentMethod()"></app-paging-search-bar>

	<table *ngIf="!loading && itemsToShow.length > 0" class="cp-std-table">
		<thead>
			<tr>
				<th *ngFor="let cd of columnDefs" (click)="setSortBy(cd.field)" class="text-center cp-pre-text cp-pointer"
					[ngClass]="{ 'text-success fw-bolder': displayOptions.sortBy === cd.field, 'cp-icon-header': !!cd.icon }"
					[hidden]="!fieldsToShow.includes(cd.field)">
					<span *ngIf="cd.label" [innerHTML]="cd.label"
						[ngClass]="{'fst-italic': displayOptions.sortBy === cd.field && displayOptions.sortDir === 'desc'}"
						[ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></span>
					<fa-icon *ngIf="cd.icon" [icon]="cd.icon" [ngbTooltip]="cd.toolTip ? cd.toolTip : ''"></fa-icon>
				</th>
			</tr>
		</thead>
		<tbody *ngIf="itemsToShow && !loading">
			<tr *ngFor="let item of itemsToShow">
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_enabled === 1" [icon]="['fas', 'power-off']" size="sm" class="text-success" placement="end"
						ngbTooltip="User Account Enabled - login permitted"></fa-icon>
					<fa-icon *ngIf="item.is_enabled === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger" placement="end"
						ngbTooltip="User Account Disabled - login not permitted"></fa-icon>
				</td>

				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.sso_id && item.sso_id !== ''" [icon]="['fas', 'sign-in-alt']" size="sm" placement="end"
						ngbTooltip="This User authenticated via Single Sign On">
					</fa-icon>
				</td>

				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_admin && item.is_admin === 1" [icon]="['fas', 'hat-wizard']" class="text-primary" size="sm"
						placement="end" ngbTooltip="Site Admin">
					</fa-icon>
				</td>
				<td class="cp-icon-cell" [title]="validationTools.getZenUserSupportLabel(item, false)">
					{{ validationTools.getZenUserSupportLabel(item, true) }}
				</td>
				<td class="cp-icon-cell">
					<fa-icon *ngIf="item.is_zixi_admin && item.is_zixi_admin === 1" [icon]="['fas', 'hat-wizard']" class="text-info"
						size="sm" placement="end" ngbTooltip="Zixi Admin">
					</fa-icon>
				</td>

				<td class="text-nowrap">
					<span *ngIf="!matchedPortalUsers[item.id]">{{ item.name }}</span>
					<a *ngIf="matchedPortalUsers[item.id]" [routerLink]="['/' + ac.urls.users, matchedPortalUsers[item.id].id]"
						placement="end" ngbTooltip="This ZEN Master User is also a ZCP User">
						{{ item.name}}
					</a>
					<fa-icon *ngIf="diffOrgUsers[item.id]" [icon]="['fas', 'exclamation-triangle']"
						ngbTooltip="The ZCP User is not linked to the Organization that is linked to this ZEN Master site.">
					</fa-icon>
					<fa-icon *ngIf="noOrgUsers[item.id]" [icon]="['fas', 'exclamation-triangle']" class="text-warning"
						ngbTooltip="The ZCP User is not linked to an Organization.">
					</fa-icon>
				</td>
				<td class="cp-chopped-column">{{ item.email }}</td>

				<td class="text-center">
					<span *ngIf="item['__sinceAdd']">
						{{ item['__sinceAdd'] | number }}
					</span>
				</td>
				<td class="text-center">
					<span *ngIf="item['__sinceLogin'] != null">
						{{ item['__sinceLogin'] | number }}
					</span>
				</td>
				<td>
					<span *ngIf="item['__notificatioState']">
						{{ item['__notificatioState'] }}
					</span>
				</td>

				<td>
					<a [routerLink]="['/' + ac.urls.zencustomers, item.customer_id]">
						{{ zenPrefixes[item.customer_id] }}
						<fa-icon *ngIf="zenEnabled[item.customer_id] === 0" [icon]="['fas', 'ban']" size="sm" class="text-danger"
							placement="end" ngbTooltip="ZEN Master Site Disabled"></fa-icon>
					</a>
				</td>
				<td>{{ ac.zenMasterTypeLabels[zenTypes[item.customer_id]] }}</td>
			</tr>
		</tbody>
	</table>
</div>