import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';
import SharedLicenseTools from 'appshared/shared-license-tools';
import LicenseValidationTools from 'appshared/license-validation-tools';

import { LicensingService } from '../licensing.service';
import { AuthService } from 'client/app/services/auth.service';
import { LicensingAdminService } from '../../licensing-admin/licensing-admin.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-offline-search',
	templateUrl: './offline-search.component.html',
	styleUrls: ['./offline-search.component.scss']
})
export class OfflineSearchComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	private userSubscription: Subscription;
	role = '';

	items: Models.LPLicense[] = [];
	itemsToShow: Models.LPLicense[] = [];

	lpusers: Models.LPUser[] = [];

	searching = false;
	showResults = false;
	showNoParamsMessage = false;
	showResetButton = false;

	keyProducts: Models.LicenseProduct[] = [];

	// other stuff...
	maxMessage = '';

	formMode = 'searchForm'; // or saveForm or savedSearches

	productsSelections = [
		{ value: 'broadcaster*', label: 'Zixi Broadcaster' },
		{ value: 'receiver', label: 'Zixi Receiver' },
		{ value: 'feeder', label: 'Zixi Feeder' },
		{ value: 'mediaconnect_fx', label: 'MediaConnect Feeder' },
		{ value: 'mediaconnect_rx', label: 'MediaConnect Receiver' }
	];
	// { value: 'wirecast_feeder', label: 'Wirecast Feeder' }

	productsFilter: string[] = [];
	typesFilter: string[] = [];
	hostidFilter = '';
	textFilter = '';
	userIDs: number[] = [];
	specialFilter: string[] = [];

	theSearchForm: UntypedFormGroup;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'offline-search';

	columnDefs: any[] = [
		{
			field: 'created_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Created',
			toolTip: null,
			icon: null
		}, {
			field: 'customer',
			type: 'baseText',
			sortType: 'text',
			label: 'Customer',
			toolTip: null,
			icon: null
		}, {
			field: 'type',
			type: 'baseText',
			sortType: 'text',
			label: 'Type',
			toolTip: null,
			icon: null
		}, {
			field: '__niceProduct',
			type: 'baseText',
			sortType: 'text',
			label: 'Product',
			toolTip: null,
			icon: null
		}, {
			field: 'hostid',
			type: 'baseText',
			sortType: 'text',
			label: 'Host ID',
			toolTip: null,
			icon: null
		}, {
			field: 'expires_at',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Expires',
			toolTip: null,
			icon: null
		}, {
			field: '__lastTouched',
			type: 'baseText',
			sortType: 'text',
			label: null,
			toolTip: 'Last Touched By',
			icon: 'user'
		}
	];

	fieldsToShow: string[] = [];


	constructor(
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private licensingAdminService: LicensingAdminService,
		private licensingService: LicensingService) {
		this.loadExtras();
	}

	ngOnInit(): void {
		let settings: any = {};
		try {
			if (localStorage.getItem('cp-offlineSearch.settings'))
				settings = JSON.parse(localStorage.getItem('cp-offlineSearch.settings'));
		} catch (e) { }
		const settingKeys: string[] = Object.keys(settings);

		if (settingKeys.includes('productsFilter')) this.productsFilter = settings.productsFilter;
		if (settingKeys.includes('typesFilter')) this.typesFilter = settings.typesFilter;
		if (settingKeys.includes('hostidFilter')) this.hostidFilter = settings.hostidFilter;
		if (settingKeys.includes('textFilter')) this.textFilter = settings.textFilter;
		if (settingKeys.includes('userIDs')) this.userIDs = settings.userIDs;
		if (settingKeys.includes('specialFilter')) this.specialFilter = settings.specialFilter;

		this.userSubscription = this.authService.user.subscribe(authUser => {
			if (authUser) {

			} // if
		});

		this.initForms();
		this.showResetButton = this.hasSearchParams();

		// can either do recent keys...
		// this.getRecent();
		// or just run the search again (if there was one)
		// if (this.hasSearchParams()) this.loadKeys();
	}

	hasSearchParams() {
		// const startDate: Date = this.getDateFromDateStruct(this.notBefore);
		// const endDate: Date = this.getDateFromDateStruct(this.notAfter);
		// || startDate != null
		// || endDate != null

		return (this.productsFilter && this.productsFilter.length > 0)
			|| (this.typesFilter && this.typesFilter.length > 0)
			|| (this.specialFilter && this.specialFilter.length > 0)
			|| (this.hostidFilter && this.hostidFilter !== '')
			|| (this.textFilter && this.textFilter !== '')
			|| (this.userIDs && this.userIDs.length > 0)
			;
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	initForms() {
		this.theSearchForm = new UntypedFormGroup({
			productsFilter: new UntypedFormControl(this.productsFilter),
			typesFilter: new UntypedFormControl(this.typesFilter),
			hostidFilter: new UntypedFormControl(this.hostidFilter),
			textFilter: new UntypedFormControl(this.textFilter),
			userIDs: new UntypedFormControl(this.userIDs),
			specialFilter: new UntypedFormControl(this.specialFilter),
		});
	}

	resetForm() {
		this.productsFilter = [];
		this.typesFilter = [];
		this.hostidFilter = '';
		this.textFilter = '';
		this.userIDs = [];
		this.specialFilter = [];

		this.showResetButton = false;
		this.initForms();
		this.saveParametersToLocalStorage();
	}

	async loadExtras() {
		this.lpusers = await this.licensingService.getClassicLicenseUsers();
		this.keyProducts = await this.licensingAdminService.getProducts();
	}

	saveParametersToLocalStorage() {
		let settings: any = {
			productsFilter: this.productsFilter,
			typesFilter: this.typesFilter,
			hostidFilter: this.hostidFilter,
			textFilter: this.textFilter,
			userIDs: this.userIDs,
			specialFilter: this.specialFilter
		};
		localStorage.setItem('cp-offlineSearch.settings', JSON.stringify(settings));
	}

	grabParametersFromForm() {
		this.productsFilter = this.theSearchForm.value.productsFilter;
		this.typesFilter = this.theSearchForm.value.typesFilter;
		this.hostidFilter = this.theSearchForm.value.hostidFilter;
		this.textFilter = this.theSearchForm.value.textFilter;
		this.userIDs = this.theSearchForm.value.userIDs;
		this.specialFilter = this.theSearchForm.value.specialFilter;
	}

	buildArgs() {
		const argsArr = [];

		argsArr.push('products=' + encodeURIComponent(this.productsFilter.join(',')));
		argsArr.push('types=' + encodeURIComponent(this.typesFilter.join(',')));
		argsArr.push('hostids=' + encodeURIComponent(this.hostidFilter));
		argsArr.push('text=' + encodeURIComponent(this.textFilter));
		argsArr.push('userIDs=' + encodeURIComponent(this.userIDs.join(',')));
		argsArr.push('special=' + encodeURIComponent(this.specialFilter.join(',')));

		return argsArr.join('&');
	}

	async doSearch() {
		this.grabParametersFromForm();
		this.saveParametersToLocalStorage();

		if (!this.hasSearchParams()) {
			this.showNoParamsMessage = true;
			this.showResults = false;
			this.showResetButton = false;
			return;
		} else {
			this.showNoParamsMessage = false;
			this.showResetButton = true;
			this.loadLicenses(this.buildArgs());
		}
	}

	async loadLicenses(params: string) {
		this.searching = true;
		this.showResults = false;

		this.items = await this.licensingService.searchOfflineLicenses(params);
		if (this.items && this.items.length > AppConstants.maxLicenseRowsToFetch) {
			this.items.sort((a, b) => ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) ? 1 : -1);
			this.items.pop(); // remove the last one..
			this.maxMessage = 'Maximum offline licenses (' + AppConstants.maxLicenseRowsToFetch.toLocaleString() + ') found.';
		} else {
			this.maxMessage = '';
		}

		for (const item of this.items) {
			item['__niceProduct'] = this.niceKeyProduct(item.product);
			if (item.user_id && item.user_id !== 0)
				item['__lastTouched'] = this.getClassicUserName(item.user_id);
		}

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.searching = false;
		this.showResults = true;

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'created_at', 'desc');
	}

	saveAsExcel() {
		if (!this.hasSearchParams()) return;

		let url = AppConstants.apiUrl + AppConstants.apiUrls.licensing;
		url += '/offline?format=xlsx'
			+ '&' + this.buildArgs();

		// window.open(url, '_blank');
	}

	niceKeyProduct(product: string) {
		const idx = MiscTools.findIndexGeneric(this.keyProducts, 'name', product);
		if (idx === -1)
			return product;
		else
			return this.keyProducts[idx].label;
	}

	getClassicUserName(id: number) {
		const idx = MiscTools.findIndex(this.lpusers, id);
		if (idx !== -1)
			return this.lpusers[idx].email.replace(/\./g, ' ');
		else
			return 'deleted user (id=' + id + ')';
	}


	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.LPLicense[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item.customer.toLowerCase().includes(w)
						|| (item.type && item.type.toLowerCase().includes(w))
						|| (item.hostid && item.hostid.toLowerCase().includes(w))
						|| (item['__niceProduct'] && item['__niceProduct'].toLowerCase().includes(w))
						|| (item['__lastTouched'] && item['__lastTouched'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}


}
