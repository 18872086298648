import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
// import ValidationTools from 'appshared/validation-tools';
// import SharedLicenseTools from 'appshared/shared-license-tools';
// import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-card-zen-master',
	templateUrl: './card-zen-master.component.html',
	styleUrls: ['./card-zen-master.component.scss']
})
export class CardZenMasterComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.ZenMasterCustomer = null;
	@Input() organizations: Models.Organization[] = [];
	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.ZenMasterCustomer = null;

	// options


	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object, this.organizations);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.ZenMasterCustomer, organizations: Models.Organization[]) {

		this.objectToShow = MiscTools.deepClone(object);
		this.organizations = MiscTools.deepClone(organizations);

		if (!this.objectToShow) return;

		this.nameToShow = this.objectToShow.name;
		this.typeToShow = 'ZEN Master';
		this.iconToShow = AppConstants.sectionIcons.zencustomers;

		this.infoLines = [];

		if (this.objectToShow.is_enabled === 1)
			this.infoLines.push('Enabled: Yes');
		else
			this.infoLines.push('Enabled: No');

		this.infoLines.push('Environment: ' + this.objectToShow.environment);
		this.infoLines.push('Name: ' + this.objectToShow.name);
		this.infoLines.push('DNS Prefix: ' + this.objectToShow.dns_prefix);
		this.infoLines.push('Type: ' + this.objectToShow.zcp_type);
		this.infoLines.push('Assessment: ' + this.objectToShow.assessment);

		if (this.objectToShow.zcp_org_id && this.objectToShow.zcp_org_id !== 0) {
			const idx = MiscTools.findIndex(organizations, this.objectToShow.zcp_org_id);
			if (idx !== -1) {
				this.infoLines.push('Organization: ' + organizations[idx].name);
				if (organizations[idx].salesforce_account_owner && organizations[idx].salesforce_account_owner !== '')
					this.infoLines.push('Account Owner: ' + organizations[idx].salesforce_account_owner);

				if (organizations[idx].salesforce_se && organizations[idx].salesforce_se !== '')
					this.infoLines.push('SE: ' + organizations[idx].salesforce_se);

				if (organizations[idx].salesforce_tam && organizations[idx].salesforce_tam !== '')
					this.infoLines.push('TAM: ' + organizations[idx].salesforce_tam);
			} // if
		} // if

		if (this.objectToShow.marketplace != null && this.objectToShow.marketplace.marketplace !== ''
			&& this.objectToShow.marketplace.accountIdentifier && this.objectToShow.marketplace.accountIdentifier !== ''
			&& this.objectToShow.marketplace.productIdentifiers && this.objectToShow.marketplace.productIdentifiers.length > 0) {

			let marketplaceProducts: string[] = [];
			let lastMarketplaceUsageReported: Date = null;
			const marketplaceAccountIdentifier = this.objectToShow.marketplace.accountIdentifier;
			let marketplaceLabel = this.objectToShow.marketplace.marketplace;
			let productSelections: any[] = [];
			let accountIdLabel = 'ID';
			const idx1 = MiscTools.findIndexGeneric(AppConstants.marketPlaceSelections, 'value', this.objectToShow.marketplace.marketplace);
			if (idx1 !== -1) {
				marketplaceLabel = AppConstants.marketPlaceSelections[idx1].label + ' (' + this.objectToShow.marketplace.marketplace + ')';
				productSelections = AppConstants.marketPlaceSelections[idx1].selections;
				accountIdLabel = AppConstants.marketPlaceSelections[idx1].accountLabel;
			} // if

			for (const prodCode of this.objectToShow.marketplace.productIdentifiers) {
				const idx = MiscTools.findIndexGeneric(productSelections, 'productCode', prodCode);
				if (idx === -1)
					marketplaceProducts.push('Unknown product code (' + prodCode + ')');
				else
					marketplaceProducts.push(productSelections[idx].label + '(' + prodCode + ')');
			} // for

			if (this.objectToShow.marketplace.latestReport != null)
				lastMarketplaceUsageReported = new Date(this.objectToShow.marketplace.latestReport);
			if (lastMarketplaceUsageReported != null && isNaN(lastMarketplaceUsageReported.getTime())) lastMarketplaceUsageReported = null;

			this.infoLines.push('Marketplace: ' + marketplaceLabel);
			this.infoLines.push(accountIdLabel + ': ' + marketplaceAccountIdentifier);
			this.infoLines.push('Marketplace Product(s): ' + marketplaceProducts.join(', '));
			if (lastMarketplaceUsageReported)
				this.infoLines.push('Marketplace Last Reported: ' + TextTools.formatDateNiceUTC(lastMarketplaceUsageReported));
		} // if

		this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.created_at));

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
