import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';

import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-clicks-table',
	templateUrl: './clicks-table.component.html',
	styleUrls: ['./clicks-table.component.scss']
})
export class ClicksTableComponent implements OnInit {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	/*
		pass in
			logs 
			storageKey
			options
			- showUserInfo : true
			- showObjectInfo: true
			- linkObject: true
			- linkUser: false
	
	*/

	authUser: Models.AuthUser = null;
	loading: boolean = false;

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = '';

	showUserInfo: boolean = true;
	linkUser: boolean = false;

	items: Models.LinkLog[] = [];
	itemsToShow: Models.LinkLog[] = [];

	columnDefs: any[] = [
		{
			field: 'clicked_on',
			type: 'baseDate',
			sortType: 'numeric',
			label: 'Date/Time',
			toolTip: null,
			icon: null
		}, {
			field: '__username',
			type: 'baseText',
			sortType: 'text',
			label: 'User',
			toolTip: null,
			icon: null
		}, {
			field: 'url',
			type: 'baseText',
			sortType: 'text',
			label: 'URL',
			toolTip: null,
			icon: null
		}, {
			field: 'location',
			type: 'baseText',
			sortType: 'text',
			label: 'IP',
			toolTip: null,
			icon: null
		}, {
			field: '__browser',
			type: 'baseText',
			sortType: 'text',
			label: 'Browser',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private uiAlertsService: UiAlertsService,
		private authService: AuthService) {
	}

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {

	}

	// ------------------------------------------------------------------------
	updateContent(items: Models.LinkLog[], storageKey: string, options: any = {}) {
		this.loading = true;

		this.items = items;
		this.storageKey = storageKey;

		let optionKeys: string[] = [];
		if (options) optionKeys = Object.keys(options);

		if (optionKeys.includes('showUserInfo')) this.showUserInfo = options.showUserInfo;
		if (optionKeys.includes('linkUser')) this.linkUser = options.linkUser;

		this.fieldsToShow = [];
		this.fieldsToShow.push('clicked_on');
		if (this.showUserInfo) this.fieldsToShow.push('__username');
		this.fieldsToShow.push('url');
		this.fieldsToShow.push('location');
		this.fieldsToShow.push('__browser');

		for (const item of this.items) {
			item['__browser'] = TextTools.parseUserAgent(item.browser, 'short');
			item['__username'] = TextTools.parseUserInfo(item.user_info);
		} // for

		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'clicked_on', 'desc');

		this.loading = false;
	} // updateContent

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.LinkLog[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if (item['__browser'].toLowerCase().includes(w)
						|| item.url.toLowerCase().includes(w)
						|| item.location.toLowerCase().includes(w)
						|| (this.showUserInfo && item['__username'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	} // filterItems

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}
}
