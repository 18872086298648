import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
// import ValidationTools from 'appshared/validation-tools';
// import SharedLicenseTools from 'appshared/shared-license-tools';
// import LicenseValidationTools from 'appshared/license-validation-tools';

import { AuthService } from 'client/app/services/auth.service';

@Component({
	selector: 'app-card-organization',
	templateUrl: './card-organization.component.html',
	styleUrls: ['./card-organization.component.scss']
})
export class CardOrganizationComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.Organization = null;
	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.Organization = null;

	// options


	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];


	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.Organization) {

		this.objectToShow = MiscTools.deepClone(object);

		if (!this.objectToShow) return;

		this.nameToShow = this.objectToShow.name;
		this.typeToShow = 'Organization';
		this.iconToShow = AppConstants.sectionIcons.organizations;

		this.infoLines = [];

		if (this.objectToShow.is_enabled === 1)
			this.infoLines.push('Enabled: Yes');
		else
			this.infoLines.push('Enabled: No');

		this.infoLines.push('Type: ' + AppConstants.organizationTypeLabels[this.objectToShow.otype]);

		if (!!this.objectToShow.salesforce_account_id && this.objectToShow.salesforce_account_id !== '')
			this.infoLines.push('Salesforce Account: ' + this.objectToShow.salesforce_account_id);

		if (!!this.objectToShow.salesforce_account_owner && this.objectToShow.salesforce_account_owner !== '')
			this.infoLines.push('Account Owner: ' + this.objectToShow.salesforce_account_owner);

		if (!!this.objectToShow.salesforce_se && this.objectToShow.salesforce_se !== '')
			this.infoLines.push('SE: ' + this.objectToShow.salesforce_se);

		if (!!this.objectToShow.salesforce_tam && this.objectToShow.salesforce_tam !== '')
			this.infoLines.push('TAM: ' + this.objectToShow.salesforce_tam);

		if (this.objectToShow.email_domains && this.objectToShow.email_domains !== '')
			this.infoLines.push('E-Mail Domain(s): ' + this.objectToShow.email_domains);

		if (this.objectToShow.num_users && +this.objectToShow.num_users !== 0) this.infoLines.push('# Users: ' + +this.objectToShow.num_users);
		if (this.objectToShow.num_keys && +this.objectToShow.num_keys !== 0) this.infoLines.push('# Keys: ' + +this.objectToShow.num_keys);
		if (this.objectToShow.num_billing_codes && +this.objectToShow.num_billing_codes !== 0) this.infoLines.push('# Billing Codes: ' + +this.objectToShow.num_billing_codes);
		if (this.objectToShow.num_zen_domains && +this.objectToShow.num_zen_domains !== 0) this.infoLines.push('# ZEN Sites: ' + +this.objectToShow.num_zen_domains);
		if (this.objectToShow.num_partnerships && +this.objectToShow.num_partnerships !== 0) this.infoLines.push('# Partnerships: ' + +this.objectToShow.num_partnerships);

		if (!!this.objectToShow.information && this.objectToShow.information !== '')
			this.infoLines.push('Information: ' + this.objectToShow.information);

		let addedByName = '';
		// if (this.objectToShow.added_by && +this.objectToShow.added_by > 0 && users && users.length > 0) {
		// 	const idx = MiscTools.findIndex(users, +this.objectToShow.added_by);
		// 	if (idx !== -1)
		// 		addedByName = ' by ' + users[idx].name;
		// } // if
		this.infoLines.push('Added: ' + TextTools.formatDateNiceUTC(this.objectToShow.added_on) + addedByName);
		if (this.objectToShow.last_download)
			this.infoLines.push('Last Download: ' + TextTools.formatDateNiceUTC(this.objectToShow.last_download));

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
