import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { PlatformsService } from '../platforms.service';
import { ProductsService } from '../../products/products.service';
import { AuthService } from 'client/app/services/auth.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-platform',
	templateUrl: './platform.component.html',
	styleUrls: ['./platform.component.scss']
})
export class PlatformComponent implements OnInit, OnDestroy {
	ac = AppConstants;

	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	// 'standard' view stuff
	id: number;
	platform: Models.Platform;
	loading = true;
	private listSubscription: Subscription;

	// other stuff
	products: Models.Product[] = [];

	private userSubscription: Subscription;
	canEdit = false;
	canDelete = false;

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private usersService: UsersService,
		private platformsService: PlatformsService,
		private productsService: ProductsService) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.platform = this.platformsService.getOne(this.id);
			if (!this.platform || this.platform == null || this.platform.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.platformsService.refreshOne(this.id);
			}
		});
	}

	ngOnInit(): void {
		this.listSubscription = this.platformsService.platforms.subscribe(platforms => {
			this.platform = platforms.find((platform: Models.Platform) => platform.id === this.id);
		});

		this.userSubscription = this.authService.user.subscribe(authUser => {
			this.canEdit = authUser && ValidationTools.checkAccess(authUser, 'manage-platforms');
			this.canDelete = this.canEdit && authUser && ValidationTools.checkAccess(authUser, 'delete-platforms');
			this.loadExtras();
		});
	}

	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.listSubscription) this.listSubscription.unsubscribe();
	}

	loadExtras() {
		this.addedByUser = null;
		if (this.platform.added_by && this.platform.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.platform.added_by);

		this.editedByUser = null;
		if (this.platform.edited_by && this.platform.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.platform.edited_by);

		if (this.platform.product_ids) {
			for (const pid of this.platform.product_ids) {
				const product = this.productsService.getOne(pid);
				if (product !== null) {
					this.products.push(product);
				}
			}
		}

		this.loading = false;
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete Platform',
			'If you delete this Platform, it will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Platform', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		// Unsubscribe from list before delete or it will attempt to find now deleted item before navigation away
		if (this.listSubscription) this.listSubscription.unsubscribe();

		const result = await this.platformsService.deleteOne(this.platform.id);
		if (result) {
			this.uiAlertsService.addMsg('The platform (' + this.platform.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.platforms]);
		} else {
			return false;
		}
	}

	getProductsURL() {
		return '/' + AppConstants.urls.products;
	}
	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
