import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { ProductsService } from '../products.service';
import { PlatformsService } from '../../platforms/platforms.service';
import { BuildsService } from '../../builds/builds.service';
import { DocumentsService } from '../../documents/documents.service';
import { OrganizationsService } from '../../organizations/organizations.service';
import { PackagesService } from '../../packages/packages.service';
import { AuthService } from 'client/app/services/auth.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UsersService } from '../../users/users.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { ReportsService } from '../../reports/reports.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { OrganizationsTableComponent } from 'client/app/components/shared/organizations-table/organizations-table.component';
import { BuildsTableComponent } from 'client/app/components/shared/builds-table/builds-table.component';
import { DocumentsTableComponent } from 'client/app/components/shared/documents-table/documents-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild('buildsTable1') buildsTable1: BuildsTableComponent = null;
	@ViewChild('orgsTable1') orgsTable1: OrganizationsTableComponent = null;
	@ViewChild('documentsTable1') documentsTable1: DocumentsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	// showPostLoadElements = false;

	// 'standard' view stuff
	id: number;
	product: Models.Product;

	// other stuff
	canEdit = false;
	canDelete = false;

	private userSubscription: Subscription;

	platforms: Models.Platform[] = [];
	builds: Models.Build[] = [];
	documents: Models.Document[] = [];
	organizations: Models.Organization[] = [];
	packages: Models.Package[] = [];
	adminLogs: Models.AdminLog[] = [];

	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	flagsToShow: string[] = [];

	canSendNotifications = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private uiAlertsService: UiAlertsService,
		private productsService: ProductsService,
		private platformsService: PlatformsService,
		private buildsService: BuildsService,
		private organizationsService: OrganizationsService,
		private packagesService: PackagesService,
		private documentsService: DocumentsService,
		private usersService: UsersService,
		private reportsService: ReportsService,
		private adminLogsService: AdminLogsService
	) {

		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.product = this.productsService.getOne(this.id);
			// this.loadExtras();
			if (!this.product || this.product == null || this.product.id === 0) {
				this.router.navigate([AppConstants.urls.notfound]);
			} else {
				this.userSubscription = this.authService.user.subscribe(authUser => {
					this.canEdit = authUser && ValidationTools.checkAccess(authUser, 'manage-products');
					this.canDelete = this.canEdit && authUser && ValidationTools.checkAccess(authUser, 'delete-products');
					this.canSendNotifications = (authUser && ValidationTools.checkAccess(authUser, 'send-general-notifications'));
					this.loadData();
				});
			}
		});
	}


	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
	}

	async loadData() {
		this.loading = true;

		this.flagsToShow = [];
		for (const flag of AppConstants.productFlags)
			if (this.product.flags && this.product.flags![flag.key] && +this.product.flags[flag.key] === 1)
				this.flagsToShow.push(flag.label);


		if (this.product.platform_ids) {
			const allPlatforms = this.platformsService.getAll();
			this.platforms = MiscTools.sortPlatforms(allPlatforms, this.product.platform_ids);
		}

		if (this.product.build_ids) {
			for (const bid of this.product.build_ids) {
				const build = this.buildsService.getOne(bid);
				if (build !== null) {
					this.builds.push(build);
				}
			}
		}

		if (this.product.document_ids) {
			for (const did of this.product.document_ids) {
				const document = this.documentsService.getOne(did);
				if (document !== null) {
					this.documents.push(document);
				}
			}
		}

		const allOrgs = this.organizationsService.getAll();
		this.organizations = [];
		for (const org of allOrgs) {
			const idx = MiscTools.findIndexGeneric(org.product_platforms, 'product_id', this.id);
			if (idx !== -1)
				this.organizations.push(org);
		}

		const allPkgs = this.packagesService.getAll();
		this.packages = [];
		for (const pkg of allPkgs) {
			const idx = MiscTools.findIndexGeneric(pkg.product_platforms, 'product_id', this.id);
			if (idx !== -1)
				this.packages.push(pkg);
		}

		this.adminLogs = await this.adminLogsService.getLogs(['product'], this.id);

		this.addedByUser = null;
		if (this.product.added_by && this.product.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.product.added_by);

		this.editedByUser = null;
		if (this.product.edited_by && this.product.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.product.edited_by);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-product-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });
		if (this.buildsTable1)
			this.buildsTable1.updateContent(this.builds, 'cp-product-builds-list', { showProduct: false });
		if (this.orgsTable1)
			this.orgsTable1.updateContent(this.organizations, 'cp-product-orgs-list', { showFavorite: false, showSalesEng: false, showSalesRep: false });
		if (this.documentsTable1)
			this.documentsTable1.updateContent(this.documents, 'cp-product-documents-list', { showProduct: false });
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete Product',
			'If you delete this Product, it, along with all related Builds and Documents, will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Product', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.productsService.deleteOne(this.product.id);
		if (result) {
			this.uiAlertsService.addMsg('The product (' + this.product.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.products]);
		} else {
			return false;
		}
	}

	getPlatformIDsFromPlatformFiles(platformFiles: Models.PlatformFile[]): number[] {
		const ids = [];
		for (const pf of platformFiles)
			ids.push(pf.platform_id);
		return ids;
	}

	getPlatformIdsForProduct(productId: number, productPlatforms: Models.ProductPlatform[]): number[] {
		const pids = [];
		for (const pp of productPlatforms)
			if (pp.product_id === productId)
				pids.push(pp.platform_id);
		return pids;
	}

	async openDownloadsReport() {
		let args = 'id=' + this.id;
		await this.reportsService.runReport('ProductDownloadsReport', args);
	}

	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'XXXXX' && args.length === 1)
					console.log('args=' + args);

				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
