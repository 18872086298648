import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';
import ValidationTools from 'appshared/validation-tools';
import TextTools from 'appshared/text-tools';

import { AuthService } from 'client/app/services/auth.service';
import { UiAlertsService } from 'client/app/components/ui-alerts/ui-alerts.service';
import { QuizzesService } from '../quizzes.service';
import { AdminLogsService } from '../../admin-logs/admin-logs.service';
import { UsersService } from '../../users/users.service';
import { UserGroupsService } from '../../users/user-groups.service';

import { LogsTableComponent } from 'client/app/components/shared/logs-table/logs-table.component';
import { PopupBoxComponent } from 'client/app/components/shared/popup-box/popup-box.component';

@Component({
	selector: 'app-quiz-view',
	templateUrl: './quiz-view.component.html',
	styleUrls: ['./quiz-view.component.scss']
})

export class QuizViewComponent implements OnInit, OnDestroy {
	ac = AppConstants;
	tt = TextTools;
	mt = MiscTools;

	@ViewChild('logsTable1') logsTable1: LogsTableComponent = null;
	@ViewChild(PopupBoxComponent) popupBox: PopupBoxComponent = null;

	loading = true;
	loadingResults = false;

	// 'standard' view stuff
	authUser: Models.AuthUser;
	id: number;
	quiz: Models.Quiz;
	private userSubscription: Subscription;

	canManageThisQuiz = false;
	adminLogs: Models.AdminLog[] = [];
	addedByUser: Models.User = null;
	editedByUser: Models.User = null;

	adminUsers: Models.User[] = [];
	users: Models.User[] = [];
	groups: Models.UserGroup[] = [];

	userIdToDelete: number = -1;

	canTakeQuiz: boolean = false;

	respondants: Models.User[] = [];

	overviewMode: string = 'checks-basic';

	autoRefreshTimer: any = null;
	autoRefreshTimeSecs = 15;

	myResponse: Models.QuizResponse = null;

	processedResults: any = {};
	answerFrequencies: any = {};

	currentQuestion: number = -1
	showAllQuestions: boolean = false;
	hasAnswers: boolean = false;

	canAdd = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private usersService: UsersService,
		private userGroupsService: UserGroupsService,
		private quizzesService: QuizzesService,
		private uiAlertsService: UiAlertsService,
		private adminLogsService: AdminLogsService
	) {
		this.route.paramMap.subscribe(params => {
			this.id = +params.get('id');
			this.userSubscription = this.authService.user.subscribe((authUser) => {
				this.authUser = authUser;
				this.canAdd = authUser && (ValidationTools.checkAccess(authUser, 'manage-quizzes') || ValidationTools.checkAccess(authUser, 'add-quizzes'));
				this.loadData();
			});
		});
	}

	// ************************************************************************************
	ngOnInit(): void {
		// changes

	}

	// ************************************************************************************
	ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.autoRefreshTimer) this.autoRefreshTimer = null;
	}

	// ************************************************************************************
	async loadData() {
		this.loading = true;


		await this.reloadResults();

		this.canManageThisQuiz = ValidationTools.checkAccess(this.authUser, 'manage-quizzes')
			|| this.quiz.added_by === this.authUser.id
			|| (this.quiz.config.adminIds && this.quiz.config.adminIds.includes(this.authUser.id));

		if (!this.canManageThisQuiz) {
			this.router.navigate([AppConstants.urls.quizzes]);
			return;
		} // if

		this.hasAnswers = this.quiz.config.quizType === 'quiz';

		if (this.quiz.config.questions && this.quiz.config.questions.length > 0) {
			this.currentQuestion = 0;
			if (this.canManageThisQuiz)
				this.canTakeQuiz = this.quiz.status !== 'complete';
			else
				this.canTakeQuiz = this.quiz.status === 'active';
		} // if

		this.addedByUser = null;
		if (this.quiz.added_by && this.quiz.added_by !== 0)
			this.addedByUser = this.usersService.getOne(this.quiz.added_by);

		this.editedByUser = null;
		if (this.quiz.edited_by && this.quiz.edited_by !== 0)
			this.editedByUser = this.usersService.getOne(this.quiz.edited_by);

		this.adminUsers = [];
		if (this.quiz.config.adminIds) {
			for (const uid of this.quiz.config.adminIds) {
				const u = this.usersService.getOne(uid);
				if (u) {
					this.adminUsers.push(u);
				}
			}
			this.adminUsers.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.users = [];
		if (this.quiz.config.userIds) {
			for (const uid of this.quiz.config.userIds) {
				const u = this.usersService.getOne(uid);
				if (u) {
					this.users.push(u);
				}
			}
			this.users.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.groups = [];
		if (this.quiz.config.groupIds) {
			for (const gid of this.quiz.config.groupIds) {
				const g = this.userGroupsService.getOne(gid);
				if (g) {
					this.groups.push(g);
				}
			}
			this.groups.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}

		this.adminLogs = await this.adminLogsService.getLogs(['quiz'], this.id);

		// if (!this.autoRefreshTimer && this.canManage && this.quiz.status === 'active')
		// 	this.autoRefreshTimer = setInterval(this.reloadResults.bind(this), this.autoRefreshTimeSecs * 1000);

		this.loading = false;

		await MiscTools.delay(100);
		if (this.logsTable1)
			this.logsTable1.updateContent(this.adminLogs, 'cp-quiz-view-admin-logs', { showUserInfo: true, showObjectInfo: false, linkObject: false, linkUser: true });

	}

	// ************************************************************************************
	async reloadResults(resetRespondants: boolean = false) {
		this.loadingResults = true;
		this.quiz = await this.quizzesService.getOne(this.id);
		if (!this.quiz || this.quiz == null || this.quiz.id === 0) {
			this.router.navigate([AppConstants.urls.notfound]);
			return;
		} // if

		if (this.quiz.responses) {
			this.processedResults = {};
			this.answerFrequencies = {};
			for (const q of this.quiz.config.questions)
				this.answerFrequencies[q.id] = [];

			if (resetRespondants) this.respondants = [];
			for (const resp of this.quiz.responses) {
				if (MiscTools.findIndex(this.respondants, resp.user_id) == -1) {
					const u = this.usersService.getOne(resp.user_id);
					this.respondants.push(u);
				} // if
			} // for
			this.respondants.sort((a, b) => (a.name > b.name) ? 1 : -1);

			for (const r of this.respondants) {
				let response: Models.QuizResponse = MiscTools.pickItem(this.quiz.responses, 'user_id', r.id);
				if (!response) response = new Models.QuizResponse(this.quiz.id, r.id, '', {});
				for (const q of this.quiz.config.questions) {
					let answersAsString: string = '';
					let isCorrect: boolean = false;
					try {
						if (q.answerType === 'multi-choice-multi') {
							let answers: string[] = [];
							if (response != null && response.answers && response.answers[q.id])
								answers = response.answers[q.id];

							let cleanAnswers: string[] = [];
							for (const answer of answers)
								cleanAnswers.push(answer.trim().toLowerCase());

							let numCorrect: number = 0;
							for (const correctAnswer of q.answers)
								if (cleanAnswers.includes(correctAnswer.toLowerCase().trim()))
									numCorrect++;
							isCorrect = numCorrect === q.answers.length;
							answersAsString = answers.join(', ');
						} else {
							if (response != null && response.answers && response.answers[q.id])
								answersAsString = response.answers[q.id];
							if (answersAsString) answersAsString = answersAsString.trim();
							for (const correctAnswer of q.answers)
								if (answersAsString && answersAsString.toLowerCase().trim() === correctAnswer.toLowerCase().trim())
									isCorrect = true;
						} // if
					} catch (e) { }

					if (this.quiz.config.quizType !== 'quiz') isCorrect = true;

					this.processedResults[q.id + '-' + response.user_id] = {
						answer: answersAsString,
						isCorrect
					};

					let freqAnswer: string = answersAsString;
					if (answersAsString.trim() === '') freqAnswer = '*no answer provided*';

					let idx: number = MiscTools.findIndexGeneric(this.answerFrequencies[q.id], 'answer', freqAnswer);
					if (idx === -1) {
						this.answerFrequencies[q.id].push(
							{
								answer: freqAnswer,
								count: 0
							}
						);
						idx = this.answerFrequencies[q.id].length - 1;
					} // if
					this.answerFrequencies[q.id][idx].count++;
				} // for
			} // for

			for (const q of this.quiz.config.questions)
				this.answerFrequencies[q.id].sort((a, b) => (a.count < b.count) ? 1 : -1);

			this.myResponse = MiscTools.pickItem(this.quiz.responses, 'user_id', this.authUser.id);
		} // if
		this.loadingResults = false;
	}

	// ------------------------------------------------------------------------
	prepDelete() {
		this.popupBox.openPopup('confirm-text', 'delete', [], 'Delete Quiz',
			'If you delete this Quiz, it will be permanently deleted along with any answers that have been provided and it cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Quiz', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ------------------------------------------------------------------------
	async delete() {
		this.loading = true;

		const result = await this.quizzesService.deleteOne(this.quiz.id);
		if (result) {
			this.uiAlertsService.addMsg('The quiz (' + this.quiz.name + ') has been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			this.router.navigate([AppConstants.urls.quizzes]);
		} else {
			return false;
		}
	}

	// ------------------------------------------------------------------------
	prepDeleteResponse(userId: number) {
		this.userIdToDelete = userId;

		this.popupBox.openPopup('confirm-text', 'deleteResponse', [], 'Delete Responses',
			'If you delete <span class="fw-bold">' + this.getUsername(this.userIdToDelete) + '\'s</span> responses, they will be permanently deleted and cannot be recovered.',
			null,
			{ confirmButtonText: 'Delete Responses', rejectButtonText: 'Cancel', confirmText: 'delete' });
	}

	// ************************************************************************************
	async deleteResponse() {
		this.loading = true;

		const result = await this.quizzesService.deleteResponse(this.quiz.id, this.userIdToDelete);
		if (result) {
			await this.reloadResults(true);
			this.uiAlertsService.addMsg('The response(s) for this user have been deleted.',
				'info', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
		} else {
			return false;
		}
		this.loading = false;
	}

	// ************************************************************************************
	getQuestionCount(questionId: string, whichCount: string): number {
		let theCount: number = 0;
		for (const r of this.respondants) {
			const key: string = questionId + '-' + r.id;
			if (whichCount === 'answered') {
				if (this.processedResults[key] && this.processedResults[key].answer !== '') theCount++;
			} else if (whichCount === 'no-response') {
				if (this.processedResults[key] && this.processedResults[key].answer === '') theCount++;
			} else if (whichCount === 'correct') {
				if (this.processedResults[key] && this.processedResults[key].isCorrect) theCount++;
			} else if (whichCount === 'incorrect') {
				if (this.processedResults[key] && this.processedResults[key].answer !== '' && !this.processedResults[key].isCorrect) theCount++;
			} // if
		} // for
		return theCount;
	}

	// ************************************************************************************
	getRespondantCount(userId: number, whichCount: string): number {
		let theCount: number = 0;
		for (const q of this.quiz.config.questions) {
			const key: string = q.id + '-' + userId;
			if (whichCount === 'answered') {
				if (this.processedResults[key] && this.processedResults[key].answer !== '') theCount++;
			} else if (whichCount === 'no-response') {
				if (this.processedResults[key] && this.processedResults[key].answer === '') theCount++;
			} else if (whichCount === 'correct') {
				if (this.processedResults[key] && this.processedResults[key].isCorrect) theCount++;
			} else if (whichCount === 'incorrect') {
				if (this.processedResults[key] && this.processedResults[key].answer !== '' && !this.processedResults[key].isCorrect) theCount++;
			} // if
		} // for
		return theCount;
	}

	// ************************************************************************************
	getUsername(userId: number): string {
		let username: string = 'user # ' + userId;
		const userObj: Models.User = MiscTools.pickItem(this.respondants, 'id', userId);
		if (userObj) username = userObj.name;
		return username;
	}

	// ************************************************************************************
	niceBoolean2(value: boolean): string {
		if (value)
			return 'Yes';
		else
			return 'No';
	}

	// ************************************************************************************
	// nice percent
	np(num: number, denom: number): string {
		if (denom === 0) return '';
		const perc = num / denom * 100;
		return perc.toFixed(0).toString();
	}
	// ------------------------------------------------------------------------
	// Back and Forth with components
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			popupCallBack: async (callBack: string, args: any) => {
				if (callBack === 'delete' && args.length === 0)
					this.delete();
				else if (callBack === 'deleteResponse' && args.length === 0)
					this.deleteResponse();
				else
					this.uiAlertsService.addMsg('Unknown callBack (' + callBack + ') or bad number of arguments (' + args.length + ').', 'danger', '', false, AppConstants.standardMessageAutoCloseTimeSecs);
			}
		}
	} // getParentMethod
}
