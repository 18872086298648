<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.platforms" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="..">Platforms</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ platform.name }}
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<div class="btn-group">
				<button *ngIf="canEdit" type="button" class="btn btn-outline-primary" [routerLink]="['edit']" ngbTooltip="Edit"
					placement="start">
					<fa-icon icon="pencil" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Edit</span> -->
				</button>

				<button *ngIf="canDelete" type="button" class="btn btn-outline-danger" (click)="prepDelete()" ngbTooltip="Delete"
					placement="start">
					<fa-icon [icon]="['fas', 'trash-alt']" size="sm"></fa-icon>
					<!-- <span class="cp-action-button-text">Delete</span> -->
				</button>
			</div>
		</div>
	</div>
</div>

<div class="page-wrapper">
	<div *ngIf="!!platform" class="cp-property-list">
		<div class="cp-property-block">
			<span class="cp-property-label">Name:</span>
			<span class="cp-property-value">{{ platform.name }}</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Type:</span>
			<span class="cp-property-value">{{ ac.platformTypeLabels[platform.ptype] }}</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Filename Keyword (for auto-builds):</span>
			<span class="cp-property-value">{{ platform.filename_keyword }}</span>
		</div>

		<div class="cp-property-block" *ngIf="!!platform.information && platform.information !== ''">
			<span class="cp-property-label">Information:</span>
			<span class="cp-property-value cp-pre-text">{{ platform.information }}</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Added:</span>
			<span class="cp-property-value">
				{{ platform.added_on | date: ac.pageDateTimeFmt }}
				<span *ngIf="addedByUser"> by
					<a [routerLink]="['/' + ac.urls.users, addedByUser.id  ]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ addedByUser.name }}</a>
					<ng-template #userPopupContent>
						<app-card-user [object]="addedByUser"></app-card-user>
					</ng-template>
				</span>
			</span>
		</div>

		<div class="cp-property-block" *ngIf="platform.added_on !== platform.edited_on">
			<span class="cp-property-label">Edited:</span>
			<span class="cp-property-value">
				{{ platform.edited_on | date: ac.pageDateTimeFmt }}
				<span *ngIf="editedByUser"> by
					<a [routerLink]="['/' + ac.urls.users, editedByUser.id  ]" [ngbPopover]="userPopupContent" popoverClass="cp-popover-std"
						triggers="mouseenter:mouseleave" container="body">
						{{ editedByUser.name }}</a>
					<ng-template #userPopupContent>
						<app-card-user [object]="editedByUser"></app-card-user>
					</ng-template>
				</span>
			</span>
		</div>

		<div class="cp-property-block">
			<span class="cp-property-label">Products</span>
			<ul class="mb-0">
				<li *ngFor="let item of products">
					<a [routerLink]="[getProductsURL(), item.id]">{{ item.name }}</a>
				</li>
			</ul>
		</div>
	</div>
</div>

<app-popup-box [parentApi]="getParentMethod()"></app-popup-box>