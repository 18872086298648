import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import TextTools from 'appshared/text-tools';
import MiscTools from 'appshared/misc-tools';
// import ValidationTools from 'appshared/validation-tools';
// import SharedLicenseTools from 'appshared/shared-this.objectToShow-tools';
// import LicenseValidationTools from 'appshared/this.objectToShow-validation-tools';

import { AuthService } from 'client/app/services/auth.service';
import SharedLicenseTools from 'appshared/shared-license-tools';

@Component({
	selector: 'app-card-license',
	templateUrl: './card-license.component.html',
	styleUrls: ['./card-license.component.scss']
})

export class CardLicenseComponent implements OnInit {
	@Input() parentApi !: any;
	@Input() object: Models.LPLicense = null;
	@Input() options: any = {};

	ac = AppConstants;
	tt = TextTools;
	mt = TextTools;

	now = new Date();

	debug: boolean = false;
	debugTime = new Date();
	loading: boolean = false;
	working: boolean = false;
	authUser: Models.AuthUser = null;

	objectToShow: Models.LPLicense = null;

	// options

	nameToShow: string = '[name]';
	typeToShow: string = '[type]';
	iconToShow: any = null;
	infoLines: string[] = [];
	extraLines: string[] = [];


	// ------------------------------------------------------------------------
	constructor(
		private router: Router,
		private authService: AuthService) {

		// this.router.routeReuseStrategy.shouldReuseRoute = function () {
		// 	return false;
		// };
	} // 

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const sessionUser: Models.AuthUser = this.authService.getUser();
		if (sessionUser != null && sessionUser.id !== 0) {
			this.authUser = sessionUser;
			this.setups();
		} // if
	}

	// ------------------------------------------------------------------------
	async setups() {
		this.loading = true;

		let optionKeys: string[] = [];
		if (this.options) optionKeys = Object.keys(this.options);

		// if (optionKeys.includes('includeClickInfo')) this.includeClickInfo = this.options.includeClickInfo;
		// if (optionKeys.includes('extraStyles')) this.extraStyles = this.options.extraStyles;
		// if (optionKeys.includes('extraClasses')) this.extraClasses = this.options.extraClasses;
		// if (optionKeys.includes('dark')) this.dark = this.options.dark;
		// if (optionKeys.includes('superscriptLevels')) this.superscriptLevels = this.options.superscriptLevels;

		this.setObject(this.object);
	}

	// ------------------------------------------------------------------------
	async setObject(object: Models.LPLicense) {

		this.objectToShow = MiscTools.deepClone(object);

		if (!this.objectToShow) return;

		this.nameToShow = this.objectToShow.hostid;
		this.typeToShow = 'License';
		this.iconToShow = AppConstants.sectionIcons.licensing;

		this.infoLines = [];


		const lines: string[] = [];

		this.infoLines.push('Issued: ' + TextTools.formatDateNiceUTC(this.objectToShow.created_at));

		if (this.objectToShow.updated_at)
			this.infoLines.push('Updated: ' + TextTools.formatDateNiceUTC(this.objectToShow.updated_at));

		if (!this.objectToShow.expires_at) {
			this.infoLines.push('Expires: Never - Permanent License');
		} else if (!MiscTools.hasExpired(this.objectToShow.expires_at)) {
			this.infoLines.push('Expires: ' + TextTools.formatDateNiceUTC(this.objectToShow.expires_at));
		} else {
			this.infoLines.push('Expired: ' + TextTools.formatDateNiceUTC(this.objectToShow.expires_at));
		} // if

		if (this.objectToShow.ip) this.infoLines.push('IP: ' + this.objectToShow.ip);

		const parsedParameters = SharedLicenseTools.parseRubyHash(this.objectToShow.parameters);

		const licenseMeterTypes: string[] = [];
		for (const meterType of AppConstants.meterProducts)
			if (parsedParameters[meterType])
				licenseMeterTypes.push(meterType);

		const meterNames = [];
		for (const licenseMeterType of licenseMeterTypes) {
			const niceName = SharedLicenseTools.niceProtocol(licenseMeterType, true);
			if (!meterNames.includes(niceName)) meterNames.push(niceName);
		} // for
		meterNames.sort();

		if (meterNames.length > 0)
			this.infoLines.push('Meter Type(s) Active in License: ' + meterNames.join('; '));

		if (this.objectToShow.first_meter_report && this.objectToShow.last_meter_report)
			this.infoLines.push('Meter Reporting: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_meter_report)
				+ ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_meter_report));

		if (this.objectToShow.first_meter_usage && this.objectToShow.last_meter_usage)
			this.infoLines.push('Meter Usage: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_meter_usage)
				+ ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_meter_usage));

		if (this.objectToShow.first_protocol_report && this.objectToShow.first_protocol_report)
			this.infoLines.push('Protocol Reporting: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_protocol_report)
				+ ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_protocol_report));

		if (this.objectToShow.first_protocol_usage && this.objectToShow.last_protocol_usage)
			this.infoLines.push('Protocol Usage: ' + TextTools.formatDateNiceUTC(this.objectToShow.first_protocol_usage)
				+ ' to ' + TextTools.formatDateNiceUTC(this.objectToShow.last_protocol_usage));

		if (this.objectToShow.last_protocol_bx_version && this.objectToShow.last_protocol_bx_version !== '')
			this.infoLines.push('Last Reported Version: ' + TextTools.cleanVersion(this.objectToShow.last_protocol_bx_version));

		if (this.objectToShow.extraProperties) {
			const niceExtras: string = SharedLicenseTools.niceLicenseExtraProperties(this.objectToShow.extraProperties);
			if (niceExtras && niceExtras !== '') {
				this.infoLines.push('Extra Properties');
				const splitExtras: string[] = niceExtras.split('\n');
				for (const e of splitExtras)
					this.infoLines.push(AppConstants.bullet + ' ' + e);
			} // if
		} // if

		this.extraLines = [];
	} //

	// ------------------------------------------------------------------------
	clickButton(id: string, blurAfter: boolean = false) {
		this.debugMessage('click ' + id);
		if (document.getElementById(id)) {
			this.debugMessage('clicking ' + id);
			document.getElementById(id).click();
			if (blurAfter) document.getElementById(id).blur();
		} // if
	} // clickButton

	// ------------------------------------------------------------------------
	debugMessage(theMsg: string) {
		if (this.debug) {
			const now = new Date();
			const ms = now.getTime() - this.debugTime.getTime();
			console.log('con.primt.card ' + ms.toLocaleString() + ' - ' + theMsg);
		}
	} // debugMessage}

}
