<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item active" aria-current="page">
				<fa-icon [icon]="ac.sectionIcons.adminlogs" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				System Logs
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm" ngbTooltip="Filter" placement="start"></fa-icon>
				<!-- <span class="cp-action-button-text">Filter</span> -->
			</button>

			<button type="button" class="btn btn-outline-secondary" (click)="loadLogs()">
				<fa-icon icon="sync-alt" size="sm" [spin]="loading || refreshing" ngbTooltip="Refresh" placement="start">
				</fa-icon>
				<!-- <span class="cp-action-button-text">Refresh</span> -->
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<!-- <div>objTypes={{ objTypes }}</div>
	<div>actions={{ actions }}</div> -->

	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-4">
				<ng-select [items]="adminLogObjTypes" bindValue="value" bindLabel="label" id="objTypes" formControlName="objTypes"
					[multiple]="true" (change)="onFormChange()" placeholder="Filter by Object Type">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="ac.adminLogActions" bindValue="value" bindLabel="label" id="actions" formControlName="actions"
					[multiple]="true" (change)="onFormChange()" placeholder="Filter by Action">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="userGroupSelections" bindValue="ids" bindLabel="name" id="userGroups" formControlName="userGroups"
					[multiple]="false" (change)="pickGroupsUsers()" placeholder="Select Users From a Group">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<ng-select [items]="userSelections" bindValue="id" bindLabel="name" id="userIDs" formControlName="userIDs" [multiple]="true"
					(change)="onFormChange()" placeholder="Filter by User">
				</ng-select>
			</div>

			<div class="cp-filter-block-4">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>
					<input class="form-control" placeholder="Not Before yyyy-mm-dd" id="notBefore" formControlName="notBefore"
						firstDayOfWeek="7" ngbDatepicker #d1="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>

			<div class="cp-filter-block-4">
				<div class="input-group">
					<button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
						<fa-icon icon="calendar-alt"></fa-icon>
					</button>
					<input class="form-control" placeholder="Not After yyyy-mm-dd" id="notAfter" formControlName="notAfter" ngbDatepicker
						firstDayOfWeek="7" #d2="ngbDatepicker" (change)="onFormChange()" (dateSelect)="onFormChange()">
				</div>
			</div>
		</div>
	</form>

	<div *ngIf="loading || refreshing" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="maxMessage !== ''" class="cp-warning-message">{{ maxMessage }}</div>

	<div *ngIf="logs && !loading" class="mb-1">
		<button type="button" class="btn btn-outline-info btn-sm" (click)="expandNames = !expandNames">
			{{ (expandNames ? 'Hide' : 'Show') }} Full Object Names
		</button>
	</div>

	<app-logs-table #logsTable1 [expandNames]="expandNames"></app-logs-table>

</div>