<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.licensingadmin" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				Licensing
			</li>
			<li class="breadcrumb-item active" aria-current="page">Products</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button *ngIf="canManage" type="button" class="btn btn-outline-primary me-2" [routerLink]="['new']">
				<fa-icon [icon]="['fas', 'plus']" size="sm"></fa-icon>
				<span class="cp-action-button-text">New Product</span>
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<table *ngIf="!loading" class="cp-std-table cp-auto-width">
		<thead>
			<tr>
				<th>Name</th>
				<th>Label(Staff)</th>
				<th>Label(Basic)</th>
				<th>Description</th>
				<th ngbTooltip="Number of Properties">#</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let product of products" [routerLink]="[product.id]">
				<td class="border-end text-nowrap"><a [routerLink]="[product.id]">
						{{ product.name }}
					</a></td>
				<td class="border-end text-nowrap">{{ product.label }}</td>
				<td class="border-end text-nowrap">{{ product.basic_label }}</td>
				<td class="border-end">{{ product.description }}</td>
				<td>{{ product.num_properties }}</td>
			</tr>
		</tbody>
	</table>

</div>