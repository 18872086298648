import { NgModule } from '@angular/core';

import {
	FontAwesomeModule,
	FaIconLibrary,
} from '@fortawesome/angular-fontawesome';

// import { fas } from '@fortawesome/pro-solid-svg-icons';
// import { far } from '@fortawesome/pro-regular-svg-icons';

import {
	faAbacus,
	faArchive,
	faArrowAltToTop,
	faArrowCircleRight,
	faArrowRight,
	faArrowLeft,
	faArrowsRotate,
	faAngleDoubleDown,
	faAngleDoubleUp,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleUp,
	faAt,
	faBan,
	faBars,
	faBinoculars,
	faBolt,
	faBook,
	faBooks,
	faBoxOpen,
	faBroadcastTower,
	faBuilding,
	faBullhorn,
	faBullseyePointer,
	faCabinetFiling,
	faCalculator,
	faCalendarAlt,
	faChartLine,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faCircle,
	faCity,
	faClipboardListCheck,
	faClipboardQuestion,
	faClock,
	faClone,
	faCloud,
	faClouds,
	faCloudDownloadAlt,
	faCloudUploadAlt,
	faCog,
	faCogs,
	faCommentDots,
	faCopy,
	faCube,
	faDownload,
	faDumpsterFire,
	faEllipsisH,
	faEnvelope,
	faExchange,
	faExclamationTriangle,
	faExternalLinkSquareAlt,
	faEye,
	faEyeSlash,
	faFile,
	faFileArchive,
	faFileDownload,
	faFileExclamation,
	faFileExcel,
	faFileUpload,
	faFilter,
	faFlagCheckered,
	faGlasses,
	faGlobe,
	faGraduationCap,
	faHandPointLeft,
	faHandPointRight,
	faHandsHelping,
	faHashtag,
	faHistory,
	faHourglass,
	faIceCream,
	faInboxIn,
	faInboxOut,
	faInfinity,
	faInfoCircle,
	faKey,
	faKeySkeleton,
	faLayerPlus,
	faLifeRing,
	faLightbulbSlash,
	faLink,
	faList,
	faListUl,
	faLock,
	faLockOpen,
	faMailbox,
	faMinus,
	faMinusSquare,
	faOctagon,
	faObjectGroup,
	faPaperPlane,
	faPassport,
	faPencil,
	faPlayCircle,
	faPlus,
	faPlusSquare,
	faPowerOff,
	faPuzzlePiece,
	faQuestion,
	faQuestionCircle,
	faQuestionSquare,
	faRepeat,
	faRulerTriangle,
	faSave,
	faScroll,
	faSearch,
	faServer,
	faShareAlt,
	faShieldAlt,
	faSignInAlt,
	faSignOutAlt,
	faSlidersH,
	faSnooze,
	faSortAlt,
	faSquare,
	faStar,
	faStickyNote,
	faSun,
	faSync,
	faSyncAlt,
	faTable,
	faTasks,
	faTachometerAlt,
	faTemperatureUp,
	faTerminal,
	faTicketAlt,
	faTimes,
	faTimesCircle,
	faThumbsUp,
	faThumbsDown,
	faToggleOff,
	faToggleOn,
	faTractor,
	faTrashAlt,
	faTrashUndo,
	faUndo,
	faUnlink,
	faUpload,
	faUser,
	faUserCog,
	faUserLock,
	faUserPlus,
	faUsers,
	faUsersClass,
	faUsersMedical,
	faUsersSlash,
	faVolumeUp,
	faVolumeMute,
	faWandMagic,
	faWindowClose,
	faWrench,

	faBadgerHoney,
	faBeer,
	faBurgerSoda,
	faCatSpace,
	faCocktail,
	faCoffee,
	faDragon,
	faDreidel,
	faDuck,
	faFistRaised,
	faFutbol,
	faGhost,
	faGingerbreadMan,
	faGlobeAfrica,
	faGrinBeam,
	faHandSpock,
	faHatWizard,
	faNarwhal,
	faPastafarianism,
	faPepperHot,
	faPizzaSlice,
	faPortalEnter,
	faRabbit,
	faRobot,
	faSackDollar,
	faSkullCrossbones,
	faSnowman,
	faStarOfDavid,
	faUnicorn,
	faUserAlien,
	faUserAstronaut,
	faUserCowboy,
	faUserGraduate,
	faUserHardHat,
	faUserHeadset,
	faUserNinja,
	faYinYang,

	faMushroom,
	faOtter,
	faSheep,
	faMonkey,
	faDolphin,
	faTurtle,
	faLobster,
	faDinosaur,
	faSquid,
	faPersonPinball,
	faAlien8bit,
	faPool8Ball,
	faTRex
} from '@fortawesome/pro-solid-svg-icons';

import {
	faSalesforce,
	faLinux,
	faApple,
	faWindows,
	faRaspberryPi,
	faDocker,
	faTwitter,
	faLinkedin,
	faAws,
	faMicrosoft,
	faGoogle
} from '@fortawesome/free-brands-svg-icons';

@NgModule({
	declarations: [],
	exports: [FontAwesomeModule],
})
export class FontAwesome {
	constructor(library: FaIconLibrary) {
		// library.addIconPacks(fas, far, fab);
		// library.addIconPacks(fas, far);
		library.addIcons(
			faSalesforce,
			faLinux,
			faApple,
			faWindows,
			faRaspberryPi,
			faDocker,
			faTwitter,
			faLinkedin,
			faAws,
			faMicrosoft,
			faGoogle,

			faAbacus,
			faArchive,
			faArrowAltToTop,
			faArrowRight,
			faArrowLeft,
			faArrowsRotate,
			faArrowCircleRight,
			faAngleDoubleDown,
			faAngleDoubleUp,
			faAngleDown,
			faAngleLeft,
			faAngleRight,
			faAngleUp,
			faAt,
			faBan,
			faBars,
			faBinoculars,
			faBolt,
			faBook,
			faBooks,
			faBoxOpen,
			faBroadcastTower,
			faBuilding,
			faBullhorn,
			faBullseyePointer,
			faCabinetFiling,
			faCalculator,
			faCalendarAlt,
			faChartLine,
			faCheck,
			faCheckCircle,
			faCheckSquare,
			faCity,
			faCircle,
			faClipboardListCheck,
			faClipboardQuestion,
			faClock,
			faClone,
			faCloud,
			faClouds,
			faCloudDownloadAlt,
			faCloudUploadAlt,
			faCog,
			faCogs,
			faCommentDots,
			faCopy,
			faCube,
			faDownload,
			faDumpsterFire,
			faEllipsisH,
			faEnvelope,
			faExchange,
			faExclamationTriangle,
			faExternalLinkSquareAlt,
			faEye,
			faEyeSlash,
			faFile,
			faFileArchive,
			faFileDownload,
			faFileExcel,
			faFileExclamation,
			faFileUpload,
			faFilter,
			faFlagCheckered,
			faGlasses,
			faGlobe,
			faGraduationCap,
			faHandsHelping,
			faHandPointLeft,
			faHandPointRight,
			faHashtag,
			faHistory,
			faHourglass,
			faIceCream,
			faInboxIn,
			faInboxOut,
			faInfinity,
			faInfoCircle,
			faKey,
			faKeySkeleton,
			faLayerPlus,
			faLifeRing,
			faLightbulbSlash,
			faLink,
			faList,
			faListUl,
			faLock,
			faLockOpen,
			faMailbox,
			faMinus,
			faMinusSquare,
			faOctagon,
			faObjectGroup,
			faPaperPlane,
			faPassport,
			faPencil,
			faPlayCircle,
			faPlus,
			faPlusSquare,
			faPortalEnter,
			faPowerOff,
			faPuzzlePiece,
			faQuestion,
			faQuestionCircle,
			faQuestionSquare,
			faRepeat,
			faRulerTriangle,
			faSave,
			faScroll,
			faSearch,
			faServer,
			faShareAlt,
			faShieldAlt,
			faSignInAlt,
			faSignOutAlt,
			faSlidersH,
			faSnooze,
			faSortAlt,
			faSquare,
			faStar,
			faStickyNote,
			faSun,
			faSync,
			faSyncAlt,
			faTable,
			faTasks,
			faTachometerAlt,
			faTemperatureUp,
			faTerminal,
			faThumbsUp,
			faThumbsDown,
			faTicketAlt,
			faTimes,
			faTimesCircle,
			faToggleOff,
			faTractor,
			faToggleOn,
			faTrashAlt,
			faTrashUndo,
			faUndo,
			faUnlink,
			faUpload,
			faUser,
			faUserCog,
			faUserLock,
			faUserPlus,
			faUsers,
			faUsersClass,
			faUsersMedical,
			faUsersSlash,
			faVolumeUp,
			faVolumeMute,
			faWandMagic,
			faWindowClose,
			faWrench,

			faBadgerHoney,
			faBeer,
			faBurgerSoda,
			faCatSpace,
			faCocktail,
			faCoffee,
			faDragon,
			faDreidel,
			faDuck,
			faFistRaised,
			faFutbol,
			faGhost,
			faGingerbreadMan,
			faGlobeAfrica,
			faGrinBeam,
			faHandSpock,
			faHatWizard,
			faNarwhal,
			faPastafarianism,
			faPepperHot,
			faPizzaSlice,
			faRabbit,
			faRobot,
			faSackDollar,
			faSkullCrossbones,
			faSnowman,
			faStarOfDavid,
			faUnicorn,
			faUserAlien,
			faUserAstronaut,
			faUserCowboy,
			faUserGraduate,
			faUserHardHat,
			faUserHeadset,
			faUserNinja,
			faYinYang,
			faMushroom,
			faOtter,
			faSheep,
			faMonkey,
			faDolphin,
			faTurtle,
			faLobster,
			faDinosaur,
			faSquid,
			faPersonPinball,
			faAlien8bit,
			faPool8Ball,
			faTRex

		);
	}
}
