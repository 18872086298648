import { Component, OnInit, OnDestroy, Directive, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import AppConstants from 'appshared/app-constants';
import * as Models from 'appshared/shared-models';
import MiscTools from 'appshared/misc-tools';

import { ZenCustomersService } from '../zen-customers.service';
import { PagingSearchBarComponent } from 'client/app/components/shared/paging-search-bar/paging-search-bar.component';

@Component({
	selector: 'app-zen-customers-report-objects',
	templateUrl: './zen-customers-report-objects.component.html',
	styleUrls: ['./zen-customers-report-objects.component.scss']
})
export class ZenCustomersReportObjectsComponent implements OnInit {
	ac = AppConstants;
	mt = MiscTools;

	@ViewChild(PagingSearchBarComponent) pagingSearchBar: PagingSearchBarComponent = null;

	items: Models.ZenMasterObject[] = [];
	itemsToShow: Models.ZenMasterObject[] = [];
	zenCustomers: Models.ZenMasterCustomer[] = [];

	loading = true;

	showFilters = false;
	theForm: UntypedFormGroup;

	searchTerm = '';

	displayOptions: Models.TableDisplayOptions = new Models.TableDisplayOptions();
	storageKey: string = 'cp-zen-objects-report';

	columnDefs: any[] = [
		{
			field: '__prefix',
			type: 'baseText',
			sortType: 'text',
			label: 'Site',
			toolTip: null,
			icon: null
		}, {
			field: 'object_type',
			type: 'baseText',
			sortType: 'text',
			label: 'Object Type',
			toolTip: null,
			icon: null
		}, {
			field: 'name',
			type: 'baseText',
			sortType: 'text',
			label: 'Name',
			toolTip: null,
			icon: null
		}, {
			field: 'sub_type',
			type: 'baseText',
			sortType: 'text',
			label: 'Sub-Type',
			toolTip: null,
			icon: null
		}
	];

	fieldsToShow: string[] = [];

	constructor(
		private router: Router,
		private zenCustomersService: ZenCustomersService
	) { }

	// ------------------------------------------------------------------------
	ngOnInit(): void {
		const searchTerm = JSON.parse(localStorage.getItem('CLEAN.zenObjects.searchTerm'));
		if (searchTerm) this.searchTerm = searchTerm;

		this.initForm();
		this.loadZenObjects();
	}

	// ------------------------------------------------------------------------
	async loadZenObjects(forceReload = false) {
		this.loading = true;
		this.zenCustomers = this.zenCustomersService.getAll();

		this.filterZens();
	}

	// ------------------------------------------------------------------------
	initForm() {
		this.theForm = new UntypedFormGroup({
			searchTerm: new UntypedFormControl(this.searchTerm),
		});
	}

	// ------------------------------------------------------------------------
	async onFormChange() {
		// console.log(this.theForm.value.objTypes);
		// console.log(this.theForm.value);

		this.searchTerm = this.theForm.value.searchTerm;

		localStorage.setItem('CLEAN.zenObjects.searchTerm', JSON.stringify(this.searchTerm));

		this.filterZens();
	}

	// ------------------------------------------------------------------------
	async filterZens() {
		this.loading = true;

		// this.zenClustersToShow = this.zenClusters.slice();

		this.items = [];

		if (this.searchTerm && this.searchTerm !== '') {
			this.items = await this.zenCustomersService.searchObjects(this.searchTerm);
			for (const item of this.items) {
				const idx = MiscTools.findIndexGeneric(this.zenCustomers, 'id', +item.customer_id);
				if (idx !== -1)
					item['__prefix'] = this.zenCustomers[idx].dns_prefix;
			} // for
		}

		this.fieldsToShow = [];
		for (const cd of this.columnDefs)
			this.fieldsToShow.push(cd.field);

		this.loading = false;

		await MiscTools.delay(100);
		this.pagingSearchBar.setupPaging(this.items, this.columnDefs, this.storageKey, 'name', 'asc');
	}

	// ------------------------------------------------------------------------
	getSiteRoute(zenObjeect: Models.ZenMasterObject) {
		return ['/' + AppConstants.urls.zencustomers, zenObjeect.customer_id];
	}

	// ------------------------------------------------------------------------
	toggleFilters() {
		this.showFilters = !this.showFilters;
	}

	// ------------------------------------------------------------------------
	filterItems(matchWords: string[]): any[] {
		const tmpList: Models.ZenMasterObject[] = [];
		for (const item of this.items) {
			let match: boolean = true;
			if (matchWords.length > 0) {
				let matches: number = 0;
				for (const w of matchWords) {
					if ((item.object_type && item.object_type.toLowerCase().includes(w))
						|| (item.name && item.name.toLowerCase().includes(w))
						|| (item.sub_type && item.sub_type.toLowerCase().includes(w))
						|| (item['prefix'] && item['prefix'].toLowerCase().includes(w))
					)
						matches++;
				} // for
				if (matches !== matchWords.length) match = false;
			} // if

			if (match) tmpList.push(item);
		} // for
		return tmpList;
	}

	// ------------------------------------------------------------------------
	setSortBy(field: string) {
		this.pagingSearchBar.setSortBy(field);
	} // setSortBy

	// ------------------------------------------------------------------------
	// Catch calls from the paging/search bar
	// ------------------------------------------------------------------------
	getParentMethod(): any {
		return {
			filterItems: (matchWords: string[]) => {
				return this.filterItems(matchWords);
			},
			updateItemsToShow: (itemsToShow: any[], displayOptions: Models.TableDisplayOptions) => {
				this.itemsToShow = itemsToShow;
				this.displayOptions = displayOptions;
			}
		}
	}


}

