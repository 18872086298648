<div class="title-bar">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item">
				<fa-icon [icon]="ac.sectionIcons.zencustomers" class="cp-admin-nav-item me-1" size="sm">
				</fa-icon>
				<a routerLink="../..">ZEN Master Sites</a>
			</li>
			<li class="breadcrumb-item"><a routerLink="..">Reports</a></li>
			<li class="breadcrumb-item active" aria-current="page">
				Staff
				<span *ngIf="staffMode === 'owner'">by Account Owner</span>
				<span *ngIf="staffMode === 'techrep'">by SE</span>
			</li>
		</ol>
	</nav>

	<div class="actions">
		<div class="form-inline">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="toggleFilters()">
				<fa-icon icon="filter" size="sm"></fa-icon>
				<span class="cp-action-button-text">Filter</span>
			</button>
		</div>
	</div>
</div>

<button (click)="mt.scrollToSpot('top-of-list')" id="top-of-list-button" class="cp-scroll-to-top-end d-none" title="Go to top">
	<fa-icon [icon]="['fas', 'angle-up']"></fa-icon>Top
</button>
<div class="list-wrapper" id="list-page-id" (scroll)="mt.onDivScrollHandler('top-of-list-button', 'list-page-id')">
	<div id="top-of-list"></div>
	<form [formGroup]="theForm" *ngIf="showFilters">
		<div class="row ps-2 pe-2">
			<div class="cp-filter-block-3">
				<ng-select [items]="enabledChoices" bindValue="value" bindLabel="label" id="enabledFilter" formControlName="enabledFilter"
					[multiple]="false" (change)="onFormChange()" placeholder="Enabled/Disabled">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="zmTypeChoices" bindValue="value" bindLabel="label" id="zmTypeFilters" formControlName="zmTypeFilters"
					[multiple]="true" [closeOnSelect]="false" (change)="onFormChange()" placeholder="Filter by Type">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="assessmentChoices" bindValue="value" bindLabel="value" id="assessmentFilters"
					formControlName="assessmentFilters" [multiple]="true" [closeOnSelect]="false" (change)="onFormChange()"
					placeholder="Filter by Assessment">
				</ng-select>
			</div>

			<div class="cp-filter-block-3">
				<ng-select [items]="recentTrafficChoices" bindValue="value" bindLabel="label" id="recentTrafficFilters"
					formControlName="recentTrafficFilters" [multiple]="true" [closeOnSelect]="false" (change)="onFormChange()"
					placeholder="Filter by Recent Traffic">
				</ng-select>
			</div>
		</div>
	</form>

	<div *ngIf="loading" style="text-align: center;">
		<app-loading-spinner></app-loading-spinner>
	</div>

	<div *ngIf="!loading">
		<div>Showing {{ zenCustomersToShow.length }} ZEN Master Sites</div>

		<button type="button" class="btn btn-outline-success btn-sm mb-1" (click)="toggleMode()">
			<span *ngIf="staffMode === 'owner'">Sort/Group by SE</span>
			<span *ngIf="staffMode === 'techrep'">Sort/Group by Account Owner</span>
		</button>

		<div *ngIf="people.length !== 0" id="top">
			<span *ngFor="let person of people; let i = index">
				<button type="button" class="btn btn-outline-primary btn-sm mb-1 me-1" (click)="scrollToSpot('owner_' + i)">{{ person
					}}</button>
			</span>
		</div>

		<div *ngFor="let person of people; let i = index" [id]="'owner_' + i" class="mt-2 cp-wider-width-limit">
			<span class="fw-bold">
				<span *ngIf="staffMode === 'owner'">Account Owner: </span>
				<span *ngIf="staffMode === 'techrep'">SE: </span>
				{{ person }}
			</span>

			<button *ngIf="people.length > 1 && i !== 0" type="button" class="btn btn-outline-primary btn-sm ms-1"
				(click)="scrollToSpot('top')" ngbTooltip="Back to the Top">
				<fa-icon icon="arrow-alt-to-top" size="sm"></fa-icon>
			</button>

			<table class="cp-std-table">
				<thead>
					<th>Name (DNS)</th>
					<th>Organization</th>
					<th *ngIf="staffMode === 'techrep'">
						<fa-icon [icon]="['fab', 'salesforce']" size="sm" ngbTooltip="Account Owner" placement="bottom">
						</fa-icon>
					</th>
					<th *ngIf="staffMode === 'owner'">
						<fa-icon [icon]="['fas', 'user-graduate']" size="sm" ngbTooltip="SE" placement="bottom">
						</fa-icon>
					</th>
					<th>Type</th>
					<th>Assessment</th>
					<th class="cp-icon-header">
						<fa-icon [icon]="ac.sectionIcons.mynotifications" size="sm"
							ngbTooltip="At Least One User Will Get ZEN Master Notifications from ZCP" placement="bottom">
						</fa-icon>
					</th>
					<th class="cp-icon-header">
						<fa-icon [icon]="['fas', 'sign-in-alt']" size="sm" ngbTooltip="Days since last login" placement="bottom">
						</fa-icon>
					</th>
					<th class="cp-icon-header">
						<fa-icon [icon]="['fas', 'clock']" size="sm" ngbTooltip="Days since added" placement="bottom">
						</fa-icon>
					</th>
					<th class="cp-icon-header">
						<fa-icon [icon]="['fas', 'bolt']" size="sm" ngbTooltip="# Active Broadcasters" placement="bottom">
						</fa-icon>
					</th>
					<th class="cp-icon-header text-nowrap">
						<fa-icon [icon]="['fas', 'chart-line']" size="sm" placement="start"
							[ngbTooltip]="'ZEN Master (Non-MediaConnect) Usage (Last ' + ac.assessmentThresholdDays + ' Days)'">
						</fa-icon>
					</th>
					<th class="cp-icon-header text-nowrap">
						<fa-icon [icon]="['fas', 'chart-line']" size="sm" placement="start"
							[ngbTooltip]="'ZEN Master (MediaConnect) Usage (Last ' + ac.assessmentThresholdDays + ' Days)'">
						</fa-icon>
					</th>
				</thead>

				<tbody *ngFor="let classification of classifications">
					<tr *ngFor="let zc of reportData[person][classification]">
						<td class="border-end">
							<a [routerLink]="['/' + ac.urls.zencustomers, zc.id]">
								{{ zc.name }} ({{ zc.dns_prefix }})</a>
						</td>
						<td *ngIf="zc.organization" class="border-end">
							<a [routerLink]="['/' + ac.urls.organizations, zc.zcp_org_id]">
								{{ zc.organization.name }}</a>
						</td>
						<td *ngIf="!zc.organization" class="border-end">
						</td>

						<td *ngIf="staffMode === 'owner'" class="border-end" [title]="accountSEFull[zc.id]">
							{{ accountSEAbrevs[zc.id] }}
						</td>
						<td *ngIf="staffMode === 'techrep'" class="border-end" [title]="accountOwnerFull[zc.id]">
							{{ accountOwnerAbrevs[zc.id] }}
						</td>

						<td *ngIf="zc.zcp_type && zc.zcp_type !== ''" class="border-end">
							{{ ac.zenMasterTypeLabels[zc.zcp_type] }}
						</td>
						<td *ngIf="!zc.zcp_type || zc.zcp_type === ''" class="border-end">
						</td>
						<td class="border-end">{{ zc.assessment }}</td>

						<td class="cp-icon-cell border-end">
							<span class="cp-hidden-content">{{ notifyWarnings[zc.id] }}</span>
							<fa-icon *ngIf="notifyWarnings[zc.id]" [icon]="['fas', 'exclamation-triangle']" size="sm" class="text-warning"
								ngbTooltip="None of this site's users are setup to receive ZEN Master notifications from the portal">
							</fa-icon>
							<fa-icon *ngIf="!notifyWarnings[zc.id]" [icon]="['fas', 'check']" size="sm" class="text-success"
								ngbTooltip="At least one of this site's users is setup to receive ZEN Master notifications from the portal">
							</fa-icon>
						</td>

						<td class="border-end text-end">{{ zc.last_login_days | number }}</td>
						<td class="border-end text-end">{{ zc.created_days | number }}</td>
						<td class="border-end text-nowrap text-end">{{ zc.num_active_bx }}</td>
						<td class="text-nowrap text-end" [attr.data-order]="getUsage(zc, 'zenmaster')">
							{{ tt.formattedMB(getUsage(zc, 'zenmaster')) }}</td>
						<td class="text-nowrap text-end" [attr.data-order]="getUsage(zc, 'mediaconnect')">
							{{ tt.formattedMB(getUsage(zc, 'mediaconnect')) }}</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
</div>